import React from "react";
import { useTranslation } from "react-i18next";
import { Box, Typography } from "@mui/joy";
import PropTypes from "prop-types";
import NotificationsRoutes from "./NotificationsRoutes";
import { BOLayout } from "Components/layouts";

const Notifications = (props) => {
	const { t } = useTranslation();

	return <NotificationsRoutes />;
};

export default Notifications;
Notifications.propTypes = {};
Notifications.defaultProps = {};
