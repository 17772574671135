import React from "react";
import { useTranslation } from "react-i18next";
import { WhiteContainer } from "UI";
import { Content, Header } from "./partials";
import useAuthContainer from "stores/Auth";
import { query, collection, where } from "firebase/firestore";
import { db } from "utils/MyFirebase";
import Place from "models/places";

const PlaceList = (props) => {
	const { t } = useTranslation();
	const { user } = useAuthContainer.useContainer();
	const [places, setPlaces] = React.useState([]);
	const [loading, setLoading] = React.useState(false);
	const [error, setError] = React.useState(false);
	const PlaceEntity = new Place();

	const fetchPlaces = async () => {
		setLoading(true);
		if (user) {
			try {
				PlaceEntity.getPlaces(
					[where("uid", "==", user.uid)],
					(docs) => {
						setPlaces(docs);
					},
				);
			} catch (e) {
				setError(e.message);
			}
			setLoading(false);
		}
	};

	React.useEffect(() => {
		fetchPlaces();
	}, []);

	return (
		<WhiteContainer>
			<Header />
			<Content documents={places} loading={loading} error={error} />
		</WhiteContainer>
	);
};

export default PlaceList;
