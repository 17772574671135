import React from "react";
import items from "datas/places/places_cadre.json";
import { ChipCheckbox, FormSeparator } from "UI";
import { Input, FormControl, FormHelperText } from "@mui/joy";

const EventFrame = ({ setValue, register, action, selectItem, error }) => {
	return (
		<>
			<FormSeparator required title="frame">
				<FormControl>
					<ChipCheckbox
						items={items}
						register={register}
						action={action}
						selectItem={selectItem}
						setValue={setValue}
						fieldName="event_frame"
					/>
					{error && <FormHelperText>{error.message}</FormHelperText>}
				</FormControl>
			</FormSeparator>
		</>
	);
};

export default EventFrame;
