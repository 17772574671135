import React from "react";
import { useTranslation } from "react-i18next";
import { Box } from "@mui/joy";
import PropTypes from "prop-types";
import { PlaceLocationField, MapToogle, PlaceFilters } from "../partials";

const PlacesSearchForm = ({ setMapCenter, showMap, setShowMap }) => {
	const { t } = useTranslation();
	const [location, setLocation] = React.useState({
		coordinates: null,
		name: null,
	});

	React.useEffect(() => {
		if (setMapCenter && location?.coordinates) {
			setMapCenter(location?.coordinates);
		}
	}, [location, setMapCenter]); // Ajout de location et setMapCenter comme dépendances

	return (
		<>
			<Box
				sx={{
					background: "#eee",
					p: 2,
					display: "flex",
					alignItems: "center", // Correction : alignItems "center" au lieu de "space-between"
					gap: 2,
				}}
			>
				{showMap && (
					<PlaceLocationField
						setLocation={setLocation}
						location={location}
					/>
				)}
				{setShowMap && (
					<MapToogle
						show={showMap}
						toggle={() => setShowMap(!showMap)}
					/>
				)}
			</Box>
			<Box
				sx={{
					background: "#FAFAFA",
					p: 3,
				}}
			>
				<PlaceFilters />
			</Box>
		</>
	);
};

export default PlacesSearchForm;
PlacesSearchForm.propTypes = {
	setMapCenter: PropTypes.func.isRequired,
};
PlacesSearchForm.defaultProps = {};
