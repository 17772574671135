import React from "react";
import { Infos, Planning } from "../../modules";
import useFrontStore from "../../FrontStore";
import { Container } from "@mui/joy";

const Plannings = (props) => {
	const { eventDatas } = useFrontStore.useContainer();
	return (
		<Container maxWidth="md" sx={{ p: 0 }}>
			<Planning {...eventDatas} />
		</Container>
	);
};

export default Plannings;
