import Icons from "assets/icons/Icons";

const salleConfigOptions = {
	lumiereDuJour: (
		<Icons width={25} height={25} name="Pictos Weevapp_Lumière du jour" />
	),
	AccesPmr: <Icons width={25} height={25} name="Pictos Weevapp_Accès PMR" />,
};

export default salleConfigOptions;
