import React from "react";
import {
	IconButton,
	List,
	ListItem,
	Box,
	Typography,
	Alert,
	Stack,
} from "@mui/joy";
import { useDropzone } from "react-dropzone";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import { FileUpload, Delete, Info } from "@mui/icons-material";

const UploadFile = ({
	saveOnDrop,
	label,
	name,
	maxFiles,
	onChange,
	onDeleteItem,
	accept,
	error,
	files,
}) => {
	const { t } = useTranslation();

	const [acceptedFilesState, setAcceptedFilesState] = React.useState(
		files || []
	);
	const onDrop = (acc) => {
		setAcceptedFilesState(acc);
	};
	const {
		fileRejections,
		acceptedFiles,
		getRootProps,
		getInputProps,
		isDragActive,
	} = useDropzone({
		maxFiles,
		onDrop,
		accept,
	});

	React.useEffect(() => {
		if (!files || files.size === 0) {
			setAcceptedFilesState([]);
		}
	}, [files]);

	React.useEffect(() => {
		if (onChange) {
			onChange(acceptedFiles);
		}
	}, [acceptedFilesState, fileRejections]);

	return (
		<>
			<Box sx={{ mb: 1 }}>
				<Typography sx={{ fontSize: "1.2rem" }}>{label}</Typography>
			</Box>

			<Box
				sx={{
					textAlign: "center",
					border: `1px solid ${error ? "red" : "#eee"}`,
					borderRadius: "10px",
					transition: "ease 200ms",
					p: 2,
					"&:hover": {
						backgroundColor: "secondary.main",
					},
					backgroundColor: isDragActive && "secondary.main",
				}}
				{...getRootProps()}
			>
				<input {...getInputProps()} />
				<Stack
					direction="row"
					justifyContent="center"
					alignItems="center"
					spacing={2}
				>
					<FileUpload />
					{isDragActive ? (
						<Typography>{t("drop_file_here")}</Typography>
					) : (
						<Typography>
							{t("drop_file_or_click_to_select")}
						</Typography>
					)}
				</Stack>
				<Box sx={{ mt: 2, textAlign: "center" }}>
					<Typography variant="subtitle1">
						{t("max_file", { nbr: maxFiles })}
					</Typography>
					<Typography variant="subtitle1">
						{t("accepted_files_type", { types: accept })}
					</Typography>
				</Box>
			</Box>

			{error && (
				<Alert severity="error">
					{" "}
					<Typography color="danger">{error}</Typography>
				</Alert>
			)}

			{acceptedFilesState && acceptedFilesState.length > 0 && (
				<Box sx={{ mt: 2 }}>
					<Typography>{t("file_list")}</Typography>
					<List>
						{acceptedFilesState.map((file, index) => (
							<ListItem
								sx={{
									border: "1px solid #eee",
								}}
								key={file.path}
								secondaryAction={
									<IconButton
										onClick={() => {
											setAcceptedFilesState(
												acceptedFilesState.filter(
													(f, i) => i !== index
												)
											);

											if (onDeleteItem) {
												onDeleteItem(
													acceptedFilesState.filter(
														(f, i) => i !== index
													)
												);
											}
										}}
									>
										<Delete />
									</IconButton>
								}
							>
								<ListItem>{file.name}</ListItem>{" "}
								<ListItem>-</ListItem>
								<ListItem>{file.size} bytes</ListItem>
							</ListItem>
						))}
					</List>
				</Box>
			)}
		</>
	);
};

export default UploadFile;

UploadFile.defaultProps = {
	saveOnDrop: false,
	label: "Your file",
	accept: "image/jpeg",

	maxFiles: 7,
};
UploadFile.propTypes = {
	saveOnDrop: PropTypes.bool,
	accept: PropTypes.string,
	label: PropTypes.string,
	maxFiles: PropTypes.number,
};
