import React from "react";
import { createContainer } from "unstated-next";
import d from "./testDatas.json";

const useCVstore = createContainer((props) => {
	const [experiences, setExperiences] = React.useState(d?.experiences);
	const [formations, setFormations] = React.useState(d?.formations);

	const [contact, setContact] = React.useState(d?.contact);
	const [langs, setLangs] = React.useState(d?.langs);
	const [profil, setProfil] = React.useState(d?.profil);

	const [theme, setTheme] = React.useState(d?.theme || "classy");
	const [color, setColor] = React.useState("#68a7a8");
	const [themeEditor, setThemeEditor] = React.useState(false);

	React.useEffect(() => {
		const datas = {
			experiences,
			formations,
			contact,
			profil,
			langs,
			color,
			theme,
		};

		console.log(datas);
	}, [experiences, formations, contact, profil, langs, color, theme]);

	return {
		theme,
		formations,
		setFormations,
		color,
		setThemeEditor,
		themeEditor,
		setTheme,
		experiences,
		setColor,
		setExperiences,
		contact,
		setContact,
		langs,
		setLangs,
		profil,
		setProfil,
	};
});

export default useCVstore;

export const CVStoreContainer = (Component) => (props) => {
	return (
		<useCVstore.Provider>
			<Component {...props} />
		</useCVstore.Provider>
	);
};
