import FirestoreService from "services/firestoreServices";
import Helpers from "utils/Helpers";
import { where } from "firebase/firestore";

/**
 * Creates an instance of the Activities class.
 * @constructor
 * @param {string} params.event_id - Event ID of the event .
 * @param {Array} params.content - Activities content .
 * @param {string} params.status - activities status (active / inactive) .
 * @param {string} params.uid - User ID of the activities creator.
 * @param {Date} params.modified_at - Last modification date.
 * @param {Date} params.created_at - Creation date of the event.
 */

class Activities {
    constructor(params) {
        Object.assign(this, params);
        this.modified_at = new Date();

        this.firestoreService = new FirestoreService("activities");
    }

    async getActivitiesByEventId(id, realtime = false, onUpdate = null) {
        try {
            const activities = await this.firestoreService.getDocuments(
                [where("event_id", "==", id)],
                realtime,
                onUpdate,
            );
            if (activities[0]) {
                return activities[0];
            } else {
                return null;
            }
        } catch (error) {
            console.error("Failed to fetch activities:", error);
            throw error;
        }
    }

    async addActivities() {
        try {
            const activities = await this.firestoreService.addDocument(
                this.toJSON(),
            );
            return { id: activities.id, ...this.toJSON() };
        } catch (error) {
            console.error("Failed to add activities:", error);
            throw error;
        }
    }

    async deleteActivities(id) {
        try {
            await this.firestoreService.deleteDocument(id);
        } catch (error) {
            console.error("Failed to delete activities:", error);
            throw error;
        }
    }

    async updateActivities(id) {
        try {
            const updatedActivities =
                await this.firestoreService.updateDocument(
                    id,
                    this.toJSON(),
                    [],
                );
            return updatedActivities;
        } catch (error) {
            console.error("Failed to update activities:", error);
            throw error;
        }
    }

    async duplicateActivity(eventId, newEventId, uid) {
        try {
            // Fetch the original activity using getActivitiesByEventId
            const originalActivity = await this.getActivitiesByEventId(eventId);
            if (!originalActivity) {
                console.warn(
                    `No activity found for event ID ${eventId}. No duplication performed.`,
                );
                return null;
            }

            // Create a new activity object with the new event_id
            const newActivity = new Activities({
                ...originalActivity,
                event_id: newEventId,
                created_at: new Date(),
                uid,
                modified_at: new Date(),
            });

            // Save the new activity to Firestore
            const addedActivity = await newActivity.addActivities();
            return addedActivity;
        } catch (error) {
            console.error("Failed to duplicate activity:", error);
            throw error;
        }
    }

    toJSON() {
        let data = {};
        const properties = {
            event_id: this.event_id,
            content: this.content,
            status: this.status,
            uid: this.uid,
            modified_at: this.modified_at,
            created_at: this.created_at,
        };

        // Add only properties that are not undefined
        for (let key in properties) {
            if (properties[key] !== undefined) {
                data[key] = properties[key];
            }
        }

        return data;
    }
}

export default Activities;
