import React from "react";
import { useTranslation } from "react-i18next";
import { Box, Typography, FormControl, FormLabel, Input } from "@mui/joy";
import PropTypes from "prop-types";

const Phone = ({ register }) => {
	const { t } = useTranslation();

	return (
		<FormControl>
			<FormLabel>{t("phone")}</FormLabel>
			<Input {...register("phone")} />
		</FormControl>
	);
};

export default Phone;
Phone.propTypes = {};
Phone.defaultProps = {};
