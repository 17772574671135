import React from "react";
import { useTranslation } from "react-i18next";
import {
	Box,
	Typography,
	Modal,
	ModalOverflow,
	ModalDialog,
	Input,
	Button,
	IconButton,
	Link,
} from "@mui/joy";
import PropTypes from "prop-types";
import { useForm } from "react-hook-form";
import { Delete, OpenInNew } from "@mui/icons-material";
import TagsInput from "react-tagsinput";
import useFileUploader from "hooks/storage/useFileUploader";

const Editable = ({
	open,
	close,
	selected,
	saveDocument,
	deleteDocument,
	setSelected,
}) => {
	const { t } = useTranslation();
	const { register, handleSubmit, reset } = useForm();
	const [tags, setTags] = React.useState([]);
	const [file, setFile] = React.useState(null);
	const formRef = React.useRef(null);
	const { firebaseUploader, deleteFileFromStorage, uploading } =
		useFileUploader();

	React.useEffect(() => {
		if (selected) {
			console.log(selected.id);
			reset(selected);
			setTags(selected?.tags || []);
			setFile(null); // Assurez-vous que le fichier est réinitialisé lors de l'édition
		} else {
			reset();
			setTags([]);
			setFile(null); // Réinitialiser l'état du fichier lors de la création d'un nouveau document
		}
	}, [selected, reset]);

	const save = async (d) => {
		let fileUrl = null;

		if (file) {
			try {
				const uploadedFiles = await firebaseUploader(
					[file],
					"documents",
					[],
					"private",
				);
				fileUrl = uploadedFiles[0]?.url || null;
			} catch (error) {
				console.error("File upload failed", error);
				alert("Le téléchargement du fichier a échoué.");
				return;
			}
		}

		const clonedSelected = selected ? { ...selected } : {}; // Cloner l'objet selected

		saveDocument({
			...clonedSelected, // Applique les changements sur un clone du document sélectionné
			...d,
			tags,
			fileUrl: fileUrl || clonedSelected?.fileUrl || null, // Conserver l'URL du fichier existant
		});

		reset();
		setSelected(null); // Réinitialiser après la sauvegarde
		close();
	};

	const handleFileChange = (e) => {
		if (e.target.files.length > 0) {
			setFile(e.target.files[0]);
		}
	};

	const handleDelete = async () => {
		if (selected?.fileUrl) {
			try {
				await deleteFileFromStorage(selected.fileUrl); // Suppression du fichier dans Firebase Storage
			} catch (error) {
				console.error("Failed to delete file from storage", error);
			}
		}

		deleteDocument(selected.id); // Supprimer le document après la suppression du fichier
		close();
	};

	return (
		<Modal open={open} onClose={close}>
			<ModalOverflow>
				<ModalDialog>
					{selected && (
						<IconButton
							sx={{
								position: "absolute",
								right: 2,
								top: 2,
							}}
							type="button"
							color="danger"
							onClick={handleDelete} // Appel de la fonction handleDelete
						>
							<Delete />
						</IconButton>
					)}
					<Typography>{t("Documents")}</Typography>
					<form ref={formRef} onSubmit={handleSubmit(save)}>
						<Box
							sx={{
								mb: 2,
								display: "flex",
								flexDirection: "column",
								gap: 2,
							}}
						>
							<Input
								placeholder={t("name")}
								{...register("name")}
							/>
							<TagsInput
								inputProps={{
									className: "react-tagsinput-input",
									placeholder: t("add_tag"),
								}}
								value={tags}
								onChange={setTags}
							/>

							{/* Affichage du lien si fileUrl existe */}
							{selected?.fileUrl && (
								<Box>
									<Typography>
										{t("current_file")}:{" "}
										<Link
											href={selected.fileUrl}
											target="_blank"
											rel="noopener noreferrer"
										>
											{t("view_file")}
											<OpenInNew />
										</Link>
									</Typography>
								</Box>
							)}
							<Input type="file" onChange={handleFileChange} />
						</Box>
						<Button fullWidth type="submit" disabled={uploading}>
							{uploading
								? t("uploading")
								: selected
								? t("save")
								: t("create")}
						</Button>
					</form>
				</ModalDialog>
			</ModalOverflow>
		</Modal>
	);
};

export default Editable;

Editable.propTypes = {
	open: PropTypes.bool.isRequired,
	close: PropTypes.func.isRequired,
	selected: PropTypes.object,
	saveDocument: PropTypes.func.isRequired,
	deleteDocument: PropTypes.func.isRequired,
};

Editable.defaultProps = {
	selected: null,
};
