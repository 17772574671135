import React from "react";
import { Typography, ListItem, IconButton, Box, List, Button } from "@mui/joy";
import { Edit, Delete, Add } from "@mui/icons-material";
import { useTranslation } from "react-i18next";
import TeamBuildingModal from "./TeamBuildingModal";
import { v4 as uuidv4 } from "uuid";

const TeamBuilding = ({ setValue, datas, errors, register, control }) => {
	const { t } = useTranslation();
	const [team_building, setTeamBuilding] = React.useState(datas);
	const [isModalOpen, setIsModalOpen] = React.useState(false);
	const [editingTeamBuilding, setEditingTeamBuilding] = React.useState(null);

	React.useEffect(() => {
		if (team_building.length < 1) {
			if (datas.length > 0) {
				setTeamBuilding(datas);
			}
		}
	}, [datas]);

	const updateValues = (d) => {
		setValue("team_building", d, { shouldDirty: true });
	};

	const handleDelete = (index) => {
		const newRooms = team_building.filter((_, i) => i !== index);
		setTeamBuilding(newRooms);
		updateValues(newRooms);
	};

	const openModalToAdd = () => {
		setEditingTeamBuilding(null);
		setIsModalOpen(true);
	};

	const openModalToEdit = (team_building) => {
		setEditingTeamBuilding(team_building);
		setIsModalOpen(true);
	};

	const closeModal = () => {
		setIsModalOpen(false);
	};

	const saveTeamBuilding = (team_buildingData) => {
		if (editingTeamBuilding) {
			const updatedTeamBuilding = team_building.map(
				(team_building, index) =>
					team_building.id === editingTeamBuilding.id
						? team_buildingData
						: team_building,
			);

			updateValues(updatedTeamBuilding);
			setTeamBuilding(updatedTeamBuilding);
		} else {
			const newsTeamBuilding = [
				...team_building,
				{ id: uuidv4(), ...team_buildingData },
			];
			updateValues(newsTeamBuilding);
			setTeamBuilding(newsTeamBuilding); // Ajouter une nouvelle salle
		}
	};

	return (
		<Box sx={{ background: "white", p: 2, mt: 1, mb: 1 }}>
			<Typography sx={{ mb: 2 }} level="h4" component="h2">
				{t("TeamBuilding")}
			</Typography>

			<div>
				<Typography sx={{ mb: 1, mt: 2 }} component="h4">
					Liste des Teams Building
				</Typography>
				<List sx={{ width: { md: "350px", xs: "100%" } }}>
					{team_building.map((_team_building, index) => (
						<ListItem
							key={_team_building.id}
							sx={{
								p: 2,
								border: "1px solid #eee",
								mb: 1,
							}}
							endAction={
								<>
									<IconButton
										onClick={() =>
											openModalToEdit(_team_building)
										}
									>
										<Edit
											variant="container"
											color="primary"
										/>
									</IconButton>
									<IconButton
										onClick={() => handleDelete(index)}
									>
										<Delete color="danger" />
									</IconButton>
								</>
							}
						>
							{_team_building.nom}
						</ListItem>
					))}
				</List>
				<Button onClick={openModalToAdd}>
					<Add />
					Ajouter un Team Building
				</Button>

				<TeamBuildingModal
					isOpen={isModalOpen}
					onClose={closeModal}
					onSave={saveTeamBuilding}
					defaultValues={editingTeamBuilding} // Passer les valeurs par défaut pour l'édition
				/>
			</div>
		</Box>
	);
};

export default TeamBuilding;
