import React from "react";
import { Box, Avatar, Typography, Stack, IconButton } from "@mui/joy";
import { NavLink } from "react-router-dom";
import { Menu, ArrowBack } from "@mui/icons-material";
import { SavingDocumentLoader } from "../../../../UI";

const Item = ({ children }) => {
	return <Box>{children}</Box>;
};
const Header = ({
	userAccount,
	title,
	isMobile,
	setShowMenu,
	isDocumentStatusMonitoring,
	documentStatus,
	backTo,
}) => {
	return (
		<Box
			sx={(theme) => ({
				borderBottom: `1px solid #eee`,
			})}
		>
			<Stack
				sx={{ p: 2, pl: 3, pr: 3 }}
				direction="row"
				alignItems="center"
				justifyContent="space-between"
			>
				<Item>
					{!isMobile ? (
						<Stack direction="row" alignItems="center" spacing={2}>
							{backTo && (
								<IconButton
									variant="outlined"
									component={NavLink}
									to={backTo}
								>
									<ArrowBack />
								</IconButton>
							)}
							<Typography level="h5">{title}</Typography>
						</Stack>
					) : (
						<IconButton onClick={() => setShowMenu(true)}>
							<Menu />
						</IconButton>
					)}
				</Item>
				{isDocumentStatusMonitoring && (
					<Item>
						<SavingDocumentLoader status={documentStatus} />
					</Item>
				)}
				<Item>
					<Stack
						sx={(theme) => ({
							textDecoration: "none",
							"&:hover": {
								".MuiAvatar-root": {
									backgroundColor:
										theme.vars.palette.primary[600],
									color: "white",
								},
							},
						})}
						component={NavLink}
						to="/account"
						direction="row"
						alignItems="center"
						spacing={1}
					>
						<Avatar alt={userAccount?.email} />
						{!isMobile && (
							<Typography>{`${
								userAccount?.additionalDatas?.name || ""
							}  ${
								userAccount?.additionalDatas?.lastname || ""
							} `}</Typography>
						)}
					</Stack>
				</Item>
			</Stack>
		</Box>
	);
};

export default Header;
