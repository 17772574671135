import React from "react";
import { useTranslation } from "react-i18next";
import { Box, Typography, List, ListItem, Stack, IconButton } from "@mui/joy";
import PropTypes from "prop-types";
import { RemoveRedEye, Edit, Delete } from "@mui/icons-material";
import Event from "models/events";
import { useHistory, Link } from "react-router-dom";
import SimpleTable from "UI/Tables/SimpleTable/SimpleTable";

const UserEvents = ({ uid }) => {
	const TypographyStyle = {
		maxWidth: "100%",
		overflow: "hidden",
		textOverflow: "ellipsis",
	};

	const { t } = useTranslation();
	const EventEntity = new Event();
	const history = useHistory();
	const [deleting, setDeleting] = React.useState(false);

	const [events, setEvents] = React.useState([]);
	const getEvent = async () => {
		const events = await EventEntity.getEventsByUid(uid);

		try {
			if (events) {
				setEvents(events);
				console.log(events[0]);
			}
		} catch (e) {
			console.log(e);
		}
	};
	React.useEffect(() => {
		if (uid) {
			getEvent();
		}
	}, [uid]);

	const deleteEvent = async (id) => {
		setDeleting(id);
		if (window.confirm(t("sure_delete_this_event"))) {
			try {
				await EventEntity.deleteEvent(id);
				setEvents(events.filter((e) => e.id !== id));
			} catch (e) {
				console.log(e);
			}
		}
		setDeleting(false);
	};

	const columns = ["event_name", "event_type", "status", ""];
	const actions = [
		(d) => (
			<Stack direction={"row"} spacing={1}>
				<IconButton
					component={Link}
					to={`/my_event/${d.id}`}
					target={"_blank"}
				>
					<RemoveRedEye />
				</IconButton>
				<IconButton>
					<Delete onClick={() => deleteEvent(d.id)} />
				</IconButton>
				<IconButton
					component={Link}
					to={`/event/${d.id}/general`}
					target={"_blank"}
				>
					<Edit />
				</IconButton>
			</Stack>
		),
	];
	const fields = {
		event_name: (v) => <Typography sx={TypographyStyle}>{v}</Typography>,
		event_type: (v) => <Typography sx={TypographyStyle}>{t(v)}</Typography>,
		status: (v) => <Typography sx={TypographyStyle}>{t(v)}</Typography>,
	};

	return (
		<>
			<Typography>{t("x_events", { nbr: events?.length })}</Typography>
			<SimpleTable
				inputProps={{
					stickyHeader: true,
					stickyFooter: true,
					hoverRow: true,
					borderAxis: "both",
				}}
				style={{
					maxWidth: "100%",
					overflow: "auto",
					background: "white",
					borderRadius: "10px",
					mb: 2,
				}}
				head={columns}
				datas={events}
				fields={fields}
				actions={actions}
			/>{" "}
		</>
	);
};

export default UserEvents;
UserEvents.propTypes = {};
UserEvents.defaultProps = {};
