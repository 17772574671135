import React from "react";
import { InputText } from "../../../../../../components/UI";
import { useForm } from "react-hook-form";
import { Button, Typography } from "@mui/joy";
import { useTranslation } from "react-i18next";
import useEmailAuth from "../../../../hooks/useEmailAuth";

const RecoverForm = (props) => {
	const { t } = useTranslation();

	const { resetEmailSended, sendingResetEmail, resetPassword } =
		useEmailAuth();
	const { control, handleSubmit } = useForm();

	const submit = (datas) => {
		const { email } = datas;
		resetPassword(email);
	};

	return !resetEmailSended ? (
		<form onSubmit={handleSubmit(submit)}>
			<InputText
				type="text"
				style={{ mt: 1, mb: 1 }}
				control={control}
				placeholder="email"
				name="email"
				rules={{ required: true }}
			/>

			<Button
				type="submit"
				loading={sendingResetEmail}
				disabled={sendingResetEmail}
				fullWidth
				color="primary"
			>
				{t("send")}
			</Button>
		</form>
	) : (
		<Typography>{t("reset_email_sended")}</Typography>
	);
};

export default RecoverForm;
