import React from "react";
import { Controller } from "react-hook-form";
import {
	FormControl,
	FormLabel,
	Select,
	Option,
	Switch,
	Typography,
	Stack,
	Box,
} from "@mui/joy";
import { useTranslation } from "react-i18next";
import { SliderPicker } from "react-color";
import { GreenContainer } from "UI";

const EventThemeColor = ({ watch, setValue, getValues }) => {
	const { t } = useTranslation();
	const [themeColor, setThemeColor] = React.useState(
		getValues("theme_color"),
	);
	let darkMode = watch("theme_mode");
	let backgroundColor = watch("no_theme_color_background");
	const handleChangeComplete = (color, name) => {
		setThemeColor(color.hex);
		setValue("theme_color", color.hex);
	};

	return (
		<Stack spacing={3} sx={{ width: "450px", maxWidth: "100%" }}>
			<SliderPicker
				color={themeColor}
				onChangeComplete={(c) => handleChangeComplete(c, "theme_color")}
			/>

			<Typography
				sx={{ mt: 3 }}
				startDecorator={
					<Switch
						checked={backgroundColor}
						onChange={(e) => {
							setValue(
								"no_theme_color_background",
								e.target.checked,
							);
						}}
					/>
				}
			>
				{t("use_colored_background")}
			</Typography>

			<Typography
				startDecorator={
					<Switch
						checked={darkMode === "dark"}
						onChange={(v) => {
							setValue(
								"theme_mode",
								v.target.checked ? "dark" : "light",
							);
						}}
					/>
				}
			>
				{t("dark_mode")}
			</Typography>
		</Stack>
	);
};

export default EventThemeColor;
