import React from "react";
import { useTranslation } from "react-i18next";
import {
	Box,
	Typography,
	Input,
	Button,
	FormControl,
	FormLabel,
} from "@mui/joy";
import PropTypes from "prop-types";

const NameSearch = ({ register, save, setValue }) => {
	const { t } = useTranslation();

	return (
		<>
			<FormControl>
				<FormLabel>{t("search_by_name")}</FormLabel>
				<Input
					placeholder={t("name")}
					{...register("name_search")}
					type="text"
				/>
				<Button onClick={save}>{t("search")}</Button>
			</FormControl>
		</>
	);
};

export default NameSearch;
NameSearch.propTypes = {};
NameSearch.defaultProps = {};
