import React from "react";
import { ConfirmModal } from "../../../../../../components/UI";
import { Box, Stack, IconButton, Button } from "@mui/joy";
import {
	GridViewOutlined,
	FormatListBulletedOutlined,
	Domain,
	ListAlt,
} from "@mui/icons-material";
import { useTranslation } from "react-i18next";
import Helpers from "../../../../../../utils/Helpers";
import { useHistory, useParams, Link, useLocation } from "react-router-dom";
import { EventGrid, EventTable } from "./partials";
import Event from "models/events";
import useAuthContainer from "stores/Auth";

const Content = () => {
	const { user } = useAuthContainer.useContainer();
	const location = useLocation();
	const isModelList = location.pathname.split("/")[2] === "models";
	const { t } = useTranslation();
	const { placeID } = useParams();
	const navigate = useHistory();
	const [grid, setGrid] = React.useState(true);
	const [showConfirm, setShowConfirm] = React.useState(false);
	const [selectedId, setSelectedId] = React.useState(null);

	const [events, setEvents] = React.useState([]);
	const EventEntity = new Event();

	const updateEvent = (d, id) => {
		Object.assign(EventEntity, d);
		EventEntity.updateEvent(id);
	};

	const deleteEvent = (id) => {
		EventEntity.deleteEvent(id);
	};

	const getEvents = async () => {
		try {
			const EventEntity = new Event();

			const _events = await EventEntity.getEventsByUid(user.uid, {
				models: isModelList,
				placeID: placeID,
			});
			setEvents(_events);
		} catch (e) {
			console.log(e.message);
		}
	};
	React.useEffect(() => {
		getEvents();
	}, [placeID, isModelList]);

	const changeStatus = (e, id) => {
		const val = e.currentTarget.checked;
		const d = {
			status: val ? "active" : "inactive",
		};

		updateEvent(d, id);
		setEvents(Helpers.deepArrayUpdate(events, d, id));
	};

	const changeShared = (e, id) => {
		const val = e.target.checked;
		const d = {
			shared: val || false,
		};

		updateEvent(d, id);
		setEvents(Helpers.deepArrayUpdate(events, d, id));
	};

	const handleDeleteEvent = (id) => {
		deleteEvent(id);

		const _e = events.filter((e) => e.id !== id);
		setEvents(_e);
	};

	return events?.length > 0 ? (
		<>
			<Stack
				direction="row"
				justifyContent="center"
				spacing={2}
				sx={{
					display: !placeID && "none",
					position: "absolute",
					top: "20px",
					left: "25px",
				}}
			>
				<Button variant="outlined" component={Link} to="/event/models">
					<ListAlt />

					{t("all_models")}
				</Button>
				<Button
					variant="outlined"
					component={Link}
					to={`/places/view/${placeID}`}
				>
					<Domain /> {t("show_place_view")}
				</Button>
			</Stack>
			<Stack
				sx={{
					position: "absolute",
					top: "14px",
					right: "2px",
					".MuiIconButton-root": {
						background: "none",
					},
					gap: "8px",
					margin: "7px",
					justifyContent: "flex-end",
				}}
				direction="row"
			>
				<IconButton
					color={!grid ? "primary" : "neutral"}
					onClick={() => setGrid(false)}
				>
					<FormatListBulletedOutlined />
				</IconButton>
				<IconButton
					color={grid ? "primary" : "neutral"}
					onClick={() => setGrid(true)}
				>
					<GridViewOutlined />{" "}
				</IconButton>
			</Stack>
			<Box sx={{ mt: 7 }}>
				{grid ? (
					<EventGrid
						events={events}
						changeStatus={changeStatus}
						t={t}
						navigate={(d) => navigate.push(d)}
						setShowConfirm={setShowConfirm}
						setSelectedId={setSelectedId}
						Helpers={Helpers}
						isModelList={isModelList}
						changeShared={changeShared}
					/>
				) : (
					<EventTable
						events={events}
						changeStatus={changeStatus}
						changeShared={changeShared}
						t={t}
						navigate={(d) => navigate.push(d)}
						setShowConfirm={setShowConfirm}
						setSelectedId={setSelectedId}
						Helpers={Helpers}
						isModelList={isModelList}
					/>
				)}
				<ConfirmModal
					show={showConfirm}
					close={() => setShowConfirm(false)}
					action={() => handleDeleteEvent(selectedId)}
					title="confirmation"
					message="delete_event_confirmation"
					action_text="im_sure"
				/>
			</Box>
		</>
	) : null;
};

export default Content;
