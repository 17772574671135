import React from "react";
import { createContainer } from "unstated-next";
import Helpers from "../../../utils/Helpers";

const useBuilderContainer = createContainer((props) => {
	const [contentState, setContentState] = React.useState(null);
	const [selected, setSelected] = React.useState(null);
	const [config, setConfig] = React.useState(null);
	const [createElementType, setCreateElementType] = React.useState(null);
	const [confirmDelete, setConfirmDelete] = React.useState(null);
	const [parent, setParent] = React.useState(null);
	const [mainParent, setMainParent] = React.useState(null);

	const deleteElement = (id) => {
		setContentState(Helpers.deepArrayDelete(contentState, id));
	};

	return {
		setConfig,
		parent,
		mainParent,
		setMainParent,
		setParent,
		createElementType,
		setCreateElementType,
		config,
		contentState,
		deleteElement,
		confirmDelete,
		setConfirmDelete,
		setContentState,
		selected,
		setSelected,
	};
});

export default useBuilderContainer;

export const BuilderStoreContainer = (Component) => (props) => {
	return (
		<useBuilderContainer.Provider>
			<Component {...props} />
		</useBuilderContainer.Provider>
	);
};
