import React from "react";
import { Experiences, Formations, Contact, Langs, Profil } from "../builders";
import useCVContainer from "../../CVStore";
import { Box } from "@mui/joy";

const BuilderContainer = (props) => {
	const {
		experiences,
		setExperiences,
		formations,
		setFormations,
		contact,
		setContact,
		langs,
		setLangs,
		profil,
		setProfil,
	} = useCVContainer.useContainer();

	return (
		<Box>
			<Profil profil={profil} setProfil={setProfil} />
			<Experiences
				experiences={experiences}
				setExperiences={setExperiences}
			/>
			<Formations formations={formations} setFormations={setFormations} />
			<Contact contact={contact} setContact={setContact} />
			<Langs langs={langs} setLangs={setLangs} />
		</Box>
	);
};

export default BuilderContainer;
