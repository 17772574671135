import React from "react";
import { useForm, Controller } from "react-hook-form";
import {
	Box,
	Button,
	Radio,
	RadioGroup,
	FormControl,
	FormLabel,
	Stack,
	Typography,
} from "@mui/joy";
import UserIcon from "@mui/icons-material/Person";
import PartnerIcon from "@mui/icons-material/Business";
import AdminIcon from "@mui/icons-material/AdminPanelSettings";
import { useTranslation } from "react-i18next";

const roleOptions = [
	{ value: "user", label: "User", icon: <UserIcon /> },
	{ value: "partner", label: "partner", icon: <PartnerIcon /> },
	{ value: "admin", label: "admin", icon: <AdminIcon /> },
];

const Role = ({ formDatas, setFormDatas, nextStep }) => {
	const { control, handleSubmit, watch } = useForm();
	const roleWatched = watch("role");

	const onSubmit = (data) => {
		if (data?.role) {
			setFormDatas({
				...formDatas,
				role: data.role,
				isApproved: data.role === "partner" || data.role === "admin",
			});
			nextStep();
		}
	};
	const { t } = useTranslation();

	return (
		<form onSubmit={handleSubmit(onSubmit)}>
			<FormControl>
				<Controller
					name="role"
					control={control}
					defaultValue={formDatas?.role}
					render={({ field }) => (
						<RadioGroup
							{...field}
							row
							sx={{
								display: "flex",
								flexDirection: "row",

								justifyContent: "center",
								gap: 2,
							}}
						>
							{roleOptions.map((option) => (
								<Box
									key={option.value}
									component={Button}
									variant={
										field.value === option.value
											? "solid"
											: "outlined"
									}
									sx={(theme) => ({
										fontSize: "30px",
										p: 4,
									})}
									onClick={() => field.onChange(option.value)}
								>
									{option.icon}
									<Typography>{t(option.label)}</Typography>
									<Radio
										value={option.value}
										checked={field.value === option.value}
										sx={{ display: "none" }}
									/>
								</Box>
							))}
						</RadioGroup>
					)}
				/>
			</FormControl>
			<Box sx={{ display: "flex", justifyContent: "right" }}>
				<Button
					disabled={!roleWatched}
					type="submit"
					variant="solid"
					sx={{ marginTop: 2 }}
				>
					{t("next")}
				</Button>
			</Box>
		</form>
	);
};

export default Role;
