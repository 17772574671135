import React from "react";
import SVG from "react-inlinesvg";

// Dynamique : charge tous les SVG du dossier assets/icons
const importAll = (r) => {
  let icons = {};
  r.keys().forEach((item) => {
    icons[item.replace("./", "").replace(".svg", "")] = r(item);
  });
  return icons;
};

// Utilise require.context pour charger tous les fichiers SVG dans le répertoire
const icons = importAll(require.context("./", false, /\.svg$/));

const Icons = ({ name, color, width = 24, height = 24, filter }) => {
  const iconPath = icons[name];
  if (!iconPath) {
    return <div>Icon not found</div>;
  }

  // Applique les styles (comme la couleur, taille) au SVG une fois chargé
  return (
    <SVG
      src={iconPath}
      preProcessor={(code) =>
        code.replace(/fill=".*?"/g, `fill="${color || "currentColor"}"`)
      }
      style={{ width: `${width}px`, height: `${height}px`, filter }}
    />
  );
};

export default Icons;
