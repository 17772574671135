import React from "react";
import { Switch, Route, useHistory, useLocation } from "react-router-dom";
import { Login, ResetPassword, CreateAccount, Informations } from "./views";
import useAuthContainer from "../../stores/Auth";

export const AuthentificationRoutes = (props) => {
  const { user, userAccount } = useAuthContainer.useContainer();
  const navigate = useHistory();
  const location = useLocation();

  React.useEffect(() => {
    if (userAccount && user && location.pathname !== "/login/informations") {
      navigate.push("/dashboard");
    }

    if (userAccount && user && userAccount?.additionnalDatasAdded) {
      navigate.push("/dashboard");
    }
  }, [user, userAccount]);

  return (
    <>
      <Switch>
        <Route exact path="/login">
          <Login />
        </Route>
        <Route path="/login/reset-password">
          <ResetPassword />
        </Route>
        <Route path="/login/create-account">
          <CreateAccount />
        </Route>
        <Route path="/login/informations">
          <Informations />
        </Route>
      </Switch>
    </>
  );
};
