import React from "react";
import useGoogleAuth from "../../hooks/useGoogleAuth";
import { FcGoogle } from "react-icons/fc";
import { Button, Stack, Typography } from "@mui/joy";
import { Google } from "@mui/icons-material";

import { useTranslation } from "react-i18next";

const GoogleAuth = () => {
  const { t } = useTranslation();
  const { signIn, signingIn } = useGoogleAuth();
  return (
    <Button
      variant="outlined"
      sx={{ mb: 1 }}
      color="neutral"
      loading={signingIn}
      onClick={() => signIn()}
      fullWidth
      loadingPosition="center"
    >
      <Stack
        direction="row"
        justifyContent="center"
        alignItems="center"
        spacing={2}
      >
        <FcGoogle />
        <Typography>{t("signin_with_google")}</Typography>
      </Stack>
    </Button>
  );
};

export default GoogleAuth;
