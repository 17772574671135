import React from "react";
import { useTranslation } from "react-i18next";
import { Box, Typography, Button } from "@mui/joy";
import PropTypes from "prop-types";
import { Filters } from "forms/admin/users";

const UserFilters = ({ setFilters, filters }) => {
	const { t } = useTranslation();

	return (
		<>
			<Filters setFilters={setFilters} filters={filters} />
		</>
	);
};

export default UserFilters;
UserFilters.propTypes = {};
UserFilters.defaultProps = {};
