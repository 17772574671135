import React from "react";
import { Switch, Route, redirect } from "react-router-dom";
import { Dashboard } from "./views";

export const DashboardRoutes = (props) => {
  return (
    <Switch>
      <Route exact path="/">
        <Dashboard />
      </Route>
      <Route path="/dashboard">
        <Dashboard />
      </Route>
    </Switch>
  );
};
