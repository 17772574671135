import React from "react";
import { useTranslation } from "react-i18next";
import { Box, Typography, Snackbar, Grid, Button, Stack } from "@mui/joy";
import PropTypes from "prop-types";
import {
	Informations,
	Contacts,
	Notes,
	Invoicing,
	Documents,
	PlaceGroup,
	PlaceUser,
	PlaceVisibility,
	PlaceUserFiles,
} from "forms/admin/places/editForm";
import Place from "models/places";
import PrivatePlace from "models/privatePlace";
import { WhiteContainer } from "UI";
import { ArrowBack, Delete } from "@mui/icons-material";
import { useParams, Link, useHistory } from "react-router-dom";

const PlaceDetails = (props) => {
	const { placeID } = useParams();
	const { t } = useTranslation();
	const history = useHistory();
	const PlaceEntity = new Place();
	const PrivatePlaceEntity = new PrivatePlace(placeID, {});
	const [datas, setDatas] = React.useState(null);
	const [privateDatas, setPrivateDatas] = React.useState(null);
	const [message, setMessage] = React.useState({ open: false, message: "" });
	const [deleting, setDeleting] = React.useState(false);

	const getPlace = async () => {
		const place = await PlaceEntity.getPlace(placeID);

		setDatas(place);

		try {
			const privateDatas = await PrivatePlaceEntity.getPrivateDocument(
				"datas",
			);
			setPrivateDatas(privateDatas);
		} catch (e) {
			setMessage({
				open: true,
				color: "danger",
				message: e.message,
				autoHideDuration: 2000,
			});
		}
	};

	const removePlace = async (id) => {
		setDeleting(true);
		if (window.confirm(t("sure_to_delete_this_place"))) {
			try {
				await PlaceEntity.deletePlace(id);
				history.push("/admin/places/");
			} catch (e) {
				console.error(e);
			}
		}
		setDeleting(false);
	};

	React.useEffect(() => {
		getPlace();
	}, [placeID]);

	return (
		<>
			<Stack
				sx={{ mb: 1 }}
				direction="row"
				justifyContent="space-between"
			>
				<Button component={Link} to="/admin/places/">
					<ArrowBack />
					{t("back_to_places_list")}
				</Button>
				<Button
					loading={deleting}
					disabled={deleting}
					onClick={() => removePlace(placeID)}
					color="danger"
				>
					<Delete />
					{t("delete")}
				</Button>
			</Stack>

			<Informations placeId={placeID} datas={datas} />
			<Contacts
				contacts={privateDatas?.contacts || []}
				placeId={placeID}
			/>
			<Documents
				placeId={placeID}
				documents={privateDatas?.documents || []}
			/>
			<WhiteContainer title="visibility">
				<PlaceVisibility place={datas} />
			</WhiteContainer>

			<Notes notes={privateDatas?.notes || []} placeId={placeID} />
			<Invoicing invoice={privateDatas?.facturation} placeId={placeID} />
			<PlaceUserFiles uid={datas?.uid} placeID={placeID} />

			<WhiteContainer title="users">
				<PlaceUser place={datas} />
			</WhiteContainer>
			<WhiteContainer title="group">
				<PlaceGroup groupId={datas?.groupId} place={datas} />
			</WhiteContainer>
		</>
	);
};

export default PlaceDetails;

PlaceDetails.propTypes = {};
PlaceDetails.defaultProps = {};
