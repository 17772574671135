import React from "react";
import { useTranslation } from "react-i18next";
import { Switch, Box, Typography } from "@mui/joy";
import PropTypes from "prop-types";
import Place from "models/places";

const PlaceVisibility = ({ place }) => {
	const PlaceEntity = new Place();
	const { t } = useTranslation();
	const [visible, setVisible] = React.useState(false);

	React.useEffect(() => {
		setVisible(place?.publish || false);
	}, [place]);

	return (
		<>
			<Typography
				endDecorator={
					<Switch
						checked={visible}
						onChange={(e) => {
							let v = e.target.checked;
							setVisible(v);
							PlaceEntity.publish = v || false;
							if (place?.id) {
								PlaceEntity.updatePlace(place.id);
							}
						}}
					/>
				}
			>
				{visible ? t("visible") : t("hidden")}
			</Typography>
		</>
	);
};

export default PlaceVisibility;
PlaceVisibility.propTypes = {};
PlaceVisibility.defaultProps = {};
