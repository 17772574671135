import React from "react";
import { useTranslation } from "react-i18next";
import { Box, Typography, Button, IconButton } from "@mui/joy";
import PropTypes from "prop-types";
import { View, Editable } from "./partials";
import User from "models/user";
import { Edit, Delete, Close } from "@mui/icons-material";

const PlaceUser = ({ place }) => {
	const { t } = useTranslation();
	const UserEntity = new User();
	const [user, setUser] = React.useState(null);
	const [editable, setEditable] = React.useState(false);
	const close = () => setEditable(false);
	const open = () => setEditable(true);

	const getUser = async () => {
		try {
			const user = await UserEntity.getUser(place.uid);
			if (user) {
				setUser({
					value: user.id,
					label: `${user.email} - ${
						user?.additionalDatas?.name || ""
					} ${user?.additionalDatas?.lastname || ""}`,
				});
			}
		} catch (e) {
			console.error(e);
		}
	};

	React.useEffect(() => {
		if (place && user === null) {
			getUser();
		}
	}, [place]);

	return (
		<>
			{editable ? (
				<Editable setUser={setUser} placeID={place.id} close={close} />
			) : (
				<View user={user} />
			)}

			{!editable && (
				<Button onClick={open}>
					<Edit />
					{t("edit")}
				</Button>
			)}
		</>
	);
};

export default PlaceUser;
PlaceUser.propTypes = {};
PlaceUser.defaultProps = {};
