import React from "react";
import { Box, Modal, ModalDialog, ModalOverflow, Typography } from "@mui/joy";

const ElementModal = ({ t, setSelectedElement, selectedElement, style }) => {
	return (
		<Modal
			open={Boolean(selectedElement)}
			onClose={() => setSelectedElement(null)}
		>
			<ModalOverflow sx={{ p: "0 !important" }}>
				<ModalDialog
					sx={{
						background:
							style?.theme_mode === "dark" ? "#222" : "white",
						borderRadius: "0px !important",
						p: 0,
						margin: "0 !important",
						position: "absolute !important",
						maxWidth: "100%",
						width: "100%",
						top: "0",
						".MuiTypography-root": {
							color:
								style?.theme_mode === "dark"
									? "white"
									: "initial",
						},
					}}
				>
					<Box
						sx={{
							display: !selectedElement?.image && "none",
							background: `url(${
								selectedElement?.image?.full ||
								selectedElement?.image
							}) center center / cover`,
							height: "211px",
							width: "100%",
						}}
					></Box>
					<Box sx={{ p: 2 }}>
						<Typography sx={{ fontWeight: "bold", mb: 1 }}>
							{selectedElement?.title}
						</Typography>
						{(selectedElement?.start_hour ||
							selectedElement?.end_hour) && (
							<Typography>
								<span style={{ color: style?.theme_color }}>
									{t("hours")}
								</span>{" "}
								:{" "}
								{selectedElement?.start_hour.replace(":", "h")}
								{selectedElement?.end_hour &&
									` - ${selectedElement?.end_hour.replace(
										":",
										"h",
									)}`}
							</Typography>
						)}
						<Typography>{selectedElement?.description}</Typography>
					</Box>
				</ModalDialog>
			</ModalOverflow>
		</Modal>
	);
};

export default ElementModal;
