import React from "react";
import { Typography, Box } from "@mui/joy";
import { useTranslation } from "react-i18next";
import useMediaQuery from "@mui/material/useMediaQuery";

const WhiteContainer = ({ title, style, ...props }) => {
	const matches = useMediaQuery("(max-width:600px)");
	const { t } = useTranslation();
	return (
		<Box
			sx={{
				borderRadius: "5px",
				position: "relative",
				background: "#FFF",
				p: !matches ? 3 : 2,
				mb: !matches ? 3 : 2,
				boxShadow: " 4px 4px 15px 0px rgba(0, 0, 0, 0.05)",
				...style,
			}}
		>
			{title && (
				<Typography sx={{ mb: 3 }} level="h5">
					{t(title)}
				</Typography>
			)}
			{props.children}
		</Box>
	);
};

export default WhiteContainer;
