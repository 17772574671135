import React from "react";
import { Infos } from "../../modules";
import useFrontStore from "../../FrontStore";
import { Container, Box, Typography } from "@mui/joy";
import { useTranslation } from "react-i18next";

const UsefullInfos = (props) => {
	const { t } = useTranslation();
	const { eventDatas } = useFrontStore.useContainer();
	const image = eventDatas?.usefull_infos_image;
	const dark = eventDatas?.style?.theme_mode === "dark";
	return (
		<Container maxWidth="md" sx={{ p: 0 }}>
			<Box
				sx={{
					height: "153px",
					width: "100%",
					position: "relative",
					background: `url(${
						image?.full || image
					}) center center / cover`,
				}}
			>
				<Box
					sx={{
						zIndex: 1,
						position: "absolute",
						top: "0",
						left: "0",
						background: image
							? "rgba(0,0,0,50%)"
							: dark
							? "#444"
							: "#F1F1F1",
						height: "100%",
						width: "100%",
					}}
				></Box>
				<Box
					sx={{
						position: "relative",
						zIndex: 2,
						height: "100%",
						display: "flex",
						flexDirection: "column",
						alignItems: "center",
						justifyContent: "center",
						".MuiTypography-root": {
							color: image ? "white" : dark ? "white" : "initial",
							textAlign: "center",
						},
					}}
				>
					<Typography sx={{ fontSize: "22px" }}>
						{t("usefull_infos")}
					</Typography>
				</Box>
			</Box>
			<Box sx={{ p: 2 }}>
				<Infos {...eventDatas} />
			</Box>
		</Container>
	);
};

export default UsefullInfos;
