import React from "react";
import { useTranslation } from "react-i18next";
import { Box, Typography, Button } from "@mui/joy";
import { Add } from "@mui/icons-material";
import { Link } from "react-router-dom";

const Header = (props) => {
	const { t } = useTranslation();

	return (
		<>
			<Button sx={{ mb: 2 }} component={Link} to="/places/create">
				<Add /> {t("create_new_place")}
			</Button>
		</>
	);
};

export default Header;
