import React from "react";
import { useTranslation } from "react-i18next";
import { Box, Typography } from "@mui/joy";
import PropTypes from "prop-types";
import AdminRoutes from "./AdminRoutes";
import useAuthContainer from "stores/Auth";
import { useHistory, useLocation } from "react-router-dom";
import { BOLayout } from "Components/layouts";

const Admin = (props) => {
	const location = useLocation();
	const title = location.pathname.split("/")[2] || "admin";
	const { userAccount } = useAuthContainer.useContainer();
	const { t } = useTranslation();
	const history = useHistory();

	React.useEffect(() => {
		if (userAccount && userAccount?.role !== "admin") {
			history.replace("/");
			alert(t("not_authorize_to_acces_admin"));
		}
	}, [userAccount]);

	return (
		<>
			<BOLayout admin title={title}>
				<AdminRoutes />
			</BOLayout>
		</>
	);
};

export default Admin;
Admin.propTypes = {};
Admin.defaultProps = {};
