import React from "react";
import { Box, Typography } from "@mui/joy";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";

const CreateAccountFooter = () => {
	const { t } = useTranslation();

	return (
		<>
			<Box sx={{ textAlign: "center", mt: 2 }}>
				<Typography>
					{t("registerd_yet")}? <Link to="/login">{t("login")}</Link>
				</Typography>
			</Box>
		</>
	);
};

export default CreateAccountFooter;
