import React from 'react'
import {
	Dialog,
	DialogTitle,
	DialogContent,
	DialogActions,
	Button,
} from '@mui/material'
import PropTypes from 'prop-types'

const SimpleDialog = ({
	title,
	buttonCancel,
	content,
	close,
	open,
	confirm,
	handleOk,
	buttonOk,
}) => {
	return (
		<Dialog open={open} onClose={!confirm && close}>
			<DialogTitle>{title}</DialogTitle>
			<DialogContent dividers>{content}</DialogContent>
			<DialogActions>
				<Button autoFocus onClick={close}>
					{buttonCancel}
				</Button>
				<Button variant="contained" color="secondary" onClick={handleOk}>
					{buttonOk}
				</Button>
			</DialogActions>
		</Dialog>
	)
}

export default SimpleDialog

SimpleDialog.propTypes = {
	title: '',
	buttonCancel: 'Cancel',
	content: 'HELLO',
	open: false,
	buttonOk: 'ok',
}
SimpleDialog.defaultProps = {
	title: PropTypes.string,
	buttonCancel: PropTypes.string,
	close: PropTypes.func,
	open: PropTypes.bool,
	handleOk: PropTypes.func,
	buttonOk: PropTypes.string,
}
