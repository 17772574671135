import * as React from "react";
import Button from "@mui/joy/Button";
import Card from "@mui/joy/Card";
import CardContent from "@mui/joy/CardContent";
import CardActions from "@mui/joy/CardActions";
import CircularProgress from "@mui/joy/CircularProgress";
import Typography from "@mui/joy/Typography";
import SvgIcon from "@mui/joy/SvgIcon";
import PropTypes from "prop-types";
import { HolidayVillage } from "@mui/icons-material";

export default function StatInfoCard({
	icon,
	number,
	texts,
	actions,
	progress,
}) {
	return (
		<Card variant="solid" color="primary" invertedColors>
			<CardContent orientation="horizontal">
				<CircularProgress size="lg" determinate value={progress}>
					{icon}
				</CircularProgress>
				<CardContent>
					<Typography level="body-md">{texts?.title}</Typography>
					<Typography level="h2">{number}</Typography>
				</CardContent>
			</CardContent>
			<CardActions sx={{ display: "block" }}>
				{texts?.button_1 && (
					<Button
						variant="soft"
						size="sm"
						fullWidth={false}
						onClick={actions.action_1}
					>
						{texts.button_1}
					</Button>
				)}
			</CardActions>
		</Card>
	);
}

StatInfoCard.propTypes = {};
StatInfoCard.defaultProps = {
	icon: <HolidayVillage />,
	number: 0,
	texts: {
		title: "",
		button_1: "",
	},
	progress: 0,
};
