import React from "react";
import { Box, Stack, CircularProgress, Typography } from "@mui/joy";
import { useTranslation } from "react-i18next";

const WhiteBackdrop = ({ text, show }) => {
	const { t } = useTranslation();
	return (
		<Box
			sx={{
				display: show ? "flex" : "none",
				justifyContent: "center",
				alignItems: "center",
				top: 0,
				left: 0,
				right: 0,
				bottom: 0,
				position: "fixed",
				background: "rgba(255,255,255,90%)",
				zIndex: 20000000000000,
			}}
		>
			<Stack
				direction="column"
				alignItems="center"
				justifyContent="center"
				gap={2}
				sx={{ p: 2 }}
			>
				<CircularProgress
					sx={{ display: "block" }}
					variant="solid"
					size="lg"
				/>
				<Typography level="h3">{t(text)}</Typography>
			</Stack>
		</Box>
	);
};

export default WhiteBackdrop;
