import React from "react";
import { createContainer } from "unstated-next";

const useUsefullInfosStore = createContainer((props) => {
	const [docStatus, setDocStatus] = React.useState();
	const [editor, setEditor] = React.useState(null);
	const [usefullInfosState, setUsefullInfosState] = React.useState(null);
	return {
		docStatus,
		usefullInfosState,
		setUsefullInfosState,
		editor,
		setEditor,
		setDocStatus,
	};
});

export default useUsefullInfosStore;

export const UsefullInfosStoreContainer = (Component) => (props) => {
	return (
		<useUsefullInfosStore.Provider>
			<Component {...props} />
		</useUsefullInfosStore.Provider>
	);
};
