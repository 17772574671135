import React from "react";
import EventRoutes from "./EventRoutes";
import { EventStoreContainer } from "./EventStore";

const Event = (props) => {
	return (
		<>
			<EventRoutes />
		</>
	);
};

export default EventStoreContainer(Event);
