import React from "react";
import {
	Input,
	Stack,
	Box,
	Button,
	Typography,
	CircularProgress,
	IconButton,
} from "@mui/joy";

import {
	AddPhotoAlternate,
	Delete,
	Description,
	UploadFile,
} from "@mui/icons-material";
import useFileUploader from "../../../hooks/storage/useFileUploader";
import { ButtonActionConfirm } from "../Modals";
import { useTranslation } from "react-i18next";
import { useSnackbar } from "notistack";
import Resizer from "react-image-file-resizer";

const ImageUploader = ({
	labelPure,
	imageUrl,
	hideLabel,
	label,
	onUploaded,
	typeFile,
	height,
	width,
	path,
	onDelete,
	confirmDeletion,
	toolTipMessages,
	compressImage,
}) => {
	const { t } = useTranslation();
	const [url, setUrl] = React.useState(imageUrl);
	const { enqueueSnackbar } = useSnackbar();

	const [uploading, setUploading] = React.useState(false);
	const { firebaseUploader } = useFileUploader();
	const [deleteConfirmAnchor, setDeleteConfirmAnchor] = React.useState(null);

	React.useEffect(() => {
		setUrl(imageUrl);
	}, [imageUrl]);

	const resizeFile = (file) =>
		new Promise((resolve) => {
			Resizer.imageFileResizer(
				file,
				compressImage,
				compressImage,
				"JPEG",
				80,
				0,
				(uri) => {
					resolve(uri);
				},
				"file",
			);
		});

	const handleChange = async (e) => {
		setUploading(true);

		let file = e.target.files[0];
		// resize image
		if (compressImage) {
			file = await resizeFile(e.target.files[0]);
			console.log(file);
		}

		if (file && file.size > 0) {
			const uploaded = await firebaseUploader([file], path, [labelPure]);

			if (uploaded) {
				if (onUploaded) onUploaded(uploaded);
				setUrl(uploaded[0].url);
				enqueueSnackbar(t("file_succesfully_modify"), {
					variant: "success",
				});
			}
		}
		setUploading(false);
	};

	const handleDelete = () => {
		if (onDelete) {
			onDelete();
		}
		setUrl(null);
	};

	return (
		<Stack
			direction="column"
			spacing={1}
			sx={(theme) => ({
				".MuiPaper-root": {
					height: height ? height : 100,
					width: { sm: width ? width : 150, xs: "100%" },
				},
			})}
		>
			{!hideLabel && <Typography variant="subtitle1">{label}</Typography>}
			<Box
				sx={(theme) => ({
					border: "1px solid #eee",
					height: height ? height : 100,
					width: { sm: width ? width : 150, xs: "100%" },
					position: "relative",
					borderRadius: "15px",
					"&:hover": {
						opacity: 0.8,
						cursor: "pointer",
					},
				})}
				variant="outlined"
			>
				{url && (
					<>
						<IconButton
							onClick={(e) =>
								confirmDeletion
									? setDeleteConfirmAnchor(e.currentTarget)
									: handleDelete()
							}
							sx={{
								position: "absolute",
								zIndex: 2,
								right: "-8px",
								top: "-8px",
								p: 1,
								transition: "ease 200ms",
								"&:hover": {
									p: 2,
									backgroundColor: "primary.main",
									".MuiSvgIcon-root": {
										color: "primary.contrastText",
									},
								},
								backgroundColor: "primary.contrastText",
							}}
						>
							<Delete />
						</IconButton>
						{confirmDeletion && (
							<ButtonActionConfirm
								action={handleDelete}
								cancelLabel={confirmDeletion?.cancel}
								okLabel={confirmDeletion?.ok}
								close={() => setDeleteConfirmAnchor(null)}
								anchor={deleteConfirmAnchor}
								actionMessage={confirmDeletion?.text}
							/>
						)}
					</>
				)}
				<Box
					component={
						!typeFile ? "label" : typeFile && !url ? "label" : ""
					}
					htmlFor={labelPure}
					sx={{
						display: "flex",
						alignItems: "center",
						height: "100%",
						cursor: "pointer",
					}}
				>
					{uploading ? (
						<CircularProgress
							sx={{ display: "block", m: "0 auto" }}
						/>
					) : (
						<Box
							sx={{
								borderRadius: "15px",
								background:
									url &&
									`url(${url}) no-repeat center center / cover`,
								justifyContent: "center",
								display: "flex",
								height: "100%",
								width: "100%",
								flexDirection: "column",
								alignItems: "center",
							}}
						>
							{typeFile && url && (
								<Stack
									direction="column"
									justifyContent="center"
									alignItems="center"
								>
									<Typography sx={{ mb: 1 }}>
										<Button
											component="label"
											htmlFor={labelPure}
										>
											<UploadFile sx={{ mr: 1 }} />{" "}
											{t("update_file")}
										</Button>
									</Typography>
									<Typography>
										<a target="_blank" href={`${url}`}>
											{t("display_file")}
										</a>
									</Typography>
								</Stack>
							)}
							{!url && (
								<>
									<Box>
										{!typeFile ? (
											<AddPhotoAlternate
												sx={{
													color: "#444",
													fontSize: "50px",
												}}
											/>
										) : (
											<Description
												sx={{
													color: "#444",
													fontSize: "50px",
												}}
											/>
										)}
									</Box>
									<Typography variant="subtitle1">
										{label}
									</Typography>
								</>
							)}
						</Box>
					)}
				</Box>
			</Box>
			<Input
				onChange={handleChange}
				accept="image/*"
				id={labelPure}
				type="file"
				sx={{ display: "none" }}
			/>
		</Stack>
	);
};

export default ImageUploader;
