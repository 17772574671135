import React from "react";
import { useTranslation } from "react-i18next";
import { Box, Typography, ListItem, List } from "@mui/joy";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import { WhiteContainer } from "UI";

const PlacesList = ({ places }) => {
	const { t } = useTranslation();

	return (
		<WhiteContainer title={"group_places"}>
			<Typography>
				{t("x_places_in_this_group", { nbr: places.length })}
			</Typography>
			{places.map((place) => (
				<ListItem sx={{ border: "1px solid #eee", m: 1, p: 2 }}>
					<Typography>
						<Link to={`/admin/places/${place.id}`}>
							{place?.name}
						</Link>
					</Typography>
				</ListItem>
			))}
		</WhiteContainer>
	);
};

export default PlacesList;
PlacesList.propTypes = {};
PlacesList.defaultProps = {};
