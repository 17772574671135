import React from "react";
import { useTranslation } from "react-i18next";
import {
	Box,
	Typography,
	Select,
	Option,
	FormControl,
	FormLabel,
} from "@mui/joy";
import PropTypes from "prop-types";
import { Controller } from "react-hook-form";

const RoleSelector = ({ setValue, control, save }) => {
	const { t } = useTranslation();
	const options = [
		{
			id: "partner",
			label: "partner_user",
		},
		{
			id: "user",
			label: "user_user",
		},
		{
			id: "admin",
			label: "admin_user",
		},
	];

	return (
		<FormControl>
			<FormLabel>{t("role_selector_select")}</FormLabel>
			<Controller
				name="role_selector"
				control={control}
				render={({ field: { onChange, ...props } }) => (
					<Select
						onChange={(e, v) => {
							onChange(v);
							save();
						}}
						{...props}
					>
						<Option value="all">{t("all_roles")}</Option>
						{options.map((option) => (
							<Option key={option.id} value={option.id}>
								{t(option.label)}
							</Option>
						))}
					</Select>
				)}
			/>
		</FormControl>
	);
};

export default RoleSelector;
RoleSelector.propTypes = {};
RoleSelector.defaultProps = {};
