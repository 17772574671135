import React from "react";
import { useTranslation } from "react-i18next";
import {
	Box,
	Typography,
	Button,
	ModalDialog,
	ModalOverflow,
	Modal,
} from "@mui/joy";
import PropTypes from "prop-types";
import { RequestQuote } from "@mui/icons-material";
import { RequestPlaceQuote } from "forms/quotes";

const QuoteModal = ({ open, close, place_id }) => (
	<Modal onClose={close} open={open}>
		<ModalOverflow>
			<ModalDialog>
				<RequestPlaceQuote el_id={place_id} />
			</ModalDialog>
		</ModalOverflow>
	</Modal>
);

const AskQuote = ({ place_id }) => {
	const { t } = useTranslation();
	const [open, setOpen] = React.useState(false);
	return (
		<>
			<Button onClick={() => setOpen(!open)} fullWidth>
				<RequestQuote />
				{t("ask_quote")}
			</Button>
			<QuoteModal
				place_id={place_id}
				open={open}
				close={() => setOpen(false)}
			/>
		</>
	);
};

export default AskQuote;
AskQuote.propTypes = {};
AskQuote.defaultProps = {};
