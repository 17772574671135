import React from "react";
import { Controller } from "react-hook-form";
import {
	FormControl,
	FormLabel,
	Select,
	Option,
	Typography,
	Switch,
} from "@mui/joy";
import modules from "datas/events/event_modules.json";
import { useTranslation } from "react-i18next";

const EventModulesGlobal = ({ setValue, watch, save, field }) => {
	const [modules_selected, setModulesSelected] = React.useState(
		watch(field) || [],
	);

	const handleChange = (f, e) => {
		let _modules_selected = [...modules_selected] || [];

		if (e.target.checked) {
			_modules_selected.push(f);
		} else {
			_modules_selected = _modules_selected.filter((el) => el !== f);
		}
		setModulesSelected(_modules_selected);
		setValue(
			field,
			modules.filter((mod) => _modules_selected.find((d) => d === mod)),
		);
	};
	const { t } = useTranslation();

	return (
		<FormControl>
			{modules.map((f) => (
				<Typography
					sx={{ mb: 2 }}
					startDecorator={
						<Switch
							checked={modules_selected.find((v) => v === f)}
							value={f}
							onChange={(e) => {
								handleChange(f, e);
							}}
						/>
					}
				>
					{t(f)}
				</Typography>
			))}
		</FormControl>
	);
};

export default EventModulesGlobal;
