import React from "react";
import { useTranslation } from "react-i18next";
import { Box, Typography } from "@mui/joy";
import PropTypes from "prop-types";
import { MealsFront } from "modules/meals";

const Meals = ({ style }) => {
	const { t } = useTranslation();

	return (
		<>
			<MealsFront style={style} />
		</>
	);
};

export default Meals;
Meals.propTypes = {};
Meals.defaultProps = {};
