import * as React from "react";
import Box from "@mui/joy/Box";
import Button from "@mui/joy/Button";
import Chip from "@mui/joy/Chip";
import Typography from "@mui/joy/Typography";
import Grid from "@mui/joy/Grid";
import ArrowForward from "@mui/icons-material/ArrowForward";
import PlayCircleOutlineIcon from "@mui/icons-material/PlayCircleOutline";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";

export default function NoDataYet({ action, loading, terms }) {
	const { t } = useTranslation();
	return (
		<Grid container>
			<Grid
				sx={(theme) => ({
					paddingLeft: "46px",
					paddingRight: "46px",
					height: "481px",
					display: "flex",
					flexDirection: "column",
					alignItems: "flex-start",
					justifyContent: "center",
					background: `linear-gradient(-45deg, ${theme.vars.palette.primary[100]} 60%, #FFFFFF 1%)`,
					gap: "10px",
				})}
				md={12}
			>
				<Chip size="lg" variant="outlined" color="neutral">
					{t(terms.subtitle)}
				</Chip>
				<Typography
					level="h1"
					fontWeight="xl"
					fontSize="clamp(1.875rem, 1.3636rem + 2.1818vw, 3rem)"
				>
					{t(terms.title)}
				</Typography>

				<Box
					sx={{
						display: "flex",
						gap: 2,
						my: 2,
						flexWrap: "wrap",
						"& > *": { flex: "auto" },
					}}
				>
					<Button
						loading={loading}
						disabled={loading}
						onClick={action}
						size="lg"
						endDecorator={<ArrowForward fontSize="xl" />}
					>
						{t(terms.button)}
					</Button>
				</Box>
			</Grid>
		</Grid>
	);
}
