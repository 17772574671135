import React from "react";
import {
	Input,
	FormControl,
	FormLabel,
	Stack,
	IconButton,
	Typography,
} from "@mui/joy";
import { useTranslation } from "react-i18next";
import useBuilderContainer from "../../../../../../BuilderStore";
import { Save, Edit } from "@mui/icons-material";
import { useForm } from "react-hook-form";

const DateRange = ({ update, type, label, rules, defaultValue, config }) => {
	const { t } = useTranslation();
	const [editable, setEditable] = React.useState(defaultValue ? false : true);
	const { reset, getValues, handleSubmit, register, setValue } = useForm();

	React.useEffect(() => {
		reset();
		if (Array.isArray(defaultValue) && defaultValue.length == 2) {
			setValue("start_year", defaultValue[0] || null);
			setValue("end_year", defaultValue[1] || null);
		}
	}, [defaultValue]);

	const handleSave = (d) => {
		console.log(d);
		update({ [label]: [d?.start_year || null, d?.end_year || null] });
		setEditable(false);
	};

	const d = new Date();
	const year = d.getFullYear();
	return (
		<FormControl sx={{ mb: 2 }}>
			<FormLabel sx={{ fontSize: "12px" }}>{t(label)}</FormLabel>

			{editable ? (
				<form onSubmit={handleSubmit(handleSave)}>
					<Stack direction="row" spacing={3} alignItems="center">
						<Stack direction="row" spacing={2}>
							<Input
								{...register("start_year", rules)}
								type={"date"}
								placeholder="yyyy"
							/>
							<span>-</span>
							<Input
								{...register("end_year", { required: false })}
								type={"date"}
								placeholder="yyyy"
							/>
						</Stack>
						<IconButton
							onClick={handleSubmit(handleSave)}
							type="submit"
						>
							<Save />
						</IconButton>
					</Stack>
				</form>
			) : (
				<Stack direction="row" spacing={3} alignItems="center">
					<Typography>
						{parseInt(getValues("start_year"))} -{" "}
						{parseInt(getValues("end_year")) || ""}
					</Typography>
					<IconButton
						type="button"
						variant="outlined"
						onClick={() => setEditable(true)}
					>
						<Edit />
					</IconButton>
				</Stack>
			)}
		</FormControl>
	);
};

export default DateRange;
