import React, { useState, useEffect, useCallback } from "react";
import { useTranslation } from "react-i18next";
import { WhiteContainer } from "UI";
import { Editable, View } from "./partials";
import PrivatePlace from "models/privatePlace";
import Helpers from "utils/Helpers";
import useAuthContainer from "stores/Auth";
import { v4 as uuidv4 } from "uuid";

const Contacts = ({ contacts: initialContacts, placeId }) => {
	const { t } = useTranslation();
	const [contacts, setContacts] = useState(initialContacts);
	const [selected, setSelected] = useState(null);
	const [open, setOpen] = useState(false);
	const { user, userAccount } = useAuthContainer.useContainer();
	const PrivatePlaceEntity = React.useMemo(
		() => new PrivatePlace(placeId),
		[placeId],
	);

	useEffect(() => {
		setContacts(initialContacts);
	}, [initialContacts]);

	const saveContact = useCallback(
		async (data) => {
			const updatedContacts = selected
				? Helpers.deepArrayUpdate(contacts, data, data.id)
				: [
						...contacts,
						{
							by: `${userAccount?.additionalDatas?.name || ""} ${
								userAccount?.additionalDatas?.lastname || ""
							}`,
							uid: user.uid,
							...data,
							id: uuidv4(),
						},
				  ];
			setSelected(null);

			setContacts(updatedContacts);
			PrivatePlaceEntity.contacts = updatedContacts;
			await PrivatePlaceEntity.updatePrivateDocument("datas");
		},
		[
			contacts,
			selected,
			user.uid,
			userAccount?.additionalDatas,
			PrivatePlaceEntity,
		],
	);

	const deleteContact = useCallback(
		async (id) => {
			const updatedContacts = contacts.filter(
				(contact) => contact.id !== id,
			);
			setContacts(updatedContacts);
			PrivatePlaceEntity.contacts = updatedContacts;
			await PrivatePlaceEntity.updatePrivateDocument("datas");
		},
		[contacts, PrivatePlaceEntity],
	);

	return (
		<WhiteContainer title={t("contacts")}>
			<View
				contacts={contacts}
				select={(contact) => {
					setSelected(contact);
					setOpen(true);
				}}
			/>
			<Editable
				deleteContact={deleteContact}
				saveContact={saveContact}
				open={open}
				close={() => setOpen(false)}
				selected={selected}
			/>
		</WhiteContainer>
	);
};

export default Contacts;
