import React from "react";
import {
	Modal,
	ModalDialog,
	ModalOverflow,
	Box,
	Typography,
	List,
	ListItem,
	ListItemDecorator,
	ListItemContent,
	Button,
	Stack,
	ListItemButton,
	Divider,
	ListSubheader,
	Select,
	Alert,
	Option,
} from "@mui/joy";
import { useTranslation } from "react-i18next";
import { useForm, Controller } from "react-hook-form";

const ImportedPlaceTress = ({ places, setFiles }) => {
	const { t } = useTranslation();

	return (
		<Box>
			<List>
				<ListSubheader>{t("imported_places_medias")}</ListSubheader>

				{places.map((place) => (
					<ListItem>
						<ListItemButton
							onClick={() => setFiles(place?.images || [])}
							key={place.id}
						>
							<ListItemDecorator></ListItemDecorator>
							{place.nom}
						</ListItemButton>
					</ListItem>
				))}
			</List>
		</Box>
	);
};

export default ImportedPlaceTress;
