import React from "react";
import {
	Box,
	Typography,
	IconButton,
	Tooltip,
	Button,
	Dropdown,
	MenuButton,
	Stack,
	Menu,
} from "@mui/joy";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import { useClearRefinements, SearchBox } from "react-instantsearch";
import { ConnectedStats } from "./partials";
import {
	Search,
	Delete,
	VisibilityOff,
	Map,
	FilterAltOff,
} from "@mui/icons-material";

const FilterContent = ({
	filters,
	t,
	selectedFilterCat,
	setSelectedFilterCat,
}) => {
	return (
		<Box>
			<Box sx={{ display: "flex", flexDirection: "row", gap: 3 }}>
				{filters &&
					filters.map((section) => (
						<Box
							sx={{ position: "relative", pt: 3 }}
							key={section.sectionTitle}
						>
							<Dropdown>
								<Tooltip title={t(section.sectionTitle)}>
									<MenuButton
										onClick={() =>
											setSelectedFilterCat(
												selectedFilterCat ===
													section.sectionTitle
													? null
													: section.sectionTitle,
											)
										}
										variant={
											selectedFilterCat ===
											section.sectionTitle
												? "solid"
												: "outlined"
										}
										color={
											selectedFilterCat ===
											section.sectionTitle
												? "primary"
												: "neutral"
										}
										sx={{
											borderRadius: "103px",
											height: "50px",
											width: "50px",
										}}
										component={IconButton}
									>
										<section.Icon
											sx={{ fontSize: "35px" }}
										/>
									</MenuButton>
								</Tooltip>
								<Menu
									sx={{
										visibility:
											selectedFilterCat ===
											section.sectionTitle
												? "visible"
												: "hidden",
										gap: 3,
									}}
									open
									onClose={() => setSelectedFilterCat(null)}
								>
									<Box
										className="filters"
										sx={{
											display: "flex",

											gap: 3,
										}}
									>
										{section?.content &&
											section.content.map((filter) => (
												<Box
													key={filter.title}
													sx={{
														mb: 2,
														p: 2,
														borderRadius: 8,
													}}
												>
													<Typography
														level="h4"
														sx={{ mb: 2 }}
													>
														{t(filter.title)}
													</Typography>
													<Box>
														{filter.refinement}
													</Box>
												</Box>
											))}
									</Box>
								</Menu>
							</Dropdown>
						</Box>
					))}
			</Box>
		</Box>
	);
};

FilterContent.propTypes = {
	filters: PropTypes.arrayOf(PropTypes.object),
	t: PropTypes.func.isRequired,
	selectedFilterCat: PropTypes.string,
	setSelectedFilterCat: PropTypes.func.isRequired,
};

const FilterContainer = ({
	filters,
	filterStyle = {},
	containerStyle = {},
	showMap,
	setShowMap,
	terms,
}) => {
	const { t } = useTranslation();
	const [selectedFilterCat, setSelectedFilterCat] = React.useState(null);
	const { canRefine, refine } = useClearRefinements();

	return (
		<Box sx={{ ...containerStyle }}>
			<Box sx={{ ...filterStyle }} elevation={3}>
				<Box
					sx={{
						display: "flex",
						alignItems: "center",
						flexWrap: "wrap",
						justifyContent: showMap && "center",
						columnGap: 3,
						rowGap: 1,
					}}
				>
					<Box sx={{ flexGrow: 1 }}>
						<SearchBox
							submitIconComponent={() => (
								<IconButton>
									<Search />
								</IconButton>
							)}
							placeholder={t("Rechercher")}
							loadingIconComponent={() => (
								<IconButton>
									<Delete />
								</IconButton>
							)}
						/>
					</Box>
					<Box>
						<Box
							sx={{
								display: "flex",
								alignItems: "center",
								columnGap: 2,
							}}
						>
							{setShowMap && (
								<IconButton
									variant="solid"
									color="primary"
									onClick={() => setShowMap(!showMap)}
								>
									{showMap ? <VisibilityOff /> : <Map />}
								</IconButton>
							)}
							<ConnectedStats label={terms?.stats} />
						</Box>
					</Box>
				</Box>
				<Stack direction="row" alignItems="baseline" spacing={3}>
					<FilterContent
						t={t}
						filters={filters}
						selectedFilterCat={selectedFilterCat}
						setSelectedFilterCat={setSelectedFilterCat}
					/>
					{canRefine && (
						<Button onClick={refine}>
							<FilterAltOff /> {t("clear_refinement")}
						</Button>
					)}
				</Stack>
			</Box>
		</Box>
	);
};

FilterContainer.propTypes = {
	filters: PropTypes.arrayOf(PropTypes.object),
	filterStyle: PropTypes.object,
	containerStyle: PropTypes.object,
	showMap: PropTypes.bool,
	setShowMap: PropTypes.func,
};

export default FilterContainer;
