import React from "react";
import { Switch, Route } from "react-router-dom";
import {
	PlaceList,
	PlaceCreate,
	PlaceEdit,
	PlaceView,
	PlaceSettings,
	PlaceRse,
	PlaceSearch,
} from "./views";
import { BOLayout } from "Components/layouts";
import { useLocation, useParams } from "react-router-dom";
import useAuthContainer from "stores/Auth";

const PlacesRoutes = (props) => {
	const [docId, setDocId] = React.useState(null);
	const [title, setTitle] = React.useState("places");
	const { user, userAccount } = useAuthContainer.useContainer();

	const loc = useLocation();
	const canSeePlaceMenu =
		userAccount?.role == "admin" ||
		(userAccount?.role == "partner" && userAccount?.isApproved == true);

	return (
		<Switch>
			<>
				<BOLayout
					documentID={docId}
					showPlaceMenu={
						loc.pathname !== "/places" &&
						loc.pathname !== "/places/" &&
						canSeePlaceMenu
					}
					noLateralMenu={loc.pathname.includes("/places/view/")}
					isDocumentStatusMonitoring
					title={title}
				>
					<Route exact path="/places">
						<PlaceList />
					</Route>
					<Route exact path="/places/create">
						<PlaceCreate />
					</Route>
					<Route exact path="/places/search">
						<PlaceSearch />
					</Route>
					<Route path="/places/edit/:placeID">
						<PlaceEdit setTitle={setTitle} setDocId={setDocId} />
					</Route>
					<Route path="/places/:placeID/settings">
						<PlaceSettings
							setTitle={setTitle}
							setDocId={setDocId}
						/>
					</Route>
					<Route path="/places/:placeID/rse">
						<PlaceRse setTitle={setTitle} setDocId={setDocId} />
					</Route>
				</BOLayout>
				<Route path="/places/view/:placeID">
					<PlaceView setTitle={setTitle} setDocId={setDocId} />
				</Route>
			</>
		</Switch>
	);
};

export default PlacesRoutes;
