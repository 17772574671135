import React from "react";
import { ServiceItem } from "../.";
import { Box } from "@mui/joy";
import ContentfulClass from "../../../../../../utils/ContentfulClass";

const ServicesListing = () => {
	const contentful = new ContentfulClass();
	const [services, setServices] = React.useState(null);

	const getServices = async () => {
		const entries = await contentful.getEntries({
			content_type: "service",
		});

		const parseDatas = entries.items.reduce((acc, val) => {
			/*contentful.getEntry(val.sys.id).then((entry) => {
				console.log(entry);
			});*/
			acc.push({
				title: val.fields.title,
				list: val.fields.list,
				image: val.fields.image.fields.file.url,
				id: val.sys.id,
				description:
					val.fields?.description?.content[0]?.content[0]?.value,
			});

			return acc;
		}, []);
		console.log(entries);
		setServices(parseDatas);
	};

	React.useEffect(() => {
		getServices();
	}, []);
	return (
		<Box
			style={{
				"&::-webkit-scrollbar": {
					display: "none",
				},
				"-ms-overflow-style": "none" /* IE and Edge */,
				scrollbarWidth: "none",
				display: "flex",
				columnGap: "20px",
				overflow: "auto",
				flexWrap: "nowrap",
			}}
		>
			{services &&
				services.map((service) => (
					<ServiceItem key={service.id} {...service} />
				))}
		</Box>
	);
};

export default ServicesListing;
