import React from "react";
import { Menu, Typography, Stack, Button, Item } from "@mui/joy";

const ButtonActionConfirm = ({
	action,
	close,
	anchor,
	actionMessage,
	cancelLabel,
	okLabel,
}) => {
	const open = Boolean(anchor);
	const handleOk = () => {
		action();
		close();
	};
	return (
		<Menu
			sx={{ zIndex: 3000 }}
			onClose={close}
			open={open}
			anchorEl={anchor}
		>
			<Typography sx={{ p: 3 }}>{actionMessage}</Typography>
			<Stack
				justifyContent="center"
				sx={{ p: 1 }}
				direction="row"
				spacing={2}
			>
				<Button variant="outlined" color="neutral" onClick={close}>
					{cancelLabel}
				</Button>
				<Button color="primary" onClick={handleOk}>
					{okLabel}
				</Button>
			</Stack>
		</Menu>
	);
};

export default ButtonActionConfirm;
