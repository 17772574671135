import React from "react";
import PlacesAutocomplete, {
	geocodeByAddress,
	getLatLng,
} from "react-places-autocomplete";
import {
	Box,
	Typography,
	Stack,
	FormControl,
	FormLabel,
	Button,
	Input,
	FormHelperText,
	Chip,
	Textarea,
	Select,
	Option,
} from "@mui/joy";
import TagsInput from "react-tagsinput";

import { useTranslation } from "react-i18next";
import { Controller } from "react-hook-form";
import { Link, useParams } from "react-router-dom";

import type_lieu from "datas/places/places_type.json";
import rse from "datas/places/places_label_rse.json";
import cadre from "datas/places/places_cadre.json";

const General = ({
	errors,
	register,
	tags,
	setTags,
	control,
	setValue,
	defaultAdress,
}) => {
	const { placeID } = useParams();
	const { t } = useTranslation();

	const [address, setAddress] = React.useState("");

	React.useEffect(() => {
		setAddress(defaultAdress);
	}, [defaultAdress]);

	const handleSelect = async (value) => {
		const results = await geocodeByAddress(value);
		const latLng = await getLatLng(results[0]);
		if (results.length > 0) {
			// Parcourir les résultats et extraire la ville, le code postal et le pays
			for (const component of results[0].address_components) {
				const componentType = component.types[0];

				switch (componentType) {
					case "locality":
						setValue("ville", component.long_name);
						break;
					case "postal_code":
						setValue("code_postal", component.long_name);
						break;
					case "country":
						setValue("pays", component.long_name);
						break;
					case "administrative_area_level_1": // Souvent utilisé pour la région
					case "administrative_area_level_2": // Parfois utilisé pour la région
						setValue("region", component.long_name);
						break;
					default:
						break;
				}
			}

			setValue("coordonnees_gps", latLng, { shouldDirty: true });
			setValue("adresse", value, { shouldDirty: true });
		}
	};

	const handleChange = (value) => {
		setAddress(value);
		if (!value) {
			setAddress("");
			setValue("adresse", "", { shouldDirty: true });
		}
	};

	return (
		<>
			<Box
				sx={{
					background: "white",
					p: 2,
					mt: 1,
					mb: 1,
				}}
			>
				<Typography sx={{ mb: 2 }} level="h4" component="h2">
					{t("general")}
				</Typography>
				{/* Nom du lieu */}

				<Stack spacing={2}>
					<FormControl variant="outlined" error={!!errors.nom}>
						<FormLabel>Nom du lieu</FormLabel>
						<Input
							{...register("nom", {
								required: "Le nom est requis",
							})}
							placeholder="Entrez le nom du lieu"
						/>

						<FormHelperText>{errors.nom?.message}</FormHelperText>
					</FormControl>

					{/* Adresse */}
					<FormControl variant="outlined" error={!!errors.adresse}>
						<FormLabel>Adresse</FormLabel>
						<PlacesAutocomplete
							value={address}
							onChange={handleChange}
							onSelect={handleSelect}
						>
							{({
								getInputProps,
								suggestions,
								getSuggestionItemProps,
								loading,
							}) => (
								<div>
									<Input
										{...getInputProps({
											placeholder:
												"Recherchez une adresse ...",
										})}
									/>
									<div
										style={{
											background: "white",
											border: "1px #eee solid",
										}}
									>
										{loading ? (
											<div>...chargement</div>
										) : null}
										{suggestions.map((suggestion) => {
											const style = {
												backgroundColor:
													suggestion.active
														? "#41b6e6"
														: "#fff",
											};

											return (
												<div
													{...getSuggestionItemProps(
														suggestion,
														{ style },
													)}
												>
													{suggestion.description}
												</div>
											);
										})}
									</div>
								</div>
							)}
						</PlacesAutocomplete>
						<FormHelperText>
							{errors.adresse?.message}
						</FormHelperText>
					</FormControl>

					{/* Ville */}
					<FormControl variant="outlined" error={!!errors.ville}>
						<FormLabel>Ville</FormLabel>
						<Input
							{...register("ville", {
								required: "La ville est requise",
							})}
							placeholder="Entrez la ville"
						/>
						<FormHelperText>{errors.ville?.message}</FormHelperText>
					</FormControl>

					{/* Code Postal */}
					<FormControl
						variant="outlined"
						error={!!errors.code_postal}
					>
						<FormLabel>Code Postal</FormLabel>
						<Input
							{...register("code_postal", {
								required: "Le code postal est requis",
							})}
							placeholder="Entrez le code postal"
						/>
						<FormHelperText>
							{errors.code_postal?.message}
						</FormHelperText>
					</FormControl>
					{/* Code Postal */}
					<FormControl
						variant="outlined"
						error={!!errors.code_postal}
					>
						<FormLabel>Région</FormLabel>
						<Input
							{...register("region", {
								required: "Région requise",
							})}
							placeholder="Votre région"
						/>
						<FormHelperText>
							{errors.region?.message}
						</FormHelperText>
					</FormControl>

					{/* Pays */}
					<FormControl variant="outlined" error={!!errors.pays}>
						<FormLabel>Pays</FormLabel>
						<Input
							{...register("pays", {
								required: "Le pays est requis",
							})}
							placeholder="Entrez le pays"
						/>
						<FormHelperText>{errors.pays?.message}</FormHelperText>
					</FormControl>
					{/* Description */}
					<FormControl variant="outlined">
						<FormLabel>Description</FormLabel>
						<Textarea
							{...register("description")}
							placeholder="Décrivez le lieu"
							multiline
							minRows={3}
						/>
						<FormHelperText>
							Une description détaillée du lieu
						</FormHelperText>
					</FormControl>

					{/* type_lieu */}
					<FormControl variant="outlined">
						<FormLabel>Type de lieu.</FormLabel>
						<Controller
							name="type_lieu"
							control={control}
							render={({ field: { onChange, value } }) => (
								<Select
									value={value}
									onChange={(event, newValue) => {
										onChange(newValue); // Utilisez newValue pour mettre à jour la valeur du formulaire
									}}
									placeholder="Choisissez un type de lieu"
									sx={{ minWidth: 200 }}
								>
									{type_lieu.map((type) => (
										<Option value={type}>{t(type)}</Option>
									))}
								</Select>
							)}
						/>
						<FormHelperText>
							Type de lieu (château, hôtel, etc.)
						</FormHelperText>
					</FormControl>
					{/*LABEL RSE*/}

					<Box sx={{ mt: 2 }}>
						{/* Description */}
						<FormControl sx={{ mb: 2 }} variant="outlined">
							<FormLabel>Actions RSE mises en place</FormLabel>
							<Textarea
								{...register("description_rse")}
								placeholder="Actions RSE mises en place"
								multiline
								minRows={3}
							/>
						</FormControl>

						<FormLabel sx={{ mb: 2 }}>Label RSE</FormLabel>
						<Box sx={{ display: "flex", gap: 1, flexWrap: "wrap" }}>
							{rse.map((option, index) => (
								<Controller
									key={option.nom}
									name={`label_rse`}
									control={control}
									render={({
										field: { onChange, value = [], name },
									}) => (
										<Chip
											onClick={() => onChange(!value)}
											label={t(option.nom)}
											size="md"
											onClick={() => {
												const newValue = value.includes(
													option.nom,
												)
													? value.filter(
															(v) =>
																v !==
																option.nom,
													  )
													: [...value, option.nom];

												onChange(newValue);
											}}
											sx={(theme) => ({
												cursor: "pointer",
												border: value.includes(
													option.nom,
												)
													? `3px solid ${theme.vars.palette.primary[800]}`
													: "3px solid #eee",

												p: 2,
												"&:hover": {
													opacity: 0.8,
												},
											})}
										>
											<Stack>
												<img
													src={option.image}
													width="90px"
												/>
												<Typography>
													{t(option.nom)}
												</Typography>
											</Stack>
										</Chip>
									)}
								/>
							))}
						</Box>
						<Button
							sx={{ mt: 1 }}
							variant="outlined"
							component={Link}
							to={`/places/${placeID}/settings`}
						>
							{t("show_more_rse_options")}
						</Button>
					</Box>

					{/* distance_points_interet */}
					<FormControl variant="outlined">
						<FormLabel>
							Distance aux points d'intérêt majeurs.
						</FormLabel>
						<Textarea
							{...register("distance_points_interet")}
							placeholder="Points d'intérets"
							multiline
							minRows={3}
						/>
					</FormControl>

					{/* tarifs_seminaire_residentiel */}
					<FormControl variant="outlined">
						<FormLabel>
							{t("tarifs_seminaire_residentiel")}
						</FormLabel>
						<Input
							type="number"
							min="1"
							step="any"
							startDecorator={t("from_")}
							endDecorator="€"
							{...register("tarifs_seminaire_residentiel")}
							placeholder={t("tarifs_seminaire_residentiel")}
						/>
					</FormControl>

					{/* 
tarifs_journee_etude */}

					<FormControl variant="outlined">
						<FormLabel>{t("tarifs_journee_etude")} </FormLabel>
						<Input
							type="number"
							min="1"
							step="any"
							startDecorator={t("from_")}
							endDecorator="€"
							{...register("tarifs_journee_etude")}
							placeholder={t("tarifs_journee_etude")}
						/>
					</FormControl>

					<FormControl
						sx={(theme) => ({
							".react-tagsinput-input": {
								width: "121px",
							},
							".react-tagsinput-tag": {
								background: theme.vars.palette.primary[500],
								color: "white",
								border: "none",
							},

							".react-tagsinput-remove": {
								cursor: "pointer",
								fontWeight: "bold",
								textDecoration: " none",
								color: "#1c3c58",
							},
						})}
					>
						<FormLabel>Tags</FormLabel>
						<TagsInput
							inputProps={{
								className: "react-tagsinput-input",
								placeholder: "Ajouter un tag",
							}}
							value={tags}
							onChange={setTags}
						/>
						<FormHelperText>
							Mots-clés associés au lieu pour la recherche
						</FormHelperText>
					</FormControl>

					<Box sx={{ mt: 2 }}>
						<FormLabel sx={{ mb: 2 }}>Cadre et ambiance</FormLabel>
						<Box sx={{ display: "flex", gap: 1, flexWrap: "wrap" }}>
							{cadre.map((option, index) => (
								<Controller
									key={option}
									name="cadre"
									control={control}
									render={({
										field: { onChange, value = [] },
									}) => (
										<Chip
											variant={
												value.includes(option)
													? "solid"
													: "outlined"
											}
											color={
												value.includes(option)
													? "primary"
													: "default"
											}
											onClick={() => {
												const newValue = value.includes(
													option,
												)
													? value.filter(
															(v) => v !== option,
													  )
													: [...value, option];

												onChange(newValue);
											}}
											label={option}
											size="md"
											sx={{
												cursor: "pointer",
												border: "1px solid #eee",
												"&:hover": {
													opacity: 0.8,
												},
											}}
										>
											{" "}
											{t(option)}
										</Chip>
									)}
								/>
							))}
						</Box>
					</Box>
				</Stack>
			</Box>
		</>
	);
};

export default General;
