import React from "react";
import { useTranslation } from "react-i18next";
import { Box, Typography, Button, Container, Divider } from "@mui/joy";
import { Check } from "@mui/icons-material";
import PropTypes from "prop-types";
import { useForm } from "react-hook-form";
import { WhiteContainer } from "UI";
import {
	EventLogoAndBackground,
	EventDescription,
	EventModulesGlobal,
} from "../commons";
import Event from "models/events";

const EditEventHomeForm = ({ eventDatas }) => {
	const { t } = useTranslation();
	const [saving, setSaving] = React.useState(false);
	const [saved, setSaved] = React.useState(false);
	const { handleSubmit, register, setValue, getValues, watch } = useForm({
		defaultValues: {
			...eventDatas,
		},
	});
	const save = async (datas) => {
		setSaving(true);
		try {
			const eventDatas = { ...datas };
			const EventEntity = new Event(eventDatas);
			const updated = await EventEntity.updateEvent(eventDatas.id);
			setSaved(true);
			setTimeout(() => {
				setSaved(false);
			}, 2000);
		} catch (e) {
			console.log(e.message);
		}
		setSaving(false);
	};

	const SaveBanner = ({ saving, saved }) => (
		<WhiteContainer>
			<Button
				loading={saving}
				disabled={saving}
				type="submit"
				variant={saved ? "outlined" : "solid"}
			>
				{saved && <Check />}
				{!saved ? t("save") : t("saved")}
			</Button>
		</WhiteContainer>
	);

	return eventDatas ? (
		<>
			<form onSubmit={handleSubmit(save)}>
				<WhiteContainer title="home_header">
					<EventLogoAndBackground
						setValue={setValue}
						getValues={getValues}
						watch={watch}
					/>
					<Divider sx={{ m: 2 }} />
					<EventDescription register={register} />
				</WhiteContainer>
				<WhiteContainer title="module_selector_global">
					<EventModulesGlobal
						field="active_modules_global"
						watch={watch}
						setValue={setValue}
					/>
				</WhiteContainer>
				<WhiteContainer title="module_selector">
					<EventModulesGlobal
						field="active_modules"
						watch={watch}
						setValue={setValue}
					/>
				</WhiteContainer>
				<SaveBanner saving={saving} saved={saved} />
			</form>
		</>
	) : null;
};

export default EditEventHomeForm;
EditEventHomeForm.propTypes = {};
EditEventHomeForm.defaultProps = {};
