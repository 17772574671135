import React from "react";
import LayoutTwoColumn from "../../LayoutTwoColumn";
import { Header, Footer } from "../../partials/";
import { InformationsForm } from "./partials";
import { useHistory } from "react-router-dom";
import useAuthContainer from "../../../../stores/Auth";
const Informations = () => {
	const navigate = useHistory();
	const { userAccount, user } = useAuthContainer.useContainer();
	React.useEffect(() => {
		if (!userAccount || !user) {
			navigate.push("/login");
		}
	}, [user, userAccount]);

	return (
		<LayoutTwoColumn>
			<Header title="complete_your_infos" subtitle="enter_your_infos" />

			<InformationsForm />
		</LayoutTwoColumn>
	);
};

export default Informations;
