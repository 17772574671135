import React from "react";
import { useStats } from "react-instantsearch";
import { useTranslation } from "react-i18next";
import { Typography, Box } from "@mui/joy";

const ConnectedStats = ({ label }) => {
	const { t } = useTranslation();
	const {
		hitsPerPage,
		nbHits,
		areHitsSorted,
		nbSortedHits,
		nbPages,
		page,
		processingTimeMS,
		query,
	} = useStats();

	return (
		<Box sx={{ mr: 2 }}>
			<Typography
				sx={{
					background: "#eee",
					padding: " 7px 16px",
					borderRadius: "52px",
				}}
			>
				{t(label || "nbr_hits_in_ms_place", {
					nbHits: nbHits,
					processingTimeMS: processingTimeMS,
				})}
			</Typography>
		</Box>
	);
};

export default ConnectedStats;
