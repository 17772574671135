import React from "react";
import { useTranslation } from "react-i18next";
import { Box, Typography, Button } from "@mui/joy";
import PropTypes from "prop-types";
import { EventTable } from "./partials";
import { orderBy, where } from "firebase/firestore";
import Event from "models/events";
import { Link } from "react-router-dom";

import { WhiteContainer } from "UI";

const EventList = (props) => {
	const EventEntity = new Event();
	const { t } = useTranslation();
	const [filters, setFilters] = React.useState([]);
	const [events, setEvents] = React.useState([]);
	const [lastVisible, setLastVisible] = React.useState(null);
	const [isMore, SetIsMore] = React.useState(true);
	const [filterChanged, setFilterChanged] = React.useState(false);

	const getEvents = async (reset = false) => {
		try {
			const datas = await EventEntity.getEvents(lastVisible, filters);
			if (datas) {
				SetIsMore(!datas?.no_more_data);
				if (datas?.lastVisible) {
					setEvents(
						reset
							? datas?.documents
							: [...events, ...datas?.documents],
					);
					setLastVisible(datas?.lastVisible);
				} else {
					setEvents(datas);
				}
			} else {
				SetIsMore(false);
			}
		} catch (e) {
			console.log(e);
		}
	};

	React.useEffect(() => {
		setEvents([]);
		setLastVisible(null);
		setFilterChanged(true);
	}, [filters]);

	React.useEffect(() => {
		if (filterChanged) {
			getEvents(true);
			setFilterChanged(false);
		}
	}, [filterChanged]);

	return (
		<>
			<WhiteContainer>
				<Button component={Link} to={"/event/templates"}>
					{t("create_event")}
				</Button>
			</WhiteContainer>
			<EventTable
				isMore={isMore}
				getEvents={getEvents}
				events={events}
				setEvents={setEvents}
			/>
		</>
	);
};

export default EventList;
EventList.propTypes = {};
EventList.defaultProps = {};
