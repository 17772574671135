import React from "react";
import { IconButton } from "@mui/joy";
import useCVContainer from "../../CVStore";
import { ColorLens } from "@mui/icons-material";

const ThemeToogle = (props) => {
	const { setThemeEditor } = useCVContainer.useContainer();

	return (
		<IconButton
			sx={{
				position: "fixed",
				right: "40px",
				top: "40px",
				zIndex: 22,
			}}
			variant="solid"
			onClick={() => setThemeEditor(true)}
		>
			<ColorLens />
		</IconButton>
	);
};

export default ThemeToogle;
