import React from "react";
import { CirclePicker } from "react-color";
import { Box } from "@mui/joy";
import useCVContainer from "../../CVStore";

const ColorSelector = (props) => {
	const { color: colorState, setColor } = useCVContainer.useContainer();
	return (
		<Box>
			<CirclePicker
				color={colorState}
				onChangeComplete={(c) => setColor(c.hex)}
			/>
		</Box>
	);
};

export default ColorSelector;
