import React from "react";
import { Box } from "@mui/joy";
import "photoswipe/dist/photoswipe.css";
import { Gallery } from "react-photoswipe-gallery";
import GalleryContent from "./GalleryContent";

const ImageSlider = ({ images, featuredImage }) => {
	return images ? (
		<Box className="slider-container">
			<Gallery>
				<GalleryContent images={images} />
			</Gallery>
		</Box>
	) : null;
};
export default ImageSlider;
