import React from "react";
import { useTranslation } from "react-i18next";
import { Box, Typography } from "@mui/joy";
import PropTypes from "prop-types";
import { PlacesTemplateCard } from "UI/Grids";
import { WhiteContainer } from "UI";
import useAuthContainer from "stores/Auth";
import { AddToFavorite } from "Common/favorites";

const PlacesFavorites = (props) => {
	const { userAccount } = useAuthContainer.useContainer();
	const { t } = useTranslation();

	return (
		<WhiteContainer>
			<Box
				sx={{
					display: "grid",
					gridTemplateColumns: {
						lg: "repeat(4,1fr)",
						md: "repeat(3,1fr)",
						sm: "repeat(2,1fr)",
						xs: "repeat(1,1fr)",
					},
					gap: 2,
				}}
			>
				{userAccount?.favorites?.length > 0 ? (
					userAccount?.favorites.map((favorite) => (
						<PlacesTemplateCard
							title={favorite?.name}
							image={favorite?.image}
							id={favorite.id}
							quoteDisabled
							style={{
								border: "1px solid #eee",
								p: 2,
							}}
							addToFavorite={
								<AddToFavorite
									id={favorite.id}
									featured_image={favorite.image}
									nom={favorite.name}
									type={"places"}
									iconButton={true}
								/>
							}
							visualizeLink={`/places/view/${favorite.id}`}
						/>
					))
				) : (
					<Typography>{t("no_favorite_added")}</Typography>
				)}
			</Box>
		</WhiteContainer>
	);
};

export default PlacesFavorites;
PlacesFavorites.propTypes = {};
PlacesFavorites.defaultProps = {};
