import React from "react";
import { useTranslation } from "react-i18next";
import { Box, Typography, IconButton, Tooltip, Button, Stack } from "@mui/joy";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import Notification from "models/notifications";
import InfiniteScroll from "react-infinite-scroll-component";
import { where, orderBy } from "firebase/firestore";
import listNotif from "datas/notifications/notifications_list.json";
import useAuthContainer from "stores/Auth";
import { MarkEmailUnread, MarkEmailRead } from "@mui/icons-material";
import Helpers from "utils/Helpers";

const NotificationEntity = new Notification();

const NotificationsList = (props) => {
	const { user } = useAuthContainer.useContainer();
	const { t } = useTranslation();
	const [notifications, setNotifications] = React.useState([]);
	const [lastVisible, setLastVisible] = React.useState(null);
	const [filters, setFilters] = React.useState([
		where("uids", "array-contains", user?.uid),
		where("read", "!=", true),
		orderBy("read", "asc"),
		orderBy("created_at", "desc"),
	]);
	const notificationsByKey = listNotif.reduce((acc, notification) => {
		acc[notification.notification] = notification;
		return acc;
	}, {});

	const markAsRead = (id, index) => {
		const notifs = [...notifications].filter((f) => f.id !== id);
		setNotifications(notifs);

		NotificationEntity.read = true;
		NotificationEntity.updateNotification(id);
	};

	const [isMore, SetIsMore] = React.useState(true);

	const getNotifications = async (reset = false) => {
		try {
			const datas = await NotificationEntity.getNotifications(
				lastVisible,
				filters,
			);
			if (datas) {
				SetIsMore(!datas?.no_more_data);
				if (datas?.lastVisible) {
					setLastVisible(datas?.lastVisible);
					setNotifications([...notifications, ...datas.documents]);
				} else {
					setNotifications(datas);
				}
			} else {
				SetIsMore(false);
			}
		} catch (e) {
			console.log(e);
		}
	};

	React.useEffect(() => {
		getNotifications(true);
	}, []);

	return notifications?.length > 0 ? (
		<Box sx={{ display: "flex", gap: 2, flexDirection: "column" }}>
			{notifications.map((notif, index) => (
				<Box sx={{ background: "white", p: 2 }}>
					<Typography sx={{ fontSize: "10px", color: "#888" }}>
						{Helpers.fbDate(notif.created_at, "DD-MM-YYYY")}
					</Typography>
					<Box
						sx={{
							display: "flex",
							justifyContent: "space-between",
						}}
					>
						<Stack spacing={1}>
							<Typography sx={{ fontSize: "14px" }}>
								{t(notif.type)}
							</Typography>
							<Typography paragraph>
								{" "}
								{t(notif.message)}
							</Typography>
							<ul>
								{notificationsByKey[notif.type]?.datas &&
									notificationsByKey[notif.type].datas.map(
										(data) => (
											<li>
												<b>{t(data?.label)}</b> :{" "}
												{notif?.datas?.[data.data]}
											</li>
										),
									)}
							</ul>
							{notificationsByKey[notif.type] &&
								notificationsByKey[notif.type]?.access_link && (
									<Typography paragraph>
										<Button
											size={"sm"}
											component={Link}
											to={`${
												notificationsByKey[notif.type]
													?.access_link
											}${notif.datas?.id}`}
										>
											{t("show_datas")}
										</Button>
									</Typography>
								)}
						</Stack>
						<Box>
							<IconButton
								onClick={() => {
									!notif?.read && markAsRead(notif.id, index);
								}}
							>
								{notif?.read ? (
									<Tooltip title={t("mark_as_unread")}>
										<MarkEmailRead />
									</Tooltip>
								) : (
									<Tooltip title={t("mark_as_read")}>
										<MarkEmailUnread color="primary" />
									</Tooltip>
								)}
							</IconButton>
						</Box>
					</Box>
				</Box>
			))}
			<Button disabled={!isMore} onClick={getNotifications}>
				{t("show_more")}
			</Button>
		</Box>
	) : (
		<Typography>{t("no_notification_for_now")}</Typography>
	);
};

export default NotificationsList;
NotificationsList.propTypes = {};
NotificationsList.defaultProps = {};
