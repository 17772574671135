import React from "react";
import { Box, Typography } from "@mui/joy";
import ContentfulClass from "../../../../utils/ContentfulClass";

const BannerInfo_1 = ({ contentFullID }) => {
	const contentful = new ContentfulClass();
	const [bannerContent, setBannerContent] = React.useState(null);

	const getBannerContent = async () => {
		try {
			const entry = await contentful.getEntry(contentFullID);

			const parseDatas = {
				title: entry.fields?.title,
				image: entry.fields?.image?.fields?.file?.url,
				id: entry.sys.id,
				description: entry.fields?.description,
			};

			setBannerContent(parseDatas);
		} catch (e) {}
	};

	React.useEffect(() => {
		getBannerContent();
	}, []);
	return bannerContent ? (
		<Box
			sx={{
				position: "relative",
				p: 3,
				mb: 3,
				background: "#333",
				borderRadius: "10px",
				background: `url(${bannerContent?.image}) center center / cover`,
			}}
		>
			<Box
				sx={{
					borderRadius: "10px",

					background: "rgba(0,0,0,45%)",
					position: "absolute",
					width: "100%",
					height: "100%",
					top: 0,
					left: 0,
				}}
			></Box>
			<Box sx={{ position: "relative", zIndex: 1 }}>
				<Typography sx={{ fontSize: "25px", color: "white" }}>
					{bannerContent?.title}
				</Typography>
				<Typography sx={{ color: "white" }}>
					{bannerContent?.description}
				</Typography>
			</Box>
		</Box>
	) : null;
};

export default BannerInfo_1;
