import React from "react";
import { useTranslation } from "react-i18next";

const ErrorRoutes = (props) => {
  const { t } = useTranslation();
  return (
    <div className=" flex h-screen justify-center items-center">
      <p className=" p-10 text-Primary-Green  text-center text-4xl uppercase ">
        {t("404_message")}
      </p>
    </div>
  );
};

export default ErrorRoutes;
