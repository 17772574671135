import React from "react";
import { useTranslation } from "react-i18next";
import {
	Box,
	Typography,
	Button,
	Drawer,
	DialogTitle,
	ModalClose,
	List,
	ListItem,
	Divider,
} from "@mui/joy";
import { AttachFile } from "@mui/icons-material";
import PropTypes from "prop-types";
import queryString from "query-string";
import { useLocation, useHistory, Link } from "react-router-dom";
import User from "models/user";

const UserDetails = () => {
	const { t } = useTranslation();
	const UserEntity = new User();
	const location = useLocation();
	const history = useHistory();
	const { userDetails, userId } = queryString.parse(location.search);
	const [datas, setDatas] = React.useState(null);

	const fetchDatas = async () => {
		try {
			const _datas = await UserEntity.getUser(userId);
			setDatas(_datas);
			console.log(_datas);
		} catch (e) {
			console.log(e);
		}
	};

	React.useEffect(() => {
		if (userDetails && userId) {
			fetchDatas();
		}
	}, [userDetails, userId]);

	return (
		<Box sx={{ display: "flex" }}>
			<Drawer
				anchor="right"
				open={userDetails || userId}
				onClose={() => history.replace(location.pathname)}
			>
				<ModalClose />
				<DialogTitle>{t("user_details")}</DialogTitle>
				<Box sx={{ mt: 2, p: 2 }}>
					{datas?.additionalDatas && (
						<List>
							{["email", "role"].map((item) =>
								datas[item] ? (
									<ListItem>
										<Typography>
											<b>{t(item)}</b>:
										</Typography>
										<Typography
											sx={{
												width: "100%",
												overflow: "hidden",
												textOverflow: "ellipsis",
											}}
										>
											{t(datas[item])}
										</Typography>
									</ListItem>
								) : null,
							)}
							<ListItem>
								<Typography>
									<b> ID: </b>
								</Typography>
								<Typography>
									<a
										target="_blank"
										href={`https://console.firebase.google.com/u/0/project/weevuptools/firestore/databases/-default-/data/users/${datas.id}?hl=fr`}
									>
										{datas.id}
									</a>
								</Typography>
							</ListItem>
						</List>
					)}
					<Divider sx={{ m: 2 }} />

					{datas?.additionalDatas && (
						<List>
							{[
								"group_name",
								"job",
								"lastname",
								"name",
								"phone",
								"profil_type",
							].map((item) =>
								datas?.additionalDatas[item] ? (
									<ListItem>
										<Typography>
											<b>{t(item)}</b>:
										</Typography>
										<Typography
											sx={{
												width: "100%",
												overflow: "hidden",
												textOverflow: "ellipsis",
											}}
										>
											{t(datas?.additionalDatas[item])}
										</Typography>
									</ListItem>
								) : null,
							)}
						</List>
					)}
					<Divider sx={{ m: 2 }} />
					<Button component={Link} to={`/admin/users/${datas?.id}`}>
						{t("show_user_details")}
					</Button>
				</Box>
			</Drawer>
		</Box>
	);
};

export default UserDetails;
UserDetails.propTypes = {};
UserDetails.defaultProps = {};
