import React from "react";
import { Typography, Box, Stack } from "@mui/joy";
import { Map } from "@mui/icons-material";

const Title = ({ nom, ville, code_postal }) => {
	return (
		<>
			<Stack spacing={1} sx={{ mt: 2 }}>
				<Typography level="h3">{nom}</Typography>
				<Stack direction="row" spacing={1}>
					<Typography color="primary">
						<Map />
						{ville}
					</Typography>
					<Typography>({code_postal})</Typography>
				</Stack>
			</Stack>
		</>
	);
};

export default Title;
