import React from "react";
import { BOLayout } from "Components/layouts";
import { WhiteContainer } from "UI";
import { useParams } from "react-router-dom";
import useEventStore from "../../EventStore";
import { Box } from "@mui/joy";
import {
	Wifi,
	Info,
	Map as LocalMap,
	LocalPhone,
	Groups,
} from "@mui/icons-material";
import { useTranslation } from "react-i18next";
import { InfoItem, Editor, TopBannerImage } from "./partials";
import useUsefullInfosStore, {
	UsefullInfosStoreContainer,
} from "./UsefullInfosStore";
import { VisualEditor } from "Common";
import Event from "models/events";

const infos = [
	{
		id: "wifi",
		label: "wifi",
		Icon: Wifi,
	},
	{
		id: "usefull_infos",
		label: "info_pratique",
		Icon: Info,
	},
	{
		id: "usefull_phone",
		label: "usefull_number",
		Icon: LocalPhone,
	},
	{
		id: "map",
		label: "map",
		Icon: LocalMap,
	},
	{
		id: "team",
		label: "team",
		Icon: Groups,
	},
];

const UsefullInfos = (props) => {
	let { eventID } = useParams();
	const { docStatus } = useEventStore.useContainer();
	const [infosState, setInfosState] = React.useState(infos);
	const { editor, usefullInfosState, setUsefullInfosState } =
		useUsefullInfosStore.useContainer();
	const { t } = useTranslation();
	const [run, setRun] = React.useState(0);
	const [datas, setDatas] = React.useState(null);
	const EventEntity = new Event();

	const fetchEvent = async () => {
		const event = await EventEntity.getEvent(eventID);
		if (event) {
			setDatas(event);
			setUsefullInfosState(event?.usefull_infos || {});
		}
	};
	const updateEvent = async (d) => {
		console.log(d);
		try {
			const eventDatas = { ...d };
			const EventEntity = new Event(eventDatas);
			const updated = await EventEntity.updateEvent(eventID);
		} catch (e) {
			console.log(e.message);
		}
	};

	React.useEffect(() => {
		fetchEvent();
	}, [eventID]);

	React.useEffect(() => {
		if (run > 1) {
			updateEvent({ usefull_infos: usefullInfosState });
		}
		setRun(run + 1);
	}, [usefullInfosState]);

	return (
		<>
			<BOLayout
				documentID={eventID}
				isDocumentStatusMonitoring
				documentStatus={docStatus}
				showEventMenu
				title="usefull_infos"
			>
				<Box sx={{ gap: 4, display: "flex", flexDirection: "row" }}>
					<Box sx={{ flexGrow: 1 }}>
						{!editor && datas ? (
							<Box>
								<WhiteContainer title="usefull_infos_image_title">
									<TopBannerImage
										t={t}
										{...datas}
										update={(img) =>
											updateEvent({
												usefull_infos_image: img,
											})
										}
										remove={() =>
											updateEvent({
												usefull_infos_image: null,
											})
										}
									/>
								</WhiteContainer>
								<WhiteContainer title="usefull_infos_list">
									<Box
										sx={{
											display: "flex",
											flexWrap: "wrap",
											gap: 2,
											justifyContent: "space-between",
										}}
									>
										{infosState.map((info) => (
											<InfoItem
												active={
													usefullInfosState[info.id]
														?.active
												}
												key={info.id}
												{...info}
											/>
										))}
									</Box>
								</WhiteContainer>
							</Box>
						) : (
							<Editor />
						)}
					</Box>
					<VisualEditor
						src={`${process.env.REACT_APP_base_url}/my_event/${eventID}/usefull_infos`}
					/>
				</Box>
			</BOLayout>
		</>
	);
};

export default UsefullInfosStoreContainer(UsefullInfos);
