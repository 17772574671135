import React from "react";
import useFacebookAuth from "../../hooks/useFacebookAuth";
import { Button, Stack, Typography } from "@mui/joy";
import { FaFacebook } from "react-icons/fa";
import { useTranslation } from "react-i18next";
import useAuthContainer from "../../../../stores/Auth";

const FacebookAuth = () => {
	const { t } = useTranslation();
	const { signIn, signingIn } = useFacebookAuth();
	return (
		<Button
			variant="outlined"
			color="neutral"
			loading={signingIn}
			onClick={() => signIn()}
			fullWidth
			loadingPosition="center"
			sx={{ mb: 1 }}
		>
			<Stack
				direction="row"
				justifyContent="center"
				alignItems="center"
				spacing={2}
			>
				<FaFacebook />
				<Typography>{t("signin_with_facebook")}</Typography>
			</Stack>
		</Button>
	);
};

export default FacebookAuth;
