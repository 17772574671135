import React from "react";
import { createContainer } from "unstated-next";

const useEventStore = createContainer((props) => {
	const [docStatus, setDocStatus] = React.useState();

	return {
		docStatus,
		setDocStatus,
	};
});

export default useEventStore;

export const EventStoreContainer = (Component) => (props) => {
	return (
		<useEventStore.Provider>
			<Component {...props} />
		</useEventStore.Provider>
	);
};
