import React from "react";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { Box, Typography, Input, Button } from "@mui/joy";
import PropTypes from "prop-types";

const Editable = ({ datas, save }) => {
	const { t } = useTranslation();

	// Initialize react-hook-form
	const {
		register,
		handleSubmit,
		formState: { errors },
	} = useForm({
		defaultValues: {
			name: datas?.name || "",
			indice_marge: datas?.indice_marge || 0,
			indice_retrocom: datas?.indice_retrocom || 0,
		},
	});

	const onSubmit = (data) => {
		save(data);
	};

	return (
		<Box>
			<form onSubmit={handleSubmit(onSubmit)}>
				{/* Name Field */}
				<Box sx={{ mb: 2 }}>
					<Typography component="label" htmlFor="name">
						{t("Name")}:
					</Typography>
					<Input
						type="text"
						id="name"
						placeholder={t("Enter your name")}
						{...register("name", {
							required: t("Name is required"),
						})}
					/>
					{errors.name && (
						<Typography color="danger">
							{errors.name.message}
						</Typography>
					)}
				</Box>

				{/* Indice Marge Field */}
				<Box sx={{ mb: 2 }}>
					<Typography component="label" htmlFor="indice_marge">
						{t("Indice Marge")} (%):
					</Typography>
					<Input
						type="number"
						id="indice_marge"
						step="0.01"
						{...register("indice_marge", {
							valueAsNumber: true,
							validate: (value) =>
								value >= 0 || t("must_be_positive_number"),
						})}
					/>
					{errors.indice_marge && (
						<Typography color="danger">
							{errors.indice_marge.message}
						</Typography>
					)}
				</Box>

				{/* Indice Retrocom Field */}
				<Box sx={{ mb: 2 }}>
					<Typography component="label" htmlFor="indice_retrocom">
						{t("Indice Retrocom")} (%):
					</Typography>
					<Input
						type="number"
						id="indice_retrocom"
						step="0.01"
						{...register("indice_retrocom", {
							valueAsNumber: true,
							validate: (value) =>
								value >= 0 || t("must_be_positive_number"),
						})}
					/>
					{errors.indice_retrocom && (
						<Typography color="danger">
							{errors.indice_retrocom.message}
						</Typography>
					)}
				</Box>

				{/* Submit Button */}
				<Button type="submit" variant="solid">
					{t("save")}
				</Button>
			</form>
		</Box>
	);
};

export default Editable;

// Define PropTypes (if necessary)
Editable.propTypes = {
	// You can define props as per your requirement
};

Editable.defaultProps = {
	// Default props if required
};
