import React from "react";
import { useTranslation } from "react-i18next";
import { Box, Typography, Button } from "@mui/joy";
import { WhiteContainer } from "UI";
import { Check } from "@mui/icons-material";
import PropTypes from "prop-types";
import { EventThemeColor, EventThemeFont } from "../commons";
import { useForm } from "react-hook-form";
import Event from "models/events";

const SaveBanner = ({ saving, saved, t }) => (
	<WhiteContainer>
		<Button
			loading={saving}
			disabled={saving}
			type="submit"
			variant={saved ? "outlined" : "solid"}
		>
			{saved && <Check />}
			{!saved ? t("save") : t("saved")}
		</Button>
	</WhiteContainer>
);

const EditEventSettings = ({ datas }) => {
	const { t } = useTranslation();
	const [saving, setSaving] = React.useState(false);
	const [saved, setSaved] = React.useState(false);
	const { handleSubmit, setValue, watch, getValues, control } = useForm({
		defaultValues: { ...(datas?.style || {}) },
	});

	const save = async (d) => {
		setSaving(true);
		try {
			const eventDatas = { style: d };
			const EventEntity = new Event(eventDatas);
			const updated = await EventEntity.updateEvent(datas.id);
			setSaved(true);
			setTimeout(() => {
				setSaved(false);
			}, 2000);
		} catch (e) {
			console.log(e.message);
		}
		setSaving(false);
	};

	return !datas ? null : (
		<>
			<form onSubmit={handleSubmit(save)}>
				<Box>
					<WhiteContainer title="theme_color">
						<EventThemeColor
							getValues={getValues}
							watch={watch}
							setValue={setValue}
						/>
					</WhiteContainer>
					<WhiteContainer title="theme_font">
						<EventThemeFont control={control} />
					</WhiteContainer>

					<Box></Box>
				</Box>
				<SaveBanner t={t} saved={saved} saving={saving} />
			</form>
		</>
	);
};

export default EditEventSettings;
EditEventSettings.propTypes = {
	datas: PropTypes.object,
};
EditEventSettings.defaultProps = {
	datas: {},
};
