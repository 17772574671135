import React from "react";
import { Switch, Route } from "react-router-dom";
import { NotificationsList } from "./views";
import { useLocation, useParams } from "react-router-dom";

const NotificationsRoutes = (props) => {
	return (
		<Switch>
			<Route exact path="/admin/notifications">
				<NotificationsList />
			</Route>
		</Switch>
	);
};

export default NotificationsRoutes;
