import React from "react";
import { useTranslation } from "react-i18next";
import { Box, Typography, IconButton, Button } from "@mui/joy";
import { Edit } from "@mui/icons-material";
import PropTypes from "prop-types";
import { Editable, View } from "./partials";
import { WhiteContainer } from "UI";
import PrivatePlace from "models/privatePlace";

const Invoicing = ({ invoice, placeId }) => {
	const { t } = useTranslation();
	const [editable, setEditable] = React.useState(false);
	const [initialInvoiceDatas, setInitialInvoiceDatas] = React.useState({});
	const PrivatePlaceEntity = React.useMemo(
		() => new PrivatePlace(placeId),
		[placeId],
	);

	React.useEffect(() => {
		setInitialInvoiceDatas(invoice);
	}, [invoice]);

	const saveInvoice = async (datas) => {
		setInitialInvoiceDatas(datas);
		PrivatePlaceEntity.facturation = datas;
		setEditable(false);
		await PrivatePlaceEntity.updatePrivateDocument("datas");
	};

	return (
		<WhiteContainer title="invoicing">
			{!editable && (
				<Button onClick={() => setEditable(true)}>
					<Edit /> {t("edit")}
				</Button>
			)}
			{editable ? (
				<Editable
					saveInvoice={saveInvoice}
					datas={initialInvoiceDatas}
					close={() => setEditable(false)}
				/>
			) : (
				<View invoice={initialInvoiceDatas} />
			)}
		</WhiteContainer>
	);
};

export default Invoicing;
Invoicing.propTypes = {};
Invoicing.defaultProps = {};
