import React from "react";
import PropTypes from "prop-types";
import { ArrowBack, Check } from "@mui/icons-material";
import { Link } from "react-router-dom";
import { WhiteContainer } from "UI";
import { useTranslation } from "react-i18next";
import {
	Box,
	Stepper,
	Step,
	StepLabel,
	Button,
	Typography,
	StepIndicator,
} from "@mui/joy";
import { Role, Infos, Email } from "./partials";

const CreateUser = (props) => {
	const { t } = useTranslation();
	const steps = [
		{
			id: 1,
			label: t("user_role"),
			Component: Role,
		},
		{
			id: 2,
			label: t("user_infos"),
			Component: Infos,
		},
		{
			id: 3,
			label: t("user_email"),
			Component: Email,
		},
	];
	const [formDatas, setFormDatas] = React.useState({});
	const [activeStep, setActiveStep] = React.useState(0);
	const Component = steps[activeStep].Component;

	return (
		<>
			<WhiteContainer>
				<Button component={Link} to={"/admin/users"}>
					<ArrowBack /> {t("back_to_user_list")}
				</Button>
			</WhiteContainer>
			<WhiteContainer title="create_new_user">
				<Stepper activeStep={activeStep}>
					{steps.map((step) => {
						return (
							<Step
								indicator={
									<StepIndicator
										variant="solid"
										color="primary"
									>
										{activeStep === step.id ? (
											<Check />
										) : (
											step.id
										)}
									</StepIndicator>
								}
								key={step.id}
							>
								{step.label}
							</Step>
						);
					})}
				</Stepper>
				<Box>
					<Component
						formDatas={formDatas}
						setFormDatas={setFormDatas}
						setActiveStep={setActiveStep}
						nextStep={() =>
							steps.length >= activeStep + 1 &&
							setActiveStep(activeStep + 1)
						}
						prevStep={() =>
							activeStep - 1 >= 0 && setActiveStep(activeStep - 1)
						}
					/>
				</Box>
			</WhiteContainer>
		</>
	);
};

export default CreateUser;
CreateUser.propTypes = {};
CreateUser.defaultProps = {};
