import React from "react";
import { useTranslation } from "react-i18next";
import { Box, Typography } from "@mui/joy";
import PropTypes from "prop-types";
import { WhiteContainer, AdvancedAccordion } from "UI";
import RseActions from "datas/places/places_rse_actions.json";

const ActionList = ({ actionsRse, setDatas }) => {
	const { t } = useTranslation();

	return (
		<>
			<WhiteContainer title="rse_place_title">
				<Typography sx={{ fontSize: "12px", mt: 1 }}>
					{t("rse_place_param")}
				</Typography>
				<AdvancedAccordion
					data={RseActions}
					selectedActions={actionsRse}
					onSave={setDatas}
					editable
				/>
			</WhiteContainer>
		</>
	);
};

export default ActionList;
ActionList.propTypes = {};
ActionList.defaultProps = {};
