import FirestoreService from "services/firestoreServices";
import cloudFunctionsServices from "services/cloudFunctionsServices";

import { where, orderBy } from "firebase/firestore";

/**
 * Creates an instance of the Notification class.
 * @constructor
 * @param {dateTime} params.created_at - date de création.
 * @param {String} params.message - message de la notification.
 * @param {DateTime} params.modified_at - date de modification.
 * @param {String} params.object - Object ( pour l'email).
 * @param {Bool} params.sendEmail - Si true déclenche l'envoie d'un mail.
 * @param {String} params.type - Type de notification.
 * @param {Object} params.datas - Données .
 * @param {bool} params.read - Données .
 * @param {Array} params.uids - Liste des UIDS destinataires.
 */
class Notification {
    constructor(params) {
        Object.assign(this, params);
        this.modified_at = new Date();
        this.firestoreService = new FirestoreService("notifications");
    }

    async getNotification(id) {
        try {
            const notification = await this.firestoreService.getDocument(id);
            return notification;
        } catch (error) {
            console.error("Failed to fetch notification:", error);
            throw error;
        }
    }

    async getNotifications(lastVisible = null, constraints = []) {
        try {
            const result = await this.firestoreService.getDocuments(
                constraints,
                false,
                null,
                10,
                lastVisible,
            );
            if (lastVisible) {
                return {
                    documents: result.documents,
                    lastVisible: result.lastVisible,
                    no_more_data: result.no_more_data,
                };
            } else {
                return result;
            }
        } catch (error) {
            console.error("Failed to fetch notifications:", error);
            throw error;
        }
    }

    async addNotification() {
        try {
            const notification = await this.firestoreService.addDocument(
                this.toJSON(),
            );
            return { id: notification.id, ...this.toJSON() };
        } catch (error) {
            console.error("Failed to add notification:", error);
            throw error;
        }
    }

    async deleteNotification(id) {
        try {
            await this.firestoreService.deleteDocument(id);
        } catch (error) {
            console.error("Failed to delete notification:", error);
            throw error;
        }
    }

    async updateNotification(id) {
        try {
            const updatedNotification =
                await this.firestoreService.updateDocument(id, this.toJSON());
            return updatedNotification;
        } catch (error) {
            console.error("Failed to update notification:", error);
            throw error;
        }
    }

    toJSON() {
        const data = {};
        const properties = {
            created_at: this.created_at,
            message: this.message,
            modified_at: this.modified_at,
            object: this.object,
            read: this.read,
            sendEmail: this.sendEmail,
            type: this.type,
            datas: this.datas,
            uids: this.uids,
        };

        // Add only properties that are not undefined
        for (let key in properties) {
            if (properties[key] !== undefined) {
                data[key] = properties[key];
            }
        }

        return data;
    }
}

export default Notification;
