import React from "react";
import { Typography, Box, Stack, IconButton, Input, Textarea } from "@mui/joy";
import { useController } from "react-hook-form";
import { Edit, Save, Cancel } from "@mui/icons-material";
import { useTranslation } from "react-i18next";
import TextField from "@mui/material/TextField";
import InputAdornment from "@mui/material/InputAdornment";

const EditableTextField = ({
	label,
	control,
	fieldName,
	typo,
	field,
	defaultValue,
	reactHookForm,
	rules,
	step,
	action,
	link,
	reset,
	endDecoratorTypo,
	focus,
	isButton,
	type,
	buttonStyle,
	multiline,
	info,
	adornment,
}) => {
	const { t } = useTranslation();
	const {
		field: { onChange, onBlur, name, value, ref },
		fieldState: { error, isTouched, isDirty },
	} = useController({
		name: fieldName,
		control,
		rules,
	});

	const [editable, setEditable] = React.useState(false);
	const [oldVal, setOldVal] = React.useState(value);
	const save = (e) => {
		e.preventDefault();
		if (!error) {
			setEditable(false);
			action && (reactHookForm ? action(value)() : action(value));
		}
	};

	const inputProps = {
		sx: {
			display: editable ? "inline-flex" : "none",
			...field,
		},
		fullWidth: true,
		step: step,
		onChange: onChange, // send value to hook form
		onBlur: () => {
			onBlur();
		}, // notify when input is touched/blur
		value: value, // input value
		name: name, // send down the input name
		inputRef: ref,
		variant: "outlined",
		label: t(label),
		type: type,
		multiline: multiline,
		color: error && "danger",
		InputProps: adornment
			? {
					startAdornment: (
						<InputAdornment position="start">
							{adornment}
						</InputAdornment>
					),
			  }
			: null,
	};

	React.useEffect(() => focus(name), [ref]);

	return (
		<Box>
			<form onSubmit={save}>
				<Stack
					sx={{
						display: editable ? "flex" : "none",
					}}
					direction="row"
					spacing={2}
				>
					{multiline ? (
						<Textarea fullWidth {...inputProps} />
					) : (
						<Input fullWidth {...inputProps} />
					)}
					<IconButton
						type={isButton ? "button" : "submit"}
						color="primary"
						onClick={(e) => {
							if (isButton) save(e);
						}}
						sx={{
							backgroundColor: "secondary.main",
						}}
					>
						<Save />
					</IconButton>
				</Stack>

				<Box>
					<Typography
						sx={{
							display: !editable ? "flex" : "none",
							fontSize: "12px",
						}}
					>
						{t(label)}
					</Typography>
					<Stack
						sx={{
							justifyContent: {
								xs: "space-between",
								sm: "normal",
							},

							".MuiSvgIcon-root": {
								opacity: 1,
								transition: "ease 200ms",
							},
							"&:hover": {
								".MuiSvgIcon-root": {
									opacity: 1,
								},
							},
							display: !editable ? "flex" : "none",
						}}
						alignItems="center"
						direction="row"
						spacing={2}
					>
						<Typography
							component={link ? "a" : Typography}
							href={value || defaultValue}
							onClick={() => {
								setEditable(true);
								setOldVal(value);
								focus(name);
							}}
							target="_blank"
							sx={(theme) => ({
								whiteSpace: "break-spaces",
								maxWidth: "62vw",
								overflow: "hidden",
								p: 2,
								borderRadius: 5,
								textOverflow: "ellipsis",
								"&:hover": {
									backgroundColor:
										theme.vars.palette.primary[10],
								},

								...typo,
							})}
						>
							{value || defaultValue} {adornment}
						</Typography>
						<IconButton
							type="button"
							color="primary"
							sx={{ ...buttonStyle }}
							onClick={() => {
								setEditable(true);
								setOldVal(value);
							}}
						>
							<Edit />
						</IconButton>
					</Stack>

					<Typography
						sx={{
							fontSize: "12px",
							fontStyle: "italic",
						}}
					>
						{info}
					</Typography>
				</Box>
			</form>
		</Box>
	);
};

export default EditableTextField;
