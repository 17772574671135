import React from "react";
import { useTranslation } from "react-i18next";
import { Box, Typography, Stack, Button, Switch } from "@mui/joy";
import PropTypes from "prop-types";
import { InsertDriveFile } from "@mui/icons-material";

const filters = [
	{
		filter: "Documents",
		value: "application/",
	},
	{
		filter: "Images",
		value: "image/",
	},
];

const handlePreview = (thumbnail, url, contentType) =>
	contentType.startsWith("image/") ? (
		<Box
			sx={{
				width: 50,
				height: 50,
				background: `url("${thumbnail || url}") center center / cover`,
			}}
		></Box>
	) : (
		<InsertDriveFile />
	);

const Item = ({ name, url, thumbnail, contentType }) => {
	return (
		<Box
			component={"a"}
			href={url}
			target="_blank"
			sx={{
				display: "flex",
				flexDirection: "row",
				columnGap: "4px",
				alignItems: "center",
				border: "1px solid #eee",
				m: " 0 3px",
			}}
		>
			{handlePreview(thumbnail, url, contentType)}
			<Typography
				sx={{
					whiteSpace: "nowrap",
					width: "183px",
					overflow: "hidden",
					textOverflow: "ellipsis",
					fontSize: "12px",
					p: 1,
				}}
			>
				{name}
			</Typography>
		</Box>
	);
};

const FilterList = ({ t, filter, setFilter }) => (
	<Stack direction="row" spacing={1}>
		{filters.map((_filter) => (
			<Button
				onClick={() => setFilter({ ...filter, type: _filter.value })}
				variant={_filter.value === filter.type ? "solid" : "outlined"}
				key={_filter.filter}
			>
				{t(_filter?.filter)}
			</Button>
		))}
	</Stack>
);

const List = ({ docs, filt, setFilt }) => {
	const { t } = useTranslation();

	return (
		<Box>
			<Stack spacing={1}>
				<FilterList filter={filt} setFilter={setFilt} t={t} />

				<Typography
					startDecorator={
						<Switch
							checked={filt.place}
							onClick={(v) => {
								setFilt({ ...filt, place: v.target.checked });
							}}
						/>
					}
				>
					{t("only_place_assets")}
				</Typography>
			</Stack>
			<Box
				sx={{
					pt: 2,
					mt: 1,
					height: "300px",
					overflow: "auto",
					display: "flex",
					flexDirection: "column",
					rowGap: 1,
				}}
			>
				{docs.map((item) => (
					<Item key={item.id} {...item} />
				))}
			</Box>
		</Box>
	);
};

export default List;
List.propTypes = {};
List.defaultProps = {};
