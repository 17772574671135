import React from "react";
import { Button, Stack, IconButton, Box } from "@mui/joy";
import { Delete } from "@mui/icons-material";
import useBuilderContainer from "../../BuilderStore";
import Helpers from "../../../../../utils/Helpers";

const GlobalActions = (props) => {
	const { checked, setContentState, contentState, setChecked } =
		useBuilderContainer.useContainer();

	const del = () => {
		const content = [...contentState];
		checked.forEach((k) => {
			Helpers.deepArrayDelete(content, k);
		});
		setContentState(content);
		setChecked([]);
	};

	return checked && checked.length > 0 ? (
		<Box>
			<Stack>
				<IconButton variant="outlined" color="danger" onClick={del}>
					<Delete />
				</IconButton>
			</Stack>
		</Box>
	) : null;
};

export default GlobalActions;
