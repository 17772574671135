import React from "react";
import { useTranslation } from "react-i18next";
import { Box, Typography, Switch, Stack } from "@mui/joy";
import PropTypes from "prop-types";
import NotificationsList from "datas/notifications/notifications_list.json";

const Notifications = ({ subscribtions, update }) => {
	const { t } = useTranslation();
	const adminNotifications = NotificationsList.filter((notif) =>
		notif.for.includes("admins"),
	);
	const handleChange = (e, notif) => {
		const v = e.target.checked;
		v
			? update([...subscribtions, notif])
			: update(subscribtions.filter((n) => n !== notif));
	};

	return (
		<Box>
			<Typography level="h4" sx={{ fontSize: "16px" }}>
				{t("Notifications")}
			</Typography>
			<Typography paragraph variant="body_2" sx={{ mb: 2 }}>
				{t("check_to_receive_notifications")}
			</Typography>
			<Stack spacing={1}>
				{adminNotifications.map((notif) => (
					<Typography
						startDecorator={
							<Switch
								checked={subscribtions.includes(
									notif.notification,
								)}
								onChange={(e) =>
									handleChange(e, notif.notification)
								}
							/>
						}
						key={notif.notification}
					>
						{t(notif.notification)}
					</Typography>
				))}
			</Stack>
		</Box>
	);
};

export default Notifications;
Notifications.propTypes = {};
Notifications.defaultProps = {};
