import React from "react";
import { Box, Typography, AspectRatio } from "@mui/joy";
import PropTypes from "prop-types";

const Banner = ({ title, desc, bgImage, logo, style }) => {
	return (
		<Box
			sx={{
				background: `url(${
					bgImage?.full || bgImage
				}) fixed center center / cover `,
				position: "relative",
				pt: 4,
				maxWidth: "852px",
				margin: "0 auto",
				pb: 4,
			}}
		>
			<Box
				sx={{
					background: `#000000b5`,
					position: "absolute",
					top: 0,
					bottom: 0,
					left: 0,
					right: 0,
				}}
			/>
			<Box
				sx={{
					display: "flex",
					flexDirection: "column",
					justifyContent: "center",
					alignItems: "center",
					verticalAlign: "center",
					gap: 2,
					position: "relative",
					zIndex: 2,
				}}
			>
				{logo && (
					<AspectRatio variant="plain" sx={{ width: 200 }}>
						<img src={logo?.full || logo} />
					</AspectRatio>
				)}

				<Typography
					level="h4"
					sx={{
						textAlign: "center",
						fontFamily: style?.theme_font,
						color: "#FFFFFF",
						p: "0 10px",
					}}
				>
					{title}
				</Typography>
				<Typography
					sx={{
						textAlign: "center",
						p: "0 10px",
						fontFamily: style?.theme_font,
						color: "#FFFFFF",
					}}
				>
					{desc}
				</Typography>
			</Box>
		</Box>
	);
};

export default Banner;

Banner.propTypes = {
	title: PropTypes.string,
	desc: PropTypes.string,
	bgImage: PropTypes.string,
	logo: PropTypes.string,
	eventDatas: PropTypes.object,
};

Banner.defaultProps = {
	title: "TITLE",
	desc: "",
	bgImage: "",
	logo: "",
	style: {
		theme_color: "#444444",
		theme_font: "Arial",
	},
};
