import React from "react";
import { Box } from "@mui/joy";
import {
	IconField,
	DateRange,
	InputText,
	InputTextarea,
	ImageFirebase,
	Visibility,
	SectionLink,
	ImageGallery,
	SimpleImage,
} from "./partials";
import useBuilderContainer from "../../../../BuilderStore";
import Helpers from "../../../../../../../utils/Helpers";

const FieldsRouter = ({ fields, content }) => {
	const {
		setContentState,
		contentState,
		config: conf,
	} = useBuilderContainer.useContainer();

	const update = (d) => {
		setContentState(Helpers.deepArrayUpdate(contentState, d, content.id));
	};

	const depth = parseInt(Helpers.findDepthById(contentState, content.id));
	const formatedDepth = `depth_${depth}`;

	const config = conf.itemsConfig[formatedDepth]
		? conf.itemsConfig[formatedDepth]
		: conf.itemsConfig.default;

	let fieldsComponents = [];
	fields &&
		fields.forEach((field) => {
			console.log(field);
			switch (field.type) {
				case "text":
					fieldsComponents.push(
						<InputText
							config={config}
							update={update}
							defaultValue={content[field.label]}
							{...field}
						/>,
					);
					break;
				case "section-link":
					fieldsComponents.push(
						<SectionLink
							config={config}
							update={update}
							defaultValue={content[field.label]}
							{...field}
						/>,
					);
					break;
				case "image-gallery":
					fieldsComponents.push(
						<ImageGallery
							config={config}
							update={update}
							defaultValue={content[field.label]}
							{...field}
						/>,
					);
					break;
				case "simple-image":
					fieldsComponents.push(
						<SimpleImage
							config={config}
							update={update}
							defaultValue={content[field.label]}
							{...field}
						/>,
					);
					break;
				case "date":
					fieldsComponents.push(
						<InputText
							config={config}
							update={update}
							defaultValue={content[field.label]}
							{...field}
						/>,
					);
					break;
				case "number":
					fieldsComponents.push(
						<InputText
							config={config}
							update={update}
							defaultValue={content[field.label]}
							{...field}
						/>,
					);
					break;
				case "icon":
					fieldsComponents.push(
						<IconField
							config={config}
							update={update}
							defaultValue={content[field.label]}
							{...field}
						/>,
					);
					break;
				case "date-range":
					fieldsComponents.push(
						<DateRange
							config={config}
							update={update}
							defaultValue={content[field.label]}
							{...field}
						/>,
					);
					break;
				case "time":
					fieldsComponents.push(
						<InputText
							config={config}
							update={update}
							defaultValue={content[field.label]}
							{...field}
						/>,
					);
					break;
				case "image-firebase":
					fieldsComponents.push(
						<ImageFirebase
							config={config}
							compression={field?.compression}
							update={update}
							defaultValue={content[field.label]}
							{...field}
							id={content?.id}
						/>,
					);
					break;
				case "textarea":
					fieldsComponents.push(
						<InputTextarea
							config={config}
							update={update}
							defaultValue={content[field.label]}
							{...field}
						/>,
					);
					break;
				case "visibility":
					fieldsComponents.push(
						<Visibility
							config={config}
							update={update}
							defaultValue={content[field.label]}
							{...field}
						/>,
					);
					break;
				default:
					return null;
					break;
			}
		});

	return fieldsComponents;
};

export default FieldsRouter;
