import React from "react";
import { useTranslation } from "react-i18next";
import { Box, Typography } from "@mui/joy";
import PropTypes from "prop-types";
import { useParams } from "react-router-dom";
import Meals from "models/meals";
import { MealsList, ElementModal } from "./partials";

const MealsFront = ({ style }) => {
	const { t } = useTranslation();
	const { eventID } = useParams();
	const [datas, setDatas] = React.useState([]);
	const MealsEntity = new Meals();
	const [selectedElement, setSelectedElement] = React.useState(null);

	const fetchDatas = async () => {
		try {
			MealsEntity.getMealsByEventId(eventID, true, (d) => {
				setDatas(d[0]);
			});
		} catch (e) {
			console.log(e.message);
		}
	};
	React.useEffect(() => {
		fetchDatas();
	}, eventID);

	return (
		<>
			{datas && (
				<>
					<MealsList
						style={style}
						setSelectedElement={setSelectedElement}
						selectedElement={selectedElement}
						activities={datas?.content}
					/>
					<ElementModal
						t={t}
						style={style}
						setSelectedElement={setSelectedElement}
						selectedElement={selectedElement}
					/>
				</>
			)}
		</>
	);
};

export default MealsFront;
MealsFront.propTypes = {};
MealsFront.defaultProps = {};
