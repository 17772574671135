import React, { useState, useEffect, useCallback } from "react";
import { useTranslation } from "react-i18next";
import { WhiteContainer } from "UI";
import { Editable, View } from "./partials";
import PrivatePlace from "models/privatePlace";
import Helpers from "utils/Helpers";
import useAuthContainer from "stores/Auth";
import { v4 as uuidv4 } from "uuid";

const Notes = ({ notes: initialNotes, placeId }) => {
	const { t } = useTranslation();
	const [notes, setNotes] = useState(initialNotes);
	const [selected, setSelected] = useState(null);
	const [open, setOpen] = useState(false);
	const { user, userAccount } = useAuthContainer.useContainer();
	const PrivatePlaceEntity = React.useMemo(
		() => new PrivatePlace(placeId),
		[placeId],
	);

	useEffect(() => {
		setNotes(initialNotes);
	}, [initialNotes]);

	const saveNote = useCallback(
		async (data) => {
			const updatedNotes = selected
				? Helpers.deepArrayUpdate(notes, data, data.id)
				: [
						...notes,
						{
							by: `${userAccount?.additionalDatas?.name || ""} ${
								userAccount?.additionalDatas?.lastname || ""
							}`,
							uid: user.uid,
							id: uuidv4(),
							...data,
						},
				  ];

			setNotes(updatedNotes);
			PrivatePlaceEntity.notes = updatedNotes;
			await PrivatePlaceEntity.updatePrivateDocument("datas");
		},
		[
			notes,
			selected,
			user.uid,
			userAccount?.additionalDatas,
			PrivatePlaceEntity,
		],
	);

	const deleteNote = useCallback(
		async (id) => {
			const updatedNotes = notes.filter((note) => note.id !== id);
			setNotes(updatedNotes);
			PrivatePlaceEntity.notes = updatedNotes;
			await PrivatePlaceEntity.updatePrivateDocument("datas");
		},
		[notes, PrivatePlaceEntity],
	);

	return (
		<WhiteContainer title={t("notes")}>
			<View
				notes={notes}
				select={(note) => {
					setSelected(note);
					setOpen(true);
				}}
			/>
			<Editable
				deleteNote={deleteNote}
				saveNote={saveNote}
				open={open}
				close={() => setOpen(false)}
				selected={selected}
			/>
		</WhiteContainer>
	);
};

export default Notes;
