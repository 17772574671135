import React from "react";
import { useForm } from "react-hook-form";
import { Divider, FormControl, FormLabel, Typography, Box } from "@mui/joy";
import { useTranslation } from "react-i18next";
import { EditableTextField } from "../../../../components/UI";

const Profil = ({ profil, setProfil }) => {
	const { reset, control, setFocus, handleSubmit } = useForm({
		defaultValue: { ...profil },
	});
	const { t } = useTranslation();

	const save = (d) => {
		setProfil({ ...profil, ...d });
	};

	React.useEffect(() => {
		reset(profil);
	}, [profil]);

	return (
		<>
			<Typography
				sx={{
					mb: 2,
				}}
				level="h4"
			>
				{t("Profil")}
			</Typography>
			<Box
				sx={{
					backgroundColor: "white",
					p: 2,
				}}
			>
				<EditableTextField
					label="name"
					focus={setFocus}
					control={control}
					action={handleSubmit(save)}
					fullWidth
					fieldName="profil_name"
					rules={{ required: false }}
				/>
				<EditableTextField
					label="job_to_find"
					focus={setFocus}
					control={control}
					action={handleSubmit(save)}
					fieldName="profil_job"
					fullWidth
					rules={{ required: false }}
				/>
				<EditableTextField
					multiline
					fullWidth
					label="profil_intro"
					focus={setFocus}
					control={control}
					action={handleSubmit(save)}
					fieldName="profil_description"
					rules={{ required: false }}
				/>
			</Box>

			<Divider sx={{ m: 3 }} />
		</>
	);
};

export default Profil;
