import React, { useState, useEffect, useCallback } from "react";
import { useTranslation } from "react-i18next";
import {
	Box,
	Typography,
	Select,
	Option,
	FormControl,
	FormLabel,
} from "@mui/joy";
import PropTypes from "prop-types";
import Meals from "models/meals";
import Activities from "models/activities";
import Planning from "models/plannings";
import Helpers from "utils/Helpers";

const sections = ["Planning", "Meals", "Activities"];
const entities = {
	Planning: new Planning(),
	Activities: new Activities(),
	Meals: new Meals(),
};

const SectionLink = ({ eventId, defaultValue, update, label }) => {
	const { t } = useTranslation();
	const [change, setChange] = useState("none");
	const [items, setItems] = useState([]);
	const [selectedItem, setSelectedItem] = useState(null);

	const fetchItems = useCallback(
		async (v) => {
			setChange(v);

			if (v === "none") {
				setItems([]);
				update({
					[label]: {
						item: null,
						section: null,
					},
				});
				return;
			}

			const Entity = entities[v];
			const datas = await Entity[`get${v}ByEventId`](eventId);
			const flattenedArray = Helpers.flattenHierarchy(
				datas?.content || [],
			);
			setItems(flattenedArray);
		},
		[eventId],
	);

	const selectItem = useCallback(
		(v) => {
			setSelectedItem(v);
			const item = items.find((d) => d.id === v);
			update({
				[label]: {
					item: v,
					section: change,
					itemName: item?.title || "",
				},
			});
		},
		[items, update, label, change],
	);

	useEffect(() => {
		if (defaultValue?.section) {
			fetchItems(defaultValue.section);
		}
	}, [defaultValue, fetchItems]);

	useEffect(() => {
		if (!selectedItem && defaultValue?.item) {
			setSelectedItem(defaultValue.item);
		}
	}, [items, defaultValue, selectedItem]);

	return (
		<Box sx={{ border: "1px solid #EEE", p: 1 }}>
			<FormControl sx={{ mb: 2 }}>
				<FormLabel sx={{ fontSize: "12px" }}>
					{t("link_to_other_section")}
				</FormLabel>
				<Select value={change} onChange={(e, v) => fetchItems(v)}>
					<Option value="none">{t("none")}</Option>
					{sections.map((section) => (
						<Option key={section} value={section}>
							{t(section)}
						</Option>
					))}
				</Select>
			</FormControl>
			{items.length > 0 && (
				<FormControl sx={{ mb: 2 }}>
					<FormLabel sx={{ fontSize: "12px" }}>
						{t("choose_an_item")}{" "}
					</FormLabel>
					<Select
						value={selectedItem}
						onChange={(e, v) => selectItem(v)}
					>
						{items.map((item) => (
							<Option key={item.id} value={item.id}>
								{item?.title}
							</Option>
						))}
					</Select>
				</FormControl>
			)}
		</Box>
	);
};

SectionLink.propTypes = {
	eventId: PropTypes.string.isRequired,
	defaultValue: PropTypes.shape({
		section: PropTypes.string,
		item: PropTypes.string,
	}),
	update: PropTypes.func.isRequired,
	label: PropTypes.string.isRequired,
};

SectionLink.defaultProps = {
	defaultValue: {
		section: "none",
		item: null,
	},
};

export default SectionLink;
