import React from "react";
import { Box, Typography, Stack } from "@mui/joy";
import { useParams, Link, useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";
import Meals from "models/meals";

const MealsItems = ({ darkMode, title, image, eventID, id }) => {
	const history = useHistory();
	return (
		<Box onClick={() => history.push(`/my_event/${eventID}/meals#${id}`)}>
			<Box
				sx={{
					borderRadius: "12px",
					width: "284px",
					height: "156px",
					background: `url("${
						image?.thumb || image?.full || image
					}") center center /cover`,
				}}
			></Box>
			<Typography
				sx={{
					fontWeight: "bold",
					mt: 1,
					color: darkMode ? "white" : "initial",
				}}
			>
				{title}
			</Typography>
		</Box>
	);
};

const MealsHomeCarousel = ({ style }) => {
	const { t } = useTranslation();
	const { eventID } = useParams();
	const [datas, setDatas] = React.useState([]);

	const MealsEntity = new Meals();

	const fetchDatas = async () => {
		try {
			MealsEntity.getMealsByEventId(eventID, true, (d) => {
				setDatas(d[0]);
			});
		} catch (e) {
			console.log(e.message);
		}
	};
	React.useEffect(() => {
		fetchDatas();
	}, eventID);

	return (
		<Box sx={{ pt: 4, pb: 4, borderTop: "1px solid #eee" }}>
			<Stack
				sx={{ mb: 2 }}
				justifyContent="space-between"
				direction="row"
			>
				<Typography
					sx={{ fontSize: "20px", color: style?.theme_color }}
				>
					{t("meals")}
				</Typography>
				<Link
					style={{
						color: style?.theme_color,
						textDecoration: "none",
					}}
					to={`/my_event/${eventID}/meals`}
				>
					{t("view_all")}
				</Link>
			</Stack>
			<Box
				sx={{
					"&::-webkit-scrollbar": {
						display: "none",
					},
					"-ms-overflow-style": "none" /* IE and Edge */,
					scrollbarWidth: "none",
					display: "flex",
					overflow: "auto",
					columnGap: "15px",
				}}
			>
				{datas &&
					datas?.content?.length > 0 &&
					datas?.content.map((activity) => (
						<MealsItems
							key={activity.id}
							{...activity}
							t={t}
							darkMode={style?.theme_mode === "dark"}
							eventID={eventID}
						/>
					))}
			</Box>
		</Box>
	);
};

export default MealsHomeCarousel;
