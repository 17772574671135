import React from "react";
import { storage, auth } from "../../utils/MyFirebase";
import {
	ref,
	uploadBytes,
	getDownloadURL,
	deleteObject,
} from "firebase/storage";

const useFileUploader = () => {
	const [filesUploaded, setFilesUploaded] = React.useState([]);
	const [uploading, setUploading] = React.useState(false);

	// Fonction pour uploader un fichier unique
	const _uploadAfile = async (
		file,
		path,
		customName = null,
		userId,
		fullPath,
	) => {
		try {
			const fileExtension = file.name.split(".").pop(); // Extract the file extension
			const name = customName
				? `${customName}.${fileExtension}`
				: file.name;
			const storageRef = ref(
				storage,

				fullPath
					? `${fullPath}/${name}`
					: `users/${userId}/${path}/${name}`,
			);

			const snapshot = await uploadBytes(storageRef, file);
			const url = await getDownloadURL(snapshot.ref);

			// Retourne l'URL et les détails du fichier
			return {
				name: snapshot.ref.name,
				url,
				ref: snapshot.ref,
			};
		} catch (error) {
			console.error("File upload error:", error);
			throw new Error(`Error uploading file: ${file.name}`);
		}
	};

	// Fonction principale pour uploader plusieurs fichiers
	const firebaseUploader = async (
		files,
		path,
		names = [],
		fullPath = null,
	) => {
		setUploading(true);

		try {
			const user = auth.currentUser; // Récupère l'utilisateur une seule fois
			if (!user) {
				throw new Error("User not authenticated");
			}

			// Téléchargement des fichiers avec Promise.all
			const uploadedFiles = await Promise.all(
				files.map((file, index) => {
					const customName = names[index] || null;
					return _uploadAfile(
						file,
						path,
						customName,
						user.uid,
						fullPath,
					);
				}),
			);

			// Mise à jour de l'état une fois tous les fichiers téléchargés
			setFilesUploaded(uploadedFiles);
			return uploadedFiles;
		} catch (error) {
			console.error("Upload failed", error);
			alert("An error occurred during file upload.");
			return []; // Retourne un tableau vide en cas d'erreur
		} finally {
			setUploading(false); // Assure que le chargement est terminé
		}
	};
	const deleteFileFromStorage = async (fileUrl) => {
		try {
			const fileRef = ref(storage, fileUrl); // Référence du fichier via son URL
			await deleteObject(fileRef);
			console.log("File deleted successfully from storage");
		} catch (error) {
			console.error("Failed to delete file from storage", error);
			throw error;
		}
	};

	return {
		firebaseUploader,
		filesUploaded,
		uploading,
		deleteFileFromStorage,
	};
};

export default useFileUploader;
