import React from "react";
import { Box, Typography, Stack, Button } from "@mui/joy";
import { KeyboardArrowRight } from "@mui/icons-material";

const SlideItem = ({
	title,
	image,
	button,
	link,
	list,
	overlay,
	color,
	overlay_color,
	text_background,
	text_background_color,
}) => {
	console.log(list);
	return (
		<Box
			onClick={() => link && window.open(link, "_blank")}
			sx={{
				cursor: link && "pointer",
				height: "250px",
				width: "auto",
				background: `url(${image}) center top / cover `,
				position: "relative",
				p: "30px 50px",
			}}
		>
			<Box
				sx={{
					display: !overlay ? "none" : "block",
					background: `${overlay_color || "#000000"}20`,
					position: "absolute",
					top: 0,
					left: 0,
					width: "100%",
					height: "100%",
				}}
			></Box>
			<Stack
				direction="row"
				justifyContent="space-between"
				sx={{
					position: "relative",
					zIndex: 1,

					height: "100%",
					"@media (max-width:712px)": {
						justifyContent: "center",
					},
				}}
			>
				<Box
					sx={{
						"@media (max-width:712px)": {
							display: "none",
						},
					}}
				>
					{list && (
						<ul
							style={{
								background:
									text_background &&
									`${text_background_color}e0`,
								borderRadius: "15px",
								padding: "9px 34px",
								textAlign: "left",
								listStyle: "disc",
								color: color,
								fontSize: "22px",
								fontWeight: "bold",
							}}
						>
							{list.map((l) => (
								<li>{l}</li>
							))}
						</ul>
					)}
				</Box>
				<Box
					sx={{
						display: "flex",

						flexDirection: "column",
						justifyContent: "space-between",
						height: "100%",
					}}
				>
					{title && (
						<Typography
							sx={{
								background: color,
								p: "5px 20px",
								borderRadius: "10px",
								color: "white",
								fontSize: "22px",
							}}
						>
							{title}
						</Typography>
					)}
					{button && (
						<Button
							sx={{
								p: "8px 20px",
								borderRadius: "100px",
								background: "white",
								fontSize: "20px",

								color: color,
								"&:hover": {
									background: color,
									color: "white",
								},
							}}
							component="a"
							target="_blank"
							href={link}
						>
							{button} <KeyboardArrowRight />
						</Button>
					)}
				</Box>
			</Stack>
		</Box>
	);
};

export default SlideItem;
