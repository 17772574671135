import React from "react";
import { Switch, Route } from "react-router-dom";
import { EventTemplateList } from "./views";

const EventTemplatesRoutes = () => {
	return (
		<Switch>
			<Route path="/event/templates">
				<EventTemplateList />
			</Route>
		</Switch>
	);
};

export default EventTemplatesRoutes;
