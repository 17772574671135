import React from "react";
import { Wifi, UsefullInfos, UsefullPhone, Plan, Team } from "./partials";

const EditorRouter = ({ id }) => {
	let Component = null;

	switch (id) {
		case "wifi":
			Component = <Wifi id={id} />;
			break;
		case "usefull_infos":
			Component = <UsefullInfos id={id} />;
			break;
		case "usefull_phone":
			Component = <UsefullPhone id={id} />;
			break;
		case "map":
			Component = <Plan id={id} />;
			break;
		case "team":
			Component = <Team id={id} />;
			break;

		default:
			Component = Component;
			break;
	}

	return Component;
};

export default EditorRouter;
