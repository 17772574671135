import React from "react";
import useCVContainer from "../../CVStore";
import { Grid, Box, Typography } from "@mui/joy";

const Modern = (props) => {
	const { experiences, color } = useCVContainer.useContainer();

	return (
		<>
			<Grid container sx={{ minHeight: "100%" }}>
				<Grid
					md={4}
					sx={4}
					lg={4}
					sx={{
						borderRight: `3px dashed ${color}`,
						minHeight: "100%",
						p: 2,
					}}
				></Grid>
				<Grid md={8} sx={8} lg={8} sx={{ p: 2 }}>
					{experiences.map((xp) => (
						<Box sx={{ mb: 3 }}>
							<Typography sx={{ fontSize: "13px" }}>
								{(xp?.period && parseInt(xp?.period[0])) || ""}-
								{(xp?.period && parseInt(xp?.period[1])) || ""}
							</Typography>
							<Typography
								level="h5"
								sx={{
									mb: 1,
									borderBottom: `2px solid ${color}`,
								}}
							>
								{xp?.title}
							</Typography>
							<Typography level="body" sx={{ mb: 1 }}>
								<b> {xp?.compagny_name}</b>
							</Typography>
							<Typography level="body">
								{xp?.description}
							</Typography>
							<ul>
								{xp?.children.map((task) => (
									<li>
										<Typography level="body2">
											{task?.title}
										</Typography>
										<Typography level="body3">
											{task?.description}
										</Typography>
									</li>
								))}
							</ul>
						</Box>
					))}
				</Grid>
			</Grid>
		</>
	);
};

export default Modern;
