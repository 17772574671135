import { useState } from "react";
import { auth, db } from "../../../utils/MyFirebase";
import useAuthContainer from "../../../stores/Auth";
import { doc, setDoc, onSnapshot } from "firebase/firestore";

import {
  signInWithEmailAndPassword,
  sendPasswordResetEmail,
  createUserWithEmailAndPassword,
  sendEmailVerification,
  EmailAuthProvider,
  updateProfile,
  signOut,
} from "firebase/auth";
import { useSnackbar } from "notistack";
import { useTranslation } from "react-i18next";

const useEmailAuth = () => {
  const { setCredentials } = useAuthContainer.useContainer();

  const [user, setUser] = useState();
  const [errors, setErrors] = useState();
  const [resetEmailSended, setResetEmailSended] = useState();
  const [sendingResetEmail, setSendingResetEmail] = useState(false);
  const [signingIn, setSigningIn] = useState(false);
  const [signingUp, setSigningUp] = useState(false);
  const [verificationEmailSending, setVerificationEmailSending] =
    useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const { t } = useTranslation();

  const signIn = async (email, password) => {
    setSigningIn(true);
    try {
      const _signin = await signInWithEmailAndPassword(auth, email, password);
      setCredentials(new EmailAuthProvider(email, password));

      setUser(_signin.user);
    } catch (error) {
      enqueueSnackbar(t("email_or_password_incorrect"), { variant: "error" });
      setErrors({
        errorCode: error.code,
        errorMessage: error.message,
        email: error.email,
      });
    }

    setSigningIn(false);
  };

  const resetPassword = async (email) => {
    setSendingResetEmail(true);
    try {
      const reset = await sendPasswordResetEmail(auth, email);
      setResetEmailSended(true);
    } catch (error) {
      enqueueSnackbar(t(error.code), { variant: "error" });
    }
    setSendingResetEmail(false);
  };

  const signUp = async (email, password, additionnalDatas) => {
    setSigningUp(true);
    try {
      const _signin = await createUserWithEmailAndPassword(
        auth,
        email,
        password,
      );
      setUser(_signin.user);
      const userRef = doc(db, "users", _signin.user.uid);
      onSnapshot(
        userRef,
        async (doc) => {
          if (doc.exists()) {
            await setDoc(
              userRef,
              {
                additionalDatas: additionnalDatas,
                additionnalDatasAdded: true,
              },
              { merge: true },
            );
            setSigningUp(false);
          }
        },
        (e) => {
          console.log("login issue==>", e.message);
        },
      );

      // read for user document creation
      // add additionnal datas
      /* const userDocUnsub = onSnapshot(
        doc(db, "users", _signin.user.uid),
        async (doc) => {
          if (doc.exists() && !doc.data().emailSignup) {
            await setDoc(
              doc(db, "users", _signin.user.uid),
              {
                ...additionnalDatas,
                emailSignup: true,
                additionnalDatasAdded: true,
              },
              { merge: true }
            );
          }
        }
      );*/
    } catch (error) {
      enqueueSnackbar(t(error.code), { variant: "error" });
      setErrors({
        errorCode: error.code,
        errorMessage: error.message,
        email: error.email,
      });
      setSigningUp(false);
    }
  };
  const signout = () => signOut(auth);

  const sendVerificationEmail = async () => {
    setVerificationEmailSending(true);
    try {
      await sendEmailVerification(auth.currentUser);
      enqueueSnackbar(t("verification_email_was_sent"), { variant: "success" });
    } catch (error) {
      enqueueSnackbar(t(error.code), { variant: "error" });
      setErrors({
        errorCode: error.code,
        errorMessage: error.message,
      });
    }
    setVerificationEmailSending(false);
  };

  return {
    signout,
    verificationEmailSending,
    sendVerificationEmail,
    signIn,
    signingIn,
    signUp,
    signingUp,
    resetEmailSended,
    sendingResetEmail,
    resetPassword,
    errors,
    user,
  };
};

export default useEmailAuth;
