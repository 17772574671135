import React from "react";
import {
	Input,
	FormControl,
	FormLabel,
	Stack,
	IconButton,
	Typography,
} from "@mui/joy";
import { useTranslation } from "react-i18next";
import useBuilderContainer from "../../../../../../BuilderStore";
import { Save, Edit, Delete } from "@mui/icons-material";
import { useForm } from "react-hook-form";
import { IconPicker, IconPickerItem } from "react-fa-icon-picker";

const IconField = ({ update, type, label, rules, defaultValue, config }) => {
	const { t } = useTranslation();
	const [editable, setEditable] = React.useState(
		!Boolean(defaultValue) || false
	);

	const [icon, setIcon] = React.useState(defaultValue);

	React.useEffect(() => {
		if (defaultValue) {
			setIcon(defaultValue);
		}
	}, [defaultValue]);

	const handleSave = (d) => {
		update({ [label]: icon });

		setEditable(false);
	};

	const del = (d) => {
		setIcon(null);

		update({ [label]: null });

		setEditable(false);
	};

	return (
		<FormControl sx={{ mb: 2 }}>
			<FormLabel sx={{ fontSize: "12px" }}>
				{t(config?.terms[label] || label)}
			</FormLabel>

			{editable ? (
				<Stack direction="row" spacing={3} alignItems="center">
					<IconPicker value={icon} onChange={(v) => setIcon(v)} />

					<IconButton onClick={handleSave}>
						<Save />
					</IconButton>
					<IconButton onClick={del}>
						<Delete />
					</IconButton>
				</Stack>
			) : (
				<Stack direction="row" spacing={3} alignItems="center">
					<IconPickerItem icon={icon} size={24} color="#000" />

					<IconButton
						type="button"
						variant="outlined"
						onClick={() => setEditable(true)}
					>
						<Edit />
					</IconButton>
				</Stack>
			)}
		</FormControl>
	);
};

export default IconField;
