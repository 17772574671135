import React from "react";
import { FormSeparator } from "UI";
import { Input, Typography } from "@mui/joy";

const EventUserSelector = ({ register, control, owner }) => {
	return (
		<FormSeparator title="user_selector">
			{owner && (
				<Typography>Vous êtes le créateur de cet évènement</Typography>
			)}
			<Input {...register("uid")} name="uid" />
		</FormSeparator>
	);
};

export default EventUserSelector;
