import React from "react";
import { useTranslation } from "react-i18next";
import { Box, Typography, Button, Grid } from "@mui/joy";
import { Check } from "@mui/icons-material";
import PropTypes from "prop-types";
import { WhiteContainer } from "UI";
import { EventQrCodeEdit } from "../commons";
import { useForm } from "react-hook-form";
import { Renderer } from "./partials";
import Event from "models/events";

const defaultQrCodeOptions = {
	width: 500,
	height: 500,
	margin: 65,
	errorCorrectionLevel: "H",
	image: null,
	imageOptions: {
		crossOrigin: "anonymous",
	},
	backgroundOptions: {
		color: null,
	},
	dotsOptions: {
		color: null,
	},
};
const SaveBanner = ({ saving, saved, t }) => (
	<WhiteContainer>
		<Button
			loading={saving}
			disabled={saving}
			type="submit"
			variant={saved ? "outlined" : "solid"}
		>
			{saved && <Check />}
			{!saved ? t("save") : t("saved")}
		</Button>
	</WhiteContainer>
);

const EditQrCodeForm = ({ eventDatas, data, setData }) => {
	const { t } = useTranslation();
	const {
		handleSubmit,
		getValues,
		setValue,
		register,
		watch,
		formState: { errors },
	} = useForm({
		defaultValues: {
			...defaultQrCodeOptions,
			...eventDatas?.qrcode_options,
		},
	});
	const [saving, setSaving] = React.useState(false);
	const [saved, setSaved] = React.useState(false);

	const save = async (d) => {
		setSaving(true);
		try {
			const dat = { qrcode_options: d };
			const EventEntity = new Event(dat);
			const updated = await EventEntity.updateEvent(eventDatas.id);
			setSaved(true);
			setTimeout(() => {
				setSaved(false);
			}, 2000);
		} catch (e) {
			console.log(e.message);
		}
		setSaving(false);
	};
	console.log(errors);
	return (
		<>
			<form onSubmit={handleSubmit(save)}>
				<WhiteContainer>
					<Grid container spacing={5}>
						<Grid>
							<Box sx={{ width: 200, maxWidth: "100%" }}>
								<Renderer
									setValue={setValue}
									links={[
										{
											label: "homepage",
											path: `/my_event/${eventDatas?.id}`,
										},
										{
											label: "plannings",
											path: `/my_event/${eventDatas?.id}/planning`,
										},
										{
											label: "activities",
											path: `/my_event/${eventDatas?.id}/activities`,
										},
										{
											label: "meal",
											path: `/my_event/${eventDatas?.id}/meals`,
										},
										{
											label: "usefull_infos",
											path: `/my_event/${eventDatas?.id}/usefull_infos`,
										},
									]}
									data={data}
									setData={setData}
									watch={watch}
								/>
							</Box>
						</Grid>
						<Grid sx={{ borderLeft: "1px solid #eee" }}>
							<Typography level="h5" sx={{ mb: 4 }}>
								{t("qr_code_style")}
							</Typography>
							<EventQrCodeEdit
								getValues={getValues}
								setValue={setValue}
								register={register}
								watch={watch}
							/>
						</Grid>
					</Grid>
				</WhiteContainer>

				<SaveBanner t={t} saving={saving} saved={saved} />
			</form>
		</>
	);
};

export default EditQrCodeForm;
EditQrCodeForm.propTypes = {};
EditQrCodeForm.defaultProps = {};
