import { httpsCallable } from "firebase/functions";
import { functions } from "utils/MyFirebase";

class CloudFunctionsService {
  constructor(functionName) {
    this.functionName = functionName;
    this.functionRef = httpsCallable(functions, functionName);
  }

  async callFunction(data) {
    try {
      const result = await this.functionRef(data);
      return result.data;
    } catch (error) {
      console.error(`Error calling function ${this.functionName}:`, error);
      throw error;
    }
  }
}

export default CloudFunctionsService;
