import React from "react";
import {
	BecomePartnerInfo,
	AdditionnalInfoForm,
	DeleteAccount,
	AdminOptions,
} from "./partials";
import { Box } from "@mui/joy";
import { WhiteContainer } from "../../../../components/UI";
import useAuthContainer from "stores/Auth";

const Account = (props) => {
	const { userAccount } = useAuthContainer.useContainer();
	return (
		<Box sx={{ pb: 2 }}>
			<WhiteContainer title="informations">
				<AdditionnalInfoForm />
			</WhiteContainer>
			{userAccount?.role === "admin" && (
				<WhiteContainer title="AdminOptions">
					<AdminOptions />
				</WhiteContainer>
			)}
			<WhiteContainer title="delete_account">
				<DeleteAccount />
			</WhiteContainer>
			<WhiteContainer title="Partenaire">
				<BecomePartnerInfo />
			</WhiteContainer>
		</Box>
	);
};

export default Account;
