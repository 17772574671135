import React from "react";
import EventTemplatesRoutes from "./EventTemplatesRoutes";
import { BOLayout } from "../../components/layouts";
import { EventTemplatesStoreContainer } from "./EventTemplatesStore";

const EventTemplates = (props) => {
	return (
		<>
			<BOLayout title="create_an_event">
				<EventTemplatesRoutes />
			</BOLayout>
		</>
	);
};

export default EventTemplatesStoreContainer(EventTemplates);
