import React from "react";
import { useTranslation } from "react-i18next";
import { Box, Typography, Button } from "@mui/joy";
import { Edit } from "@mui/icons-material";
import PropTypes from "prop-types";
import { WhiteContainer } from "UI";
import { EditPlaceForm } from "forms/places";
import { View } from "./partials";

const Informations = ({ datas: initialDatas, placeId }) => {
	const { t } = useTranslation();
	const [editable, setEditable] = React.useState(false);
	const [datas, setDatas] = React.useState(initialDatas);

	React.useEffect(() => {
		setDatas(initialDatas);
	}, [initialDatas]);
	return (
		<>
			<WhiteContainer title="informations">
				{!editable ? (
					<Button
						sx={{ position: "absolute", top: 8, right: 8 }}
						onClick={() => setEditable(true)}
					>
						<Edit /> {t("edit")}
					</Button>
				) : (
					<Button
						sx={{ position: "absolute", top: 8, right: 8 }}
						onClick={() => setEditable(false)}
					>
						<Edit /> {t("close")}
					</Button>
				)}
				{editable ? (
					<EditPlaceForm
						adminMode
						close={(d) => {
							setDatas({ ...datas, ...d });
							setEditable(false);
						}}
					/>
				) : (
					<View {...datas} />
				)}
			</WhiteContainer>
		</>
	);
};

export default Informations;
Informations.propTypes = {};
Informations.defaultProps = {};
