import React from "react";
import { useTranslation } from "react-i18next";
import {
	Link,
	List,
	Box,
	Typography,
	Button,
	IconButton,
	ListItem,
} from "@mui/joy";
import PropTypes from "prop-types";

const View = ({ user }) => {
	const { t } = useTranslation();

	return (
		user && (
			<List>
				<ListItem>
					<b>{t("id")}</b>{" "}
					<Link to={`/admin/users/${user.value}`}>{user.value}</Link>
				</ListItem>
				<ListItem>
					<b>{t("email")}</b>
					{user.label}
				</ListItem>
			</List>
		)
	);
};

export default View;
View.propTypes = {};
View.defaultProps = {};
