import React from "react";
import { useTranslation } from "react-i18next";
import { Box, Typography } from "@mui/joy";
import PropTypes from "prop-types";
import { Switch, Route } from "react-router-dom";
import { PlacesList, PlaceDetails } from "./views";

const Places = (props) => {
	const { t } = useTranslation();

	return (
		<Switch>
			<Route exact path="/admin/places">
				<PlacesList />
			</Route>
			<Route path="/admin/places/:placeID">
				<PlaceDetails />
			</Route>
		</Switch>
	);
};

export default Places;
Places.propTypes = {};
Places.defaultProps = {};
