import React from "react";
import { Grid, Sheet, Box, Typography, Divider, Button } from "@mui/joy";
import { Builder } from "../../components/UI";
import testDatas from "./testDatas.json";
import {
	BuilderContainer,
	CVContainer,
	ThemeSelector,
	ThemeToogle,
} from "./partials";
import useCVContainer, { CVStoreContainer } from "./CVStore";
import ReactToPrint from "react-to-print";
import { useTranslation } from "react-i18next";
import { Print } from "@mui/icons-material";

const CV = (props) => {
	const { setExperiences, experiences, setThemeEditor } =
		useCVContainer.useContainer();

	const sheetRef = React.useRef(null);
	const { t } = useTranslation();
	return (
		<>
			<ThemeToogle />
			<ThemeSelector />
			<Grid
				onClick={() => setThemeEditor(false)}
				container
				sx={{
					position: "fixed",
					width: "100%",
				}}
			>
				<Grid
					md={4}
					sx={{
						height: "100vh",
						overflow: "auto",
						p: 2,
						backgroundColor: "#f1f1f1",
					}}
				>
					<BuilderContainer />
				</Grid>
				<Grid
					md={8}
					sx={{
						height: "100vh",
						backgroundColor: "#444",
						display: "flex",
						justifyContent: "center",
						pt: 4,
						pb: 2,
						overflow: "auto",
						height: "100vh",
					}}
				>
					<ReactToPrint
						trigger={() => (
							<Button
								sx={{
									height: "39px",
									position: "absolute",
									bottom: "40px",
									right: "40px",
									zIndex: "200",
								}}
							>
								<Print />
								{t("print")}
							</Button>
						)}
						content={() => sheetRef.current}
					/>
					<Sheet
						ref={sheetRef}
						sx={{
							minHeight: "calc(29.7cm)",
							width: "calc(21cm)",
							minWidth: "calc(21cm)",
							overflow: "auto",
							position: "relative",

							boxShadow: "2px 8px 10px rgba(0,0,0,0.5)",
						}}
					>
						<CVContainer />
					</Sheet>
				</Grid>
			</Grid>
		</>
	);
};

export default CVStoreContainer(CV);
