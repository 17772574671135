import React from "react";
import {
	Box,
	Typography,
	List,
	Switch,
	Stack,
	IconButton,
	Tooltip,
} from "@mui/joy";
import {
	Event,
	Timelapse,
	Groups,
	Star,
	Edit,
	Delete,
	Visibility,
	VisibilityOff,
	SettingsOutlined,
	ViewQuilt,
	RemoveRedEyeOutlined,
	NightsStay,
	WbSunny,
	Share,
	CalendarMonth,
} from "@mui/icons-material";
import Helpers from "../../../../../../../../../utils/Helpers";
import { useTranslation } from "react-i18next";
import { IKImage } from "imagekitio-react";
import { Dropdown } from "react-bootstrap";
import { useHistory, Link } from "react-router-dom";

const EventGridItem = ({
	event_name,
	event_date_stopped,
	isModel,
	changeShared,
	event_periods,
	status,
	shared,
	id,
	changeStatus,
	event_background_image,
	days_duration,
	nights_duration,
	event_date_period,
	event_start_date,
	event_end_date,
	event_day_duration,
	event_type,
	event_participants_qty,
	event_participants_qty_min,
	event_participants_qty_max,
	setShowConfirm,
	setSelectedId,
}) => {
	const { t } = useTranslation();
	const history = useHistory();
	return (
		<Box
			sx={{
				border: "1px solid #888",
				borderRadius: "7px",
				width: { md: "450px", xs: "100%" },
				position: "relative",

				display: "flex",
				flexDirection: "column",
				justifyContent: "space-between",
			}}
		>
			<Box
				onClick={() => history.push(`/event/${id}/general`)}
				sx={(theme) => ({
					"&:hover": {
						".title": {
							color: theme.vars.palette.primary[400],
						},
						".image": {
							opacity: 0.7,
						},
					},
					cursor: "pointer",
					p: 3,
					display: "flex",
					position: "relative",
					flexDirection: "row-reverse",
					justifyContent: "space-between",
					alignItems: "center",
					flexWrap: "wrap",
					"@media(max-width:1280px)": {
						justifyContent: "left",
					},
				})}
			>
				{isModel && (
					<Tooltip title={t("model")}>
						<ViewQuilt
							sx={(theme) => ({
								fontSize: "40px",
								borderRadius: "100px",
								p: "8px",
								color: "white",
								background: theme.vars.palette.primary[500],
								position: "absolute",
								left: "-9px",
								top: "-12px",
							})}
						/>
					</Tooltip>
				)}

				<Box
					className="image"
					sx={{
						background: `url(${
							event_background_image?.thumb ||
							event_background_image?.full ||
							event_background_image
						}) center center / cover`,
						width: "120px",
						height: "120px",
						borderRadius: "10px",
						"@media(max-width:1280px)": {
							width: "100%",
						},
					}}
				></Box>
				<Box
					sx={{
						maxWidth: "268px",
						whiteSpace: "nowrap",
						overflow: "hidden",
					}}
				>
					<Typography
						className="title"
						sx={{
							textOverflow: "ellipsis",
							fontSize: "18px",
							fontWeight: "bold",
							mb: 2,
							"@media(max-width:1280px)": {
								mt: 1,
							},
						}}
					>
						{event_name}
						<i> {isModel && <>({t("model")})</>}</i>
					</Typography>

					{isModel ? (
						<List
							sx={(theme) => ({
								margin: 0,
								padding: 0,

								listStyle: "none",
								li: {
									textOverflow: "ellipsis",
									width: "100%",
									overflow: "hidden",
									listStyle: "none",
								},
								".MuiSvgIcon-root": {
									color: theme.vars.palette.primary[400],
								},
							})}
						>
							<li>
								<Event />
								{event_periods &&
									event_periods.map((d) => t(d)).join(",")}
							</li>
							{days_duration && (
								<li>
									<WbSunny />
									{days_duration}
								</li>
							)}
							{nights_duration && (
								<li>
									<NightsStay />
									{nights_duration}
								</li>
							)}

							{(event_participants_qty_min ||
								event_participants_qty_max) && (
								<li>
									<Groups />{" "}
									{t("from_min_to_max_participants", {
										min: event_participants_qty_min,
										max: event_participants_qty_max,
									})}
								</li>
							)}
						</List>
					) : (
						<List
							sx={(theme) => ({
								margin: 0,
								padding: 0,
								listStyle: "none",
								li: {
									listStyle: "none",
								},
								".MuiSvgIcon-root": {
									color: theme.vars.palette.primary[400],
								},
							})}
						>
							{(event_date_period ||
								event_start_date ||
								event_end_date) && (
								<li>
									<Typography>
										<Event />{" "}
										{!event_date_stopped
											? `${t(
													event_date_period?.split(
														"_",
													)[0],
											  )} ${
													event_date_period?.split(
														"_",
													)[1]
											  } `
											: `${t("from")} ${Helpers.fbDate(
													event_start_date,
													"DD/MM/YYYY",
											  )} ${t("to")} ${Helpers.fbDate(
													event_end_date,
													"DD/MM/YYYY",
											  )} `}
									</Typography>
								</li>
							)}
							{!event_date_stopped && event_day_duration && (
								<li>
									<Timelapse /> {event_day_duration}{" "}
									{t("days")}
								</li>
							)}

							{event_participants_qty && (
								<li>
									<Groups /> {event_participants_qty}{" "}
									{t("peoples")}
								</li>
							)}

							{event_type && (
								<li>
									<Star /> {t(event_type)}
								</li>
							)}
						</List>
					)}
				</Box>
			</Box>
			<Stack
				sx={{ p: "10px 20px", borderTop: "1px solid #888" }}
				direction="row"
				justifyContent="space-between"
			>
				<Stack direction="row" spacing={2}>
					<Stack direction="row" spacing={1} alignItems="center">
						{status === "active" && <Visibility color="primary" />}
						{status !== "active" && (
							<VisibilityOff color="neutral" />
						)}
						<Switch
							checked={status === "active"}
							variant="solid"
							onChange={(e) => changeStatus(e, id)}
						/>
					</Stack>
					<Box
						sx={{
							display: !isModel && "none",
							width: 2,
							height: 40,
							background: "#bbb",
						}}
					></Box>
					{isModel && (
						<Stack alignItems="center" direction="row" spacing={1}>
							<Share color={shared ? "primary" : "neutral"} />
							<Tooltip title={t("shared_as_model")}>
								<Switch
									checked={shared}
									variant="solid"
									onChange={(e) => changeShared(e, id)}
								/>
							</Tooltip>
						</Stack>
					)}
				</Stack>
				<Stack spacing={1} direction="row">
					<IconButton
						onClick={() => history.push(`/event/${id}/general`)}
						variant="outlined"
					>
						<Edit />
					</IconButton>
					<IconButton
						component={Link}
						target="_blank"
						to={`/my_event/${id}`}
						variant="outlined"
					>
						<RemoveRedEyeOutlined />
					</IconButton>
					<IconButton
						onClick={() => {
							setShowConfirm(true);
							setSelectedId(id);
						}}
						variant="outlined"
					>
						<Delete />
					</IconButton>
				</Stack>
				{/*<Box>
					<Dropdown>
						<Dropdown.Toggle
							sx={{
								"&::after": {
									display: "none",
								},
								background: "none",
								color: "initial",
							}}
							as={IconButton}
						>
							<SettingsOutlined sx={{ fontSize: "33px" }} />
						</Dropdown.Toggle>
						<Dropdown.Menu>
							<Dropdown.Item
								onClick={() =>
									history.push(`/event/${id}/general`)
								}
							>
								<Edit /> {t("manage")}
							</Dropdown.Item>
							<Dropdown.Item
								onClick={() => history.push(`/my_event/${id}`)}
							>
								<RemoveRedEyeOutlined /> {t("visualize")}
							</Dropdown.Item>
							<Dropdown.Item
								onClick={() => {
									setShowConfirm(true);
									setSelectedId(id);
								}}
							>
								<Delete />
								{t("delete")}
							</Dropdown.Item>
						</Dropdown.Menu>
					</Dropdown>
				</Box>*/}
			</Stack>
		</Box>
	);
};

export default EventGridItem;
