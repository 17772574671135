import React from "react";
import {
	Textarea,
	FormControl,
	FormLabel,
	Stack,
	IconButton,
	Typography,
} from "@mui/joy";
import { useTranslation } from "react-i18next";
import useBuilderContainer from "../../../../../../BuilderStore";
import { Save, Edit } from "@mui/icons-material";
import { useForm } from "react-hook-form";

const InputTextarea = ({ update, rules, type, label, defaultValue }) => {
	const { t } = useTranslation();
	const [editable, setEditable] = React.useState(
		!Boolean(defaultValue) || false
	);
	const { reset, getValues, handleSubmit, register, setValue } = useForm();

	React.useEffect(() => {
		reset();
		setValue(label, defaultValue);
	}, [defaultValue]);

	const handleSave = (d) => {
		update({ [label]: d[label] });
		setEditable(false);
	};

	return (
		<FormControl sx={{ mb: 2 }}>
			<FormLabel sx={{ fontSize: "12px" }}>{t(label)}</FormLabel>
			{editable ? (
				<form onSubmit={handleSubmit(handleSave)}>
					<Stack direction="row" spacing={3} alignItems="center">
						<Textarea
							{...register(label, rules)}
							sx={{ width: "100%" }}
							type={type}
							placeholder={t(label)}
						/>
						<IconButton
							onClick={handleSubmit(handleSave)}
							type="submit"
						>
							<Save />
						</IconButton>
					</Stack>
				</form>
			) : (
				<Stack direction="row" spacing={3} alignItems="center">
					<Typography>{getValues(label)}</Typography>
					<IconButton
						type="button"
						variant="outlined"
						onClick={() => setEditable(true)}
					>
						<Edit />
					</IconButton>
				</Stack>
			)}{" "}
		</FormControl>
	);
};

export default InputTextarea;
