import React from "react";
import { Box, Typography, Stack } from "@mui/joy";
import { useParams, Link, useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";
import Planning from "models/plannings";

const PlanningItems = ({ darkMode, title, image, eventID, id }) => {
	const history = useHistory();
	console.log(image);
	return (
		<Box
			sx={{
				position: "relative",
			}}
			onClick={() => history.push(`/my_event/${eventID}/planning#${id}`)}
		>
			<Box
				sx={{
					borderRadius: "12px",
					width: "100%",
					height: "130px",

					background: `url("${
						image?.thumb || image?.full || image
					}") center center /cover`,
				}}
			>
				<Box
					sx={{
						display: "flex",
						justifyContent: "center",
						alignItems: "center",
						background: "#00000040",
						width: "100%",
						borderRadius: "12px",
						height: "100%",
						top: 0,
						left: 0,
						position: "absolute",
					}}
				>
					<Typography
						sx={{
							fontSize: "16px",

							fontWeight: "bold",
							mt: 1,
							color: "white",
						}}
					>
						{title}
					</Typography>
				</Box>
			</Box>
		</Box>
	);
};

const PlanningHomeCarousel = ({ style }) => {
	const { t } = useTranslation();
	const { eventID } = useParams();
	const [datas, setDatas] = React.useState([]);

	const PlanningEntity = new Planning();

	const fetchDatas = async () => {
		try {
			PlanningEntity.getPlanningByEventId(eventID, true, (d) => {
				setDatas(d[0]);
			});
		} catch (e) {
			console.log(e.message);
		}
	};
	React.useEffect(() => {
		fetchDatas();
	}, eventID);

	return (
		<Box sx={{ pt: 4, pb: 4, borderTop: "1px solid #eee" }}>
			<Stack
				sx={{ mb: 2 }}
				justifyContent="space-between"
				direction="row"
			>
				<Typography
					sx={{ fontSize: "20px", color: style?.theme_color }}
				>
					{t("plannings")}
				</Typography>
				<Link
					style={{
						color: style?.theme_color,
						textDecoration: "none",
					}}
					to={`/my_event/${eventID}/plannings`}
				>
					{t("view_all")}
				</Link>
			</Stack>
			<Box
				sx={{
					"&::-webkit-scrollbar": {
						display: "none",
					},
					"-ms-overflow-style": "none" /* IE and Edge */,
					scrollbarWidth: "none",
					display: "flex",
					flexDirection: "column",
					rowGap: "10px",
				}}
			>
				{datas &&
					datas?.content?.length > 0 &&
					datas?.content.map((activity) => (
						<PlanningItems
							key={activity.id}
							{...activity}
							t={t}
							darkMode={style?.theme_mode === "dark"}
							eventID={eventID}
						/>
					))}
			</Box>
		</Box>
	);
};

export default PlanningHomeCarousel;
