import React from "react";
import { Card, Stack, Box, Typography, IconButton, Chip } from "@mui/joy";
import {
	DragIndicator,
	Edit,
	Delete,
	Folder,
	Description,
} from "@mui/icons-material";
import useBuilderContainer from "../../../../BuilderStore";
import useMediaQuery from "@mui/material/useMediaQuery";

const ChildrenCard = ({ t, elementID, id, type, children, ...props }) => {
	const matches = useMediaQuery("(max-width:600px)");
	const { selected, setConfirmDelete, setSelected, config, setParent } =
		useBuilderContainer.useContainer();

	return (
		<Card
			sx={(theme) => ({
				mb: 2,
				borderColor:
					selected === id
						? theme.vars.palette.primary[400]
						: "transparent",
				borderWidth: "2px",
				borderStyle: "solid",
			})}
		>
			<Stack direction="row" justifyContent="space-between">
				<Stack direction="row" alignItems="center" spacing={2}>
					<DragIndicator
						className="element-drag-handle"
						sx={{
							cursor: "move",
						}}
					/>
					<Chip>
						{matches ? (
							type === "element" ? (
								<Description />
							) : (
								<Folder />
							)
						) : (
							t(config?.terms[type] || type)
						)}{" "}
					</Chip>
					<Stack
						sx={{ ml: 2 }}
						direction="row"
						spacing={3}
						alignItems="center"
					>
						{config &&
							config[type]?.cardFields.map((field) => (
								<Typography>{props[field]}</Typography>
							))}

						{type === "subCategory" && !matches && (
							<Typography
								sx={{
									fontStyle: "italic",
									fontSize: "12px",
								}}
							>
								{t(
									config?.terms["element_in_this_cat"] ||
										"element_in_this_cat",
									{
										nbr: children.length,
									},
								)}
							</Typography>
						)}
					</Stack>
				</Stack>

				<Stack direction="row" spacing={1}>
					<IconButton
						onClick={() => setConfirmDelete(id)}
						variant="outlined"
					>
						<Delete />
					</IconButton>
					<IconButton
						onClick={() => {
							setSelected(id);
							setParent(elementID);
						}}
						variant="outlined"
					>
						<Edit />
					</IconButton>
				</Stack>
			</Stack>
		</Card>
	);
};

export default ChildrenCard;
