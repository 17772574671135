import * as React from "react";
import { Box, Button, Typography, Container } from "@mui/joy";
import { CssVarsProvider } from "@mui/joy/styles";
import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";
import useEmailAuth from "../../../../../authentification/hooks/useEmailAuth";
import useAuthContainer from "stores/Auth";
import { DocumentsValidation } from "./partials";

export default function AccountApproval() {
	const { t } = useTranslation();
	const history = useHistory();
	const { signout } = useEmailAuth();
	const { user, userAccount } = useAuthContainer.useContainer();
	return (
		<CssVarsProvider>
			<Box
				sx={{
					display: "flex",
					mt: 3,
					flexDirection: { xs: "column", md: "row" }, // Stack on mobile, row on tablet and above
					alignItems: "space-between", // Center the form vertically on mobile
					justifyContent: { md: "space-between", xs: "center" }, // Center the form horizontally on mobile
				}}
			>
				<Container
					sx={{
						width: { md: 790, xs: "90%" },

						display: "flex",
						flexDirection: "column",
						alignItems: "center", // Center the form items on mobile
					}}
				>
					<Typography
						level="h4"
						component="h1"
						sx={{ textAlign: "center" }}
					>
						{t("waiting_for_account_approval")}
					</Typography>
					<Typography level="body2" sx={{ textAlign: "center" }}>
						{t("waiting_for_account_approval_desc")}
					</Typography>

					{user && userAccount && (
						<DocumentsValidation
							{...user}
							role={userAccount?.role || null}
						/>
					)}
				</Container>
			</Box>
		</CssVarsProvider>
	);
}
