import React, { useRef } from "react";
import { Box, Button } from "@mui/joy";
import { ArrowBackIos, ArrowForwardIos } from "@mui/icons-material";

const SwipeComponent = ({ children }) => {
    const scrollContainerRef = useRef(null);

    const scroll = (direction) => {
        const { current } = scrollContainerRef;
        if (current) {
            const scrollAmount = direction === "left" ? -200 : 200;
            current.scrollBy({ left: scrollAmount, behavior: "smooth" });
        }
    };
    const btnStyle = {
        position: "absolute",
        zIndex: "1",
        height: "68px",
        top: "72px",
        backgroundColor: "#00000060",
    };

    return (
        <Box
            sx={{
                display: "flex",
                alignItems: "center",
                overflow: "hidden",
                position: "relative",
            }}
        >
            <Button sx={{ ...btnStyle }} onClick={() => scroll("left")}>
                <ArrowBackIos />
            </Button>
            <Box
                ref={scrollContainerRef}
                sx={{
                    display: "inline-flex",
                    overflowX: "scroll",
                    whiteSpace: "nowrap",
                    scrollbarWidth: "none" /* Firefox */,
                    msOverflowStyle: "none" /* Internet Explorer 10+ */,
                    "&::-webkit-scrollbar": {
                        display: "none" /* Safari and Chrome */,
                    },
                }}
            >
                {children}
            </Box>
            <Button
                sx={{ ...btnStyle, right: 0 }}
                onClick={() => scroll("right")}
            >
                <ArrowForwardIos />
            </Button>
        </Box>
    );
};

export default SwipeComponent;
