import React from "react";
import { Box, Typography, Chip } from "@mui/joy";
import { useTranslation } from "react-i18next";

const CadreAmbiance = ({ cadres }) => {
	const { t } = useTranslation();
	return (
		<Box
			sx={{
				mt: 1,
				mb: 1,
				display: "flex",
				flexDirection: "row",
				flexWrap: "wrap",
				gap: 1,
			}}
		>
			{" "}
			{cadres &&
				cadres.map((cadre) => <Chip key={cadre}>#{t(cadre)}</Chip>)}
		</Box>
	);
};

export default CadreAmbiance;
