import React from "react";
import { useTranslation } from "react-i18next";
import PropTypes from "prop-types";
import Quotes from "models/quotes";
import { useForm } from "react-hook-form";
import {
	Input,
	Button,
	Box,
	FormControl,
	FormHelperText,
	Textarea,
	FormLabel,
	Typography,
} from "@mui/joy";
import useAuthContainer from "stores/Auth";

const RequestPlaceQuote = ({ el_id }) => {
	const { user } = useAuthContainer.useContainer();
	const { t } = useTranslation();
	const [sendQuote, setSendQuote] = React.useState(false);
	const [sended, setSended] = React.useState(false);
	const QuoteModel = new Quotes();
	const {
		register,
		handleSubmit,
		formState: { errors },
	} = useForm();

	const onSubmit = async (data) => {
		setSendQuote(true);
		QuoteModel.email = data?.email || user.email;
		QuoteModel.uid = user.uid;
		QuoteModel.created_at = new Date();
		QuoteModel.place_id = el_id;
		QuoteModel.message = data?.message || "";

		const addedQuote = await QuoteModel.addQuote();
		if (addedQuote?.id) {
			setSended(true);
		}

		setSendQuote(false);
	};
	return (
		<>
			<Typography level="h4">{t("quote")}</Typography>{" "}
			{sended ? (
				<Box>
					<Typography>{t("quote_sended")}</Typography>
				</Box>
			) : (
				<Box
					component="form"
					onSubmit={handleSubmit(onSubmit)}
					sx={{ display: "flex", flexDirection: "column", gap: 2 }}
				>
					{/* Champ Email avec Label */}
					<FormControl>
						<FormLabel>Email</FormLabel>
						<Input
							{...register("email", {
								required: "L'email est requis",
								pattern: {
									value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
									message: "Adresse email non valide",
								},
							})}
							label="Email"
							defaultValue={user.email}
							error={!!errors.email}
							placeholder="Votre email de contact"
						/>
						<FormHelperText>
							{errors.email ? errors.email.message : ""}
						</FormHelperText>
					</FormControl>

					{/* Champ Message avec Label, utilisant Textarea */}
					<FormControl>
						<FormLabel>Message</FormLabel>
						<Textarea
							{...register("message", {
								required: "Un message est requis",
							})}
							label="Message"
							placeholder="Écrivez votre message ici..."
							minRows={6}
							error={!!errors.message}
						/>
					</FormControl>
					<FormHelperText>
						{errors.message ? errors.message.message : ""}
					</FormHelperText>

					<Button
						loading={sendQuote}
						disabled={sendQuote}
						type="submit"
					>
						Envoyer
					</Button>
				</Box>
			)}
		</>
	);
};

export default RequestPlaceQuote;
RequestPlaceQuote.propTypes = {
	el_id: PropTypes.string,
};
RequestPlaceQuote.defaultProps = {};
