import React, { useState, useEffect, useCallback } from "react";
import { useTranslation } from "react-i18next";
import { WhiteContainer } from "UI";
import { Editable, View } from "./partials";
import Group from "models/groups";
import Helpers from "utils/Helpers";
import useAuthContainer from "stores/Auth";
import { v4 as uuidv4 } from "uuid";

const Notes = ({ notes: initialNotes, groupId }) => {
	const { t } = useTranslation();
	const [notes, setNotes] = useState(initialNotes);
	const [selected, setSelected] = useState(null);
	const [open, setOpen] = useState(false);
	const { user, userAccount } = useAuthContainer.useContainer();
	const GroupEntity = React.useMemo(() => new Group(), [groupId]);

	useEffect(() => {
		setNotes(initialNotes);
	}, [initialNotes]);

	const saveNote = useCallback(
		async (data) => {
			const updatedNotes = selected
				? Helpers.deepArrayUpdate(notes, data, data.id)
				: [
						...notes,
						{
							by: `${userAccount?.additionalDatas?.name || ""} ${
								userAccount?.additionalDatas?.lastname || ""
							}`,
							uid: user.uid,
							id: uuidv4(),
							...data,
						},
				  ];

			setNotes(updatedNotes);
			GroupEntity.notes = updatedNotes;
			await GroupEntity.updateGroup(groupId);
		},
		[notes, selected, user.uid, userAccount?.additionalDatas, GroupEntity],
	);

	const deleteNote = useCallback(
		async (id) => {
			const updatedNotes = notes.filter((note) => note.id !== id);
			setNotes(updatedNotes);
			GroupEntity.notes = updatedNotes;
			await GroupEntity.updateGroup(groupId);
		},
		[notes, GroupEntity],
	);

	return (
		<WhiteContainer title={t("notes")}>
			<View
				notes={notes}
				select={(note) => {
					setSelected(note);
					setOpen(true);
				}}
			/>
			<Editable
				deleteNote={deleteNote}
				saveNote={saveNote}
				open={open}
				close={() => setOpen(false)}
				selected={selected}
			/>
		</WhiteContainer>
	);
};

export default Notes;
