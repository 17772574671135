import React from "react";
import MainCategoryItem from "./MainCategoryItem";
import { Button, Stack, Typography, Box } from "@mui/joy";
import { Add, DragIndicator } from "@mui/icons-material";
import { useTranslation } from "react-i18next";
import useBuilderContainer from "../../BuilderStore";
import { Container, Draggable } from "react-smooth-dnd";
import Helpers from "../../../../../utils/Helpers";
import { Tree } from "antd";

const MainCategoryList = ({ content }) => {
	const {
		setCreateElementType,
		contentState,
		mainParent,
		setContentState,
		setChecked,
		config,
	} = useBuilderContainer.useContainer();

	const { t } = useTranslation();

	const onDrop = (d) =>
		setContentState(
			Helpers.onDrop(d, Helpers.addKeys(contentState), config.limitDepth),
		);
	const onCheck = (checked, info) => {
		setChecked(checked);
	};

	const conf = config?.itemsConfig?.depth_1
		? config?.itemsConfig?.depth_1
		: config?.itemsConfig?.default;
	return (
		<Box
			sx={(theme) => ({
				border: "1px solid #eee",
				m: 1,
				".category-dragging": {
					borderColor: theme.vars.palette.primary[400],
					borderStyle: "dashed",
					borderWidth: "2px",
				},

				".ant-tree-node-content-wrapper": {
					"&:hover": {
						background: "none !important",
					},
				},
				".ant-tree-node-selected": {
					background: "none !important",
				},
				".ant-tree-treenode": {
					p: "1px",
					alignItems: "baseline",
					borderTop: "1px solid #eee",
					alignItems: "center",
					pt: "5px",
					pb: "5px",
					"&:hover": {
						background: "#F1F1F1",
					},
				},
			})}
		>
			<Box>
				{content && (
					<Tree
						defaultExpandAll
						defaultExpandParent
						autoExpandParent
						showIcon
						checkable
						onCheck={onCheck}
						className="draggable-tree"
						draggable={{ icon: <DragIndicator /> }}
						blockNode
						titleRender={(n) => (
							<MainCategoryItem
								selectedEl={mainParent === n.id}
								key={n.id}
								id={n.id}
								{...n}
							/>
						)}
						onDrop={onDrop}
						treeData={Helpers.addKeys(contentState)}
					/>
				)}
			</Box>

			<Button
				onClick={() => setCreateElementType("category")}
				fullWidth
				varian="solid"
			>
				<Add />
				{t(conf?.terms.create_new_item || "add_main_category")}
			</Button>
		</Box>
	);
};

export default MainCategoryList;
