import React from "react";
import { useTranslation } from "react-i18next";
import { Typography, Grid } from "@mui/joy";

const Title = ({ title, subtitle }) => {
	const { t } = useTranslation();
	return (
		<Grid sx={{ textAlign: "center", mb: "20px" }}>
			<Typography fontWeight="lg" level="h4">
				{t(title)}
			</Typography>
			<Typography level="body1">{t(subtitle)}</Typography>
		</Grid>
	);
};

export default Title;
