import React from "react";
import { useTranslation } from "react-i18next";
import { Box, Typography, Button } from "@mui/joy";
import { Add } from "@mui/icons-material";
import PropTypes from "prop-types";
import { UserList, UserFilters, UserDetails } from "./partials";
import { orderBy, where } from "firebase/firestore";
import User from "models/user";
import { Link } from "react-router-dom";
import { WhiteContainer } from "UI";

const AppUsers = (props) => {
	const UserEntity = new User();
	const { t } = useTranslation();
	const [filters, setFilters] = React.useState([]);
	const [users, setUsers] = React.useState([]);
	const [lastVisible, setLastVisible] = React.useState(null);
	const [isMore, SetIsMore] = React.useState(true);
	const [filterChanged, setFilterChanged] = React.useState(false);

	const mergeAdditionalDatas = (datas) => {
		return datas.map((d) => ({ ...d, ...(d?.additionalDatas || {}) }));
	};

	const getUsers = async (reset = false) => {
		try {
			const datas = await UserEntity.getUsers(lastVisible, filters);
			if (datas) {
				SetIsMore(!datas?.no_more_data);
				if (datas?.lastVisible) {
					setUsers(
						reset
							? mergeAdditionalDatas(datas?.documents)
							: [
									...mergeAdditionalDatas(users),
									...mergeAdditionalDatas(datas?.documents),
							  ],
					);
					setLastVisible(datas?.lastVisible);
				} else {
					setUsers(mergeAdditionalDatas(datas));
				}
			} else {
				SetIsMore(false);
			}
		} catch (e) {
			console.log(e);
		}
	};

	React.useEffect(() => {
		setUsers([]);
		setLastVisible(null);
		setFilterChanged(true);
	}, [filters]);

	React.useEffect(() => {
		if (filterChanged) {
			getUsers(true);
			setFilterChanged(false);
		}
	}, [filterChanged]);

	return (
		<>
			<WhiteContainer>
				<Button component={Link} to={"/admin/users/new-user"}>
					<Add /> {t("create_new_user")}
				</Button>
			</WhiteContainer>
			<UserFilters filters={filters} setFilters={setFilters} />
			<UserList
				isMore={isMore}
				getUsers={getUsers}
				users={users}
				setUsers={setUsers}
			/>
			<UserDetails />
		</>
	);
};

export default AppUsers;
AppUsers.propTypes = {};
AppUsers.defaultProps = {};
