import React from "react";
import { useTranslation } from "react-i18next";
import { Box, Typography, LinearProgress } from "@mui/joy";
import PropTypes from "prop-types";
import Meals from "models/meals";
import { NoDataYet } from "UI";
import { MealsBuilder } from "./views";
import AuthContainer from "stores/Auth";

const MealsBack = ({ eventID }) => {
	const { t } = useTranslation();
	const { user } = AuthContainer.useContainer();
	const [datas, setDatas] = React.useState(null);
	const [creating, setCreating] = React.useState(false);
	const [fetchAttempt, setFetchAttempt] = React.useState(false);
	const mealsEntity = new Meals({
		content: [],
		created_at: new Date(),
		uid: user.uid,
		event_id: eventID,
		status: "active",
	});

	const fetchMeals = async () => {
		try {
			const meals = await mealsEntity.getMealsByEventId(eventID);
			setFetchAttempt(true);
			if (meals) {
				setDatas(meals);
			}
		} catch (e) {
			console.log(e.message);
		}
	};

	const addMeals = async () => {
		setCreating(true);
		try {
			const d = await mealsEntity.addMeals();
			setDatas(d);
		} catch (e) {
			console.log(e.message);
		}
		setCreating(false);
	};

	React.useEffect(() => {
		fetchMeals();
	}, []);

	const NoData = (
		<NoDataYet
			action={addMeals}
			loading={creating}
			terms={{
				subtitle: "meals",
				title: "meals_to_your_event",
				button: "meals",
			}}
		/>
	);

	return datas ? (
		<MealsBuilder datas={datas} />
	) : !datas && !fetchAttempt ? (
		<LinearProgress />
	) : (
		NoData
	);
};

export default MealsBack;
MealsBack.propTypes = {};
MealsBack.defaultProps = {};
