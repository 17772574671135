import React from "react";
import { useTranslation } from "react-i18next";
import { Box, Typography, Button, Stack } from "@mui/joy";
import PropTypes from "prop-types";
import { useForm } from "react-hook-form";
import { Job, Name, GroupName, Phone } from "./partials";
import User from "models/user";

const AdditionnalDataForm = ({ datas = {}, id, close }) => {
	const { t } = useTranslation();
	const { register, handleSubmit, setValue, watch, getValues, control } =
		useForm({
			defaultValues: {
				...datas,
			},
		});

	const save = (d) => {
		const UserEntity = new User({ additionalDatas: d });
		UserEntity.updateUser(id);
		close(d);
	};

	return (
		<>
			<form onSubmit={handleSubmit(save)}>
				<Stack spacing={2}>
					<Job control={control} />
					<Name register={register} />
					<GroupName register={register} />
					<Phone register={register} />
				</Stack>

				<Button sx={{ mt: 2 }} type="submit">
					{t("save")}
				</Button>
			</form>
		</>
	);
};

export default AdditionnalDataForm;
AdditionnalDataForm.propTypes = {
	datas: PropTypes.object,
};
AdditionnalDataForm.defaultProps = {
	datas: {},
};
/*
{
    "job": "ceo",
    "lastname": "Julien",
    "phone": "0673555687",
    "group_name": "Weevup",
    "name": "Boisard",
    "profil_type": "compagny"
}*/
