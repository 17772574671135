import React from "react";
import { useTranslation } from "react-i18next";
import { Box, Typography, Button, IconButton } from "@mui/joy";
import PropTypes from "prop-types";
import { FavoriteBorder, Favorite } from "@mui/icons-material";
import User from "models/user";
import { arrayUnion, arrayRemove } from "firebase/firestore";
import useAuthContainer from "stores/Auth";

const AddToFavorite = ({
	id,
	featured_image,
	nom,
	type = "places",
	iconButton = true,
	style,
}) => {
	const { t } = useTranslation();
	const { user, userAccount } = useAuthContainer.useContainer();
	const isFav = userAccount?.favorites?.find((fav) => fav.id === id);
	const favObj = {
		id,
		name: nom || "",
		type,
		image: featured_image || null,
	};

	const UserModel = new User({
		favorites: !isFav ? arrayUnion(favObj) : arrayRemove(favObj),
	});

	const Component = iconButton ? IconButton : Button;
	return (
		<Box
			sx={{
				...style,
				display: "flex",
				justifyContent: { xs: "center" },
			}}
		>
			<Component
				variant={iconButton ? null : !isFav ? "outlined" : "solid"}
				onClick={() => UserModel.updateUser(user.uid)}
				sx={(theme) => ({
					".MuiSvgIcon-root": {
						color:
							iconButton && !isFav
								? "default"
								: theme.vars.palette.primary[500],
					},
				})}
			>
				{isFav ? <Favorite /> : <FavoriteBorder />}{" "}
				{iconButton
					? null
					: !isFav
					? t("add_to_favorite")
					: t("added_to_favorite")}
			</Component>
		</Box>
	);
};

export default AddToFavorite;
AddToFavorite.propTypes = {
	id: PropTypes.string,
	featured_image: PropTypes.string,
	nom: PropTypes.string,
	type: PropTypes.string,
	iconButton: PropTypes.bool,
	style: PropTypes.object,
};
AddToFavorite.defaultProps = {
	type: "places",
	iconButton: false,
	style: {},
};
