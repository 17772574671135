import React from "react";
import { useTranslation } from "react-i18next";
import { Box, Typography } from "@mui/joy";
import PropTypes from "prop-types";
import { PlaceSearch } from "containers/places/views";

const PlaceAdvancedSearch = (props) => {
	const { t } = useTranslation();

	return (
		<>
			<PlaceSearch adminSearch />
		</>
	);
};

export default PlaceAdvancedSearch;
PlaceAdvancedSearch.propTypes = {};
PlaceAdvancedSearch.defaultProps = {};
