import React from "react";
import { BannerInfo_1, BannerSlider } from "./partials";

const Banners = ({ name, contentFullID, contentModel }) => {
	const BannerRouter = (name) => {
		let Banner = null;
		switch (name) {
			case "banner_1":
				Banner = <BannerInfo_1 contentFullID={contentFullID} />;
				break;
			case "banner_slider":
				Banner = <BannerSlider contentModel={contentModel} />;
				break;

			default:
				Banner = Banner;

				break;
		}

		return Banner;
	};

	return BannerRouter(name);
};

export default Banners;
