import React from "react";
import {
	Select,
	Input,
	Checkbox,
	Option,
	MenuItem,
	Grid,
	Button,
	FormControl,
	FormHelperText,
} from "@mui/joy";
import Datas from "../../../../../authentification/views/Informations/partials/InformationsForm/ListDatas.json";
import { useTranslation } from "react-i18next";
import { useForm, Controller } from "react-hook-form";
import useEmailAuth from "../../../../../authentification/hooks/useEmailAuth";
import { db } from "../../../../../../utils/MyFirebase";
import { doc, setDoc } from "firebase/firestore";
import useAuthContainer from "../../../../../../stores/Auth";
import Helpers from "../../../../../../utils/Helpers";

const AdditionalInfoForm = (props) => {
	const { user, userAccount } = useAuthContainer.useContainer();
	const { signUp, signingUp } = useEmailAuth();
	const [saving, setSaving] = React.useState(false);
	const [additionalDatas, setAdditionalDatas] = React.useState(null);

	const { t } = useTranslation();
	const {
		handleSubmit,
		register,
		watch,
		setValue,
		formState: { errors },
		control,
	} = useForm();
	const { password, confirm_password, profil_type, job } = watch();
	const passwordNotConfirm = password != confirm_password;

	React.useEffect(() => {
		if (userAccount && !additionalDatas) {
			const datas = userAccount?.additionalDatas;
			setAdditionalDatas(datas);
			Helpers.populateForm(datas, setValue);
		}
	}, [userAccount]);

	const save = async (datas) => {
		setSaving(true);
		const ref = doc(db, "users", user.uid);
		await setDoc(ref, { additionalDatas: datas }, { merge: true });
		setSaving(false);
	};

	const ignore = () => {
		save({
			additionnalDatasAdded: true,
		});
	};
	const submit = (data) => {
		const additionalDatas = {
			...data,
			job: data?.job || "",
			profil_type: data?.profil_type || "",
			additionnalDatasAdded: true,
		};

		save(additionalDatas);
	};

	return additionalDatas ? (
		<form onSubmit={handleSubmit(submit)}>
			<Grid
				spacing={1}
				sx={{ flexGrow: 1 }}
				justifyContent="space-between"
				container
			>
				<Grid xs={profil_type === "other_profil" ? 6 : 12}>
					<Controller
						name="profil_type"
						control={control}
						render={({ field: { onChange, value, ref } }) => (
							<>
								<Select
									value={value}
									placeholder={t("profil_type")}
									sx={{ mb: 1 }}
									onChange={(e, v) => onChange(v)}
								>
									{Datas.profil.map((i) => (
										<Option value={i}>{t(i)}</Option>
									))}
									<Option value="other_profil">
										{t("other_profil")} :{" "}
									</Option>
								</Select>{" "}
							</>
						)}
					/>
				</Grid>
				{profil_type === "other_profil" && (
					<Grid md={6} xs={12}>
						<Input
							{...register("other_profil_type")}
							sx={{ mb: 1 }}
							type="text"
							placeholder={t("precise_profil_type")}
							name="other_profil_type"
						/>
					</Grid>
				)}
			</Grid>
			{console.log("additionalDatas", additionalDatas)}
			<Input
				{...register("group_name")}
				sx={{ mb: 1 }}
				type="text"
				placeholder={t("group_name")}
				name="group_name"
			/>
			<Grid
				spacing={1}
				sx={{ flexGrow: 1, mb: 1 }}
				justifyContent="space-between"
				container
			>
				<Grid md={6} xs={12}>
					<Input
						placeholder={t("lastname")}
						{...register("lastname")}
					/>
				</Grid>

				<Grid md={6} xs={12}>
					<Input placeholder={t("name")} {...register("name")} />
				</Grid>
			</Grid>

			<Grid
				spacing={1}
				sx={{ flexGrow: 1 }}
				justifyContent="space-between"
				container
			>
				<Grid xs={job === "other_job" ? 6 : 12}>
					<Controller
						name="job"
						control={control}
						render={({ field: { onChange, value } }) => (
							<Select
								value={value}
								onChange={(e, v) => onChange(v)}
								sx={{ mb: 1 }}
								placeholder={t("job")}
							>
								{Datas.job.map((i) => (
									<Option value={i}>{t(i)}</Option>
								))}
								<Option value="other_job">
									{t("other_job")} :
								</Option>
							</Select>
						)}
					/>
				</Grid>
				{job === "other_job" && (
					<Grid md={6} xs={12}>
						<Input
							{...register("other_job_type")}
							sx={{ mb: 1 }}
							type="text"
							placeholder={t("precise_job")}
							name="other_job_type"
						/>
					</Grid>
				)}
			</Grid>

			<Input
				sx={{ mb: 3 }}
				placeholder={t("phone")}
				{...register("phone")}
			/>

			<Grid
				spacing={1}
				sx={{ flexGrow: 1 }}
				justifyContent="space-between"
				container
			>
				<Grid xs={12}>
					<Button
						disabled={saving}
						loading={saving}
						type="submit"
						fullWidth
					>
						{t("save")}
					</Button>
				</Grid>
			</Grid>
		</form>
	) : null;
};

export default AdditionalInfoForm;
