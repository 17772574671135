import React from "react";
import { useTranslation } from "react-i18next";
import { Box, Typography, Stack } from "@mui/joy";

const Tarifs = ({ price, type }) => {
	const { t } = useTranslation();

	return (
		<Box sx={(theme) => ({})}>
			<Stack
				spacing={2}
				alignItems="center"
				sx={{
					textAlign: "center",
				}}
			>
				<Typography variant="paragraph">
					{t(`vue_tarifs_${type}`)}
				</Typography>
				<Typography variant="paragraph">
					{t("from_")}
					{price}€ / {t("day")} / {t("person")}
				</Typography>
				{/*<Typography paragraph variant="body2">
					{t(`vue_tarifs_${type}_desc`)}
				</Typography>*/}
			</Stack>
		</Box>
	);
};

export default Tarifs;
