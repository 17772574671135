import React, { useState } from "react";
import { useForm, Controller } from "react-hook-form";
import { Box, Autocomplete, TextField, Button, Stack } from "@mui/joy";
import User from "models/user"; // Assurez-vous d'importer correctement votre classe User
import Place from "models/places"; // Assurez-vous d'importer correctement votre classe User
import { useTranslation } from "react-i18next";
import { arrayUnion } from "firebase/firestore";

const Editable = ({ setUser, close, placeID }) => {
	const { t } = useTranslation();

	const { control, handleSubmit, setValue } = useForm();
	const [options, setOptions] = useState([]);
	const [loading, setLoading] = useState(false);
	const [name, setName] = React.useState(null);

	// Instance de la classe User
	const userInstance = new User(); // Passez des paramètres si nécessaire
	const placeInstance = new Place(); // Passez des paramètres si nécessaire

	// Fonction pour fetch les useres par nom via la méthode de la classe User
	const fetchUsersByName = async (searchTerm) => {
		setLoading(true);
		if (searchTerm) {
			try {
				const result = await userInstance.searchUsersByName(searchTerm);
				// Transformez les résultats pour avoir des objets avec label et value
				const formattedOptions = result.map((user) => ({
					label: `${user.email} - ${
						user?.additionalDatas?.name || ""
					} ${user?.additionalDatas?.lastname || ""}`, // Ce qui est affiché
					value: user.id, // Ce qui est stocké
				}));
				setOptions(formattedOptions);
			} catch (error) {
				console.error("Error fetching users by name:", error);
			} finally {
				setLoading(false);
			}
		} else {
			setOptions([]);
		}
	};

	const onSubmit = (data) => {
		// Récupère le usere sélectionné à partir du nom

		// Utilisez le usere sélectionné
		if (data.user) {
			placeInstance.uid = data.user;
			placeInstance.updatePlace(placeID);

			setUser({
				value: data.user,
				label: data.name,
			});
		}

		close();
	};

	return (
		<Box sx={{ mt: 1 }}>
			<form onSubmit={handleSubmit(onSubmit)}>
				<Controller
					name="user"
					control={control}
					render={({ field }) => (
						<Autocomplete
							placeholder={t("enter_email_to_search")}
							{...field}
							options={options} // Les options sont maintenant des objets avec label et value
							getOptionLabel={(option) => option.label} // Affiche uniquement le label (email)
							isOptionEqualToValue={(option, value) =>
								option.value === value
							} // Comparer par la valeur (ID)
							loading={loading}
							onInputChange={(event, value) => {
								fetchUsersByName(value);
							}}
							onChange={(event, value) => {
								// Met à jour le champ avec la valeur de l'option sélectionnée (ID de l'utilisateur)
								setValue("user", value ? value.value : ""); // Stocke la valeur (ID) au lieu du label
								setValue("name", value ? value.label : ""); // Stocke la valeur (ID) au lieu du label
							}}
							renderInput={(params) => (
								<TextField
									{...params}
									label="Select User"
									placeholder="Search for a user"
								/>
							)}
						/>
					)}
				/>
				<Stack sx={{ mt: 1 }} direction="row" spacing={1}>
					<Button type="submit" color="primary">
						{t("save")}
					</Button>
					<Button color="neutral" onClick={close} type="button">
						{t("cancel")}
					</Button>
				</Stack>
			</form>
		</Box>
	);
};

export default Editable;
