import React from "react";
import {
	Modal,
	ModalDialog,
	ModalOverflow,
	Box,
	Typography,
	List,
	ListItem,
	ListItemDecorator,
	ListItemContent,
	Button,
	Stack,
	ListItemButton,
	Divider,
	ListSubheader,
	Select,
	Alert,
	Option,
} from "@mui/joy";
import { useTranslation } from "react-i18next";
import folderList from "../folderList";
import { useForm, Controller } from "react-hook-form";

const FolderTree = ({ setFolderSearch, folderSearch }) => {
	const { t } = useTranslation();

	return (
		<Box>
			<List>
				<ListItemButton
					selected={folderSearch === null}
					onClick={() => setFolderSearch(null)}
				>
					<ListItemDecorator></ListItemDecorator>
					{t("show_all")}
				</ListItemButton>

				<ListSubheader>{t("Images")}</ListSubheader>

				<ListItem>
					<List>
						{folderList().images.map((image) => (
							<ListItemButton
								selected={folderSearch === image}
								onClick={() => setFolderSearch(image)}
								key={image}
							>
								<ListItemDecorator></ListItemDecorator>
								{t(image)}
							</ListItemButton>
						))}
					</List>
				</ListItem>
			</List>
			<List>
				<ListSubheader>{t("Documents")}</ListSubheader>

				<ListItem>
					<List>
						{folderList().documents.map((doc) => (
							<ListItemButton
								selected={folderSearch === doc}
								onClick={() => setFolderSearch(doc)}
								key={doc}
							>
								<ListItemDecorator></ListItemDecorator>
								{t(doc)}
							</ListItemButton>
						))}
					</List>
				</ListItem>
			</List>
		</Box>
	);
};

export default FolderTree;
