import React from "react";
import { IconButton, Typography, Box, Stack, Switch } from "@mui/joy";
import { Wifi as WifiIcon, ArrowBack } from "@mui/icons-material";
import { WhiteContainer } from "UI";
import { Builder } from "Common";
import useUsefullInfosContainer from "../../../../UsefullInfosStore";
import { useTranslation } from "react-i18next";

const Wifi = ({ id }) => {
	const { t } = useTranslation();

	const { setEditor, setUsefullInfosState, usefullInfosState } =
		useUsefullInfosContainer.useContainer();

	return (
		<WhiteContainer>
			<Stack
				direction="row"
				alignItems="center"
				justifyContent="space-between"
				sx={{ mb: 3 }}
				spacing={2}
			>
				<Stack direction="row" alignItems="center" spacing={1}>
					<IconButton onClick={() => setEditor(null)}>
						<ArrowBack />
					</IconButton>
					<Typography>{t("back_usefull_infos")}</Typography>
				</Stack>

				<Stack direction="row" spacing={1} alignItems="center">
					<Switch
						checked={usefullInfosState?.wifi?.active}
						onChange={(e) => {
							const v = e.target.checked;
							setUsefullInfosState({
								...usefullInfosState,
								wifi: { ...usefullInfosState?.wifi, active: v },
							});
						}}
					/>

					<Typography
						startDecorator={<WifiIcon />}
						level="h5"
						sx={(theme) => ({
							textTransform: "uppercase",
							background: usefullInfosState?.wifi?.active
								? theme.vars.palette.primary[400]
								: "#EEE",
							color: usefullInfosState?.wifi?.active
								? "white"
								: "initial",
							borderRadius: "200px",

							pl: 2,
							pr: 2,
						})}
					>
						WiFi
					</Typography>
				</Stack>
			</Stack>

			<Box>
				<Builder
					content={usefullInfosState?.wifi?.content || []}
					action={(d) =>
						setUsefullInfosState({
							...usefullInfosState,
							wifi: { ...usefullInfosState?.wifi, content: d },
						})
					}
					editorConfig={{
						modalSize: 700,
						limitDepth: 1,
						itemsConfig: {
							default: {
								cardFields: ["title"],
								terms: {
									type: "wifi",
									type_plural: "wifis",
									create_new_item: "add_wifi_code",
									create_new_children: "create_new_wifi",
									detail_tab: "wifi_detail",
									children_type: "item",
									content_tab: "item_content",
								},
								editor: {
									hasChildren: false,
									fields: [
										{
											label: "title",
											placeholder: "place_or_name",
											type: "text",
											rules: {
												required: true,
											},
										},
										{
											label: "wifi_code",
											type: "text",
											rules: {
												required: false,
											},
										},
										{
											label: "network_name",
											type: "text",
											rules: {
												required: false,
											},
										},
									],
								},
							},
						},
					}}
				/>
			</Box>
		</WhiteContainer>
	);
};

export default Wifi;
