import React from "react";
import ServicesRoutes from "./ServicesRoutes";

const Services = (props) => {
	return (
		<>
			<ServicesRoutes />
		</>
	);
};

export default Services;
