import React from "react";
import Modal, { ModalProps, modalClasses } from "@mui/joy/Modal";

import {
	Container,
	ModalDialog,
	IconButton,
	ModalClose,
	Sheet,
	Box,
	Tabs,
	Stack,
	Divider,
	ModalOverflow,
	Button,
	Chip,
	TabList,
	Typography,
	TabPanel,
} from "@mui/joy";
import { ArrowBack } from "@mui/icons-material";
import useBuilderContainer from "../../BuilderStore";
import Helpers from "../../../../../utils/Helpers";
import { FieldsRouter, Children } from "./partials";
import Tab, { tabClasses } from "@mui/joy/Tab";
import { useTranslation } from "react-i18next";
import useMediaQuery from "@mui/material/useMediaQuery";

const Editor = ({ content }) => {
	const matches = useMediaQuery("(max-width:600px)");
	const { t } = useTranslation();
	const {
		selected,
		setSelected,
		contentState,
		config,
		parent,
		mainParent,
		setMainParent,
	} = useBuilderContainer.useContainer();

	const [element, setElement] = React.useState(null);
	const [fields, setFields] = React.useState([]);

	const getEl = () => Helpers.deepArraySearch(contentState, selected);
	const depth = parseInt(Helpers.findDepthById(contentState, getEl()?.id));
	const formatedDepth = `depth_${depth}`;
	const maxDepthReach = depth >= config?.limitDepth;
	const [itemsConfig, setItemsConfig] = React.useState(null);

	React.useEffect(() => {
		if (selected) {
			let conf = config.itemsConfig[formatedDepth]
				? config.itemsConfig[formatedDepth]
				: config.itemsConfig.default;

			if (conf?.ifNochilds && getEl()?.children.length < 1) {
				conf = config.itemsConfig[conf.ifNochilds];
			}

			setItemsConfig(conf);
			setFields(conf?.editor?.fields);
		}
	}, [selected]);

	const modalProperties = !matches
		? {
				width: config?.modalSize || 700,
				position: "fixed !important",
				right: "0 !important",
				overflowY: "auto",
				height: "100vh",
				top: "0 !important",
		  }
		: {};
	return (
		<Modal
			sx={{
				".MuiModal-backdrop": {
					display: "none",
				},
				".MuiModalDialog-root": modalProperties,
			}}
			size="lg"
			open={Boolean(selected)}
			onClose={() => {
				setMainParent(null);
				setSelected(null);
			}}
		>
			<ModalOverflow>
				<ModalDialog
					aria-labelledby="layout-modal-title"
					aria-describedby="layout-modal-description"
					layout={matches && "fullscreen"}
				>
					<ModalClose
						sx={{
							zIndex: 2,
							background: "#eee",
						}}
					/>
					<Container>
						<Stack
							direction="row"
							justifyContent="center"
							spacing={3}
							sx={{ mb: 5 }}
						>
							{parent && (
								<IconButton onClick={() => setSelected(parent)}>
									<ArrowBack />
								</IconButton>
							)}
							<Typography level="h3">{getEl()?.title}</Typography>
							<Chip size="sm">
								{t(itemsConfig?.terms?.type || "element")}
							</Chip>
						</Stack>

						{!maxDepthReach ? (
							<Tabs
								size="lg"
								defaultValue={1}
								sx={(theme) => ({
									"--Tabs-gap": "0px",
									overflow: "auto",
								})}
							>
								<TabList
									sx={{
										mb: 3,
										"--ListItem-radius": "0px",
										borderRadius: 0,

										[`& .${tabClasses.root}`]: {
											fontWeight: "lg",
											flex: 1,
											bgcolor: "background.body",
											position: "relative",
											[`&.${tabClasses.selected}`]: {
												color: "primary.500",
											},
											[`&.${tabClasses.selected}:before`]:
												{
													content: '""',
													display: "block",
													position: "absolute",
													bottom: -1,
													width: "100%",
													height: 2,
													bgcolor: "primary.400",
												},
											[`&.${tabClasses.focusVisible}`]: {
												outlineOffset: "-3px",
											},
										},
									}}
								>
									<Tab>
										{t(
											itemsConfig?.terms.content_tab ||
												"content",
										)}
									</Tab>
									<Tab>
										{t(
											itemsConfig?.terms.detail_tab ||
												"informations",
										)}
									</Tab>
								</TabList>

								<Divider sx={{ mb: 5 }} />
								<TabPanel
									value={0}
									sx={{
										overflow: "auto",
										height: "66vh",
									}}
								>
									<Children element={getEl()} />
								</TabPanel>
								<TabPanel value={1}>
									<FieldsRouter
										content={getEl()}
										fields={fields}
									/>
								</TabPanel>
							</Tabs>
						) : (
							<FieldsRouter content={getEl()} fields={fields} />
						)}
					</Container>
				</ModalDialog>
			</ModalOverflow>
		</Modal>
	);
};

export default Editor;
