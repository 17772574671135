import React from "react";
import { useTranslation } from "react-i18next";
import { Box, Typography, List, ListItem, Button } from "@mui/joy";
import PropTypes from "prop-types";
import { AdditionnalDataForm } from "forms/admin/users";

const UserInfos = ({ user, setUser }) => {
	const { t } = useTranslation();
	const { additionalDatas } = user;
	const [edit, setEdit] = React.useState(false);

	return (
		<>
			{edit ? (
				<AdditionnalDataForm
					datas={additionalDatas}
					id={user.id}
					close={(d) => {
						setEdit(false);
						console.log(d);
						setUser({ ...user, additionalDatas: d });
					}}
				/>
			) : (
				<Box>
					<List>
						{[
							"group_name",
							"job",
							"lastname",
							"name",
							"phone",
							"profil_type",
						].map((item, i) =>
							additionalDatas && additionalDatas[item] ? (
								<ListItem key={`item_${i}`}>
									<Typography>
										<b>{t(item)} : </b>
									</Typography>
									<Typography>
										{t(additionalDatas[item])}
									</Typography>
								</ListItem>
							) : null,
						)}
					</List>
					<Button onClick={() => setEdit(true)}>{t("edit")}</Button>
				</Box>
			)}
		</>
	);
};

export default UserInfos;
UserInfos.propTypes = {};
UserInfos.defaultProps = {};
