import React from "react";
import { useCollection } from "react-firebase-hooks/firestore";
import {
  query,
  collection,
  where,
  deleteDoc,
  doc,
  addDoc,
} from "firebase/firestore";
import { Box, Alert, Typography, Link } from "@mui/joy";
import { db, storage } from "utils/MyFirebase";
import List from "@mui/joy/List";
import ListItem from "@mui/joy/ListItem";
import ListItemContent from "@mui/joy/ListItemContent";
import { Chip } from "@mui/joy";
import ListItemDecorator from "@mui/joy/ListItemDecorator";
import IconButton from "@mui/joy/IconButton";
import DeleteIcon from "@mui/icons-material/Delete";
import useAuthContainer from "stores/Auth";
import {
  getStorage,
  ref,
  deleteObject,
  uploadBytes,
  getDownloadURL,
} from "firebase/storage";
import { useForm } from "react-hook-form";
import { v4 as uuidv4 } from "uuid";
import Button from "@mui/joy/Button";
import Input from "@mui/joy/Input";
import { useTranslation } from "react-i18next";

const DocumentUpload = ({ uid, role }) => {
  const { register, handleSubmit, reset, watch } = useForm();
  const [uploading, setUploading] = React.useState(false);

  const fileWatch = watch("file");

  const uploadDocument = async ({ file }) => {
    setUploading(true);
    try {
      if (!file.length) return;

      const id = uuidv4();

      const storageRef = ref(storage, `documents/${uid}/${id}${file[0].name}`);
      const uploadResult = await uploadBytes(storageRef, file[0]);
      const downloadURL = await getDownloadURL(uploadResult.ref);
      await addDoc(collection(db, "documents"), {
        uid,
        user: doc(db, "users", uid),
        id: `${id}${file[0].name}`,
        fileName: file[0].name,
        filePath: uploadResult.metadata.fullPath,
        downloadURL,
        role,
        reason: "",
        status: "pending",
        createdAt: new Date(),
      });

      setUploading(false);
    } catch (e) {
      console.log(e);
      setUploading(false);
    }
    reset();
  };

  return (
    <Box
      sx={{
        p: 2,
        mt: 2,
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
      }}
    >
      <form
        onSubmit={handleSubmit(uploadDocument)}
        style={{
          width: 300,
          display: "flex",
          flexDirection: "column",
          gap: "16px",
        }}
      >
        <Input {...register("file")} type="file" variant="container" />
        <Button
          color="primary"
          loading={uploading}
          disabled={uploading || !fileWatch?.length || fileWatch.length < 1}
          type="submit"
        >
          Envoyer
        </Button>
      </form>
    </Box>
  );
};

const DocumentsValidation = ({ uid, role }) => {
  const { t } = useTranslation();
  const { userAccount } = useAuthContainer.useContainer();

  const documentsQuery = query(
    collection(db, "documents"),
    where("uid", "==", uid),
  );
  const [documents, loading, error] = useCollection(documentsQuery);

  const deleteDocument = async (documentId, fileName) => {
    // Suppression du document Firestore
    await deleteDoc(doc(db, "documents", documentId));

    // Suppression du fichier dans Firebase Storage
    const fileRef = ref(storage, `documents/${uid}/${fileName}`);
    await deleteObject(fileRef);
  };

  if (loading) return <p>Loading...</p>;

  return (
    <Box
      sx={{
        background: "#FAFAFA",
        p: 1,
        borderRadius: "10px",
        mt: 3,
        border: "1px solid  #888",
      }}
    >
      <Typography
        level="h4"
        sx={{ fontSize: "18px", mt: 1, mb: 1, textAlign: "center" }}
      >
        {t("send_data_validation")}
      </Typography>
      <i>{t("document_upload_limitation")}</i>
      {userAccount?.accountValidationInfo && (
        <Alert color="primary" sx={{ textAlign: "center" }}>
          {userAccount?.accountValidationInfo}
        </Alert>
      )}
      <DocumentUpload role={role} uid={uid} />
      <List>
        {documents?.docs.map((doc) => (
          <ListItem
            sx={{ borderBottom: "1px solid", "&:hover": { opacity: 0.7 } }}
            key={doc.id}
          >
            <ListItemDecorator>
              <IconButton
                color="danger"
                onClick={() => deleteDocument(doc.id, doc.data().id)}
              >
                <DeleteIcon />
              </IconButton>
            </ListItemDecorator>
            <ListItemContent>
              <Link href={`${doc.data()?.downloadURL}`} target="_blank">
                {doc.data().fileName}
              </Link>{" "}
              -{" "}
              <Chip
                color={
                  doc.data().status === "pending"
                    ? "neutral"
                    : doc.data().status === "approved"
                    ? "success"
                    : "danger"
                }
              >
                {t(doc.data().status)}
              </Chip>
              {doc.data().status === "rejected" && (
                <Alert sx={{ m: 1 }} color="danger">
                  {doc.data()?.reason}
                </Alert>
              )}
            </ListItemContent>
          </ListItem>
        ))}
      </List>
    </Box>
  );
};

export default DocumentsValidation;
