import CheckIcon from "@mui/icons-material/Check";
import Box from "@mui/joy/Box";
import Chip from "@mui/joy/Chip";
import Radio from "@mui/joy/Radio";
import Typography from "@mui/joy/Typography";
import * as React from "react";
import { useTranslation } from "react-i18next";

export default function ChipList({
	items,
	register,
	action,
	selectItem,
	withReactHookForm,
	setValue,
	rules,
	fieldName,
}) {
	const { t } = useTranslation();
	const [selected, setSelected] = React.useState(selectItem);

	// Synchroniser l'état interne avec les props
	React.useEffect(() => {
		setSelected(selectItem);
	}, [selectItem]);

	return (
		<Box sx={{ display: "flex", gap: 1, alignItems: "center" }}>
			<Box>
				<Box sx={{ display: "flex", flexWrap: "wrap", gap: 1 }}>
					{items.map((name) => {
						const checked = selected === name;
						return (
							<Chip
								key={name}
								variant={checked ? "soft" : "plain"}
								color={checked ? "primary" : "neutral"}
								startDecorator={
									checked && (
										<CheckIcon
											sx={{
												zIndex: 1,
												pointerEvents: "none",
											}}
										/>
									)
								}
							>
								<Radio
									variant="outlined"
									color={checked ? "primary" : "neutral"}
									disableIcon
									overlay
									label={t(name)}
									{...register(fieldName, {
										...rules,
									})}
									value={name}
									checked={checked}
									onChange={(event) => {
										if (event.target.checked) {
											setValue(
												fieldName,
												event.target.value,
											);
											setSelected(name);
											if (action) {
												withReactHookForm
													? action()()
													: action();
											}
										}
									}}
								/>
							</Chip>
						);
					})}
				</Box>
			</Box>
		</Box>
	);
}
