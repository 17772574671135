import React, { useState } from "react";
import {
	Select,
	Input,
	Checkbox,
	Option,
	MenuItem,
	Grid,
	Button,
	FormControl,
	FormHelperText,
} from "@mui/joy";
import Datas from "datas/users/users_additionnal_datas.json";
import { useTranslation } from "react-i18next";
import { useForm, Controller } from "react-hook-form";
import useEmailAuth from "../../../../hooks/useEmailAuth";

const CreateAccountForm = (props) => {
	const [isGroupMember, setIsGroupMember] = useState(false);
	const {
		handleSubmit,
		register,
		watch,
		formState: { errors },
		control,
	} = useForm();
	const { signUp, signingUp } = useEmailAuth();
	const { password, confirm_password, profil_type, job } = watch();
	const passwordNotConfirm = password != confirm_password;

	const { t } = useTranslation();
	const companyRequiredProfiles = [
		"PartnerLocation",
		"PartnerCateringFB",
		"PartnerEntertainment",
		"PartnerTeamBuilding",
		"Company",
		"Agency",
	];

	const submit = (data) => {
		const { email, password } = data;
		delete data.password;
		delete data.confirm_password;
		const additionalDatas = {
			...data,
			job: data?.job || "",
			profil_type: data?.profil_type || "",
		};

		signUp(email, password, additionalDatas);
	};

	return (
		<>
			<form onSubmit={handleSubmit(submit)}>
				<Grid
					spacing={1}
					sx={{ flexGrow: 1 }}
					justifyContent="space-between"
					container
				>
					<Grid xs={profil_type === "other_profil" ? 6 : 12}>
						<Controller
							name="profil_type"
							control={control}
							render={({ field: { onChange, value } }) => (
								<>
									<Select
										placeholder={t("profil_type")}
										sx={{ mb: 1 }}
										onChange={(e, v) => onChange(v)}
									>
										{Datas.profil.map((i) => (
											<Option value={i}>{t(i)}</Option>
										))}
									</Select>{" "}
								</>
							)}
						/>
					</Grid>
					{profil_type === "other_profil" && (
						<Grid xs={6}>
							<Input
								{...register("other_profil_type")}
								sx={{ mb: 1 }}
								type="text"
								placeholder={t("precise_profil_type")}
								name="other_profil_type"
							/>
						</Grid>
					)}
				</Grid>

				<Checkbox
					checked={isGroupMember}
					onChange={(e) => setIsGroupMember(e.target.checked)}
					label={t("belong_to_a_group")}
					sx={{ mb: 1 }}
				/>

				{isGroupMember && (
					<Input
						{...register("group_name")}
						sx={{ mb: 1 }}
						type="text"
						placeholder={t("group_name")}
						name="group_name"
					/>
				)}
				{companyRequiredProfiles.includes(profil_type) && (
					<Grid xs={12}>
						<Input
							{...register("company")}
							sx={{ mb: 1 }}
							type="text"
							placeholder={t("compagny_place")}
							name="company"
						/>
					</Grid>
				)}

				<Grid
					spacing={1}
					sx={{ flexGrow: 1, mb: 1 }}
					justifyContent="space-between"
					container
				>
					<Grid xs={6}>
						<Input
							placeholder={t("lastname")}
							{...register("lastname")}
						/>
					</Grid>

					<Grid xs={6}>
						<Input placeholder={t("name")} {...register("name")} />
					</Grid>
				</Grid>

				<Grid
					spacing={1}
					sx={{ flexGrow: 1 }}
					justifyContent="space-between"
					container
				>
					<Grid xs={job === "other_job" ? 6 : 12}>
						<Controller
							name="job"
							control={control}
							render={({ field: { onChange, value } }) => (
								<Select
									onChange={(e, v) => onChange(v)}
									sx={{ mb: 1 }}
									placeholder={t("job")}
								>
									{Datas.job.map((i) => (
										<Option value={i}>{t(i)}</Option>
									))}
									<Option value="other_job">
										{t("other_job")} :
									</Option>
								</Select>
							)}
						/>
					</Grid>
					{job === "other_job" && (
						<Grid xs={6}>
							<Input
								{...register("other_job_type")}
								sx={{ mb: 1 }}
								type="text"
								placeholder={t("precise_job")}
								name="other_job_type"
							/>
						</Grid>
					)}
				</Grid>
				<Grid
					spacing={1}
					sx={{ flexGrow: 1 }}
					justifyContent="space-between"
					container
				>
					<Grid xs={6}>
						<Input
							placeholder={t("email")}
							color={errors.email ? "danger" : "neutral"}
							{...register("email", {
								required: true,
								pattern: /^\S+@\S+$/i,
							})}
						/>
					</Grid>

					<Grid xs={6}>
						<Input
							placeholder={t("phone")}
							{...register("phone")}
						/>
					</Grid>
				</Grid>

				<FormControl>
					<Input
						color={
							passwordNotConfirm || errors.password
								? "danger"
								: "neutral"
						}
						sx={{ mt: 2, mb: 1 }}
						type="password"
						{...register("password", { required: true })}
						placeholder={t("password")}
					/>
					<FormHelperText>{t("8_caracter_minimum")}</FormHelperText>
				</FormControl>
				<FormControl>
					<Input
						color={
							passwordNotConfirm || errors.confirm_password
								? "danger"
								: "neutral"
						}
						sx={{ mt: 2, mb: 1 }}
						type="password"
						{...register("confirm_password", {
							required: true,
							minLength: 8,
						})}
						placeholder={t("confirm_password")}
					/>
					<FormHelperText>
						{passwordNotConfirm && t("password_not_match")}
					</FormHelperText>
				</FormControl>

				<Checkbox
					color={errors.policy ? "danger" : "neutral"}
					sx={{ mt: 1, mb: 2, fontSize: "0.8rem" }}
					label={t("policy")}
					{...register("policy", { required: true })}
				/>

				<Button
					disabled={signingUp}
					loading={signingUp}
					type="submit"
					fullWidth
				>
					{t("create_my_account")}
				</Button>
			</form>
		</>
	);
};

export default CreateAccountForm;
