import React from 'react'
import {
	Drawer,
	Box,
	List,
	ListItem,
	ListItemIcon,
	ListItemText,
} from '@mui/material'
import PropTypes from 'prop-types'

const BottomDrawer = ({
	sx,
	anchor,
	content,
	confirmAction,
	title,
	display,
	setOpen,
	close,
	showBackdrop,
}) => {
	const handleCancel = () => {
		setOpen(false)
	}

	return (
		<Drawer
			hideBackdrop={showBackdrop}
			anchor={anchor}
			open={display}
			onClose={() => handleCancel()}
		>
			<Box
				sx={{ width: anchor === 'top' || anchor === 'bottom' ? 'auto' : 250, ...sx }}
				role="presentation"
			>
				{Array.isArray(content) ? (
					<List>
						{content.length > 0 &&
							content.map((item) => {
								return (
									<ListItem
										onClick={() => handleCancel()}
										onKeyDown={() => handleCancel()}
										color="primary"
										button
										key={item.id}
										onClick={() => item.func()}
									>
										<ListItemIcon>{item.icon}</ListItemIcon>
										<ListItemText primary={item.label} />
									</ListItem>
								)
							})}
					</List>
				) : (
					content
				)}
			</Box>
		</Drawer>
	)
}

export default BottomDrawer

BottomDrawer.defaultProps = {
	buttonOk: 'Are you sure ? ',
	content: [{}],
	buttonCancel: 'Ok',
	title: 'Ok',
}
BottomDrawer.propTypes = {
	buttonOk: PropTypes.string,
	buttonCancel: PropTypes.string,
	content: PropTypes.arrayOf(PropTypes.object),
	title: PropTypes.string,
}
