import React from "react";
import { useTranslation } from "react-i18next";
import {
	Box,
	Typography,
	Select,
	Option,
	FormControl,
	FormLabel,
} from "@mui/joy";
import PropTypes from "prop-types";
import { Controller } from "react-hook-form";
import Datas from "datas/users/users_additionnal_datas.json";

const Job = ({ control }) => {
	const { t } = useTranslation();

	return (
		<FormControl>
			<FormLabel>{t("job")}</FormLabel>
			<Controller
				control={control}
				name="job"
				render={({ field: { value, onChange } }) => (
					<Select
						defaultValue={value}
						onChange={(e, v) => onChange(v)}
					>
						{Datas.job.map((job) => (
							<Option value={job}>{t(job)}</Option>
						))}
					</Select>
				)}
			/>
		</FormControl>
	);
};

export default Job;
Job.propTypes = {};
Job.defaultProps = {};
