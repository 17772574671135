import React from "react";
import { useTranslation } from "react-i18next";
import { Box, Typography, Button, Stack } from "@mui/joy";
import PropTypes from "prop-types";
import {
	EventName,
	EventType,
	ParticipantQty,
	EventDate,
	EventObjective,
	EventFrame,
	EventLocations,
	EventSeminarType,
	BudgetEvent,
	EventDatePartner,
	EventPaxPartner,
	EventLocationFromAModel,
	EventUserSelector,
	DetachEvent,
} from "../commons";
import { useForm } from "react-hook-form";
import Event from "models/events";
import { SavingButton, WhiteContainer } from "UI";
import Helpers from "utils/Helpers";
import useAuthContainer from "stores/Auth";

const EditEventGeneralForm = ({ formDatas, setFormDatas, place }) => {
	const { t } = useTranslation();
	const { user, userAccount } = useAuthContainer.useContainer();
	const [saving, setSaving] = React.useState(false);
	const fromAModel = formDatas?.fromPlaceId;
	const {
		handleSubmit,
		register,
		getValues,
		watch,
		control,
		setValue,
		formState: { errors, isDirty },
	} = useForm({
		defaultValues: {
			event_name: formDatas?.event_name,
			event_type: formDatas?.event_type,
			event_participants_qty: formDatas?.event_participants_qty,
			event_participants_qty_min: formDatas?.event_participants_qty_min,
			event_participants_qty_max: formDatas?.event_participants_qty_max,
			event_start_date: formDatas?.event_start_date,
			event_seminar_type: formDatas?.event_seminar_type || [],
			event_end_date: formDatas?.event_end_date,
			event_date_stopped: formDatas?.event_date_stopped || false,
			event_date_period: formDatas?.event_date_period,
			event_day_duration: formDatas?.event_day_duration,
			event_objective: formDatas?.event_objective || [],
			event_frame: formDatas?.event_frame || [],
			event_budget: formDatas?.event_budget,
			event_budget_type: formDatas?.event_budget_type,
			event_locations: formDatas?.event_locations || [],
			event_periods: formDatas?.event_periods || [],
			days_duration: formDatas?.days_duration,
			nights_duration: formDatas?.nights_duration,
			event_coordonnees_gps: formDatas?.event_coordonnees_gps,
			event_adresse: formDatas?.event_adresse,
			event_ville: formDatas?.event_ville,
			event_region: formDatas?.event_region,
			event_pays: formDatas?.event_pays,
			event_code_postal: formDatas?.event_code_postal,
			uid: formDatas?.uid,
		},
	});

	const save = async (d) => {
		setSaving(true);
		try {
			const eventDatas = { ...d };
			if (eventDatas.event_end_date) {
				eventDatas.event_end_date = Helpers.formatToDate(
					eventDatas.event_end_date,
				);
			}
			if (eventDatas.event_start_date) {
				eventDatas.event_start_date = Helpers.formatToDate(
					eventDatas.event_start_date,
				);
			}
			const EventEntity = new Event(eventDatas);
			const updated = await EventEntity.updateEvent(formDatas.id);
		} catch (e) {
			console.log(e.message);
		}
		setSaving(false);
	};

	return (
		<>
			<form onSubmit={handleSubmit(save)}>
				{userAccount?.role === "admin" && (
					<WhiteContainer>
						<EventUserSelector
							owner={user?.uid === formDatas?.uid}
							register={register}
							control={control}
						/>
					</WhiteContainer>
				)}
				<WhiteContainer>
					<EventName register={register} error={errors?.event_name} />
				</WhiteContainer>
				<WhiteContainer>
					<EventType
						register={register}
						setValue={setValue}
						selected={getValues("event_type")}
					/>

					<EventSeminarType
						selected={getValues("event_seminar_type")}
						setValue={setValue}
						register={register}
						watch={watch}
						error={errors?.event_seminar_type}
					/>

					<EventObjective
						setValue={setValue}
						register={register}
						watch={watch}
						selectItem={formDatas?.event_objective}
						error={errors?.event_objective}
					/>
				</WhiteContainer>
				<WhiteContainer>
					{formDatas?.placeId ? (
						<EventPaxPartner register={register} />
					) : (
						<ParticipantQty
							register={register}
							error={errors?.event_participants_qty}
						/>
					)}
				</WhiteContainer>
				<WhiteContainer>
					{!formDatas?.placeId ? (
						<EventDate
							watch={watch}
							register={register}
							control={control}
							setValue={setValue}
							getValues={getValues}
						/>
					) : (
						<EventDatePartner
							register={register}
							control={control}
							setValue={setValue}
							getValues={getValues}
						/>
					)}
				</WhiteContainer>
				<WhiteContainer>
					<EventFrame
						setValue={setValue}
						register={register}
						selectItem={formDatas?.event_frame}
						error={errors?.event_frame}
					/>
				</WhiteContainer>
				<WhiteContainer>
					<BudgetEvent
						control={control}
						setValue={setValue}
						watch={watch}
					/>
				</WhiteContainer>
				<WhiteContainer>
					{fromAModel && <EventLocationFromAModel place={place} />}

					{!fromAModel && (
						<EventLocations
							setValue={setValue}
							getValues={getValues}
							register={register}
							errors={errors}
							defaultAdresse={watch("event_adresse")}
						/>
					)}
				</WhiteContainer>
				<WhiteContainer>
					<Stack spacing={2}>
						{formDatas?.fromPlaceId && (
							<DetachEvent id={formDatas?.id} />
						)}
						<SavingButton loading={saving} />
					</Stack>
				</WhiteContainer>
			</form>
		</>
	);
};

export default EditEventGeneralForm;
EditEventGeneralForm.propTypes = {};
EditEventGeneralForm.defaultProps = {};
