import React from "react";

import { WhiteBackdrop } from "../../../../components/UI";
import { BOLayout } from "../../../../components/layouts";
import NewEventLayout from "./NewEventLayout";
import { CreateEventForm } from "forms/events";

const NewEvent = (props) => {
	return (
		<BOLayout title="create_new_event">
			<WhiteBackdrop text="new_event_creating" />
			<NewEventLayout title="create_new_event">
				<CreateEventForm />
			</NewEventLayout>
		</BOLayout>
	);
};

export default NewEvent;
