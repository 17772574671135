import React from "react";
import { FormSeparator } from "UI";
import { Input, FormControl, FormHelperText } from "@mui/joy";
import { useTranslation } from "react-i18next";

const EventName = ({ register, error }) => {
	const { t } = useTranslation();
	return (
		<FormSeparator required title="your_event">
			<FormControl>
				<Input
					sx={{ mb: 1 }}
					{...register("event_name", {
						required: t("event_name_required"),
					})}
					type="text"
					placeholder={t("event_name")}
				/>
				{error && <FormHelperText>{error.message}</FormHelperText>}
			</FormControl>
		</FormSeparator>
	);
};

export default EventName;
