import React from "react";
import { useTranslation } from "react-i18next";
import { Stack, Box, Typography, Button, Select, Option, Chip } from "@mui/joy";
import PropTypes from "prop-types";
import Document from "models/documents";
import SimpleTable from "UI/Tables/SimpleTable/SimpleTable";
import { WhiteContainer } from "UI";
import { useHistory } from "react-router-dom";

import User from "models/user";

const RequestsList = (props) => {
	const history = useHistory();
	const DocumentEntity = new Document();
	const [requests, setRequests] = React.useState([]);
	const { t } = useTranslation();

	const fetchRequests = async () => {
		await DocumentEntity.fetchUniqueUserDocuments((_requests) => {
			setRequests(
				_requests.map((r) => ({
					...r,
					phone: r?.additionalDatas?.phone,
					job: r?.additionalDatas?.job,
					profil_type: r?.additionalDatas?.profil_type,
					name: `${r?.additionalDatas?.lastname} ${r?.additionalDatas?.name}`,
				})),
			);
		});
	};

	const columns = ["email", "phone", "name", "isApproved", ""];
	const actions = [
		(d) => (
			<Stack direction="row" spacing={1}>
				<Button
					onClick={() => DocumentEntity.approveRequest(d?.userId)}
				>
					{t("approve")}
				</Button>
				<Button
					onClick={() =>
						history.push({
							search: `?requestModal=true&userId=${d?.userId}`,
						})
					}
					color="info"
				>
					{t("show_documents")}
				</Button>
			</Stack>
		),
	];
	const fields = {
		email: (v) => <Typography>{v}</Typography>,
		phone: (v) => <Typography>{v}</Typography>,
		name: (v) => <Typography>{t(v)}</Typography>,
		isApproved: (v) => (
			<Chip>{!v ? t("not_approved") : t("approved")}</Chip>
		),
	};

	React.useEffect(() => {
		fetchRequests();
	}, []);

	return (
		<>
			<WhiteContainer>
				<Typography>
					{t("request_to_proceed", { nbr: requests.length })}
				</Typography>
			</WhiteContainer>
			<Box sx={{ height: 400, width: "100%" }}>
				<SimpleTable
					inputProps={{
						borderAxis: "none",
					}}
					style={{
						background: "white",
						borderRadius: "10px",
						mb: 2,
					}}
					head={columns}
					datas={requests}
					fields={fields}
					actions={actions}
				/>{" "}
			</Box>
		</>
	);
};

export default RequestsList;
RequestsList.propTypes = {};
RequestsList.defaultProps = {};
