import React from "react";
import { useTranslation } from "react-i18next";
import { Box, Typography, Button } from "@mui/joy";
import PropTypes from "prop-types";

const FloatingMenu = ({ actions }) => {
	const { t } = useTranslation();

	return (
		<Box
			sx={{
				display: "flex",
				flexDirection: "column",
				width: "180px",
				position: "fixed",
				transition: "ease 300ms",

				zIndex: 7,
				transform: "translateX(-141px)",
				"&:hover": {
					transform: "translateX(0px)",
				},
			}}
		>
			{actions.map(
				(action) =>
					!action?.hidden && (
						<Button
							sx={{
								mb: 1,
								textAlign: "right",
								justifyContent: "right",
							}}
							endDecorator={action?.icon}
							onClick={() => action.action()}
						>
							{t(action.label)}
						</Button>
					),
			)}
		</Box>
	);
};

export default FloatingMenu;
FloatingMenu.propTypes = {};
FloatingMenu.defaultProps = {};
