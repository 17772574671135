import React from "react";
import { Modal, ModalDialog, Divider, Button, Typography, Box } from "@mui/joy";
import { WarningRounded } from "@mui/icons-material";
import { useTranslation } from "react-i18next";
import { doc, deleteDoc } from "firebase/firestore";
import { db } from "utils/MyFirebase";

const DeleteDocModal = ({
  selected,
  close,
  collection,
  text = "sure_remove_item",
}) => {
  const { t } = useTranslation();
  const [deleting, setDeleting] = React.useState(false);

  const removePlace = async () => {
    setDeleting(true);
    if (selected) {
      try {
        await deleteDoc(doc(db, collection, selected));
        close();
      } catch (error) {
        alert(
          "Erreur durant la suppression . Si l'erreur persiste nous vous invitons à nous contacter ",
        );
      }
    }
    setDeleting(false);
  };

  return (
    <Modal open={selected} onClose={close}>
      <ModalDialog
        variant="outlined"
        role="alertdialog"
        aria-labelledby="alert-dialog-modal-title"
        aria-describedby="alert-dialog-modal-description"
      >
        <Typography
          id="alert-dialog-modal-title"
          component="h2"
          startDecorator={<WarningRounded />}
        >
          Confirmation
        </Typography>
        <Divider />
        {t(text)}
        <Box
          sx={{
            display: "flex",
            gap: 1,
            justifyContent: "flex-end",
            pt: 2,
          }}
        >
          <Button
            disabled={deleting}
            loading={deleting}
            onClick={removePlace}
            variant="solid"
            color="danger"
          >
            {t("ok")}
          </Button>
          <Button variant="plain" color="neutral" onClick={close}>
            {t("cancel")}
          </Button>
        </Box>
      </ModalDialog>
    </Modal>
  );
};

export default DeleteDocModal;
