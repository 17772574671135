import React from "react";
import { Box, Typography, Tooltip } from "@mui/joy";
import { Autorenew, Done, ErrorOutline } from "@mui/icons-material";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import { BsFileEarmarkCheck, BsFileEarmarkX } from "react-icons/bs";
import { AiFillFileExclamation, AiOutlineFileSync } from "react-icons/ai";
import { RiFileCloudLine } from "react-icons/ri";

const SavingDocumentLoader = ({ status }) => {
	const { t } = useTranslation();
	const style = {
		display: "flex",
		justifyContent: "center",
		flexDirection: "row",
		alignItems: "center",
		color: "#707070",
		fontSize: "15px",
	};

	const display = () => {
		switch (status) {
			case "waiting":
				return <></>;
				break;
			case "saving":
				return (
					<Box sx={{ mr: 2, ...style }}>
						<div>
							<Autorenew
								sx={{
									color: "#a5ccff",
								}}
							/>
						</div>
						<Typography>{t(status)}</Typography>...
					</Box>
				);

				break;
			case "saved":
				return (
					<Box sx={style}>
						<Done
							sx={{
								color: "#50bf6c",
							}}
						/>
						<Typography>{t(status)}</Typography>
					</Box>
				);
				break;
			case "saved_ok":
				return (
					<Box sx={style}>
						<RiFileCloudLine
							style={{
								color: "#a5ccff",
								fontSize: "23px",
							}}
						/>
					</Box>
				);
				break;
			case "error":
				return (
					<Box sx={style}>
						<AiFillFileExclamation
							style={{
								color: "#ff9999",
								fontSize: "23px",
							}}
						/>
					</Box>
				);
				break;
		}
	};

	return (
		<Box>
			<Tooltip title={t(status)}>{display()}</Tooltip>
		</Box>
	);
};

export default SavingDocumentLoader;

SavingDocumentLoader.propTypes = {
	status: PropTypes.string,
};
SavingDocumentLoader.defaultProps = {
	status: "waiting",
};
