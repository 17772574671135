import React from "react";
import {
	Modal,
	ModalDialog,
	ModalOverflow,
	ModalClose,
	Box,
	Button,
	Stack,
	Typography,
	FormLabel,
	FormControl,
	Input,
} from "@mui/joy";

import { useTranslation } from "react-i18next";
import { useForm } from "react-hook-form";
import { arrayUnion } from "firebase/firestore";
import { useHistory } from "react-router-dom";
import Event from "models/events";
import User from "models/user";

import useAuthContainer from "stores/Auth";

const EventLoadModelModal = ({ event, close }) => {
	const { handleSubmit, register } = useForm();
	const [saving, setSaving] = React.useState(false);
	const history = useHistory();
	const UserEntity = new User();
	const { user } = useAuthContainer.useContainer();
	const { t } = useTranslation();

	const open = Boolean(event);
	const LoadEvent = async () => {};
	const save = async (d) => {
		setSaving(true);
		try {
			const EventEntity = new Event();
			const added = await EventEntity.duplicateEvent(
				d.event_name,
				event.objectID,
				["objectID", "id", "shared", "placeId"],
				user.uid,
			);

			Object.assign(UserEntity, {
				accessiblePlacesMedias: arrayUnion(event.placeId),
			});

			if (added?.id) {
				close();
				await UserEntity.updateUser(user.uid);

				history.push(`/event/${added?.id}/general`);
			}
		} catch (e) {
			console.log(e.message);
		}
		setSaving(false);
	};

	return (
		<Modal
			aria-labelledby="modal-title"
			aria-describedby="modal-desc"
			open={open}
			onClose={close}
		>
			<ModalOverflow>
				<ModalDialog>
					<ModalClose />
					<Typography sx={{ maxWidth: "80%" }}>
						{t("load_event_model")} : <b>{event?.event_name}</b>
					</Typography>

					<form onSubmit={handleSubmit(save)}>
						<Box sx={{ mt: 3 }}>
							<FormControl>
								<FormLabel>{t("event_model_name")}</FormLabel>
								<Input
									required
									type="text"
									{...register("event_name", {
										required: true,
									})}
								/>
							</FormControl>
						</Box>
						<Box>
							<Stack sx={{ mt: 2 }} direction="row" spacing={1}>
								<Button
									type="submit"
									disabled={saving}
									loading={saving}
								>
									{t("load_this_event")}
								</Button>
								<Button
									onClick={close}
									disabled={saving}
									color="neutral"
								>
									{t("cancel")}
								</Button>
							</Stack>
						</Box>
					</form>
				</ModalDialog>
			</ModalOverflow>
		</Modal>
	);
};

export default EventLoadModelModal;
