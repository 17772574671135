import React from "react";
import { Box, Typography } from "@mui/joy";

const Description = ({ description }) => {
	return (
		<Box>
			<Typography
				level="h4"
				sx={(theme) => ({
					mb: 2,
					textAlign: "left",
					color: theme.vars.palette.primary[600],
				})}
			>
				Description
			</Typography>
			<Typography
				sx={{
					whiteSpace: "pre-wrap",
				}}
				variant="body1"
				paragraph
			>
				{description}
			</Typography>
		</Box>
	);
};

export default Description;
