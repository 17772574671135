import React from "react";
import { useTranslation } from "react-i18next";
import { Box, Typography, Button } from "@mui/joy";
import PropTypes from "prop-types";
import { Map } from "@mui/icons-material";

const MapToogle = ({ show, toggle }) => {
	const { t } = useTranslation();

	return (
		<Button
			variant="solid"
			onClick={toggle}
			color={show ? "neutral" : "primary"}
		>
			<Map /> {!show ? t("show_map") : t("hide_map")}
		</Button>
	);
};

export default MapToogle;
MapToogle.propTypes = {};
MapToogle.defaultProps = {};
