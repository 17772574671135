import React from "react";
import GooglePlacesAutocomplete from "react-google-places-autocomplete";
import { Input, Box } from "@mui/joy";
import { LocationOn } from "@mui/icons-material";
import { useTranslation } from "react-i18next";
import { Map, InfoWindow, Marker, GoogleApiWrapper } from "google-maps-react";

const geocoder = new window.google.maps.Geocoder();

const InputGooglePlace = ({
	setVal,
	withReactHookForm,
	getValues,
	name,
	action,
	noMap,
	...props
}) => {
	const [value, setValue] = React.useState(null);
	const [location, setLocation] = React.useState({});
	const [hasFocus, setHasFocus] = React.useState(true);
	const { t } = useTranslation();

	React.useEffect(() => {
		if (getValues) {
			const current_val = getValues(name);
			if (current_val && typeof current_val === "object") {
				setValue(current_val);
				geocode(current_val);
			}
		}
	}, []);

	const geocode = (v) => {
		geocoder.geocode({ placeId: v.value.place_id }).then(({ results }) => {
			if (results[0]) {
				const location = {
					lat: results[0].geometry.location.lat(),
					lng: results[0].geometry.location.lng(),
				};
				setLocation(location);
				const d = {
					...v,
					coordinates: location,
				};
				setVal(name || "event_location", d);
				if (action) {
					withReactHookForm ? action()() : action();
				}
			}
		});
	};

	return (
		<Box
			sx={(theme) => ({
				" > div": {
					position: "relative",
				},
			})}
		>
			<Box sx={{ position: "relative", zIndex: 2 }}>
				<Input
					component={GooglePlacesAutocomplete}
					selectProps={{
						value,
						onChange: (v) => {
							setValue(v);
							geocode(v);
							if (hasFocus) setHasFocus(false);
						},
						onFocus: () => {
							if (!hasFocus) setHasFocus(true);
						},
						onBlur: () => {
							if (hasFocus) setHasFocus(false);
						},
						onClick: () => {
							if (!hasFocus) setHasFocus(true);
						},
					}}
					apiKey={process.env.REACT_APP_GOOGLE_API_KEY}
				/>
			</Box>
			{location && !hasFocus && !noMap && (
				<Box
					sx={{
						mt: 4,
						" > div:nth-child(2)": {
							position: "relative",
							zIndex: 1,
						},
					}}
				>
					<Map
						style={{
							width: "100%",
							height: "300px",
							position: "relative",
						}}
						google={props.google}
						zoom={14}
						center={location}
					>
						<Marker name={value?.label} position={location} />
					</Map>
				</Box>
			)}
		</Box>
	);
};

export default GoogleApiWrapper({
	apiKey: process.env.REACT_APP_GOOGLE_API_KEY,
})(InputGooglePlace);
