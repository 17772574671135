import React from "react";
import { EditPlaceForm } from "forms/places";
import { Button, Sheet, Typography, Stack } from "@mui/joy";
import { ArrowBack, Visibility } from "@mui/icons-material";
import { Link, useParams } from "react-router-dom";

function PlaceEdit({ setDocId, setTitle }) {
	const { placeID } = useParams();

	React.useEffect(() => {
		setTitle("place_details_menu");
		setDocId(placeID);
	}, [placeID]);

	return (
		<>
			<Button
				variant="outlined"
				sx={{ mb: 3, textDecoration: "none" }}
				component={Link}
				to="/places"
				direction="row"
			>
				<ArrowBack />
				<Typography>Retour à la liste des lieux</Typography>
			</Button>
			<Button
				sx={{ mb: 3, textDecoration: "none" }}
				component={Link}
				to={`/places/view/${placeID}`}
				direction="row"
			>
				<Visibility />
				<Typography>Voir</Typography>
			</Button>
			<Sheet sx={{ background: "none" }}>
				<EditPlaceForm />
			</Sheet>
		</>
	);
}

export default PlaceEdit;
