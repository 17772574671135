import React from "react";
import {
	Modal,
	ModalDialog,
	ModalOverflow,
	Box,
	Typography,
	Button,
	Stack,
	FormLabel,
	Divider,
	Select,
	Alert,
	Option,
} from "@mui/joy";
import { useTranslation } from "react-i18next";
import folderList from "../folderList";
import { useForm, Controller } from "react-hook-form";
import useAuthContainer from "stores/Auth";

const FolderSelector = ({
	selectedFolder,
	setSelectFolder,
	selectedPlace,
	folderSearch,
	placeSearch,
	setSelectedPlace,
	places,
	open,
	close,
	file,
	upload,
}) => {
	const { userAccount } = useAuthContainer.useContainer();
	const { t } = useTranslation();
	const folders = file?.type.startsWith("image")
		? folderList().images
		: folderList().documents;
	const {
		handleSubmit,
		control,
		formState: { errors },
		setValue,
	} = useForm();

	const save = (data) => {
		setSelectFolder(data.folderChoice);
		setSelectedPlace(data.selectedPlace);
		upload(data.folderChoice, data?.placeChoice || null);
		close();
	};

	React.useEffect(() => {
		setValue("placeChoice", placeSearch);
		setValue("folderChoice", folderSearch);
	}, [folderSearch, placeSearch]);

	return (
		<Modal open={open} onClose={close}>
			<ModalOverflow>
				<ModalDialog>
					<Typography>
						{file?.type.startsWith("image")
							? t("choose_folder_for_this_image")
							: t("choose_folder_for_this_document")}
					</Typography>
					<form onSubmit={handleSubmit(save)}>
						<FormLabel>{t("folder")}</FormLabel>
						<Controller
							control={control}
							name="folderChoice"
							defaultValue={folderSearch}
							render={({ field: { onChange, ...props } }) => (
								<Select
									{...props} // Utilisez spread pour passer les props du Controller au Select
									onChange={(e, v) => onChange(v)}
								>
									{folders.map((folder) => (
										<Option key={folder} value={folder}>
											{t(folder)}
										</Option>
									))}
								</Select>
							)}
						/>
						{errors.folderChoice && (
							<Alert severity="error">
								{errors.folderChoice.message}
							</Alert>
						)}
						<FormLabel sx={{ mt: 1 }}>{t("place")}</FormLabel>
						<Controller
							control={control}
							name="placeChoice"
							defaultValue={placeSearch}
							render={({ field: { onChange, ...props } }) => (
								<Select
									{...props} // Utilisez spread pour passer les props du Controller au Select
									onChange={(e, v) => onChange(v)}
								>
									{places &&
										places.map((place) => (
											<Option value={place?.id}>
												{place.data()?.nom}
											</Option>
										))}
								</Select>
							)}
						/>
						{errors.folderChoice && (
							<Alert severity="error">
								{errors.folderChoice.message}
							</Alert>
						)}
						<Button sx={{ mt: 2 }} fullWidth type="submit">
							{t("save")}
						</Button>
					</form>
				</ModalDialog>
			</ModalOverflow>
		</Modal>
	);
};

export default FolderSelector;
