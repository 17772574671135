import React from "react";
import { useTranslation } from "react-i18next";
import { Box, Typography, LinearProgress } from "@mui/joy";
import PropTypes from "prop-types";
import Activities from "models/activities";
import { NoDataYet } from "UI";
import { ActivitiesBuilder } from "./views";
import AuthContainer from "stores/Auth";

const ActivitiesBack = ({ eventID }) => {
	const { t } = useTranslation();
	const { user } = AuthContainer.useContainer();
	const [datas, setDatas] = React.useState(null);
	const [creating, setCreating] = React.useState(false);
	const [fetchAttempt, setFetchAttempt] = React.useState(false);
	const activitiesEntity = new Activities({
		content: [],
		created_at: new Date(),
		uid: user.uid,
		event_id: eventID,
		status: "active",
	});

	const fetchActivities = async () => {
		try {
			const activities = await activitiesEntity.getActivitiesByEventId(
				eventID,
			);
			setFetchAttempt(true);
			if (activities) {
				setDatas(activities);
			}
		} catch (e) {
			console.log(e.message);
		}
	};

	const addActivities = async () => {
		setCreating(true);
		try {
			const d = await activitiesEntity.addActivities();
			setDatas(d);
		} catch (e) {
			console.log(e.message);
		}
		setCreating(false);
	};

	React.useEffect(() => {
		fetchActivities();
	}, []);

	const NoData = (
		<NoDataYet
			action={addActivities}
			loading={creating}
			terms={{
				subtitle: "activities",
				title: "add_activities_to_your_event",
				button: "start_to_add_activities",
			}}
		/>
	);

	return datas ? (
		<ActivitiesBuilder datas={datas} />
	) : !datas && !fetchAttempt ? (
		<LinearProgress />
	) : (
		NoData
	);
};

export default ActivitiesBack;
ActivitiesBack.propTypes = {};
ActivitiesBack.defaultProps = {};
