import React from "react";
import { Builder } from "../../../../components/UI";
import { Typography, Box, Divider } from "@mui/joy";
import { useTranslation } from "react-i18next";

const Experiences = ({ experiences, setExperiences }) => {
	const { t } = useTranslation();
	return (
		<Box>
			<Typography
				sx={{
					mb: 2,
				}}
				level="h4"
			>
				{t("Experiences")}
			</Typography>

			<Builder
				content={experiences}
				action={(d) => setExperiences(d)}
				editorConfig={{
					hideSubcat: true,

					terms: {
						category: "Poste",
						period: "Période",
						subCategory: "Sous-catégorie plannign",
						element: "Tâches",
						add_main_category: " Ajouter un nouveau poste",
						content: "Listes des tâches",
						informations: "Détails",
						add_sub_cat: "Sous cat créer",
						compagny_name: "Nom de la boite",
						element_in_this_cat: "",
						sure_you_want_delete_this_element:
							"Tu veux supprimer ça ?!  Ah OUAI  ?  t'es ouuuuuuf ou quoi !  ",
						add_element: "Ajouter une tâche",
					},
					modalSize: "100%",
					limitCatDepth: true,
					mainCategory: {
						cardFields: ["title"],
						editor: {
							hasChildren: true,
							fields: [
								{
									label: "title",
									type: "text",
									rules: {
										required: true,
									},
								},
								{
									label: "description",
									type: "textarea",
									rules: {
										required: false,
									},
								},
								{
									label: "compagny_name",
									type: "text",
									rules: {
										required: true,
									},
								},
								{
									label: "period",
									type: "date-range",
									rules: {
										required: true,
									},
								},
							],
						},
					},
					element: {
						cardFields: ["title"],
						editor: {
							hasChildren: false,
							fields: [
								{
									label: "title",
									type: "text",
									rules: {
										required: true,
									},
								},

								{
									label: "description",
									type: "textarea",
									rules: {
										required: false,
									},
								},
							],
						},
					},
					subCategory: {
						cardFields: ["title"],
						editor: {
							hasChildren: false,
							fields: [
								{
									label: "title",
									type: "text",
									rules: {
										required: true,
									},
								},
								{
									label: "description",
									type: "textarea",
									rules: {
										required: false,
									},
								},
							],
						},
					},
				}}
			/>
			<Divider sx={{ m: 3 }} />
		</Box>
	);
};

export default Experiences;
