const contentful = require("contentful");

class ContentfulClass {
	constructor() {
		this.client = contentful.createClient({
			space: "dyb7l1ulpnw5",
			accessToken: process.env.REACT_APP_contentful_access_token,
		});
	}
	async getEntry(entryId) {
		const entry = await this.client.getEntry(entryId);

		return entry;
	}
	async getEntries(entryId) {
		const entry = await this.client.getEntries(entryId);

		return entry;
	}
	async getAssets(entryId) {
		const entry = await this.client.getAssets(entryId);

		return entry;
	}
}

export default ContentfulClass;
