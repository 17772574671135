import React from "react";
import {
	Switch,
	Box,
	Typography,
	Stack,
	Input,
	Grid,
	Select,
	Button,
	IconButton,
	Option,
} from "@mui/joy";
import { EditableDateRangeField, AdvancedDateRange, FormSeparator } from "UI";
import {
	NightsStay,
	WbSunny,
	CalendarMonth,
	Save,
	Edit,
} from "@mui/icons-material";

import { useTranslation } from "react-i18next";
import { Controller } from "react-hook-form";
import Helpers from "utils/Helpers";

const months = [
	"January",
	"February",
	"March",
	"April",
	"May",
	"June",
	"July",
	"August",
	"September",
	"October",
	"November",
	"December",
];

const EventDatePartner = ({ register, control, setValue, getValues }) => {
	const { t } = useTranslation();
	return (
		<>
			<FormSeparator title="event_date_form_create">
				<Stack spacing={2}>
					<Stack direction="row" spacing={1} alignItems="center">
						<CalendarMonth sx={{ color: "action.active" }} />
						<Controller
							name="event_periods"
							control={control}
							render={({ field: { onChange, value } }) => (
								<Select
									sx={{ width: "205px" }}
									placeholder={t("select_a_period")}
									multiple
									defaultValue={value}
									onChange={(e, val) => {
										onChange(val);
									}}
									sx={{ minWidth: 120 }}
								>
									{months.map((month, index) => (
										<Option key={index} value={month}>
											{t(month)}
										</Option>
									))}
								</Select>
							)}
						/>
					</Stack>
					<Stack direction="row" spacing={1} alignItems="center">
						<WbSunny sx={{ color: "action.active" }} />
						<Input
							type="number"
							{...register("days_duration")}
							placeholder={t("how_many_days")}
						/>
					</Stack>
					<Stack direction="row" spacing={1} alignItems="center">
						<NightsStay sx={{ color: "action.active" }} />
						<Input
							type="number"
							{...register("nights_duration")}
							placeholder={t("how_many_nights")}
						/>
					</Stack>
				</Stack>
			</FormSeparator>
		</>
	);
};

export default EventDatePartner;
