import React from "react";
import { Box, Typography } from "@mui/joy";
import useProgressiveImage from "../../../../../../../utils/hooks/useProgressiveImage";
import { IKImage } from "imagekitio-react";

const SubCategory = ({ id, title, description, image, children, style }) => {
	const dark = style?.theme_mode === "dark";
	return (
		<Box
			id={id}
			sx={{
				mb: 2,
				mt: 2,
				background: dark
					? "#222"
					: style?.no_theme_color_background
					? `${style?.theme_color}12`
					: "white",
				ml: 2,
				mr: 2,
				pt: "1px",
				pb: "10px",
				borderRadius: "10px",
			}}
		>
			<Box
				sx={{
					height: image && "100px",
					width: "96%",
					borderRadius: "10px",
					m: "15px auto",
					position: "relative",
					background: `url(${
						image?.thumb || image?.full || image
					}) center center / cover `,
				}}
			>
				<Box
					sx={{
						borderRadius: "10px",
						zIndex: 1,
						position: "absolute",
						top: "0",
						left: "0",
						background: image
							? "rgba(0,0,0,50%)"
							: style?.theme_mode === "dark"
							? "transparent"
							: "transparent",
						height: "100%",
						width: "100%",
					}}
				></Box>
				<Box
					sx={{
						position: "relative",
						zIndex: 2,
						height: "100%",
						display: "flex",
						flexDirection: "column",
						alignItems: "flex-start",
						justifyContent: "flex-end",
						p: "12px",
						".MuiTypography-root": {
							color: image
								? "white"
								: style?.theme_mode === "dark"
								? "white"
								: "initial",
							textAlign: "center",
						},
					}}
				>
					<Typography sx={{ fontSize: "18px" }}>{title}</Typography>
					<Typography>{description}</Typography>
				</Box>
			</Box>

			{children}
		</Box>
	);
};

export default SubCategory;
