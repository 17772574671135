import React from "react";
import { useTranslation } from "react-i18next";
import { Box, Typography, Stack, Button } from "@mui/joy";
import PropTypes from "prop-types";
import { ClearRefinements, SearchBox } from "react-instantsearch";

const HeaderResults = ({ map_visible, setShowMap }) => {
	const { t } = useTranslation();

	return (
		<>
			<Stack spacing={2}>
				<ClearRefinements
					translations={{
						resetButtonText: "Effacer les fitres",
					}}
				/>
				<SearchBox />
				<Button onClick={() => setShowMap(!map_visible)}>
					{" "}
					{map_visible ? t("hide_map") : t("show_map")}
				</Button>
			</Stack>
		</>
	);
};

export default HeaderResults;
HeaderResults.propTypes = {
	map_visible: PropTypes.bool,
	setShowMap: PropTypes.func,
};
HeaderResults.defaultProps = {};
