import React from "react";
import { createContainer } from "unstated-next";

const usePlacesStore = createContainer((props) => {
	return {};
});

export default usePlacesStore;

export const PlacesStoreContainer = (Component) => (props) => {
	return (
		<usePlacesStore.Provider>
			<Component {...props} />
		</usePlacesStore.Provider>
	);
};
