import React from "react";
import { useTranslation } from "react-i18next";
import {
	Box,
	Typography,
	Select,
	Option,
	FormControl,
	FormLabel,
} from "@mui/joy";
import PropTypes from "prop-types";
import { Controller } from "react-hook-form";

const DateFilter = ({ setValue, control, save }) => {
	const { t } = useTranslation();
	const options = [
		{
			id: "desc",
			label: "desc",
		},
		{
			id: "asc",
			label: "asc",
		},
	];

	return (
		<FormControl>
			<FormLabel>{t("date_filter_select")}</FormLabel>
			<Controller
				name="date_selector"
				control={control}
				render={({ field: { onChange, ...props } }) => (
					<Select
						onChange={(e, v) => {
							onChange(v);
							save();
						}}
						{...props}
					>
						<Option value="all">{t("all_dates")}</Option>
						{options.map((option) => (
							<Option key={option.id} value={option.id}>
								{t(option.label)}
							</Option>
						))}
					</Select>
				)}
			/>
		</FormControl>
	);
};

export default DateFilter;
DateFilter.propTypes = {};
DateFilter.defaultProps = {};
