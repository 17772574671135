import React from "react";
import { useTranslation } from "react-i18next";
import { Box, Typography, Button } from "@mui/joy";
import { AccountApproval } from "./partials";
import { WhiteContainer } from "UI";
import { Link } from "react-router-dom";

const BecomePartner = (props) => {
	const { t } = useTranslation();

	return (
		<>
			<WhiteContainer>
				<Button component={Link} to="/account">
					{" "}
					{t("back_to_my_account")}
				</Button>
				<AccountApproval />
			</WhiteContainer>
		</>
	);
};

export default BecomePartner;
