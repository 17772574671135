import React from "react";
import { useTranslation } from "react-i18next";
import { Box, Typography, FormControl, FormLabel, Input } from "@mui/joy";
import PropTypes from "prop-types";

const GroupName = ({ register }) => {
	const { t } = useTranslation();

	return (
		<FormControl>
			<FormLabel>{t("group_name")}</FormLabel>
			<Input {...register("group_name")} />
		</FormControl>
	);
};

export default GroupName;
GroupName.propTypes = {};
GroupName.defaultProps = {};
