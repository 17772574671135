import moment from "moment";
import _, { isArray, isObject } from "underscore";
import { v4 as uuidv4 } from "uuid";
import cloneDeep from "lodash/cloneDeep";

var traverse = require("traverse");

class Helpers {
	static findDepthById(data, targetId) {
		let depthFound = null;

		traverse(data).forEach(function (node) {
			if (this?.node?.id === targetId) {
				depthFound = this.level;
			}
		});

		return Math.ceil(depthFound / 2);
	}

	static checkIsArray = (data) => (Array.isArray(data) ? data : []);

	static objectToQueryString = (obj) => {
		if (!obj || Object.keys(obj).length === 0) {
			return "";
		}
		return Object.keys(obj)
			.map(
				(key) =>
					`${encodeURIComponent(key)}=${encodeURIComponent(
						obj[key],
					)}`,
			)
			.join("&");
	};

	static addObjectToUrl = (url, obj) => {
		const queryString = Helpers.objectToQueryString(obj);
		if (!queryString) {
			return url;
		}
		const additionnalDatas = `additionnalDatas=${encodeURIComponent(
			`&${queryString}`,
		)}`;
		// Vérifier si l'URL a déjà des paramètres
		const separator = url.includes("?") ? "&" : "?";
		return `${url}${separator}${additionnalDatas}`;
	};
	static getConfig(content, id, config) {
		const getEl = () => Helpers.deepArraySearch(content, id);
		const depth = parseInt(Helpers.findDepthById(content, getEl()?.id));
		const formatedDepth = `depth_${depth}`;
		if (id) {
			let conf = config.itemsConfig[formatedDepth]
				? config.itemsConfig[formatedDepth]
				: config.itemsConfig.default;

			if (conf?.ifNochilds && getEl()?.children.length < 1) {
				conf = config.itemsConfig[conf.ifNochilds];
			}

			return {
				conf,
				depth,
				formatedDepth,
			};
		}
	}

	static onDrop(info, _datas, limitDepth) {
		const dropKey = info.node.key;
		const dragKey = info.dragNode.key;
		const dropPos = info.node.pos.split("-");
		const unchanged = cloneDeep(_datas);

		const dropPosition =
			info.dropPosition - Number(dropPos[dropPos.length - 1]); // the drop position relative to the drop node, inside 0, top -1, bottom 1

		const loop = (data, key, callback) => {
			for (let i = 0; i < data.length; i++) {
				if (data[i].key === key) {
					return callback(data[i], i, data);
				}
				if (data[i].children) {
					loop(data[i].children, key, callback);
				}
			}
		};

		const data = [..._datas];

		// Find dragObject
		let dragObj;
		loop(data, dragKey, (item, index, arr) => {
			arr.splice(index, 1);
			dragObj = item;
		});

		if (!info.dropToGap) {
			// Drop on the content
			loop(data, dropKey, (item) => {
				item.children = item.children || [];
				// where to insert. New item was inserted to the start of the array in this example, but can be anywhere
				item.children.unshift(dragObj);
			});
		} else {
			let ar = [];
			let i;
			loop(data, dropKey, (_item, index, arr) => {
				ar = arr;
				i = index;
			});
			if (dropPosition === -1) {
				// Drop on the top of the drop node
				ar.splice(i, 0, dragObj);
			} else {
				// Drop on the bottom of the drop node
				ar.splice(i + 1, 0, dragObj);
			}
		}

		let returned = data;
		const depth = Helpers.findDepthById(data, dragKey);
		if (depth < limitDepth + 1) {
			returned = data;
		} else {
			returned = unchanged;
		}

		return returned;
	}

	static addKeys(data) {
		return data.map((item) => ({
			...item,
			key: item.id,
			children: item.children ? Helpers.addKeys(item.children) : [],
		}));
	}
	static getExpandedKeys(data, keys = []) {
		data.forEach((item) => {
			keys.push(item.id); // Utilisez 'item.key' si vous avez déjà ajouté des clés uniques
			if (item.children && item.children.length > 0) {
				Helpers.getExpandedKeys(item.children, keys);
			}
		});
		return keys;
	}

	//Change roles fron roles object

	static getFileTypeFromURL(url) {
		const segments = url.split("?");
		const extension = segments[segments.length - 1];
		return extension.toLowerCase(); // Convert to lowercase for consistency
	}
	static changeRoles({ userRoles, actualRoles, uid }) {
		let availableRolesObject = {
			update: [],
			delete: [],
			read: [],
		};

		Object.keys(availableRolesObject).map((role) => {
			let tmp_roles = actualRoles[role] || [];
			if (userRoles[role] === true) {
				availableRolesObject[role] = tmp_roles.find((r) => r === uid)
					? tmp_roles
					: [...tmp_roles, uid];
			} else {
				availableRolesObject[role] = tmp_roles.filter((r) => r !== uid);
			}
		});

		return availableRolesObject;
	}

	static uuid() {
		return uuidv4();
	}

	// static get uri parts

	static uriParts(uri, part) {
		const s = uri.split("/");
		s.shift();
		return s[part];
	}

	//  apply drag

	static applyDrag(dragResult, datas) {
		const { removedIndex, addedIndex, payload } = dragResult;
		if (removedIndex === null && addedIndex === null) return datas;

		const result = [...datas];
		let itemToAdd = payload;

		if (removedIndex !== null) {
			itemToAdd = result.splice(removedIndex, 1)[0];
		}

		if (addedIndex !== null) {
			result.splice(addedIndex, 0, itemToAdd);
		}

		return result;
	}

	static isElementVisible(visibility, visibility_date) {
		if (!visibility) {
			return true;
		}

		const date = new Date(visibility_date);
		if (isNaN(date.getTime())) {
			return false;
		}

		const today = new Date().toISOString().split("T")[0];

		const formattedVisibilityDate = date.toISOString().split("T")[0];
		if (formattedVisibilityDate !== today) {
			return false;
		}

		return true;
	}

	// check if closed or open based on day/hours array

	static isClosed(hours) {
		let closed = true;
		const currentMomentDate = moment();
		const currentMomentHour = moment(new Date(), "hh:mm");
		const currentDay = currentMomentDate.format("dddd").toLowerCase();

		if (!hours || !hours.find((d) => d.id === currentDay)) closed = true;

		if (hours) {
			hours.forEach((d) => {
				if (currentDay === d.id) {
					d.hours.map((h) => {
						const start = moment(h[0], "hh:mm");
						const end = moment(h[1], "hh:mm");

						const isBetween = currentMomentHour.isBetween(
							start,
							end,
						);

						if (isBetween) {
							closed = false;
						}
					});
				}
			});
		}

		return closed;
	}
	// Object to array of object labels and values with translation
	static objectToArray(d, t = null) {
		return d
			? Object.values(d).map((v) => ({
					label: t ? t(v) : v,
					value: v,
			  }))
			: [];
	}

	// check if no visibility
	static noVisibilityDefined(hours) {
		let noVisibility = true;

		if (hours) {
			hours.forEach((d) => {
				if (d.hours && d.hours.length > 0) {
					noVisibility = false;
				}
			});
		}

		return noVisibility;
	}

	// clean undefined

	static cleanObject(obj) {
		traverse(obj).forEach(function (value) {
			if (value === undefined) {
				this.update(null);
			}
		});
		return obj;
	}

	// seach if element exist in an array and retrieve it

	static deepArraySearch(array, id) {
		try {
			let _array = [...array];
			let element = null;
			if (id !== null) {
				traverse(_array).forEach(function (item) {
					if (this?.node?.id === id) {
						if (typeof this?.node === "object") {
							element = this.node;
						}
					}
				});
			}
			return element;
		} catch (e) {
			console.log(e);
			return null;
		}
	}

	// delete element in deep array
	static deepArrayDelete(array, id) {
		try {
			let _array = [...array];
			traverse(_array).forEach(function (item) {
				if (this?.node?.id === id) {
					this.remove();
				}
			});
			return _array;
		} catch (e) {
			console.log(e);
			return false;
		}
	}
	// update element in deep array
	static deepArrayUpdate(array, datas, id) {
		try {
			let _array = [...array];
			if (id !== null && this.deepArraySearch(_array, id) !== null) {
				traverse(_array).forEach(function (item) {
					if (this?.node?.id === id) {
						if (typeof this?.node === "object") {
							this.update({ ...this.node, ...datas });
						}
					}
				});
			} else {
				_array.push(datas);
			}
			return _array;
		} catch (e) {
			console.log(e);
			return false;
		}
	}

	// check if closed or open based on vacation array

	static isInVacation(vacations) {
		let closed = false;
		let period = null;
		let until = null;
		const currentMomentDate = moment();

		if (vacations) {
			vacations.forEach((d) => {
				const start = moment(d.start.toDate());
				const end = moment(d.end.toDate());
				const isBetween = currentMomentDate.isBetween(start, end);
				if (isBetween) {
					closed = true;
					period = d.title;
					until = end.format("DD/MM/YYYY");
				}
			});
		}

		return { closed, period, until };
	}

	//formatLangObject

	static multilangFormatObject(obj, lang) {
		let data = "";
		if (Array.isArray(obj)) {
			const ml_field = obj.find((item) => item.lang === lang);
			data = ml_field?.content || "";
		} else if (typeof obj === "string") {
			data = obj;
		}

		return data;
	}

	// prepare multilang fields
	static prepareMultilang({ fields, lang }) {
		if (fields) {
			let formatedDatas = {};
			Object.keys(fields).map((key, i) => {
				if (key.match(/_ml$/gm)) {
					formatedDatas[key] = [
						{
							content: fields[key],
							lang: lang,
						},
					];
				} else {
					formatedDatas[key] = fields[key];
				}
			});

			return formatedDatas;
		}
	}

	// Multilang get lang from doc
	static multilangFormat({ item, lang }) {
		if (item) {
			let formatedDatas = {};
			Object.keys(item).map((key, i) => {
				if (key.match(/_ml$/gm)) {
					if (Array.isArray(item[key])) {
						const ml_field = item[key].find(
							(item) => item.lang === lang,
						);
						formatedDatas[key] = ml_field?.content || "";
					} else if (typeof item[key] === "string") {
						formatedDatas[key] = item[key];
					}
				} else {
					formatedDatas[key] = item[key];
				}
			});

			return formatedDatas;
		}
	}

	// Format multilang recursive
	static multilangFormatRecursive(datas, lang) {
		let formatedDatas = datas;
		traverse(formatedDatas).forEach(function (elmt) {
			if (this.key && this.key.match(/_ml$/gm)) {
				this.update(Helpers.multilangFormatObject(elmt, lang));
			}
		});

		return formatedDatas;
	}

	static flattenHierarchy(arr) {
		const result = [];

		function processItem(item) {
			// Ajouter l'élément courant au tableau résultat
			result.push({
				...item,
				childrenCount: item.children ? item.children.length : 0,
			});

			// Si l'élément a des enfants, les traiter récursivement
			if (item.children && item.children.length > 0) {
				item.children.forEach((child) => processItem(child));
			}
		}

		// Traiter chaque élément de l'array principal
		arr.forEach((item) => processItem(item));

		return result;
	}

	// Update Multilang format for saving in db
	static updateMultilangItem({ oldDatas, datas, currentLang }) {
		let newItem = {};
		Object.keys(datas).map((key) => {
			if (key.match(/_ml$/gm)) {
				if (oldDatas[key] && Array.isArray(oldDatas[key])) {
					const n = oldDatas[key].map((data, i) => {
						if (data.lang === currentLang) {
							return { ...data, content: datas[key] };
						} else {
							return data;
						}
					});
					newItem[key] = n;
				}
				if (oldDatas[key] === undefined) {
					if (datas[key] !== undefined) {
						newItem[key] = [
							{
								lang: currentLang,
								content: datas[key],
							},
						];
					}
				} else {
					if (!oldDatas[key].find((l) => l.lang === currentLang)) {
						newItem[key] = [
							...oldDatas[key],
							{
								lang: currentLang,
								content: datas[key],
							},
						];
					}
				}
			} else {
				newItem[key] = datas[key];
			}
		});

		return newItem;
	}
	// add Multilang format for saving in db
	static addMultilangItem({ datas, currentLang }) {
		let newDatas = {};
		Object.keys(datas).map((key) => {
			newDatas[key] = [
				{
					lang: currentLang,
					content: datas[key],
				},
			];
		});
		return newDatas;
	}

	// format firebase date
	static fbDate(date, format = null, returned = null) {
		let formatedDate;
		if (date?.toDate && typeof date?.toDate === "function") {
			if (returned === "moment") {
				formatedDate = moment(date.toDate());
			} else {
				formatedDate = moment(date.toDate()).format(format);
			}
		} else {
			formatedDate = moment(date).format(format);
		}

		return formatedDate;
	}

	// Check facebook permissions
	static checkFBPermissions(permissionsNeeded, permissions) {
		const perms = permissions.reduce((acc, p) => {
			if (
				permissionsNeeded.includes(p.permission) &&
				p.status === "granted"
			) {
				acc.push(p);
			}
			return acc;
		}, []);

		return perms.length === permissionsNeeded.length;
	}

	// get an array with year from specific date to current year
	static getYearRange(startYear) {
		const arr = [];
		const y = new Date().getFullYear();
		for (var i = startYear; i < y + 1; i++) {
			arr.push(i);
		}
		return arr.reverse();
	}

	// Calculate cart price

	static calculateCartPrice = (content) => {
		const totalPrice = traverse(content).reduce(function (acc, elmt) {
			if (this.key === "selected_price") {
				const fPrice = parseFloat(this.node.price);
				if (!isNaN(fPrice)) {
					acc = acc + fPrice;
				}
			}

			if (this.key === "selected_option") {
				const p = traverse(this.node).reduce(function (a, b) {
					const fPrice = parseFloat(this.node.price_sup);
					if (!isNaN(fPrice)) {
						a = a + fPrice;
					}
					return a;
				}, 0);

				acc = acc + p;
			}

			return acc;
		}, 0);

		return totalPrice;
	};

	// clean undefined

	static cleanObject(obj) {
		traverse(obj).forEach(function (value) {
			if (value === undefined) {
				this.update(null);
			}
		});
		return obj;
	}
	static cleanUndefined(obj) {
		return Helpers.cleanObject(obj);
	}

	//populate form

	static populateForm(datas, func) {
		Object.keys(datas).map((k) => {
			func(k, datas[k]);
		});
	}

	// format string to date object if exists

	static formatToDate(date) {
		if (date && date !== "" && typeof date === "string") {
			return new Date(date);
		} else if (date?.toDate && typeof date?.toDate === "function") {
			return date.toDate();
		} else {
			return null;
		}
	}

	// check file

	static checkFiles(file, maxSize, qty) {
		let err = false;

		if (file) {
			Array.from(file).map((f) => {
				if (f.size / 100000 > maxSize) {
					err = { status: "error", message: "some_files_larger" };
				}
			});

			if (file.length > qty) {
				err = { status: "error", message: "to_many_files" };
			}
		} else {
			err = {
				status: "error",
				message: "no_files",
			};
		}
		return err;
	}

	// calculate product options price and get options

	static calculateProductOptions(options) {
		console.log(options);
		const price = traverse(options).reduce(function (acc, x) {
			if (this?.node?.selected_option) {
				const selected_option = this.node.selected_option;
				if (_.isArray(selected_option)) {
					const p = selected_option.reduce(
						(accl, curr) => accl + parseFloat(curr.price_sup),
						0,
					);

					acc = acc + p;
				} else {
					try {
						if (!isNaN(selected_option?.price_sup)) {
							acc = acc + parseFloat(selected_option?.price_sup);
						}
					} catch (e) {}
				}
			}
			return acc;
		}, 0);

		return price;
	}
}

export default Helpers;
