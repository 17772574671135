import React from "react";
import { FormSeparator } from "UI";
import { Textarea, Typography, FormControl, FormHelperText } from "@mui/joy";
import { Group } from "@mui/icons-material";
import { useTranslation } from "react-i18next";

const EventDescription = ({ register }) => {
	const { t } = useTranslation();
	return (
		<FormSeparator title="event_description">
			<FormControl>
				<Textarea
					type="number"
					placeholder={t("event_description")}
					{...register("event_description")}
				/>
			</FormControl>
		</FormSeparator>
	);
};

export default EventDescription;
