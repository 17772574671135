import React from "react";
import { Box, Input, Button } from "@mui/joy";
import { useTranslation } from "react-i18next";
import { useForm } from "react-hook-form";
import { InputText } from "../../../../../../components/UI";
import useEmailAuth from "../../../../hooks/useEmailAuth";

const LoginForm = () => {
	const { register, handleSubmit, control } = useForm();
	const { t } = useTranslation();
	const { signIn, signingIn, errors } = useEmailAuth();
	const submitForm = (datas) => {
		const { email, password } = datas;

		if (email && password) {
			signIn(email, password);
		}
	};

	return (
		<>
			<Box sx={{ m: "0 auto" }}>
				<form onSubmit={handleSubmit(submitForm)}>
					<InputText
						control={control}
						placeholder="email"
						name="email"
						rules={{ required: true }}
						style={{ mb: 1 }}
						type="text"
					/>
					<InputText
						control={control}
						placeholder="password"
						name="password"
						rules={{ required: true }}
						style={{ mb: 1 }}
						type="password"
					/>

					<Button
						disabled={signingIn}
						loading={signingIn}
						fullWidth
						type="submit"
					>
						{t("connection")}
					</Button>
				</form>
			</Box>
		</>
	);
};

export default LoginForm;
