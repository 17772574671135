import React from "react";
import { useTranslation } from "react-i18next";
import { Box, Typography, List, ListItem, Button, Chip } from "@mui/joy";
import PropTypes from "prop-types";
import { Add, OpenInNew } from "@mui/icons-material";

const View = ({ documents, select }) => {
	const { t } = useTranslation();

	return (
		<>
			<List>
				{documents &&
					documents.map((doc) => (
						<ListItem
							sx={{
								p: 1,
								m: 1,
								cursor: "pointer",
								border: "1px solid #eee",
								"&:hover": {
									background: "#eee",
								},
							}}
							onClick={() => select(doc)}
						>
							<Typography
								sx={{
									textOverflow: "ellipsis",
									overflow: "hidden",
									whiteSpace: "nowrap",
								}}
							>
								{doc?.name}
							</Typography>
							<Box
								sx={{
									overflow: "auto",
									whiteSpace: "nowrap",
								}}
							>
								{doc?.tags?.length > 0 &&
									doc?.tags.map((tag) => <Chip>#{tag}</Chip>)}
							</Box>

							<Box
								sx={{
									position: "absolute",
									top: "2",
									right: "14px",
								}}
							>
								{doc?.fileUrl && (
									<a target="_blank" href={`${doc.fileUrl}`}>
										<OpenInNew />
									</a>
								)}
							</Box>
						</ListItem>
					))}
			</List>
			<Button onClick={() => select(null)} fullWidth>
				<Add /> {t("add_document")}
			</Button>
		</>
	);
};

export default View;
View.propTypes = {};
View.defaultProps = {};
