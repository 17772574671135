import React from "react";
import { Button, Box, Typography } from "@mui/joy";
import { AddPhotoAlternate } from "@mui/icons-material";
import { AddSimpleImage } from "../../../../../../components/UI";

const TopBannerImage = ({ t, remove, update, usefull_infos_image }) => {
	const [image, setImage] = React.useState(usefull_infos_image);

	return (
		<AddSimpleImage
			style={{
				maxWidth: "500px",
				width: "100%",
				height: "200px",
			}}
			icon={<AddPhotoAlternate />}
			remove={() => {
				update(null);
				setImage(null);
			}}
			image={image?.full || image}
			action={(img) => {
				update(img);
				setImage(img);
			}}
		/>
	);
};

export default TopBannerImage;
