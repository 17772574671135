import FirestoreService from "services/firestoreServices";

/**
 * Creates an instance of the Quotes class.
 * @constructor
 * @param {Object} params - The constructor parameters.
 */
class Quotes {
    constructor(params) {
        Object.assign(this, params);
        this.modified_at = new Date();
        this.firestoreService = new FirestoreService("quotes");
    }

    async addQuote() {
        try {
            const quote = await this.firestoreService.addDocument(
                this.toJSON(),
            );
            return { id: quote.id, ...this.toJSON() };
        } catch (error) {
            console.error("Failed to add quote:", error);
            throw error; // Propagation de l'erreur
        }
    }

    toJSON() {
        const data = {};
        const properties = {
            email: this.email,
            uid: this.uid,
            message: this.message,
            place_id: this.place_id,
            created_at: this.created_at,
            modified_at: this.modified_at,
        };

        // Validate required properties
        if (!this.email) {
            throw new Error("Email is required");
        }
        if (!this.uid) {
            throw new Error("UID is required");
        }

        // Add only properties that are not undefined
        for (let key in properties) {
            if (properties[key] !== undefined) {
                data[key] = properties[key];
            }
        }

        return data;
    }
}

export default Quotes;
