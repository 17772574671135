import React from "react";
import { useTranslation } from "react-i18next";
import { Box, Typography, Input, List, ListItem } from "@mui/joy";
import PropTypes from "prop-types";

const arr = ["name", "indice_marge", "indice_retrocom"];

const View = ({ datas }) => {
	const { t } = useTranslation();

	return (
		<List>
			{arr.map((v) => (
				<ListItem>
					<Typography>
						<b>{t(v)}</b> : {datas?.[v]}
					</Typography>
				</ListItem>
			))}
		</List>
	);
};

export default View;
View.propTypes = {};
View.defaultProps = {};
