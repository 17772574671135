import React from "react";
import { useTranslation } from "react-i18next";
import { Box, Typography, Button, IconButton } from "@mui/joy";
import { Search } from "@mui/icons-material";
import PropTypes from "prop-types";
import { WhiteContainer } from "UI";
import { useForm } from "react-hook-form";
import { RoleSelector, DateFilter, EmailSearch } from "./partials";
import { where, orderBy } from "firebase/firestore";

const Filters = ({ filters, setFilters }) => {
	const { t } = useTranslation();
	const { register, handleSubmit, setValue, control, reset } = useForm({
		defaultValues: {
			role_selector: "all",
			date_selector: "all",
			email_search: "",
		},
	});

	const save = (d) => {
		let constraints = [];
		if (d?.role_selector && d?.role_selector !== "all") {
			constraints = [
				...constraints,
				where("role", "==", d?.role_selector),
			];
		}

		if (d?.email_search && d?.email_search !== "") {
			const endAt = d.email_search + "\uf8ff";
			constraints = [
				...constraints,
				where("email", ">=", d.email_search),
				where("email", "<", endAt),
			];
		}

		if (d?.date_selector && d?.date_selector !== "all") {
			constraints = [
				...constraints,

				orderBy("email"),
				orderBy("created_at", d?.date_selector),
			];
		}

		setFilters(constraints);
	};

	return (
		<WhiteContainer title="filters">
			<Box
				sx={{
					display: "grid",
					gridTemplateColumns: "2fr 2fr 1fr",
					gap: 2,
				}}
			>
				<RoleSelector
					save={handleSubmit(save)}
					control={control}
					setValue={setValue}
				/>
				<DateFilter
					save={handleSubmit(save)}
					control={control}
					setValue={setValue}
				/>
				<EmailSearch
					save={handleSubmit(save)}
					control={control}
					register={register}
					setValue={setValue}
				/>
			</Box>
			{filters.length > 0 && (
				<Box sx={{ display: "flex", justifyContent: "center", pt: 3 }}>
					<Button
						onClick={() => {
							setFilters([]);
							reset();
						}}
					>
						{t("clear_filters")}
					</Button>
				</Box>
			)}
		</WhiteContainer>
	);
};

export default Filters;
Filters.propTypes = {};
Filters.defaultProps = {};
