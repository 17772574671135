import React from "react";
import useCVContainer from "../../../CVStore";
import { Stack, Grid, Box, Typography } from "@mui/joy";
import { useTranslation } from "react-i18next";

const Profil = (props) => {
	const { t } = useTranslation();
	const { profil, color } = useCVContainer.useContainer();

	return (
		<>
			<Typography
				sx={{
					textTransform: "uppercase",

					color: color,
				}}
				level="h1"
			>
				{profil?.profil_name}
			</Typography>
			<Typography level="h5">{profil?.profil_job}</Typography>
			<Typography
				sx={{ mt: 3, mb: 3, pl: 2, borderLeft: `2px solid ${color} ` }}
				level="body3"
			>
				{profil?.profil_description}
			</Typography>
		</>
	);
};

export default Profil;
