import React from "react";
import {
	Select,
	Input,
	Checkbox,
	Option,
	MenuItem,
	Grid,
	Button,
	FormControl,
	FormHelperText,
} from "@mui/joy";
import Datas from "datas/users/users_additionnal_datas.json";
import { useTranslation } from "react-i18next";
import { useForm, Controller } from "react-hook-form";
import useEmailAuth from "../../../../hooks/useEmailAuth";
import { db } from "../../../../../../utils/MyFirebase";
import { doc, setDoc } from "firebase/firestore";
import useAuthContainer from "../../../../../../stores/Auth";

const InformationsForm = (props) => {
	const {
		handleSubmit,
		register,
		watch,
		formState: { errors },
		control,
	} = useForm();
	const { user } = useAuthContainer.useContainer();
	const { signUp, signingUp } = useEmailAuth();
	const { password, confirm_password, profil_type, job } = watch();
	const passwordNotConfirm = password != confirm_password;
	const [saving, setSaving] = React.useState(false);
	const [isGroupMember, setIsGroupMember] = React.useState(false);

	const { t } = useTranslation();
	const companyRequiredProfiles = [
		"PartnerLocation",
		"PartnerCateringFB",
		"PartnerEntertainment",
		"PartnerTeamBuilding",
		"Company",
		"Agency",
	];

	const save = async (datas) => {
		setSaving(true);
		const ref = doc(db, "users", user.uid);
		await setDoc(
			ref,
			{ additionalDatas: datas, additionnalDatasAdded: true },
			{ merge: true },
		);
		setSaving(false);
	};

	const ignore = () => {
		save({});
	};
	const submit = (data) => {
		const additionalDatas = {
			...data,
			job: data?.job || "",
			profil_type: data?.profil_type || "",
		};

		save(additionalDatas);
	};

	return (
		<form onSubmit={handleSubmit(submit)}>
			<Grid
				spacing={1}
				sx={{ flexGrow: 1 }}
				justifyContent="space-between"
				container
			>
				<Grid xs={profil_type === "other_profil" ? 6 : 12}>
					<Controller
						name="profil_type"
						control={control}
						render={({ field: { onChange, value } }) => (
							<>
								<Select
									placeholder={t("profil_type")}
									sx={{ mb: 1 }}
									onChange={(e, v) => onChange(v)}
								>
									{Datas.profil.map((i) => (
										<Option value={i}>{t(i)}</Option>
									))}
									<Option value="other_profil">
										{t("other_profil")} :{" "}
									</Option>
								</Select>{" "}
							</>
						)}
					/>
				</Grid>
				{profil_type === "other_profil" && (
					<Grid xs={6}>
						<Input
							{...register("other_profil_type")}
							sx={{ mb: 1 }}
							type="text"
							placeholder={t("precise_profil_type")}
							name="other_profil_type"
						/>
					</Grid>
				)}
			</Grid>
			<Checkbox
				checked={isGroupMember}
				onChange={(e) => setIsGroupMember(e.target.checked)}
				label={t("belong_to_a_group")}
				sx={{ mb: 1 }}
			/>
			{companyRequiredProfiles.includes(profil_type) && (
				<Grid xs={12}>
					<Input
						{...register("company")}
						sx={{ mb: 1 }}
						type="text"
						placeholder={t("compagny_place")}
						name="company"
					/>
				</Grid>
			)}
			{isGroupMember && (
				<Input
					{...register("group_name")}
					sx={{ mb: 1 }}
					type="text"
					placeholder={t("group_name")}
					name="group_name"
				/>
			)}
			<Grid
				spacing={1}
				sx={{ flexGrow: 1, mb: 1 }}
				justifyContent="space-between"
				container
			>
				<Grid xs={6}>
					<Input
						placeholder={t("lastname")}
						{...register("lastname")}
					/>
				</Grid>

				<Grid xs={6}>
					<Input placeholder={t("name")} {...register("name")} />
				</Grid>
			</Grid>

			<Grid
				spacing={1}
				sx={{ flexGrow: 1 }}
				justifyContent="space-between"
				container
			>
				<Grid xs={job === "other_job" ? 6 : 12}>
					<Controller
						name="job"
						control={control}
						render={({ field: { onChange, value } }) => (
							<Select
								onChange={(e, v) => onChange(v)}
								sx={{ mb: 1 }}
								placeholder={t("job")}
							>
								{Datas.job.map((i) => (
									<Option value={i}>{t(i)}</Option>
								))}
								<Option value="other_job">
									{t("other_job")} :
								</Option>
							</Select>
						)}
					/>
				</Grid>
				{job === "other_job" && (
					<Grid xs={6}>
						<Input
							{...register("other_job_type")}
							sx={{ mb: 1 }}
							type="text"
							placeholder={t("precise_job")}
							name="other_job_type"
						/>
					</Grid>
				)}
			</Grid>

			<Input
				sx={{ mb: 3 }}
				placeholder={t("phone")}
				{...register("phone")}
			/>

			<Grid
				spacing={1}
				sx={{ flexGrow: 1 }}
				justifyContent="space-between"
				container
			>
				<Grid xs={6}>
					<Button
						disabled={saving}
						loading={saving}
						type="submit"
						fullWidth
					>
						{t("save")}
					</Button>
				</Grid>

				<Grid xs={6}>
					<Button
						variant="outlined"
						type="button"
						fullWidth
						color="neutral"
						loading={saving}
						disabled={saving}
						onClick={ignore}
					>
						{t("ignore_step")}
					</Button>
				</Grid>
			</Grid>
		</form>
	);
};

export default InformationsForm;
