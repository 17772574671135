import React from "react";
import { Box } from "@mui/joy";
import { Add } from "@mui/icons-material";
import { useTranslation } from "react-i18next";
import { AddSimpleImage } from "UI";

const SimpleImage = ({ update, label, defaultValue, limit }) => {
	const { t } = useTranslation();

	return (
		<Box sx={{ background: "white", p: 2, mt: 1, mb: 1 }}>
			<AddSimpleImage
				style={{
					width: "100%",
					height: "300px",
				}}
				icon={<Add />}
				remove={() => update({ [label]: null })}
				action={(img) => update({ [label]: img })}
				image={defaultValue}
			/>
		</Box>
	);
};

export default SimpleImage;
