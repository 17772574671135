import React from "react";
import { useTranslation } from "react-i18next";
import { Box, Typography } from "@mui/joy";
import { PlacesGrid, RemoveModal } from "./partials";
import { DeleteDocModal } from "UI";

const Content = ({ documents, loading, error }) => {
	const { t } = useTranslation();
	const [selected, setSelected] = React.useState(null);
	if (error) {
		console.log(error);
		return;
	}

	return loading ? null : (
		<>
			<Box>
				<PlacesGrid
					setSelected={setSelected}
					places={documents.map((d) => ({ ...d, id: d.id }))}
				/>
			</Box>
			<DeleteDocModal
				selected={selected}
				close={() => setSelected(null)}
				collection="places"
				text="sure_to_remove_this_places"
			/>
		</>
	);
};

export default Content;
