import React from "react";
import { useTranslation } from "react-i18next";
import { Box, Typography, Button, Alert, Stack } from "@mui/joy";
import useAuthContainer from "stores/Auth";
import { Link } from "react-router-dom";

const BecomePartnerInfo = (props) => {
	const { userAccount } = useAuthContainer.useContainer();
	const { t } = useTranslation();

	return (
		<>
			{userAccount?.isApproved ? (
				<Box>
					<Alert color="success">
						<Typography sx={{ textAlign: "center" }}>
							{t("you_are_an_approved_partner")}
						</Typography>
					</Alert>
				</Box>
			) : (
				<Box>
					<Stack spacing={2} alignItems="flex-start">
						<Typography>{t("not_approve_partner")}</Typography>
						<Button
							size="md"
							fullWidth={false}
							component={Link}
							to="/account/partner"
						>
							{t("become_partner")}
						</Button>
					</Stack>
				</Box>
			)}
		</>
	);
};

export default BecomePartnerInfo;
