import React from "react";
import { Card, Stack, Box, Typography, IconButton } from "@mui/joy";
import {
	DragIndicator,
	Edit,
	Delete,
	KeyboardArrowRight,
} from "@mui/icons-material";
import useBuilderContainer from "../../BuilderStore";

const MainCategoryItem = ({ id, selectedEl, ...props }) => {
	const {
		selected,
		setSelected,
		config,
		deleteElement,
		setConfirmDelete,
		setMainParent,
		setParent,
	} = useBuilderContainer.useContainer();
	return (
		<Card
			sx={(theme) => ({
				mb: 2,
				borderColor: selectedEl
					? theme.vars.palette.primary[400]
					: "transparent",
				borderWidth: "2px",
				borderStyle: "solid",
			})}
		>
			<Stack direction="row" justifyContent="space-between">
				<Stack direction="row" alignItems="center" spacing={2}>
					<DragIndicator
						className="category-drag-handle"
						sx={{
							cursor: "move",
						}}
					/>
					{config.mainCategory.cardFields.map((field) => (
						<Typography key={field}>{props[field]}</Typography>
					))}
				</Stack>

				<Stack direction="row" spacing={1}>
					<IconButton
						onClick={() => setConfirmDelete(id)}
						variant="outlined"
					>
						<Delete />
					</IconButton>
					<IconButton
						onClick={() => {
							setSelected(id);
							setParent(id);
							setMainParent(id);
						}}
						variant={selectedEl ? "solid" : "outlined"}
					>
						{selectedEl ? <KeyboardArrowRight /> : <Edit />}
					</IconButton>
				</Stack>
			</Stack>
		</Card>
	);
};

export default MainCategoryItem;
