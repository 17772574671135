import React from "react";
import useCVContainer from "../../CVStore";
import { Classy, Modern } from "../../themes";

const CVContainer = (props) => {
	const { theme } = useCVContainer.useContainer();

	switch (theme) {
		case "classy":
			return <Classy />;
			break;
		case "modern":
			return <Modern />;
			break;
		default:
			return <Classy />;
			break;
	}
};

export default CVContainer;
