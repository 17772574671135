import React from "react";
import { useTranslation } from "react-i18next";
import { Box, Typography, Grid } from "@mui/joy";
import {
	RefinementList,
	Configure,
	Stats,
	RangeInput,
	Pagination,
} from "react-instantsearch";

import "instantsearch.css/themes/satellite.css"; // ou autre thème au choix
import { FilterContainer, AlgoliaContainer } from "Components/algolia";
import { WhiteContainer } from "UI";
import { Filters, PlacesMap, HeaderResults, Results } from "./partials";

const PlacesList = (props) => {
	const { t } = useTranslation();
	const [showMap, setShowMap] = React.useState(false);
	const [selected, setSelect] = React.useState(null);

	return (
		<AlgoliaContainer index="places">
			<Configure hitsPerPage={30} />
			<Box
				sx={{
					mb: 2,
				}}
			></Box>

			<WhiteContainer
				style={{
					flexDirection: "column",
					display: "flex",
					height: "100%",
				}}
			>
				<Grid
					sx={{
						flex: 1,
						mb: 2,
						overflow: showMap ? "hidden" : "auto",
						position: "relative",
					}}
					container
					spacing={3}
				>
					<Grid md={showMap ? 4 : 12}>
						<Box sx={{ mb: 2 }}>
							<Filters
								map_visible={showMap}
								setShowMap={setShowMap}
								showMap={showMap}
							/>
						</Box>
						<Box sx={{ background: "#F1F1F1" }}>
							<Results
								setSelect={setSelect}
								showMap={showMap}
								selected={selected}
							/>
						</Box>
						<Box
							sx={{
								".ais-Pagination": {
									display: "flex",
									justifyContent: "center",
								},
							}}
						></Box>
					</Grid>
					{showMap && (
						<Grid
							sx={{
								position: "relative",

								display: { xs: "none", md: "block" },
							}}
							md={8}
						>
							<PlacesMap selected={selected} />
						</Grid>
					)}
				</Grid>
				<Pagination />
			</WhiteContainer>
		</AlgoliaContainer>
	);
};

export default PlacesList;
