import React from "react";
import { useTranslation } from "react-i18next";
import { Box, Typography } from "@mui/joy";
import PropTypes from "prop-types";
import { Switch, Route } from "react-router-dom";
import { RequestsList } from "./views";
import { RequestEditModal } from "./partials";

const Requests = (props) => {
	const { t } = useTranslation();

	return (
		<>
			<Switch>
				<Route exact path="/admin/requests">
					<RequestsList />
				</Route>
			</Switch>
			<RequestEditModal />
		</>
	);
};

export default Requests;
Requests.propTypes = {};
Requests.defaultProps = {};
