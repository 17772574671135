import React from "react";
import { useTranslation } from "react-i18next";
import { Box } from "@mui/joy";
import { LocationOn } from "@mui/icons-material";
import PropTypes from "prop-types";
import { usePlacesWidget } from "react-google-autocomplete";

const PlaceLocationField = ({ location, setLocation }) => {
	const { t } = useTranslation();
	const { ref, autocompleteRef } = usePlacesWidget({
		apiKey: process.env.REACT_APP_GOOGLE_API_KEY,
		options: {
			componentRestrictions: { country: "fr" },
		},
		onPlaceSelected: (place) => {
			if (place) {
				setLocation({
					coordinates: {
						lat: place?.geometry?.location.lat(),
						lng: place?.geometry?.location.lng(),
					},
					name: place.formatted_address,
				});
			}
		},
	});

	return (
		<Box
			sx={{
				background: "white",
				display: "inline-block",
				borderRadius: "7px",
				pl: 1,
			}}
		>
			<LocationOn />
			<input
				style={{
					border: "0px",
					height: "45px",
					padding: "15px",
					borderRadius: "7px",
					color: "#333",
				}}
				ref={ref}
				placeholder={t("search_location_on_map")}
			/>
		</Box>
	);
};

PlaceLocationField.propTypes = {
	location: PropTypes.object,
	setLocation: PropTypes.func.isRequired,
};

export default PlaceLocationField;
