import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import {
	Box,
	Typography,
	Input,
	FormControl,
	FormLabel,
	Switch,
} from "@mui/joy";
import PropTypes from "prop-types";
import { useForm } from "react-hook-form";

const Visibility = ({ label, update, defaultValue }) => {
	const { t } = useTranslation();
	const { reset, register, handleSubmit, watch, setValue } = useForm({
		defaultValues: {
			visibility: defaultValue?.visibility || false,
			visibility_date: defaultValue?.visibility_date || "",
		},
	});
	const watchVisibility = watch("visibility"); // Watch the Switch value
	const onSubmit = (data) => {
		const updated = {
			visibility_date: data.visibility_date,
			visibility: data.visibility,
		};
		update({ [label]: updated });
	};
	React.useEffect(() => {
		reset();
		setValue("visibility", defaultValue?.visibility || false);
		setValue("visibility_date", defaultValue?.visibility_date || "");
	}, [defaultValue]);

	return (
		<Box sx={{ mt: 2 }}>
			<form>
				{/* Switch to toggle visibility */}
				<FormControl sx={{ mb: 1 }}>
					<Typography
						startDecorator={
							<Switch
								checked={watchVisibility}
								onChange={(e) => {
									setValue("visibility", e.target.checked);
									handleSubmit(onSubmit)();
								}}
							/>
						}
					>
						{t("Show/Hide Visibility Settings")}
					</Typography>
				</FormControl>

				{/* FormControl section visible only when switch is toggled on */}
				{watchVisibility && (
					<FormControl>
						<FormLabel>{t("visibility_date")}</FormLabel>
						<Input
							type="date"
							{...register("visibility_date", {
								onBlur: () => {
									handleSubmit(onSubmit)();
								},
							})}
						/>
					</FormControl>
				)}
			</form>
		</Box>
	);
};

export default Visibility;

Visibility.propTypes = {
	label: PropTypes.string,
	update: PropTypes.func.isRequired,
	defaultValue: PropTypes.object,
};

Visibility.defaultProps = {
	defaultValue: {},
};
