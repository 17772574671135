import React from "react";
import {
	ModalOverflow,
	ModalClose,
	Stack,
	Avatar,
	Modal,
	ModalDialog,
	Container,
	Typography,
	Box,
} from "@mui/joy";
import { useTranslation } from "react-i18next";
import { NavLink } from "react-router-dom";
import useAuthContainer from "../../../../stores/Auth";
import { WhiteContainer } from "../../../../components/UI";
import bg from "../../../../assets/bg_event_layout.jpg";

const Header = ({ title, email }) => (
	<Box
		sx={(theme) => ({
			borderBottom: `1px solid #eee`,
			background: "white",
			zIndex: 2,
			position: "fixed",
			width: "100%",
		})}
	>
		<Container maxWidth="lg">
			<Stack
				sx={{ p: 2, pl: 3, pr: 3 }}
				direction="row"
				alignItems="center"
				justifyContent="space-between"
			>
				<Box>
					<Typography level="h5">{title}</Typography>
				</Box>
				<Box>
					<Stack
						component={NavLink}
						to="/account"
						direction="row"
						alignItems="center"
						spacing={1}
					>
						<Avatar alt={email} />
					</Stack>
				</Box>
			</Stack>
		</Container>
	</Box>
);

const NewEventLayout = ({ title, children }) => {
	const { t } = useTranslation();
	const { userAccount } = useAuthContainer.useContainer();

	return (
		<Modal sx={{ zIndex: 4000 }} open={true}>
			<ModalOverflow>
				<ModalDialog
					sx={{
						m: 0,
						p: 0,
						background: `url(${bg}) fixed center center / cover`,
					}}
					layout="fullscreen"
					size="sm"
				>
					{" "}
					<Box
						sx={{
							background: "rgba(0,0,0,0.5)",
							position: "absolute",
							top: 0,
							left: 0,
							bottom: 0,
							right: 0,
						}}
					/>
					<Box sx={{ position: "relative", zIndex: 100 }}>
						<Header email={userAccount?.email} title={t(title)} />

						<Container
							maxWidth="md"
							sx={(theme) => ({
								mt: 15,
								mb: 30,
							})}
						>
							<WhiteContainer>{children}</WhiteContainer>
						</Container>
					</Box>
				</ModalDialog>
			</ModalOverflow>
		</Modal>
	);
};

export default NewEventLayout;
