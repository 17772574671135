import React from "react";
import { Box, Typography, List, ListItem, Button } from "@mui/joy";
import { Check } from "@mui/icons-material";
import { useTranslation } from "react-i18next";

const ServiceItem = ({ title, description, list, image }) => {
	const { t } = useTranslation();

	return (
		<Box
			sx={{
				width: "285px",
				borderRadius: "8px",
				background: "white",
				boxShadow: "1px 2px 7px #9e9e9e33",
			}}
		>
			<Box
				sx={{
					borderRadius: "8px 8px 0 0 ",
					width: "285px",
					height: "200px",
					background: `url(${image}) center center / cover`,
				}}
			></Box>
			<Box sx={{ p: 2, mt: 2, height: "250px", overflow: "auto" }}>
				<Typography sx={{ mb: 1 }}>
					<b>{title}</b>
				</Typography>
				<Typography sx={{ mb: 1 }}>{description}</Typography>
				<ul style={{ margin: 0, padding: 0 }}>
					{list.map((item) => (
						<ListItem key={`list_${item}`} sx={{ m: 0, p: 0 }}>
							<Typography
								sx={{ display: "flex", alignItems: "center" }}
							>
								<Check
									sx={(theme) => ({
										color: theme.vars.palette.primary[400],
									})}
								/>
								<span style={{ fontStyle: "italic" }}>
									{item}
								</span>
							</Typography>
						</ListItem>
					))}
				</ul>
			</Box>
			<Box sx={{ p: 2 }}>
				<Button
					component={"a"}
					href={"https://www.weevup.fr/page-contact"}
					target="_blank"
					sx={{ mt: 1, mb: 1 }}
					fullWidth
				>
					{t("contact_us")}
				</Button>
			</Box>
		</Box>
	);
};

export default ServiceItem;
