import FirestoreService from "services/firestoreServices";
import Helpers from "utils/Helpers";
import { where, orderBy } from "firebase/firestore";

/**
 * Creates an instance of the Group class.
 * @constructor
 * @param {array} params.contacts - liste de contact
 * @param {array} params.documents - liste de documents
 * @param {string} params.name - Nom du groupe
 * @param {string} params.notes - Notes
 * @param {array} params.places - places
 * @param {float} params.indice_marge - indice_de_marge
 * @param {float} params.indice_retrocom - indice retrocom
 * @param {date} params.created_at - Date de création
 * @param {Object} params.modified_at - Date de modification
 */

class Group {
    constructor(params) {
        Object.assign(this, params);
        this.modified_at = new Date();

        this.firestoreService = new FirestoreService("groups");
    }

    async getGroups(clause, onUpdate) {
        try {
            const group = await this.firestoreService.getDocuments(
                clause,
                true,
                onUpdate,
            );
            return group;
        } catch (error) {
            console.error("Failed to fetch group:", error);
            throw error;
        }
    }

    async searchGroupsByName(searchTerm) {
        try {
            const queryConstraints = [
                where("name", ">=", searchTerm),
                where("name", "<=", searchTerm + "\uf8ff"),
                orderBy("name"),
            ];

            const result = await this.firestoreService.getDocuments(
                queryConstraints,
                false,
                null,
                5,
            ); // Limité à 5 résultats
            return result.documents; // Renvoie les groupes trouvés
        } catch (error) {
            console.error("Failed to fetch groups by name:", error);
            throw error;
        }
    }

    async getGroupsPagination(lastVisible = null, constraints = []) {
        try {
            const result = await this.firestoreService.getDocuments(
                constraints,
                false,
                null,
                20,
                lastVisible,
            );
            if (lastVisible) {
                return {
                    documents: result.documents,
                    lastVisible: result.lastVisible,
                    no_more_data: result.no_more_data,
                };
            } else {
                return result;
            }
        } catch (error) {
            console.error("Failed to fetch events:", error);
            throw error;
        }
    }

    async getGroup(id) {
        try {
            const group = await this.firestoreService.getDocument(id);
            return group;
        } catch (error) {
            console.error("Failed to fetch group:", error);
            throw error;
        }
    }

    async addGroup() {
        try {
            const group = await this.firestoreService.addDocument(
                this.toJSON(),
            );
            return { id: group.id, ...this.toJSON() };
        } catch (error) {
            console.error("Failed to add group:", error);
            throw error;
        }
    }

    async deleteGroup(id) {
        try {
            await this.firestoreService.deleteDocument(id);
        } catch (error) {
            console.error("Failed to delete group:", error);
            throw error;
        }
    }

    async updateGroup(id) {
        try {
            const updatedGroup = await this.firestoreService.updateDocument(
                id,
                this.toJSON(),
                [],
            );
            return updatedGroup;
        } catch (error) {
            console.error("Failed to update group:", error);
            throw error;
        }
    }

    toJSON() {
        let data = {};
        const properties = {
            modified_at: this.modified_at,
            contacts: this.contacts,
            documents: this.documents,
            name: this.name,
            indice_marge: this.indice_marge,
            places: this.places,
            indice_retrocom: this.indice_retrocom,
            created_at: this.created_at,
            modified_at: this.modified_at,
            notes: this.notes,
        };

        // Add only properties that are not undefined
        for (let key in properties) {
            if (properties[key] !== undefined) {
                data[key] = properties[key];
            }
        }

        return data;
    }
}

export default Group;
