import React from "react";
import useCVContainer from "../../../CVStore";
import { Stack, Grid, Box, Typography } from "@mui/joy";
import { useTranslation } from "react-i18next";

const Formations = (props) => {
	const { t } = useTranslation();
	const { formations, color } = useCVContainer.useContainer();

	return (
		<>
			<Typography
				sx={{
					textTransform: "uppercase",
					mb: 2,
					"&::after": {
						content: '""',
						position: "relative",
						width: "40%	",
						display: "block",
						height: "2px",
						backgroundColor: "#333",
					},
				}}
				level="h4"
			>
				{t("Formations")}
			</Typography>
			{formations.map((fo) => (
				<Box sx={{ mb: 1 }}>
					<Box>
						<Typography
							level="body1"
							sx={{ fontWeight: "bold", color: "#333" }}
						>
							{fo?.title}
						</Typography>
						<Typography level="body3" sx={{ color: "#333" }}>
							<b> {fo?.compagny_name}</b>
						</Typography>
						<Typography level="body3" sx={{ color: "#444" }}>
							{fo?.description}
						</Typography>
						{fo?.children.length > 0 && (
							<ul>
								{fo?.children.map((task) => (
									<li>
										<Typography level="body2">
											{task?.title}
										</Typography>
										<Typography level="body3">
											{task?.description}
										</Typography>
									</li>
								))}
							</ul>
						)}
						<Typography sx={{ fontSize: "13px" }}>
							{(fo?.period && parseInt(fo?.period[0])) || ""}-
							{(fo?.period && parseInt(fo?.period[1])) || ""}
						</Typography>
					</Box>
				</Box>
			))}
		</>
	);
};

export default Formations;
