import React from "react";
import { useTranslation } from "react-i18next";
import { Stack, Box, Typography, Card, List, ListItem, Badge } from "@mui/joy";
import { CircleNotifications } from "@mui/icons-material";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import Helpers from "utils/Helpers";
import Document from "models/documents";

const Requests = (props) => {
	const { t } = useTranslation();

	const DocumentEntity = new Document();

	const [requests, setRequests] = React.useState([]);

	const fetchRequests = async () => {
		await DocumentEntity.fetchUniqueUserDocuments((_requests) => {
			setRequests(
				_requests.map((r) => ({
					...r,
					phone: r?.additionalDatas?.phone,
					job: r?.additionalDatas?.job,
					profil_type: r?.additionalDatas?.profil_type,
					name: `${r?.additionalDatas?.lastname} ${r?.additionalDatas?.name}`,
				})),
			);
		});
	};

	React.useEffect(() => {
		fetchRequests();
	}, []);

	return (
		<>
			{requests.length > 0 && (
				<Stack direction={"row"} spacing={1}>
					<Typography>{t("new_requests")}</Typography>
					<Badge color="primary" badgeContent={requests.length || 0}>
						<CircleNotifications />
					</Badge>
				</Stack>
			)}
			<List sx={{ maxHeight: "200px", overflow: "auto" }}>
				{requests.map((req) => (
					<>
						<ListItem
							component={Link}
							target="_blank"
							to={`/admin/requests?requestModal=true&userId=${req.uid}`}
							sx={{
								border: "#eee",
								background: "#F1F1F1",
								mb: 1,
								"&:hover": {
									background: "white",
									border: "1px solid ",
								},
							}}
						>
							<Typography>
								{Helpers.fbDate(req?.created_at, "DD-MM-YYYY")}
							</Typography>
							<Typography>{req?.name}</Typography>
						</ListItem>
					</>
				))}
			</List>
		</>
	);
};

export default Requests;
Requests.propTypes = {};
Requests.defaultProps = {};
