import React, { useRef, useEffect } from "react";
import {
  MapContainer,
  TileLayer,
  Marker,
  Popup,
  useMap,
  useMapEvents,
} from "react-leaflet";
import { Stack, Button, Typography } from "@mui/joy";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";

const MapEventsHandler = ({ refine }) => {
  useMapEvents({
    zoomend: (event) => {
      const bounds = {
        northEast: event.target.getBounds().getNorthEast(),
        southWest: event.target.getBounds().getSouthWest(),
      };
      console.log("Zoomend bounds:", bounds);
      refine(bounds);
    },
    dragend: (event) => {
      const bounds = {
        northEast: event.target.getBounds().getNorthEast(),
        southWest: event.target.getBounds().getSouthWest(),
      };
      console.log("Dragend bounds:", bounds);
      refine(bounds);
    },
    moveend: (event) => {
      const bounds = {
        northEast: event.target.getBounds().getNorthEast(),
        southWest: event.target.getBounds().getSouthWest(),
      };
      console.log("Moveend bounds:", bounds);
      refine(bounds);
    },
  });
  return null;
};

const MapCenterUpdater = ({ center }) => {
  const map = useMap();
  useEffect(() => {
    if (center && center.lat && center.lng) {
      // Simuler un léger déplacement
      const slightMove = 0.0001;
      const newCenter = {
        lat: center.lat + slightMove,
        lng: center.lng + slightMove,
      };
      map.setView([newCenter.lat, newCenter.lng], map.getZoom());
      setTimeout(() => {
        map.setView([center.lat, center.lng], map.getZoom());
      }, 100);
    }
  }, [center, map]);
  return null;
};

const MapWrapper = ({ mapCenter, onMapReady, refine, items, markerRefs }) => {
  const mapRef = useRef(null);
  const { t } = useTranslation();
  const history = useHistory();

  useEffect(() => {
    if (mapRef.current && mapCenter && mapCenter.lat && mapCenter.lng) {
      const bounds = {
        northEast: mapRef.current.getBounds().getNorthEast(),
        southWest: mapRef.current.getBounds().getSouthWest(),
      };
      console.log("Refine bounds on mapCenter change:", bounds);
      refine(bounds);
    }
  }, [mapCenter, refine]);

  return (
    <MapContainer
      center={[mapCenter.lat, mapCenter.lng]}
      zoom={10}
      maxZoom={15} // Verrouiller le zoom maximal
      style={{ minHeight: "100%" }}
      scrollWheelZoom={true}
      whenCreated={(mapInstance) => {
        mapRef.current = mapInstance;
        onMapReady(mapInstance);
        const bounds = {
          northEast: mapInstance.getBounds().getNorthEast(),
          southWest: mapInstance.getBounds().getSouthWest(),
        };
        console.log("Initial bounds:", bounds);
        refine(bounds);
      }}
    >
      <TileLayer
        attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
        url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
      />
      <MapCenterUpdater center={mapCenter} />
      <MapEventsHandler refine={refine} />
      {items.map((item) => (
        <Marker
          key={item.objectID}
          position={item._geoloc}
          ref={(ref) => {
            if (ref) {
              markerRefs.current[item.objectID] = ref;
            }
          }}
        >
          <Popup>
            <Stack spacing={1}>
              <Typography>
                <strong>{item.nom}</strong>
              </Typography>
              <Typography>{item.region}</Typography>
              <Button
                onClick={() => history.push(`/places/view/${item.objectID}`)}
              >
                {t("visualize")}
              </Button>
            </Stack>
          </Popup>
        </Marker>
      ))}
    </MapContainer>
  );
};

export default MapWrapper;
