import React from "react";
import { Header, Footer } from "./partials";
import { Box } from "@mui/joy";
const FrontLayout = ({
	children,
	style,
	eventID,
	authorID,
	active_modules_global,
}) => {
	return (
		<>
			<Header
				style={style}
				active_modules_global={active_modules_global}
			/>
			<Box
				sx={{
					minHeight: "100vh",
					height: "100%",

					pt: "48px",
					position: "relative",
					pb: "151px",
				}}
			>
				<Box
					sx={{
						position: "absolute",
						background:
							style?.theme_mode === "dark"
								? "#000000"
								: style?.no_theme_color_background
								? "transparent"
								: style?.theme_color,
						height: "100%",
						width: "100%",
						top: 0,
						left: 0,
						opacity: style?.theme_mode === "dark" ? 1 : 0.1,
						zIndex: 1,
					}}
				></Box>
				<Box sx={{ position: "relative", zIndex: 2 }}>{children}</Box>
			</Box>

			<Footer style={style} />
		</>
	);
};

export default FrontLayout;
