import React from "react";
import { useTranslation } from "react-i18next";
import { Box, Typography, Button, Stack } from "@mui/joy";
import PropTypes from "prop-types";
import { orderBy, where } from "firebase/firestore";
import Group from "models/groups";
import { WhiteContainer } from "UI";
import { Link } from "react-router-dom";
import { GroupsTable, CreateGroupModal } from "./partials";

const GroupList = (props) => {
	const GroupEntity = new Group();
	const { t } = useTranslation();
	const [filters, setFilters] = React.useState([]);
	const [groups, setGroups] = React.useState([]);
	const [lastVisible, setLastVisible] = React.useState(null);
	const [isMore, SetIsMore] = React.useState(true);
	const [filterChanged, setFilterChanged] = React.useState(false);
	const [showCreateModal, setShowCreateModal] = React.useState(false);

	const getGroups = async (reset = false) => {
		try {
			const datas = await GroupEntity.getGroupsPagination(
				lastVisible,
				filters,
			);
			if (datas) {
				SetIsMore(!datas?.no_more_data);
				if (datas?.lastVisible) {
					setGroups(
						reset
							? datas?.documents
							: [...groups, ...datas?.documents],
					);
					setLastVisible(datas?.lastVisible);
				} else {
					setGroups(datas);
				}
			} else {
				SetIsMore(false);
			}
		} catch (e) {
			console.log(e);
		}
	};

	React.useEffect(() => {
		setGroups([]);
		setLastVisible(null);
		setFilterChanged(true);
	}, [filters]);

	React.useEffect(() => {
		if (filterChanged) {
			getGroups(true);
			setFilterChanged(false);
		}
	}, [filterChanged]);

	return (
		<>
			{console.log(groups)}
			<WhiteContainer>
				<Stack direction="row" spacing={2}>
					<Button onClick={() => setShowCreateModal(true)}>
						{t("create_group")}
					</Button>
				</Stack>
			</WhiteContainer>
			<GroupsTable
				isMore={isMore}
				getGroups={getGroups}
				groups={groups}
				setGroups={setGroups}
			/>
			<CreateGroupModal
				open={showCreateModal}
				close={() => setShowCreateModal(false)}
			/>
		</>
	);
};

export default GroupList;
GroupList.propTypes = {};
GroupList.defaultProps = {};
