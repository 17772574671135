import React from "react";
import { useTranslation } from "react-i18next";
import {
	Box,
	Typography,
	Stack,
	Button,
	Chip,
	IconButton,
	Tooltip,
} from "@mui/joy";
import PropTypes from "prop-types";
import SimpleTable from "UI/Tables/SimpleTable/SimpleTable";
import InfiniteScroll from "react-infinite-scroll-component";
import Helpers from "utils/Helpers";
import { RemoveRedEye, Edit, Delete, Storage } from "@mui/icons-material";

import { WhiteContainer } from "UI";
import { useHistory, Link } from "react-router-dom";

const EventTable = ({ events, getEvents, setEvents, isMore }) => {
	console.log(events);
	const { t } = useTranslation();
	const history = useHistory();
	const TypographyStyle = {
		maxWidth: "100%",
		overflow: "hidden",
		textOverflow: "ellipsis",
	};
	const columns = [
		"created_at",
		"modified_at",
		"type",
		"user_id",
		"event_type",
		"event_name",
		"",
	];
	const actions = [
		(d) => (
			<Stack direction="row" spacing={1}>
				<IconButton
					variant="outlined"
					color="primary"
					component={Link}
					to={`/my_event/${d.id}`}
					target={"_blank"}
				>
					<RemoveRedEye />
				</IconButton>
				<IconButton
					variant="outlined"
					color="primary"
					component={Link}
					to={`/event/${d.id}/general`}
					target={"_blank"}
				>
					<Edit />
				</IconButton>
				<Tooltip title={t("display_in_db")}>
					<IconButton
						variant="outlined"
						color="primary"
						component={"a"}
						href={`https://console.firebase.google.com/project/weevuptools/firestore/databases/-default-/data/events/${d.id}`}
						target={"_blank"}
					>
						<Storage />
					</IconButton>
				</Tooltip>
			</Stack>
		),
	];
	const fields = {
		created_at: (v) => (
			<Typography sx={TypographyStyle}>
				{Helpers.fbDate(v, "DD-MM-YYYY")}
			</Typography>
		),
		modified_at: (v) => (
			<Typography sx={TypographyStyle}>
				{Helpers.fbDate(v, "DD-MM-YYYY")}
			</Typography>
		),
		placeId: (v) => (
			<Typography sx={TypographyStyle}>
				{v ? (
					<Chip color="primary">{t("model")}</Chip>
				) : (
					<Chip color="success">{t("event")}</Chip>
				)}
			</Typography>
		),
		uid: (v) => (
			<Typography sx={TypographyStyle}>
				<Link to={`/admin/users/${v}`}>{v}</Link>
			</Typography>
		),
		event_type: (v) => <Typography sx={TypographyStyle}>{v}</Typography>,
		event_name: (v) => <Typography sx={TypographyStyle}>{v}</Typography>,
	};

	return (
		<WhiteContainer>
			<Box
				id="ed"
				sx={{ height: "60vh", width: "100%", overflowY: "scroll" }}
			>
				<InfiniteScroll
					dataLength={events.length}
					scrollableTarget="ed"
					next={getEvents}
					hasMore={isMore}
					loader={<h4>{t("loading")}...</h4>}
				>
					<SimpleTable
						inputProps={{
							stickyHeader: true,
							stickyFooter: true,
							hoverRow: true,
							borderAxis: "both",
						}}
						style={{
							maxWidth: "100%",
							overflow: "auto",
							background: "white",
							borderRadius: "10px",
							mb: 2,
						}}
						head={columns}
						datas={events}
						fields={fields}
						actions={actions}
					/>{" "}
				</InfiniteScroll>
			</Box>
		</WhiteContainer>
	);
};

export default EventTable;
EventTable.propTypes = {
	getEvents: PropTypes.func,
	events: PropTypes.array,
	setEvents: PropTypes.func,
};
EventTable.defaultProps = {
	events: [],
};
