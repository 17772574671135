import React from "react";
import { Banner, Plannings } from "./partials";
import { Infos } from "../../modules";
import { PlanningHomeCarousel } from "modules/planning";
import { ActivitiesHomeCarousel } from "modules/activities";
import { MealsHomeCarousel } from "modules/meals";
import useFrontStore from "../../FrontStore";
import { Container } from "@mui/joy";

const Home = (props) => {
	const { eventDatas } = useFrontStore.useContainer();

	return (
		<>
			<Banner
				style={eventDatas?.style}
				title={eventDatas?.event_name}
				desc={eventDatas?.event_description}
				bgImage={eventDatas?.event_background_image}
				logo={eventDatas?.event_logo}
			/>
			<Container maxWidth="md">
				{eventDatas?.active_modules &&
					eventDatas?.active_modules.map((mod) => {
						if (mod === "plannings")
							return (
								<PlanningHomeCarousel
									style={eventDatas?.style}
								/>
							);
						if (mod === "activities")
							return (
								<ActivitiesHomeCarousel
									style={eventDatas?.style}
								/>
							);
						if (mod === "meals")
							return (
								<MealsHomeCarousel style={eventDatas?.style} />
							);

						if (mod === "usefull_infos")
							return <Infos {...eventDatas} />;
					})}
			</Container>
		</>
	);
};

export default Home;
