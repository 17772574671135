import React from "react";
import { BOLayout } from "../../../../components/layouts";
import Helpers from "../../../../utils/Helpers";
import { useParams } from "react-router-dom";
import { Grid, Box } from "@mui/joy";
import Event from "models/events";
import useEventStore from "../../EventStore";
import { EditEventHomeForm } from "forms/events";
import { VisualEditor } from "Common";

const Home = (props) => {
	let { eventID } = useParams();
	const { docStatus } = useEventStore.useContainer();
	const [datas, setDatas] = React.useState(null);
	const EventEntity = new Event();

	const fetchEvent = async () => {
		const event = await EventEntity.getEvent(eventID);
		if (event) {
			setDatas(event);
		}
	};

	React.useEffect(() => {
		fetchEvent();
	}, []);

	return (
		<>
			<BOLayout
				documentID={eventID}
				isDocumentStatusMonitoring
				showEventMenu
				title="home"
			>
				{datas && (
					<Box sx={{ gap: 4, display: "flex", flexDirection: "row" }}>
						<Box sx={{ flexGrow: 1 }}>
							<EditEventHomeForm eventDatas={datas} />
						</Box>
						<VisualEditor
							src={`${process.env.REACT_APP_base_url}/my_event/${eventID}`}
						/>
					</Box>
				)}
			</BOLayout>
		</>
	);
};

export default Home;
