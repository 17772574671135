import React from "react";
import { useTranslation } from "react-i18next";
import {
	Box,
	Typography,
	Input,
	FormControl,
	FormLabel,
	Button,
} from "@mui/joy";
import PropTypes from "prop-types";
import { useHistory } from "react-router-dom";
import { useForm } from "react-hook-form";
import Group from "models/groups";

const CreateForm = ({ onEnd }) => {
	const { t } = useTranslation();
	const history = useHistory();
	const [creating, setCreating] = React.useState(false);
	const GroupEntity = new Group();
	const {
		register,
		handleSubmit,
		formState: { errors },
	} = useForm();

	const onSubmit = async (data) => {
		setCreating(true);
		try {
			GroupEntity.name = data?.name || "";
			GroupEntity.places = [];
			const addedGroup = await GroupEntity.addGroup();
			if (addedGroup?.id) {
				history.push(`/admin/groups/${addedGroup.id}`);
			}
		} catch (e) {
			//console.log(e)
		}
		setCreating(false);
	};
	return (
		<Box>
			<FormControl>
				<form onSubmit={handleSubmit(onSubmit)}>
					<Typography component="label" htmlFor="name">
						{t("group_name")}:
					</Typography>
					<Input
						type="text"
						id="name"
						placeholder={t("enter_group_name")}
						{...register("name", {
							required: t("group_name_required"),
						})}
					/>
					{errors.name && (
						<Typography color="danger">
							{errors.name.message}
						</Typography>
					)}{" "}
					<Button
						loading={creating}
						disabled={creating}
						sx={{ mt: 2 }}
						type="submit"
						variant="solid"
					>
						{t("create")}
					</Button>
				</form>
			</FormControl>
		</Box>
	);
};

export default CreateForm;
CreateForm.propTypes = {};
CreateForm.defaultProps = {};
