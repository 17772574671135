import React from "react";
import { Box, Typography, Table, Sheet, Card } from "@mui/joy";
import { useTranslation } from "react-i18next";
import { PlaceInfoCard } from "UI/Grids";
import SwipeComponent from "Components/hoc/SwipeComponent";
import { HideSectionButton } from "forms/places";

const TeamBuilding = ({ uid, team_building, placeId, sections }) => {
	const { t } = useTranslation();

	return (
		<>
			<Box>
				<Typography
					level="h4"
					sx={(theme) => ({
						mb: 2,
						textAlign: "left",
						color: theme.vars.palette.primary[600],
					})}
				>
					{t("team_building")}
				</Typography>

				<HideSectionButton
					section="team_building"
					placeId={placeId}
					uid={uid}
					sectionsVisibility={sections}
				/>
				<Box sx={{ overflow: "auto", pt: 2, pb: 2 }}>
					<SwipeComponent>
						{team_building &&
							team_building.map((e, index) => (
								<PlaceInfoCard
									key={`team_building_${index}`}
									image={e?.image}
									title={e?.nom}
									description={e?.description}
								/>
							))}
					</SwipeComponent>
				</Box>
			</Box>
		</>
	);
};

export default TeamBuilding;
