import React from "react";
import { useTranslation } from "react-i18next";
import {
	Box,
	Typography,
	Button,
	Stack,
	FormHelperText,
	Input,
	FormControl,
	FormLabel,
	Alert,
} from "@mui/joy";
import PropTypes from "prop-types";
import { useForm } from "react-hook-form";
import { Link } from "react-router-dom";
import User from "models/user";

const Email = ({
	formDatas,
	setFormDatas,
	nextStep,
	prevStep,
	setActiveStep,
}) => {
	const userEntity = new User();
	const { t } = useTranslation();
	const [creating, setCreating] = React.useState(false);
	const [notification, setNotification] = React.useState({
		success: false,
		message: null,
	});

	const {
		register,
		handleSubmit,
		formState: { errors },
	} = useForm({
		defaultValues: { email: formDatas?.email },
	});

	const save = async (data) => {
		setCreating(true);
		const updatedFormDatas = { ...formDatas, email: data?.email };
		setFormDatas(updatedFormDatas);

		if (updatedFormDatas.email) {
			const response = await userEntity.addNewUser(updatedFormDatas);

			setNotification({
				success: response?.status === "success",
				message: response?.message,
			});
		}
		setCreating(false);
	};

	const resetForm = () => {
		setActiveStep(0);
		setFormDatas({});
		setNotification({ success: false, message: null });
	};

	return (
		<>
			{!notification.success ? (
				<form onSubmit={handleSubmit(save)}>
					<Stack spacing={2} sx={{ mt: 2 }}>
						<FormControl>
							<FormLabel>{t("email")}</FormLabel>
							<Input
								{...register("email", {
									required: t("email_required"),
									pattern: {
										value: /^[^\s@]+@[^\s@]+\.[^\s@]+$/,
										message: t("valid_email_required"),
									},
								})}
								error={Boolean(errors.email)}
							/>
							{errors.email && (
								<FormHelperText error>
									{errors.email.message}
								</FormHelperText>
							)}
						</FormControl>
					</Stack>

					<Box
						sx={{
							mt: 2,
							display: "flex",
							justifyContent: "right",
							gap: 1,
						}}
					>
						<Button
							disabled={creating}
							type="button"
							onClick={prevStep}
						>
							{t("prev")}
						</Button>
						<Button
							disabled={creating}
							loading={creating}
							type="submit"
						>
							{t("next")}
						</Button>
					</Box>
				</form>
			) : (
				<Box></Box>
			)}
			{notification.message && (
				<Box>
					<Alert color={notification.success ? "success" : "danger"}>
						{t(notification.message)}
					</Alert>
					{notification.success && (
						<Stack spacing={1} direction="row">
							<Button onClick={resetForm}>
								{t("create_new_user")}
							</Button>
						</Stack>
					)}
				</Box>
			)}
		</>
	);
};

Email.propTypes = {
	formDatas: PropTypes.object,
	setFormDatas: PropTypes.func.isRequired,
	nextStep: PropTypes.func.isRequired,
	prevStep: PropTypes.func.isRequired,
	setActiveStep: PropTypes.func.isRequired,
};

Email.defaultProps = {
	formDatas: {},
};

export default Email;
