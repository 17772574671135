import React from "react";
import { useTranslation } from "react-i18next";
import { Box, Typography } from "@mui/joy";
import PropTypes from "prop-types";
import { ActionList } from "./partials";
import Place from "models/places";

const PlaceEntity = new Place();

const Rse = ({ datas, setDatas }) => {
	const { t } = useTranslation();
	const save = async (d) => {
		if (datas?.id) {
			Object.assign(PlaceEntity, d);
			await PlaceEntity.updatePlace(datas?.id);
			setDatas(d);
		}
	};
	return (
		<>
			<ActionList
				actionsRse={datas?.actions_rse || []}
				setDatas={(d) => save({ ...datas, actions_rse: d })}
			/>
		</>
	);
};

export default Rse;
Rse.propTypes = {};
Rse.defaultProps = {};
