import React from "react";
import { useTranslation } from "react-i18next";
import { Box, Typography } from "@mui/joy";
import PropTypes from "prop-types";

const FeaturedHeader = ({ image, children }) => {
	const { t } = useTranslation();
	const style = {
		height: "600px",
		width: "100%",
		borderRadius: "0",
		background: `url("${
			image?.full || image
		}") no-repeat center center / cover`,
		position: "relative",
	};

	return (
		<Box sx={style}>
			{" "}
			<Box
				sx={{
					position: "absolute",
					bottom: 0,
					left: 0,
					borderRadius: "10px 0 0 0",
					m: 2,
				}}
			>
				{children}
			</Box>
		</Box>
	);
};

export default FeaturedHeader;
FeaturedHeader.propTypes = {};
FeaturedHeader.defaultProps = {};
