import Icons from "assets/icons/Icons";

const salleConfig = {
	cocktail: <Icons width={40} height={40} name="Pictos Weevapp_Cocktail" />,
	enConference: (
		<Icons width={40} height={40} name="Pictos Weevapp_Conférence" />
	),
	enRangDEcole: (
		<Icons width={40} height={40} name="Pictos Weevapp_Rang d'école" />
	),
	tableRondeGala: (
		<Icons width={40} height={40} name="Pictos Weevapp_Cabaret" />
	),
	enRond: <Icons width={40} height={40} name="Pictos Weevapp_Gala" />,
	enReunion: <Icons width={40} height={40} name="Pictos Weevapp_Réunion" />,
	enU: <Icons width={40} height={40} name="Pictos Weevapp_En U" />,
};

export default salleConfig;
