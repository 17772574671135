import { initializeApp } from "firebase/app";
import { enableIndexedDbPersistence, getFirestore } from "firebase/firestore";
import { getAuth } from "firebase/auth";
import { getFunctions, connectFunctionsEmulator } from "firebase/functions";
import { getAnalytics } from "firebase/analytics";
import { getStorage } from "firebase/storage";

// TODO: Replace the following with your app's Firebase project configuration
const firebaseConfig = {
  apiKey: process.env.REACT_APP_apiKey,
  authDomain: process.env.REACT_APP_authDomain,
  projectId: process.env.REACT_APP_projectId,
  storageBucket: process.env.REACT_APP_storageBucket,
  messagingSenderId: process.env.REACT_APP_messagingSenderId,
  appId: process.env.REACT_APP_appId,
  measurementId: process.env.REACT_APP_measurementId,
};

const emulators = false;
const MyFirebase = initializeApp(firebaseConfig);
const db = getFirestore();
const auth = getAuth();
const functions = getFunctions();
const analytics = getAnalytics();
const storage = getStorage();

if (emulators) {
  connectFunctionsEmulator(functions, "localhost", 5001);
}

// enable persistence .
enableIndexedDbPersistence(db).catch((err) => {
  if (err.code === "failed-precondition") {
    /* alert(
      'Your app is already open in another tab  , cache might be not handle in this tab '
    )*/
  } else if (err.code === "unimplemented") {
    /*alert(
      'Your browser doesnt support offline mode , for better experience use a modern browser'
    )*/
  }
});

export { MyFirebase, db, auth, functions, analytics, storage };
