import React from "react";
import { useTranslation } from "react-i18next";
import { Box, Typography, Modal, ModalOverflow, ModalDialog } from "@mui/joy";
import PropTypes from "prop-types";
import { CreateForm } from "forms/admin/groups";

const CreateGroupModal = ({ open, close }) => {
	const { t } = useTranslation();

	return (
		<Modal open={open} onClose={close}>
			<ModalOverflow>
				<ModalDialog>
					<CreateForm onEnd={close} />
				</ModalDialog>
			</ModalOverflow>
		</Modal>
	);
};

export default CreateGroupModal;
CreateGroupModal.propTypes = {};
CreateGroupModal.defaultProps = {};
