import CheckIcon from "@mui/icons-material/Check";
import Box from "@mui/joy/Box";
import Chip from "@mui/joy/Chip";
import Tooltip from "@mui/joy/Tooltip";
import Checkbox from "@mui/joy/Checkbox";
import Typography from "@mui/joy/Typography";
import * as React from "react";
import { useTranslation } from "react-i18next";

export default function ChipCheckbox({
	items,
	register,
	withReactHookForm,
	action,
	hasToolTips,
	selectItem,
	setValue,
	fieldName,
}) {
	const { t } = useTranslation();
	const [selected, setSelected] = React.useState(selectItem || []);

	const handleChange = (event, name) => {
		const names = [...selected];
		const newNames = !event.target.checked
			? names.filter((n) => n !== name)
			: [...names, name];

		setSelected(newNames);
		setValue && setValue(fieldName, newNames);
		if (action) {
			withReactHookForm ? action()() : action();
		}
	};

	return (
		<Box sx={{ display: "flex", gap: 1, alignItems: "center" }}>
			<Box>
				<Box sx={{ display: "flex", flexWrap: "wrap", gap: 1 }}>
					{items.map((name) => {
						const checked = selected.includes(name);
						return (
							<Tooltip
								key={name}
								title={
									hasToolTips ? t(`${name}_description`) : ""
								}
							>
								<Chip
									variant={checked ? "soft" : "plain"}
									color={checked ? "primary" : "neutral"}
									startDecorator={
										checked && (
											<CheckIcon
												sx={{
													zIndex: 1,
													pointerEvents: "none",
												}}
											/>
										)
									}
								>
									<Checkbox
										variant="outlined"
										color={checked ? "primary" : "neutral"}
										disableIcon
										overlay
										label={t(name)}
										{...register(fieldName)}
										value={name}
										checked={checked}
										onChange={(e) => handleChange(e, name)}
									/>
								</Chip>
							</Tooltip>
						);
					})}
				</Box>
			</Box>
		</Box>
	);
}
