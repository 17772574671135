import React from "react";
import { useTranslation } from "react-i18next";
import { Box, Typography } from "@mui/joy";
import PropTypes from "prop-types";
import { Notifications } from "./partials";
import useAuthContainer from "stores/Auth";
import User from "models/user";

const UserEntity = new User();

const AdminOptions = (props) => {
	const { t } = useTranslation();
	const { userAccount, user } = useAuthContainer.useContainer();

	const updateUser = async (datas) => {
		try {
			UserEntity.notifications_subscribtions = datas;
			UserEntity.updateUser(user.uid);
		} catch (e) {
			console.error(e.message);
		}
	};

	return (
		<>
			<Notifications
				update={(d) => updateUser(d)}
				subscribtions={userAccount?.notifications_subscribtions || []}
			/>
		</>
	);
};

export default AdminOptions;
AdminOptions.propTypes = {};
AdminOptions.defaultProps = {};
