import React from "react";
import { useTranslation } from "react-i18next";
import { Box, Typography, Stack, Button } from "@mui/joy";
import PropTypes from "prop-types";
import { useForm } from "react-hook-form";
import {
	GroupName,
	Job,
	Name,
	Phone,
} from "forms/admin/users/AdditionnalDataForm/partials";

const Infos = ({ formDatas, setFormDatas, nextStep, prevStep }) => {
	const { t } = useTranslation();
	const { register, control, handleSubmit } = useForm({
		defaultValues: { ...(formDatas?.infos || {}) },
	});

	const save = (data) => {
		setFormDatas({ ...formDatas, infos: data });
		nextStep();
	};

	return (
		<form onSubmit={handleSubmit(save)}>
			<Stack spacing={2} sx={{ mt: 2 }}>
				<GroupName register={register} />
				<Job register={register} control={control} />
				<Name register={register} />
				<Phone register={register} />
			</Stack>
			<Box
				sx={{ mt: 2, display: "flex", justifyContent: "right", gap: 1 }}
			>
				<Button type="button" onClick={prevStep}>
					{t("prev")}
				</Button>
				<Button type="submit">{t("next")}</Button>
			</Box>
		</form>
	);
};

export default Infos;
Infos.propTypes = {};
Infos.defaultProps = {};
