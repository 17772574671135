import React from "react";
import { useTranslation } from "react-i18next";
import { Box, Typography, Snackbar, Grid, Button } from "@mui/joy";
import PropTypes from "prop-types";
import {
	Informations,
	Contacts,
	Notes,
	Documents,
} from "forms/admin/groups/editForm";
import { PlacesList } from "./partials";
import Group from "models/groups";
import { ArrowBack, Delete } from "@mui/icons-material";
import { useParams, Link, useHistory } from "react-router-dom";

const GroupDetails = (props) => {
	const { groupID } = useParams();
	const { t } = useTranslation();
	const history = useHistory();
	const GroupEntity = new Group();
	const [datas, setDatas] = React.useState(null);
	const [message, setMessage] = React.useState({ open: false, message: "" });
	const [deleting, setDeleting] = React.useState(false);
	const deleteGroup = async () => {
		if (window.confirm(t("sure_delete_group"))) {
			try {
				setDeleting(true);
				await GroupEntity.deleteGroup(groupID);
				history.push("/admin/groups");
			} catch {
				//console.log(e)
			}

			setDeleting(false);
		}
	};

	const getGroup = async () => {
		try {
			const group = await GroupEntity.getGroup(groupID);

			setDatas(group);
		} catch (e) {
			setMessage({
				open: true,
				color: "danger",
				message: e.message,
				autoHideDuration: 2000,
			});
		}
	};

	React.useEffect(() => {
		getGroup();
	}, [groupID]);

	/*DATAS*/
	React.useEffect(() => {
		if (datas) {
			console.log("datas group===>", datas);
		}
	}, [datas]);

	/*DATAS*/

	return (
		<>
			<Box sx={{ display: "flex", justifyContent: "space-between" }}>
				<Button sx={{ mb: 1 }} component={Link} to="/admin/groups/">
					<ArrowBack />
					{t("back_to_groups_list")}
				</Button>
				<Button
					disabled={deleting}
					loading={deleting}
					color="danger"
					sx={{ mb: 1 }}
					onClick={deleteGroup}
				>
					<Delete />
					{t("remove_group")}
				</Button>
			</Box>
			<Informations groupId={groupID} datas={datas} setDatas={setDatas} />

			<Grid container spacing={2}>
				<Grid md={4}>
					<Contacts
						contacts={datas?.contacts || []}
						groupId={groupID}
					/>
				</Grid>
				<Grid md={4}>
					<Documents
						groupId={groupID}
						documents={datas?.documents || []}
					/>{" "}
				</Grid>{" "}
				<Grid md={4}>
					<Notes notes={datas?.notes || []} groupId={groupID} />
				</Grid>
			</Grid>
			<Grid container spacing={2}>
				<Grid md={4}>
					<PlacesList places={datas?.places || []} />
				</Grid>
			</Grid>
		</>
	);
};

export default GroupDetails;

GroupDetails.propTypes = {};
GroupDetails.defaultProps = {};
