import React from "react";
import { useTranslation } from "react-i18next";
import { Box, Typography, Input, Stack } from "@mui/joy";
import PropTypes from "prop-types";
import { HexColorPicker } from "react-colorful";
import { BackgroundColor, DotsColor, DotStyle, Image } from "./partials";

const EventQrCodeEdit = ({ setValue, getValues, register, watch }) => {
	const { t } = useTranslation();
	let backgroundOptions = watch("backgroundOptions");
	let dotsOptions = watch("dotsOptions");
	let image = watch("image");
	return (
		<Stack spacing={2}>
			<BackgroundColor
				color={backgroundOptions?.color}
				setValue={setValue}
			/>

			<DotsColor dotsOptions={dotsOptions} setValue={setValue} />

			<DotStyle dotsOptions={dotsOptions} setValue={setValue} />
			<Image setValue={setValue} image={image} />
		</Stack>
	);
};

export default EventQrCodeEdit;
EventQrCodeEdit.propTypes = {};
EventQrCodeEdit.defaultProps = {};

/*const [qrOption, setQrOptions] = React.useState({
		width: 1500,
		height: 1500,
		margin: 65,
		errorCorrectionLevel: "H",
		image: null,
		imageOptions: {
			crossOrigin: "anonymous",
		},
		data: `${process.env.REACT_APP_base_url}/my_event/${eventID}`,
		backgroundOptions: {
			color: null,
		},
	});*/
