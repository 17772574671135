import React from "react";
import ChildrenCard from "./ChildrenCard";
import { Container, Draggable } from "react-smooth-dnd";
import Helpers from "../../../../../../../utils/Helpers";
import useBuilderContainer from "../../../../BuilderStore";
import { Button } from "@mui/joy";
import { Add } from "@mui/icons-material";
import { useTranslation } from "react-i18next";

const Chidren = ({ element }) => {
	console.log(element);
	const { t } = useTranslation();
	const { contentState, setContentState, setCreateElementType, config } =
		useBuilderContainer.useContainer();
	const onDragEnd = (e) => {
		const childrens = [...element.children];

		const _res = Helpers.applyDrag(e, childrens);

		console.log(_res);
		const el = { ...element, children: _res };

		setContentState(Helpers.deepArrayUpdate(contentState, el, element.id));
	};

	return (
		<>
			<Container
				lockAxis="y"
				dragHandleSelector=".element-drag-handle"
				onDrop={onDragEnd}
				dragClass="element-dragging"
			>
				{element?.children &&
					element?.children.map((child) => (
						<Draggable key={child.id}>
							<ChildrenCard
								t={t}
								elementID={element.id}
								key={child.id}
								{...child}
							/>
						</Draggable>
					))}
			</Container>

			{!config?.hideSubcat &&
				!config?.limitCatDepth &&
				element.type !== "subCategory" && (
					<Button
						onClick={() => setCreateElementType("subCategory")}
						fullWidth
						sx={{ mb: 2 }}
						varian="solid"
					>
						<Add />
						{t(config?.terms["add_sub_cat"] || "add_sub_cat")}
					</Button>
				)}

			<Button
				onClick={() => setCreateElementType("element")}
				fullWidth
				varian="solid"
			>
				<Add />
				{t(config?.terms["add_element"] || "add_element")}
			</Button>
		</>
	);
};

export default Chidren;
