import FirestoreService from "services/firestoreServices";
import { where } from "firebase/firestore";

/**
 * Creates an instance of the Kpi class.
 * @constructor
 * @param {Object} params - The constructor parameters.
 * @param {Array} params.favorites - List of favorite items.
 * @param {Boolean} params.isApproved - Approved or not.
 * @param {Object} params.additionalDatas - donnée aditionnel.
 * @param {string} params.role - kpi role.
 */
class Kpi {
    constructor(params) {
        Object.assign(this, params);
        this.modified_at = new Date();
        this.firestoreService = new FirestoreService("kpi");
    }

    async getKpis() {
        try {
            const kpis = await this.firestoreService.getDocuments();
            if (kpis) {
                return kpis.reduce((acc, current) => {
                    acc[current["id"]] = current;
                    return acc;
                }, {});
            } else {
                return null;
            }
        } catch (error) {
            console.error("Failed to fetch kpis:", error);
            throw error;
        }
    }
}

export default Kpi;
