import React from "react";
import { useTranslation } from "react-i18next";
import { Box, Typography, Input } from "@mui/joy";
import PropTypes from "prop-types";

const DotsColor = ({ setValue, dotsOptions }) => {
	const { t } = useTranslation();

	return (
		<>
			<Box
				sx={{
					display: "flex",
					flexDirection: "row",
					alignItems: "center",
					gap: 1,
					textAlign: "center",
				}}
			>
				<Input
					sx={{
						boxShadow: "none",
						border: "none",
						margin: 0,
						p: 0,
					}}
					type="color"
					value={dotsOptions.color}
					onChange={(e) => {
						setValue("dotsOptions", {
							...dotsOptions,
							color: e.target.value,
						});
					}}
				/>
				<Typography>{t("dots_color")}</Typography>
			</Box>
		</>
	);
};

export default DotsColor;
DotsColor.propTypes = {};
DotsColor.defaultProps = {};
