import React from "react";
import { Box, Typography } from "@mui/joy";
import moment from "moment";

const Footer = ({ style }) => {
	return (
		<Box
			sx={{
				background: style?.theme_color,
				p: 2,
				textAlign: "center",
				position: "relative",
				width: "100%",
				bottom: 0,
			}}
		>
			<Typography
				sx={{
					color: "white",
				}}
			>
				<a
					href="weevup.fr"
					style={{ color: "white", textDecoration: "none" }}
				>
					<sup>© </sup> {moment().format("YYYY")} Weevup
				</a>{" "}
			</Typography>
		</Box>
	);
};

export default Footer;
