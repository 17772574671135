import React, { useState } from "react";
import { useForm, Controller } from "react-hook-form";
import { Autocomplete, TextField, Button, Stack } from "@mui/joy";
import Group from "models/groups"; // Assurez-vous d'importer correctement votre classe Group
import Place from "models/places"; // Assurez-vous d'importer correctement votre classe Group
import { useTranslation } from "react-i18next";
import { arrayUnion } from "firebase/firestore";

const Editable = ({ setGroup, close, place }) => {
	const { t } = useTranslation();

	const { control, handleSubmit, setValue } = useForm();
	const [options, setOptions] = useState([]);
	const [loading, setLoading] = useState(false);

	// Instance de la classe Group
	const groupInstance = new Group(); // Passez des paramètres si nécessaire
	const placeInstance = new Place(); // Passez des paramètres si nécessaire

	// Fonction pour fetch les groupes par nom via la méthode de la classe Group
	const fetchGroupsByName = async (searchTerm) => {
		setLoading(true);
		try {
			const result = await groupInstance.searchGroupsByName(searchTerm); // Appel à la méthode de la classe Group
			setOptions(result); // Stockez les objets complets
		} catch (error) {
			console.error("Error fetching groups by name:", error);
		} finally {
			setLoading(false);
		}
	};

	const onSubmit = (data) => {
		// Récupère le groupe sélectionné à partir du nom
		const selectedGroup = options.find(
			(group) => group.name === data.group,
		);

		// Utilisez le groupe sélectionné
		if (selectedGroup) {
			placeInstance.groupId = selectedGroup.id;

			placeInstance.updatePlace(place.id);
			groupInstance.places = arrayUnion({
				name: place?.nom || "",
				id: place.id,
			});
			groupInstance.updateGroup(selectedGroup.id);

			setGroup(selectedGroup);
		}

		close();
	};

	return (
		<form onSubmit={handleSubmit(onSubmit)}>
			<Controller
				name="group"
				control={control}
				render={({ field }) => (
					<Autocomplete
						{...field}
						options={options.map((group) => group.name)} // Affiche uniquement les noms dans l'Autocomplete
						loading={loading}
						onInputChange={(event, value) => {
							// Appeler la fonction de recherche lorsque l'utilisateur tape quelque chose
							fetchGroupsByName(value);
						}}
						onChange={(event, value) => {
							// Met à jour le champ avec le nom sélectionné
							setValue("group", value);
						}}
						renderInput={(params) => (
							<TextField
								{...params}
								label="Select Group"
								placeholder="Search for a group"
							/>
						)}
					/>
				)}
			/>

			<Stack sx={{ mt: 1 }} direction="row" spacing={1}>
				<Button type="submit" color="primary">
					{t("save")}
				</Button>
				<Button type="button" color="neutral" onClick={close}>
					{t("cancel")}
				</Button>
			</Stack>
		</form>
	);
};

export default Editable;
