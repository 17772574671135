import React from "react";
const contentful = require("contentful");

const useContentfullDelivery = () => {
	const [entry, setEntry] = React.useState(null);

	const getContentfullEntry = async (entryId) => {
		const client = contentful.createClient({
			space: "dyb7l1ulpnw5",
			accessToken: process.env.REACT_APP_contentful_access_token,
		});

		const entry = await client.getEntry(entryId);

		setEntry(entry);
	};

	return {
		entry,
		getContentfullEntry,
	};
};

export default useContentfullDelivery;
