import * as React from "react";
import Tabs from "@mui/joy/Tabs";
import TabList from "@mui/joy/TabList";
import Tab, { tabClasses } from "@mui/joy/Tab";
import { NavLink, useParams, useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";

const links = [
	{
		path: "/my_event/:ID",
		id: 0,
		label: "home",
	},
	{
		path: "/my_event/:ID/planning",
		id: 1,
		label: "plannings",
	},
	{
		path: "/my_event/:ID/activities",
		id: 2,
		label: "activities",
	},
	{
		path: "/my_event/:ID/meals",
		id: 3,
		label: "meals",
	},
	{
		path: "/my_event/:ID/usefull_infos",
		id: 4,
		label: "usefull_infos",
	},
];

const Header = ({ style, active_modules_global }) => {
	const { t } = useTranslation();
	const { eventID } = useParams();
	const { pathname } = useLocation();
	const [currentLink, setCurrentLink] = React.useState(null);

	// Formater le lien pour remplacer :ID par eventID
	const formatLink = (l) => l.replace(":ID", eventID);

	// Mettre à jour currentLink chaque fois que le pathname change
	React.useEffect(() => {
		const matchedLink = links.find(
			(l) => formatLink(l.path) === pathname,
		)?.id;
		if (matchedLink !== currentLink) {
			setCurrentLink(matchedLink);
		}
	}, [pathname, currentLink, eventID]);

	const dark_mode = style?.theme_mode === "dark";

	return (
		<Tabs
			sx={{
				overflow: "auto",
				position: "fixed",
				zIndex: 3,
				background: dark_mode ? "#000000" : "#FFFFFF",
				width: "100%",
			}}
			aria-label="tabs"
			value={currentLink} // Utiliser "value" pour synchroniser avec currentLink
		>
			<TabList
				variant="plain"
				sx={{
					width: "852px",
					margin: "0 auto",
					background: dark_mode ? "#000000" : "#FFFFFF",
					"--List-padding": "0px",
					"--List-radius": "0px",
					"--ListItem-minHeight": "48px",
					overflow: "auto",
					[`& .${tabClasses.root}`]: {
						fontFamily: style?.theme_font || "Arial",
						boxShadow: "none",
						fontWeight: "md",
						"&:hover": {
							background: dark_mode && style?.theme_color,
						},
						color: dark_mode && "#FFFFFF",
						[`&.${tabClasses.selected}`]: {
							background: dark_mode && "none",
							color: style?.theme_color || "primary.400",
						},
						[`&.${tabClasses.selected}::before`]: {
							content: '""',
							display: "none",
							position: "absolute",
							left: "var(--ListItem-paddingLeft)",
							right: "var(--ListItem-paddingRight)",
							bottom: 0,
							height: 3,
							bgcolor: style?.theme_color || "primary.400",
						},
					},
				}}
			>
				{links
					.filter(
						(i) =>
							!active_modules_global ||
							active_modules_global.includes(i.label),
					)
					.map((l) => (
						<Tab
							key={l.id}
							sx={{ whiteSpace: "nowrap" }}
							component={NavLink}
							to={`${formatLink(l.path)}`}
						>
							{t(l.label)}
						</Tab>
					))}
			</TabList>
		</Tabs>
	);
};

export default Header;
