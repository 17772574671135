import FirestoreService from "services/firestoreServices";
import Helpers from "utils/Helpers";
import { where } from "firebase/firestore";

/**
 * Creates an instance of the Meals class.
 * @constructor
 * @param {string} params.event_id - Event ID of the event .
 * @param {Array} params.content - Meals content .
 * @param {string} params.status - meals status (active / inactive) .
 * @param {string} params.uid - User ID of the meals creator.
 * @param {Date} params.modified_at - Last modification date.
 * @param {Date} params.created_at - Creation date of the event.
 */

class Meals {
    constructor(params) {
        Object.assign(this, params);
        this.modified_at = new Date();

        this.firestoreService = new FirestoreService("meals");
    }

    async getMealsByEventId(id, realtime = false, onUpdate = null) {
        try {
            const meals = await this.firestoreService.getDocuments(
                [where("event_id", "==", id)],
                realtime,
                onUpdate,
            );
            if (meals[0]) {
                return meals[0];
            } else {
                return null;
            }
        } catch (error) {
            console.error("Failed to fetch meals:", error);
            throw error;
        }
    }

    async addMeals() {
        try {
            const meals = await this.firestoreService.addDocument(
                this.toJSON(),
            );
            return { id: meals.id, ...this.toJSON() };
        } catch (error) {
            console.error("Failed to add meals:", error);
            throw error;
        }
    }

    async deleteMeals(id) {
        try {
            await this.firestoreService.deleteDocument(id);
        } catch (error) {
            console.error("Failed to delete meals:", error);
            throw error;
        }
    }

    async updateMeals(id) {
        try {
            const updatedMeals = await this.firestoreService.updateDocument(
                id,
                this.toJSON(),
                [],
            );
            return updatedMeals;
        } catch (error) {
            console.error("Failed to update meals:", error);
            throw error;
        }
    }

    async duplicateMeals(eventId, newEventId, uid) {
        try {
            // Fetch the original meal using getMealsByEventId
            const originalMeals = await this.getMealsByEventId(eventId);
            if (!originalMeals) {
                console.warn(
                    `No meals found for event ID ${eventId}. No duplication performed.`,
                );
                return null;
            }

            // Create a new meal object with the new event_id
            const newMeals = new Meals({
                ...originalMeals,
                event_id: newEventId,
                uid,
                created_at: new Date(),
                modified_at: new Date(),
            });

            // Save the new meal to Firestore
            const addedMeals = await newMeals.addMeals();
            return addedMeals;
        } catch (error) {
            console.error("Failed to duplicate meals:", error);
            throw error;
        }
    }

    toJSON() {
        let data = {};
        const properties = {
            event_id: this.event_id,
            content: this.content,
            status: this.status,
            uid: this.uid,
            modified_at: this.modified_at,
            created_at: this.created_at,
        };

        // Add only properties that are not undefined
        for (let key in properties) {
            if (properties[key] !== undefined) {
                data[key] = properties[key];
            }
        }

        return data;
    }
}

export default Meals;
