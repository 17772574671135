import React from "react";
import { createContainer } from "unstated-next";
import { onAuthStateChanged } from "firebase/auth";
import { doc, onSnapshot } from "firebase/firestore";
import { db, auth } from "../utils/MyFirebase";
import { useHistory } from "react-router-dom";

const useAuthContainer = createContainer(() => {
  const [user, setUser] = React.useState(false);
  const [userAccount, setUserAccount] = React.useState(null);
  const [credentials, setCredentials] = React.useState();
  const [claims, setClaims] = React.useState();
  const [mediaLibUrl, setMediaLibUrl] = React.useState(null);
  const [lang, setLang] = React.useState(process.env.REACT_APP_default_lang);

  React.useEffect(() => {
    onAuthStateChanged(auth, (u) => {
      if (u) {
        setUser(u);
        onSnapshot(
          doc(db, "/users", u.uid),
          (doc) => {
            if (doc.exists()) {
              auth.currentUser.getIdTokenResult(true).then((tr) => {
                setClaims(tr.claims);
              });
              setUserAccount(doc.data());
              const { default_lang } = doc.data();
              if (default_lang) {
                setLang(default_lang);
              }
            }
          },
          (e) => {
            console.log("login issue==>", e.message);
            setUserAccount(null);
          },
        );
      } else {
        setUser(false);
      }
    });
  }, []);

  return {
    user,
    userAccount,
    lang,
    claims,
    mediaLibUrl,
    setMediaLibUrl,
    setCredentials,
    credentials,
  };
});

export default useAuthContainer;

export const withAuthContainer = (Component) => (props) => {
  return (
    <useAuthContainer.Provider>
      <Component {...props} />
    </useAuthContainer.Provider>
  );
};
