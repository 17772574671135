import React from "react";
import { useTranslation } from "react-i18next";
import { Box, Typography } from "@mui/joy";
import PropTypes from "prop-types";
import { Switch, Route } from "react-router-dom";
import { AppUsers, UserDetails, CreateUser } from "./views";

const Users = (props) => {
	const { t } = useTranslation();

	return (
		<Switch>
			<Route exact path="/admin/users">
				<AppUsers />
			</Route>
			<Route exact path="/admin/users/new-user">
				<CreateUser />
			</Route>
			<Route path="/admin/users/:userId">
				<UserDetails />
			</Route>
		</Switch>
	);
};

export default Users;
Users.propTypes = {};
Users.defaultProps = {};
