import React from "react";
import { useTranslation } from "react-i18next";
import { Box, Typography, Button } from "@mui/joy";
import { Edit, Close } from "@mui/icons-material";
import PropTypes from "prop-types";
import { WhiteContainer } from "UI";
import { View, Editable } from "./partials";
import Group from "models/groups";

const Informations = ({ datas, setDatas, groupId }) => {
	const { t } = useTranslation();
	const [editable, setEditable] = React.useState(false);
	const GroupEntity = React.useMemo(() => new Group(), [groupId]);

	const saveInfo = async (data) => {
		try {
			setDatas({ ...datas, ...data });
			setEditable(false);
			Object.assign(GroupEntity, data);
			await GroupEntity.updateGroup(groupId);
		} catch (e) {
			//console.log(e);
		}
	};
	return (
		<>
			<WhiteContainer title={datas?.name}>
				{!editable ? (
					<Button
						sx={{ position: "absolute", top: 8, right: 8 }}
						onClick={() => setEditable(true)}
					>
						<Edit /> {t("edit")}
					</Button>
				) : (
					<Button
						sx={{ position: "absolute", top: 8, right: 8 }}
						onClick={() => setEditable(false)}
					>
						<Close /> {t("close")}
					</Button>
				)}
				{editable ? (
					<Editable datas={datas} save={saveInfo} />
				) : (
					<View datas={datas} />
				)}
			</WhiteContainer>
		</>
	);
};

export default Informations;
Informations.propTypes = {};
Informations.defaultProps = {};
