import * as React from "react";
import Box from "@mui/joy/Box";
import Button from "@mui/joy/Button";
import Divider from "@mui/joy/Divider";
import Modal from "@mui/joy/Modal";
import ModalDialog from "@mui/joy/ModalDialog";
import DeleteForever from "@mui/icons-material/DeleteForever";
import WarningRoundedIcon from "@mui/icons-material/WarningRounded";
import Typography from "@mui/joy/Typography";
import useBuilderContainer from "../BuilderStore";
import { useTranslation } from "react-i18next";

export default function ConfirmDelete() {
	const { t } = useTranslation();
	const { confirmDelete, setConfirmDelete, deleteElement, config } =
		useBuilderContainer.useContainer();

	const setOpen = (v) => setConfirmDelete(v);
	let open = Boolean(confirmDelete);

	return (
		<React.Fragment>
			<Modal open={open} onClose={() => setOpen(false)}>
				<ModalDialog
					variant="outlined"
					role="alertdialog"
					aria-labelledby="alert-dialog-modal-title"
					aria-describedby="alert-dialog-modal-description"
				>
					<Typography
						id="alert-dialog-modal-title"
						component="h2"
						startDecorator={<WarningRoundedIcon />}
					>
						Confirmation
					</Typography>
					<Divider />
					<Typography
						id="alert-dialog-modal-description"
						textColor="text.tertiary"
					>
						{t("sure_you_want_delete_this_element")}
					</Typography>
					<Box
						sx={{
							display: "flex",
							gap: 1,
							justifyContent: "flex-end",
							pt: 2,
						}}
					>
						<Button
							variant="plain"
							color="neutral"
							onClick={() => setOpen(false)}
						>
							{t("cancel")}
						</Button>
						<Button
							variant="solid"
							color="danger"
							onClick={() => {
								deleteElement(confirmDelete);
								setOpen(false);
							}}
						>
							{t("delete")}
						</Button>
					</Box>
				</ModalDialog>
			</Modal>
		</React.Fragment>
	);
}
