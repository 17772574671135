import React from "react";
import { Box, Typography, FormLabel, Chip } from "@mui/joy";
import { useTranslation } from "react-i18next";
import { Controller } from "react-hook-form";
import activites from "datas/places/places_activities.json";

const Loisir = ({ errors, register, control, setValue }) => {
	const { t } = useTranslation();

	return (
		<>
			<Box
				sx={{
					background: "white",
					p: 2,
					mt: 1,
					mb: 1,
				}}
			>
				<Typography sx={{ mb: 2 }} level="h4" component="h2">
					{t("activites_loisir")}
				</Typography>

				<Box sx={{ mt: 2 }}>
					<FormLabel sx={{ mb: 2 }}>
						{t("liste_des_activites")}
					</FormLabel>
					<Box sx={{ display: "flex", gap: 1, flexWrap: "wrap" }}>
						{activites.map((option, index) => (
							<Controller
								key={option}
								name={`activites_loisir`}
								control={control}
								render={({
									field: { onChange, value = [], name },
								}) => (
									<Chip
										variant={
											value.includes(option)
												? "solid"
												: "outlined"
										}
										color={
											value.includes(option)
												? "primary"
												: "default"
										}
										onClick={() => {
											const newValue = value.includes(
												option,
											)
												? value.filter(
														(v) => v !== option,
												  )
												: [...value, option];

											onChange(newValue);
										}}
										label={option}
										size="md"
										sx={{
											cursor: "pointer",
											border: "1px solid #eee",
											"&:hover": {
												opacity: 0.8,
											},
										}}
									>
										{" "}
										{t(option)}
									</Chip>
								)}
							/>
						))}
					</Box>
				</Box>
			</Box>
		</>
	);
};

export default Loisir;
