import React, { useEffect, useState } from "react";
import { useForm, Controller } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { Box, Typography, Switch } from "@mui/joy";
import PropTypes from "prop-types";
import { WhiteContainer } from "UI";
import options from "datas/places/places_places_sections.json";

const Visibility = ({ sectionsVisibility, setDatas }) => {
	const { t } = useTranslation();

	const { control, watch } = useForm({
		defaultValues: options.reduce((acc, option) => {
			acc[option] = sectionsVisibility.includes(option);
			return acc;
		}, {}),
	});

	const [activeOptions, setActiveOptions] = useState(
		options.filter((option) => sectionsVisibility.includes(option)),
	);

	const watchOptions = watch();

	useEffect(() => {
		const enabledOptions = Object.keys(watchOptions).filter(
			(option) => watchOptions[option],
		);

		if (JSON.stringify(enabledOptions) !== JSON.stringify(activeOptions)) {
			setActiveOptions(enabledOptions);
			setDatas(enabledOptions); // Appelle la fonction de sauvegarde avec le tableau mis à jour
		}
	}, [watchOptions, activeOptions]);

	return (
		<WhiteContainer title="place_item_visibility_title">
			<Typography sx={{ fontSize: "12px", mt: 1 }}>
				{t("description_visibility_place_param")}
			</Typography>
			<Box sx={{ mt: 2 }}>
				{options.map((option) => (
					<Box
						key={option}
						sx={{
							display: "flex",
							alignItems: "center",
							mt: 1,
						}}
					>
						<Controller
							name={option}
							control={control}
							render={({ field }) => (
								<Switch
									checked={!field.value}
									onChange={(e) =>
										field.onChange(!e.target.checked)
									}
								/>
							)}
						/>
						<Typography sx={{ fontSize: "14px", mr: 2, ml: 2 }}>
							{t(option)}
						</Typography>
					</Box>
				))}
			</Box>
		</WhiteContainer>
	);
};

Visibility.propTypes = {
	sectionsVisibility: PropTypes.arrayOf(PropTypes.string).isRequired, // Liste des options activées par défaut
	onSave: PropTypes.func.isRequired, // Fonction de sauvegarde pour récupérer les options activées
};

export default Visibility;
