import React from "react";
import { useTranslation } from "react-i18next";
import { Box, Typography, Stack, Button } from "@mui/joy";
import { WhiteContainer } from "UI";
import { Domain } from "@mui/icons-material";
import { useHistory } from "react-router-dom";
import { AttachAPlace } from "./partials";
import Event from "models/events";

const PartnerModelHeader = ({ placeID, show, event }) => {
	const { t } = useTranslation();
	const history = useHistory();
	const [place, setPlace] = React.useState(null);
	const [openModal, setOpenModal] = React.useState(false);
	const EventEntity = new Event();
	const [saving, setSaving] = React.useState(false);

	React.useEffect(() => {
		setPlace(placeID);
	}, [placeID]);

	const attach = async (place_id) => {
		setSaving(true);
		try {
			EventEntity.placeId = place_id;
			await EventEntity.updateEvent(event.id);
			setPlace(place_id);
			history.go(0);
		} catch (e) {
			console.log(e.message);
		}
		setSaving(false);
	};

	return show ? (
		<>
			<WhiteContainer>
				<Stack>
					{place ? (
						<Button
							onClick={() =>
								history.push(`/places/view/${place}`)
							}
							variant="solid"
						>
							<Domain />
							{t("show_attachached_place")}
						</Button>
					) : (
						<Button
							onClick={() => setOpenModal(true)}
							variant="solid"
						>
							<Domain />
							{t("attached_a_place")}
						</Button>
					)}
				</Stack>
			</WhiteContainer>
			<AttachAPlace
				open={openModal}
				close={() => setOpenModal(false)}
				title={event?.name}
				attach={attach}
				saving={saving}
			/>
		</>
	) : null;
};

export default PartnerModelHeader;
