import React from "react";
import { Switch, Route } from "react-router-dom";
import { ServicesList } from "./views";

const ServicesRoutes = (props) => {
	return (
		<Switch>
			<Route exact path="/services">
				<ServicesList />
			</Route>
		</Switch>
	);
};

export default ServicesRoutes;
