import React from "react";
import { Box, Typography } from "@mui/joy";
import useProgressiveImage from "../../../../../../../utils/hooks/useProgressiveImage";

const MainCategory = ({ id, style, title, description, image, children }) => {
	return (
		<Box id={id} sx={{ mb: 2 }}>
			<Box
				sx={{
					height: "280px",
					width: "100%",
					position: "relative",

					background: `url(${
						image?.full || image
					}) center center / cover `,
				}}
			>
				<Box
					sx={{
						zIndex: 1,
						position: "absolute",
						top: "0",
						left: "0",
						background: image
							? "rgba(0,0,0,50%)"
							: style?.theme_mode === "dark"
							? "#444"
							: "#F1F1F1",
						height: "100%",
						width: "100%",
					}}
				></Box>
				<Box
					sx={{
						position: "relative",
						zIndex: 2,
						height: "100%",
						display: "flex",
						flexDirection: "column",
						alignItems: "center",
						justifyContent: "center",
						".MuiTypography-root": {
							color: image
								? "white"
								: style?.theme_mode === "dark"
								? "white"
								: "initial",
							textAlign: "center",
						},
					}}
				>
					<Typography sx={{ fontSize: "22px" }}>{title}</Typography>
					<Typography>{description}</Typography>
				</Box>
			</Box>

			{children}
		</Box>
	);
};

export default MainCategory;
