import React, { useState, useCallback, useEffect } from "react";
import {
  GoogleMap,
  Marker,
  DirectionsService,
  DirectionsRenderer,
  Autocomplete,
} from "@react-google-maps/api";
import usePlacesAutocomplete, { getGeocode } from "use-places-autocomplete";
import PlacesAutocomplete, {
  geocodeByAddress,
  getLatLng,
} from "react-places-autocomplete";
import {
  Grid,
  Box,
  Select,
  Input,
  Stack,
  Typography,
  Option,
  List,
  ListItem,
  ListItemText,
} from "@mui/joy";
import {
  DirectionsCar,
  DirectionsWalk,
  DirectionsBike,
  DirectionsTransit,
} from "@mui/icons-material";
import { useTranslation } from "react-i18next";

const TravelIcons = {
  DRIVING: <DirectionsCar />,
  WALKING: <DirectionsWalk />,
  BICYCLING: <DirectionsBike />,
  TRANSIT: <DirectionsTransit />,
};

const RouteMap = ({ destination }) => {
  const { t } = useTranslation();
  const [directions, setDirections] = useState(null);
  const [origin, setOrigin] = useState(destination);
  const [originAddress, setOriginAddress] = useState("");
  const [directionsDetail, setDirectionsDetail] = useState(null);
  const [travelMode, setTravelMode] = useState("DRIVING");
  const [markers, setMarkers] = useState([]);
  const [noRoutes, setNoRoutes] = useState(false);

  const [error, setError] = useState(null);

  const {
    ready,
    value,
    suggestions: { status, data },
    setValue,
    clearSuggestions,
  } = usePlacesAutocomplete({
    requestOptions: {
      /* Configuration optionnelle */
    },
    debounce: 300,
  });

  // Centre initial de la carte

  // Options de la carte
  const mapContainerStyle = {
    height: "452px",
    width: "95%",
  };

  const options = {
    disableDefaultUI: false,
    zoomControl: true,
  };

  const handleSelect = async (value) => {
    const results = await geocodeByAddress(value);
    const latLng = await getLatLng(results[0]);
    setOriginAddress(value);
    setOrigin(latLng);
  };

  const onLoad = useCallback(function callback(map) {
    const bounds = new window.google.maps.LatLngBounds();
    map.fitBounds(bounds);
  }, []);

  const calculateRoute = useCallback(async () => {
    setNoRoutes(false);
    if (origin || destination) {
      // Ici, utilisez DirectionsService de l'API Google Maps pour calculer l'itinéraire
      const directionsService = new window.google.maps.DirectionsService();
      directionsService.route(
        {
          origin: origin,
          destination: destination,
          travelMode,
        },
        (result, status) => {
          if (status === window.google.maps.DirectionsStatus.OK) {
            setDirections(result);
            const route = result.routes[0];
            console.log(result.routes);

            setDirectionsDetail({
              distance: route.legs[0].distance.text,
              duration: route.legs[0].duration.text,
              steps: route.legs[0].steps.map((step) => ({
                instruction: step.instructions,
                distance: step.distance.text,
              })),
            });
          } else {
            console.error(`error fetching directions ${result}`);
            setNoRoutes(true);
          }
        },
      );
    }
  }, [travelMode, origin, destination]);

  // Calculez l'itinéraire lorsque le composant est monté ou lorsque la destination change
  useEffect(() => {
    calculateRoute();
  }, [calculateRoute, origin, destination, travelMode]);

  return (
    <Box sx={{ background: "white", p: 2 }}>
      <Grid container>
        <Grid md={4}>
          <Stack direction="row" spacing={1} justifyContent="center">
            <PlacesAutocomplete
              value={originAddress}
              onChange={(e) => setOriginAddress(e)}
              onSelect={handleSelect}
            >
              {({
                getInputProps,
                suggestions,
                getSuggestionItemProps,
                loading,
              }) => (
                <Box sx={{ position: "relative" }}>
                  <Input
                    {...getInputProps({
                      placeholder: "Adresse de départ ...",
                    })}
                  />

                  {suggestions.length > 0 && (
                    <>
                      <div
                        style={{
                          background: "white",
                          border: "1px #eee solid",
                          position: "absolute",
                          padding: "8px",
                          zIndex: 1,
                          boxShadow: "0px 0px 10px #00000025",
                        }}
                      >
                        {loading ? <div>...chargement</div> : null}
                        {suggestions.map((suggestion) => {
                          const style = {
                            backgroundColor: suggestion.active
                              ? "#41b6e6"
                              : "#fff",
                            color: suggestion.active ? "#fff" : "initial",
                            cursor: "pointer",
                            padding: "5px",
                          };

                          return (
                            <div
                              {...getSuggestionItemProps(suggestion, { style })}
                            >
                              {suggestion.description}
                            </div>
                          );
                        })}
                      </div>
                    </>
                  )}
                </Box>
              )}
            </PlacesAutocomplete>
            <Select
              onChange={(e, v) => setTravelMode(v)}
              defaultValue={travelMode}
            >
              <Option value="DRIVING">Voiture</Option>
              <Option value="WALKING">Marche</Option>
              <Option value="BICYCLING">Vélo</Option>
              <Option value="TRANSIT">Transports en commun</Option>
            </Select>
          </Stack>
          <Box sx={{ m: 2, p: 1, background: "#F1F1F1" }}>
            {/* ... (GoogleMap et autres composants) */}
            {directionsDetail && (
              <div>
                <Box
                  sx={(theme) => ({
                    ".MuiTypography-root": {
                      color: "white",
                      fontSize: "19px",
                      textAlign: "center",
                    },
                    background: theme.vars.palette.primary[600],
                    p: 1,
                    mb: 2,
                    borderRadius: "10px",
                  })}
                >
                  <Box
                    sx={{
                      textAlign: "center",
                      mb: 1,
                      ".MuiSvgIcon-root": {
                        fontSize: "38px",
                        color: "white",
                      },
                    }}
                  >
                    {TravelIcons[travelMode]}
                  </Box>
                  {!noRoutes ? (
                    <>
                      <Typography> {directionsDetail.distance}</Typography>
                      <Typography>
                        <b> {directionsDetail.duration}</b>
                      </Typography>
                    </>
                  ) : (
                    <Typography> {t("no_routes_available")}</Typography>
                  )}
                </Box>
                {!noRoutes && (
                  <List sx={{ height: "250px", overflow: "auto" }}>
                    {directionsDetail.steps.map((step, index) => (
                      <ListItem key={index} sx={{ borderBottom: " 1px solid" }}>
                        <Box
                          dangerouslySetInnerHTML={{ __html: step.instruction }}
                        />
                        <Typography>
                          <b>{step.distance}</b>
                        </Typography>
                      </ListItem>
                    ))}
                  </List>
                )}
              </div>
            )}
          </Box>
        </Grid>
        <Grid md={8} sx={{ display: "flex", justifyContent: "center" }}>
          {" "}
          <GoogleMap
            mapContainerStyle={mapContainerStyle}
            origin={origin}
            zoom={7}
            onLoad={onLoad}
            options={options}
          >
            {/* Afficher le marqueur de départ */}
            <Marker position={origin} />

            {/* Afficher le marqueur de destination si défini */}
            {destination && <Marker position={destination} />}

            {/* Afficher les directions si disponibles */}
            {directions && <DirectionsRenderer directions={directions} />}

            {/* Afficher l'erreur si disponible */}
            {error && <div>{error}</div>}
          </GoogleMap>
        </Grid>
      </Grid>
    </Box>
  );
};

// Assurez-vous de fournir une instance valide pour la clé d'API dans le composant englobant ou en utilisant un provider
export default RouteMap;
