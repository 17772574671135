import React from "react";
import { useTranslation } from "react-i18next";
import { Box, Typography, Stack, Button, Chip } from "@mui/joy";
import PropTypes from "prop-types";
import SimpleTable from "UI/Tables/SimpleTable/SimpleTable";
import InfiniteScroll from "react-infinite-scroll-component";
import Helpers from "utils/Helpers";
import { WhiteContainer } from "UI";
import { useHistory } from "react-router-dom";

const UserList = ({ users, getUsers, setUsers, isMore }) => {
	const { t } = useTranslation();
	const history = useHistory();
	const TypographyStyle = {
		maxWidth: "100%",
		overflow: "hidden",
		textOverflow: "ellipsis",
	};
	const columns = [
		"created_at",
		"email",
		"name",
		"lastname",
		"phone",
		"role",
		"partner",
		"",
	];
	const actions = [
		(d) => (
			<Stack direction="row" spacing={1}>
				<Button
					onClick={() =>
						history.push({
							search: `?userDetails=true&userId=${d.id}`,
						})
					}
				>
					{t("details")}
				</Button>
			</Stack>
		),
	];
	const fields = {
		created_at: (v) => (
			<Typography sx={TypographyStyle}>
				{Helpers.fbDate(v, "DD-MM-YYYY")}
			</Typography>
		),
		email: (v) => (
			<Typography sx={TypographyStyle}>
				<a href={`mailto:${v}`}>{v}</a>
			</Typography>
		),
		name: (v) => <Typography sx={TypographyStyle}>{v}</Typography>,
		lastname: (v) => <Typography sx={TypographyStyle}>{v}</Typography>,
		phone: (v) => <Typography sx={TypographyStyle}>{v}</Typography>,
		role: (v) => (
			<Typography sx={TypographyStyle}>{v || "user"}</Typography>
		),
		isApproved: (v) => (
			<Chip color={v ? "primary" : "neutral"}>
				{!v ? t("not_approved") : t("approved")}
			</Chip>
		),
	};

	return (
		<WhiteContainer>
			<Box
				id="ed"
				sx={{ height: "50vh", width: "100%", overflowY: "scroll" }}
			>
				<InfiniteScroll
					dataLength={users.length}
					scrollableTarget="ed"
					next={getUsers}
					hasMore={isMore}
					loader={<h4>{t("loading")}...</h4>}
				>
					<SimpleTable
						inputProps={{
							stickyHeader: true,
							stickyFooter: true,
							hoverRow: true,
							borderAxis: "both",
						}}
						style={{
							maxWidth: "100%",
							overflow: "auto",
							background: "white",
							borderRadius: "10px",
							mb: 2,
						}}
						head={columns}
						datas={users}
						fields={fields}
						actions={actions}
					/>{" "}
				</InfiniteScroll>
			</Box>
		</WhiteContainer>
	);
};

export default UserList;
UserList.propTypes = {
	getUsers: PropTypes.func,
	users: PropTypes.array,
	setUsers: PropTypes.func,
};
UserList.defaultProps = {
	users: [],
};
