import React from "react";
import ContentfulClass from "../../../../utils/ContentfulClass";
import { SlideItem } from "./partials";
import { Box } from "@mui/joy";
import { Carousel } from "react-responsive-carousel";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader

const BannerSlider = ({ contentModel }) => {
	const contentful = new ContentfulClass();
	const [slides, setSlides] = React.useState(null);

	const getSlides = async () => {
		const entries = await contentful.getEntries({
			content_type: contentModel,
		});

		const parseDatas = entries.items.reduce((acc, val) => {
			acc.push({
				color: val.fields?.color,
				overlay: val.fields?.overlay,
				overlay_color: val.fields?.overlay_color,
				text_background: val.fields?.text_background,
				text_background_color: val.fields?.text_background_color,
				title: val.fields?.displayed_title,
				list: val.fields?.listeDtail,
				button: val.fields?.contenuDuBouton,
				image: val.fields?.imageDeFond.fields.file.url,
				link: val.fields?.lien,
				id: val.sys.id,
			});

			return acc;
		}, []);
		console.log(entries);
		setSlides(parseDatas);
	};

	React.useEffect(() => {
		getSlides();
	}, []);

	return (
		<Box
			sx={{
				".carousel": {
					borderRadius: "14px",
				},
			}}
		>
			<Carousel
				showStatus={false}
				showArrows={false}
				infiniteLoop
				autoPlay
				interval={4000}
			>
				{slides &&
					slides.map((slide) => (
						<SlideItem key={slide?.id} {...slide} />
					))}
			</Carousel>
		</Box>
	);
};

export default BannerSlider;
