import React from "react";
import { useTranslation } from "react-i18next";
import { Box, Typography, IconButton, Stack } from "@mui/joy";
import { Edit } from "@mui/icons-material";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";

const View = ({ group, edit }) => {
	const { t } = useTranslation();

	return (
		<>
			{group ? (
				<Stack direction="row" spacing={2} alignItems="center">
					<Typography>
						<b>{t("group")}</b> :
						<Link to={`/admin/groups/${group?.id}`}>
							{" "}
							{group?.name}
						</Link>
					</Typography>
					<IconButton color="primary" onClick={edit}>
						<Edit />
					</IconButton>
				</Stack>
			) : (
				<Stack direction="row" spacing={2} alignItems="center">
					<Typography>{t("no_groups")}</Typography>
					<IconButton color="primary" onClick={edit}>
						<Edit />
					</IconButton>
				</Stack>
			)}
		</>
	);
};

export default View;
View.propTypes = {};
View.defaultProps = {};
