import React from "react";
import { EditableTextField } from "UI";
import {
	Box,
	Input,
	FormLabel,
	FormControl,
	List,
	Stack,
	IconButton,
} from "@mui/joy";
import { Save, Edit } from "@mui/icons-material";
import { useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";

const EventPaxPartner = ({ register }) => {
	const { t } = useTranslation();

	return (
		<Box sx={{ mt: 2, mb: 1 }}>
			<>
				<Box>
					<Stack
						spacing={2}
						sx={{ maxWidth: { md: "300px", xs: "100%" } }}
					>
						<FormControl>
							<FormLabel>
								{t("event_participants_qty_min")}
							</FormLabel>
							<Input
								type="number"
								{...register("event_participants_qty_min")}
							/>
						</FormControl>

						<FormControl>
							<FormLabel>
								{t("event_participants_qty_max")}
							</FormLabel>
							<Input
								type="number"
								{...register("event_participants_qty_max")}
							/>
						</FormControl>
					</Stack>
				</Box>
			</>
		</Box>
	);
};

export default EventPaxPartner;
