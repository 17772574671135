import React from "react";
import {
  Box,
  Typography,
  Accordion,
  AccordionSummary,
  Stack,
  AccordionGroup,
  AccordionDetails,
  Chip,
} from "@mui/joy";
import FavoriteIcon from "@mui/icons-material/Favorite";
import FavoriteBorderIcon from "@mui/icons-material/FavoriteBorder";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { useTranslation } from "react-i18next";

const AdvancedAccordion = ({
  editable = false,
  data = [],
  style = {},
  selectedActions = [],
  hideNotSelected,
  onSave,
}) => {
  const { t } = useTranslation();
  const [index, setIndex] = React.useState(0);
  const [selectedActionsState, setSelectedActionsState] =
    React.useState(selectedActions);

  const isActionSelected = React.useCallback(
    (id) => selectedActionsState.includes(id),
    [selectedActionsState],
  );

  const handleActionClick = (id) => {
    if (editable) {
      const newActions = selectedActionsState.includes(id)
        ? selectedActionsState.filter((i) => i !== id)
        : [...selectedActionsState, id];

      setSelectedActionsState(newActions);
      onSave && onSave(newActions);
    }
  };

  // Rendu de chaque section (sous-catégorie) avec les éléments
  const renderSection = (title, items) => (
    <Box sx={{ mt: 2 }}>
      <Typography
        color="primary"
        level="h6"
        sx={{
          fontWeight: "bold",
          textAlign: "center",
          background: "#E1F0FB",
          borderRadius: "100px",
          padding: "8px 0",
          marginBottom: "12px",
        }}
      >
        {t(title)}
      </Typography>
      <Box sx={{ display: "flex", flexWrap: "wrap", gap: 1 }}>
        {items
          .filter((it) => (!hideNotSelected ? true : isActionSelected(it.id)))
          .map((item) => (
            <Box
              onClick={() => handleActionClick(item?.id)}
              sx={{
                width: "100%",
                "&:hover": editable && {
                  background: "#F1F1F1",
                  cursor: "pointer",
                },
                display: "flex",
                p: "3px",
                borderRadius: "5px",
                flexDirection: "row",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <Typography
                paragraph
                variant="body2"
                key={item.id}
                sx={{ width: "75%", fontSize: "13px" }}
                color={isActionSelected(item.id) ? "primary" : "neutral"}
              >
                {item.action}
              </Typography>
              {isActionSelected(item.id) ? (
                <FavoriteIcon sx={{ fontSize: 30 }} color="secondary" />
              ) : (
                <FavoriteBorderIcon sx={{ fontSize: 30 }} color="secondary" />
              )}
            </Box>
          ))}
      </Box>
    </Box>
  );

  return (
    <Box sx={{ width: "100%", mt: 3, ...style }}>
      <AccordionGroup>
        {Object.keys(data).map((category, _index) => (
          <Accordion
            expanded={index === _index + 1}
            onChange={(event, expanded) => {
              setIndex(expanded ? _index + 1 : null);
            }}
            key={category}
            sx={{
              ".MuiAccordionSummary-indicator": {
                ".MuiSvgIcon-root": {
                  color: "white",
                },
                position: "absolute",
                color: "white",
                left: "20px",
              },
              mb: 2,
            }}
          >
            <AccordionSummary
              expandIcon={<ExpandMoreIcon sx={{ color: "#FFF" }} />}
              sx={(theme) => ({
                position: "relative",
                color: "white",
                fontWeight: "bold",
                display: "flex",
                justifyContent: "center",
                borderRadius: 100,

                background: theme.vars.palette.secondary[50],
                textAlign: "center",
                ".MuiAccordionSummary-button": {
                  p: 1,
                  pl: 2,
                  pr: 2,
                  justifyContent: "space-between",
                  borderRadius: 100,
                  "&:hover": {
                    background: theme.vars.palette.secondary[50],
                    opacity: 0.8,
                  },
                },
                alignItems: "center",
              })}
            >
              <Box></Box>
              <Typography level="h4" sx={{ color: "white" }}>
                {t(category)}
              </Typography>
              <Typography
                sx={{ color: "#FFFFFF", fontWeight: "100", fontSize: "20px" }}
              >
                {
                  // Compte les actions sélectionnées dans la catégorie
                  Object.values(data[category])
                    .flat()
                    .filter((item) => isActionSelected(item.id)).length
                }
                /
                {
                  // Compte total des actions dans la catégorie
                  Object.values(data[category]).flat().length
                }
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: { xs: "column", sm: "column", md: "row" },
                  columnGap: "36px",
                }}
              >
                {Object.keys(data[category]).map((section) =>
                  renderSection(section, data[category][section]),
                )}
              </Box>
            </AccordionDetails>
          </Accordion>
        ))}
      </AccordionGroup>
    </Box>
  );
};

export default AdvancedAccordion;
