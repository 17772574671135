import React from "react";
import { useTranslation } from "react-i18next";
import { Box, Typography, Stack, Button } from "@mui/joy";
import PropTypes from "prop-types";
import { InfoBox_1 } from "UI/CallToAction";
import { AskQuote } from "../.";

import {
	AccessAlarms,
	RequestQuote,
	SupportAgent,
	Tune,
	LiveHelp,
	PeopleAlt,
} from "@mui/icons-material";
import useAuthContainer from "stores/Auth";

const WeevupCallToAction = ({ place_id }) => {
	const { userAccount } = useAuthContainer.useContainer();
	const isPartner = userAccount?.isApproved;

	const { t } = useTranslation();
	const iconsStyle = { height: "60px" };
	const datas = [
		{
			id: "FullSupportFromAtoZ",
			desc: t("FullSupportFromAtoZ"),
			icon: <SupportAgent style={iconsStyle} />,
		},
		{
			id: "CustomizableOffers",
			desc: t("CustomizableOffers"),
			icon: <Tune style={iconsStyle} />,
		},
		{
			id: "AdvisorWeever",
			desc: t("AdvisorWeever"),
			icon: <LiveHelp style={iconsStyle} />,
		},
		{
			id: "CommittedPartners",
			desc: t("CommittedPartners"),
			icon: <PeopleAlt style={iconsStyle} />,
		},
	];
	return !isPartner ? (
		<>
			<Stack spacing={2} sx={{ mb: 2, m: 2 }}>
				<AskQuote place_id={place_id} />{" "}
			</Stack>
		</>
	) : null;
};

export default WeevupCallToAction;
WeevupCallToAction.propTypes = {};
WeevupCallToAction.defaultProps = {};
