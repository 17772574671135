import * as React from 'react'
import { Box, SpeedDial, SpeedDialIcon, SpeedDialAction } from '@mui/material'
import { Settings, Close, FileCopy, Save, Print, Share } from '@mui/icons-material'
import PropTypes from 'prop-types'

const actions = [
	{ icon: <FileCopy />, label: 'copy', name: 'Copier' },
	{ icon: <Save />, label: 'save', name: 'Sauvegarder' },
	{ icon: <Print />, label: 'print', name: 'Imprimer' },
	{ icon: <Share />, label: 'share', name: 'Partager' },
]

const ActionSpeedDial = ({ actions }) => {
	return (
		<SpeedDial
			ariaLabel="SpeedDial basic example"
			sx={{
				position: 'absolute',
				bottom: 90,
				right: 25,
			}}
			icon={
				<SpeedDialIcon
					icon={<Settings color="secondary" />}
					openIcon={<Close color="secondary" />}
				/>
			}
		>
			{actions.map((action) => (
				<SpeedDialAction
					sx={{
						'.MuiSpeedDialAction-staticTooltipLabel': {
							backgroundColor: 'primary.main',
							color: 'primary.contrastText',
						},
						'.MuiSpeedDialAction-fab': {
							backgroundColor: 'secondary.main',
							color: 'primary.main',
						},
					}}
					tooltipOpen
					key={action.label}
					icon={action.icon}
					onClick={action.func}
					tooltipTitle={action.name}
				/>
			))}
		</SpeedDial>
	)
}

export default ActionSpeedDial

ActionSpeedDial.defaultProps = {
	actions: actions,
}
ActionSpeedDial.propTypes = {
	actions: PropTypes.arrayOf(PropTypes.object),
}
