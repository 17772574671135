import React from "react";
import { Box, Typography } from "@mui/joy";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";

const ResetPasswordFooter = () => {
	const { t } = useTranslation();

	return (
		<>
			<Box sx={{ textAlign: "center", mt: 2 }}>
				<Typography>
					<Link to="/login">{t("login")}</Link>
				</Typography>
				<Typography>
					<Link to="/login/create-account">
						{t("create_an_account")}
					</Link>
				</Typography>
			</Box>
		</>
	);
};

export default ResetPasswordFooter;
