import React from "react";
import { Box, Typography, Stack, IconButton } from "@mui/joy";
import { Delete, Add, Image } from "@mui/icons-material";
import { useTranslation } from "react-i18next";
import useAuthContainer from "../../../../stores/Auth";
import { Link, useParams } from "react-router-dom";
import { AddSimpleImage } from "UI";

const FeaturedImage = ({ setValue, values }) => {
	const { t } = useTranslation();
	const prevMediaLibUrlRef = React.useRef();
	const { placeID } = useParams();

	const addImage = (imageUrl) => {
		setValue("featured_image", imageUrl, {
			shouldDirty: true,
		});
	};

	return (
		<Box sx={{ background: "white", p: 2, mt: 1, mb: 1 }}>
			<AddSimpleImage
				style={{
					maxWidth: "700px",
					width: "100%",
					height: "300px",
				}}
				label={t("add_featured_image")}
				icon={<Image />}
				additionnalDatas={{ placeId: placeID }}
				remove={() => addImage(null)}
				action={(img) => addImage(img)}
				image={values}
			/>
		</Box>
	);
};

export default FeaturedImage;
