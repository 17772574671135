import React, { useState, useEffect, useCallback } from "react";
import { useTranslation } from "react-i18next";
import { Box, Typography } from "@mui/joy";
import PropTypes from "prop-types";
import Group from "models/groups";
import { View, Editable } from "./partials";

const GroupEntity = new Group();

const PlaceGroup = ({ groupId, place }) => {
	const { t } = useTranslation();
	const [editable, setEditable] = useState(false);
	const [group, setGroup] = useState(null);

	const getGroup = useCallback(async () => {
		try {
			const fetchedGroup = await GroupEntity.getGroup(groupId);
			setGroup(fetchedGroup);
		} catch (e) {
			console.error("Error fetching group:", e);
		}
	}, [groupId]);

	useEffect(() => {
		getGroup();
	}, [getGroup]);

	return (
		<>
			{editable ? (
				<Editable
					close={() => setEditable(false)}
					setGroup={setGroup}
					place={place}
				/>
			) : (
				<View edit={() => setEditable(true)} group={group} />
			)}
		</>
	);
};

PlaceGroup.propTypes = {
	groupId: PropTypes.string.isRequired,
};

PlaceGroup.defaultProps = {
	groupId: "",
};

export default PlaceGroup;
