import React from "react";
import { useTranslation } from "react-i18next";
import {
	Box,
	Typography,
	Modal,
	ModalOverflow,
	ModalDialog,
	Textarea,
	Button,
	IconButton,
} from "@mui/joy";
import PropTypes from "prop-types";
import { useForm } from "react-hook-form";
import { v4 as uuidv4 } from "uuid";
import { Delete } from "@mui/icons-material";

const Editable = ({ open, close, selected, saveNote, deleteNote }) => {
	const { t } = useTranslation();
	const { register, handleSubmit, reset } = useForm();

	React.useEffect(() => {
		if (selected) {
			reset(selected);
		} else {
			reset({
				note: "",
			});
		}
	}, [selected]);

	const save = (d) => {
		saveNote({ ...selected, ...d });
		reset({
			note: "",
		});
		close();
	};

	return (
		<Modal open={open} onClose={close}>
			<ModalOverflow>
				<ModalDialog>
					{selected && (
						<IconButton
							sx={{ position: "absolute", right: 2, top: 2 }}
							type="button"
							color="danger"
							onClick={() => {
								deleteNote(selected.id);
								close();
							}}
						>
							<Delete />
						</IconButton>
					)}
					<Typography>{t("note")}</Typography>
					<form onSubmit={handleSubmit(save)}>
						<Textarea
							sx={{ mb: 2 }}
							{...register("note")}
							minRows={4}
						/>
						{selected ? (
							<Button fullWidth type="submit">
								{t("save")}
							</Button>
						) : (
							<Button fullWidth type="submit">
								{t("create")}
							</Button>
						)}
					</form>
				</ModalDialog>
			</ModalOverflow>
		</Modal>
	);
};

export default Editable;
Editable.propTypes = {};
Editable.defaultProps = {};
