import React from "react";
import { useTranslation } from "react-i18next";
import { Box, Typography } from "@mui/joy";
import PropTypes from "prop-types";
import { useParams } from "react-router-dom";
import Planning from "models/plannings";
import { PlanningList, ElementModal } from "./partials";

const PlanningFront = ({ style }) => {
	const { t } = useTranslation();
	const { eventID } = useParams();
	const [datas, setDatas] = React.useState([]);
	const PlanningEntity = new Planning();
	const [selectedElement, setSelectedElement] = React.useState(null);

	const fetchDatas = async () => {
		try {
			PlanningEntity.getPlanningByEventId(eventID, true, (d) => {
				setDatas(d[0]);
			});
		} catch (e) {
			console.log(e.message);
		}
	};
	React.useEffect(() => {
		fetchDatas();
	}, eventID);

	return (
		<>
			{datas && (
				<>
					<PlanningList
						style={style}
						setSelectedElement={setSelectedElement}
						selectedElement={selectedElement}
						planning={datas?.content}
					/>
					<ElementModal
						t={t}
						style={style}
						setSelectedElement={setSelectedElement}
						selectedElement={selectedElement}
					/>
				</>
			)}
		</>
	);
};

export default PlanningFront;
PlanningFront.propTypes = {};
PlanningFront.defaultProps = {};
