import React from "react";
import { FormSeparator, AdvancedDateRange } from "UI";
import { Switch, Box, Typography, Stack, Input, Grid } from "@mui/joy";
import { useTranslation } from "react-i18next";
import { Controller } from "react-hook-form";

const EventDate = ({ watch, register, control, setValue, getValues }) => {
	const { t } = useTranslation();
	const [show, setShow] = React.useState(false);
	return (
		<FormSeparator title="event_date_form_create">
			<Box>
				<AdvancedDateRange
					setVal={setValue}
					control={control}
					withPortal={false}
					autoSave
					names={{
						dateStopped: "event_date_stopped",
						datePeriod: "event_date_period",
						dateDuration: "event_day_duration",
						dateStart: "event_start_date",
						dateEnd: "event_end_date",
					}}
					getVal={getValues}
				/>
			</Box>
		</FormSeparator>
	);
};

export default EventDate;
