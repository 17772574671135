import React from "react";
import { BOLayout } from "../../../../components/layouts";
import { useParams, Link } from "react-router-dom";
import { Grid, Button, Box } from "@mui/joy";
import { EditEventSettings } from "forms/events";
import Event from "models/events";
import { VisualEditor } from "Common";

const Settings = (props) => {
	const [eventDatas, setEventDatas] = React.useState(null);
	const { eventID } = useParams();

	const fetchEvent = async () => {
		const eventEntity = new Event();
		try {
			const event = await eventEntity.getEvent(eventID);
			if (event) {
				setEventDatas(event);
			}
		} catch (e) {
			console.log(e.message);
		}
	};

	React.useEffect(() => {
		fetchEvent();
	}, []);

	return (
		<>
			<BOLayout
				documentID={eventID}
				isDocumentStatusMonitoring
				showEventMenu
				title="settings"
			>
				{eventDatas && (
					<Box sx={{ gap: 4, display: "flex", flexDirection: "row" }}>
						<Box sx={{ flexGrow: 1 }}>
							<EditEventSettings datas={eventDatas} />
						</Box>
						<VisualEditor
							src={`${process.env.REACT_APP_base_url}/my_event/${eventID}`}
						/>
					</Box>
				)}
			</BOLayout>
		</>
	);
};

export default Settings;
