import React from "react";
import { Box, Typography, Chip, Stack } from "@mui/joy";
import { useTranslation } from "react-i18next";
import Helpers from "utils/Helpers";
import { LocationOn, ChevronRight } from "@mui/icons-material";
import { Link, useParams } from "react-router-dom";

const Element = ({
	style,
	image,
	title,
	id,
	eventUID,
	description,
	location,
	start_hour,
	link_to_section,
	visibility,
	end_hour,
	selectElement,
	isSubcatecoryItem,
}) => {
	const { eventID } = useParams();
	const { t } = useTranslation();
	const dark = style?.theme_mode === "dark";
	const isVisible = Helpers.isElementVisible(
		visibility?.visibility,
		visibility?.visibility_date,
	);
	const LinkToSection = () => {
		if (link_to_section?.section && link_to_section?.item) {
			return (
				<Box
					sx={(theme) => ({
						mt: 1,
						mr: 2,
						p: 1,
						background:
							style?.theme_mode === "dark" ? "none" : "#FAFAFA",
						borderRadius: 5,
						a: {
							color:
								style?.theme_color ||
								theme.vars.palette.primary[400],
						},
					})}
				>
					<Typography>
						<Link
							to={`/my_event/${eventID}/${link_to_section.section.toLowerCase()}#${
								link_to_section.item
							}`}
						>
							{t("show")}
							{t(link_to_section.itemName)}
							<ChevronRight />
						</Link>
					</Typography>
				</Box>
			);
		}
	};

	return (
		isVisible && (
			<Box
				id={id}
				sx={{
					width: "92%",
					m: "0 auto",
					borderBottom: "1px solid #eee",
					background: dark
						? "#111"
						: isSubcatecoryItem
						? style?.no_theme_color_background
							? "white"
							: `${style?.theme_color}14`
						: "white",
					borderRadius: "10px",
					mt: 1,
					border: dark ? "none" : "1px solid #eee",
				}}
				onClick={selectElement}
			>
				<Stack
					direction="row"
					spacing={1}
					sx={{
						width: "100%",
						overflow: "auto",
						position: "relative",
						pt: 2,
						pl: 2,
					}}
				>
					{(start_hour || end_hour) && (
						<Chip>
							{start_hour && !end_hour && (
								<>
									{t("à ")}
									<b> {start_hour.replace(":", "h")}</b>
								</>
							)}
							{end_hour && !start_hour && (
								<>
									{t("jusque ")}
									<b>{end_hour.replace(":", "h")}</b>
								</>
							)}
							{start_hour && end_hour && (
								<>
									{t("de ")}
									<b>{start_hour.replace(":", "h")}</b>
									{t(" à ")}
									<b>{end_hour.replace(":", "h")}</b>
								</>
							)}
						</Chip>
					)}
					{location && (
						<Chip>
							<LocationOn />
							{location}
						</Chip>
					)}
				</Stack>
				<Box
					sx={{
						p: "18px 18px",
						display: "flex",
						justifyContent: "space-between",
						alignItems: "center",
						".MuiTypography-root": {
							color:
								style?.theme_mode === "dark"
									? "white"
									: "initial",
						},
					}}
				>
					<Box sx={{ maxWidth: image ? "70%" : "100%" }}>
						<Typography
							sx={{
								fontSize: "16px",
								mb: 1,
								fontWeight: "bold",
								maxWidth: "calc(100vw - 151px)",
								whiteSpace: "nowrap",
								textOverflow: "ellipsis",
								overflow: "hidden",
							}}
						>
							{title}
						</Typography>

						<Typography
							sx={{
								maxWidth: image && "calc(100vw - 151px)",
								whiteSpace: "nowrap",
								textOverflow: "ellipsis",
								overflow: "hidden",
							}}
						>
							{description}
						</Typography>
						{LinkToSection()}
					</Box>
					<Box
						sx={{
							borderRadius: "12px",
							width: "80px",
							height: "80px",
							background: `url("${
								image?.thumb || image?.full || image
							}") no-repeat center center / cover`,
						}}
					/>
				</Box>
			</Box>
		)
	);
};

export default Element;
