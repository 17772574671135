import React from "react";
import { auth } from "../../../utils/MyFirebase";
import useAuthContainer from "../../../stores/Auth";
import { signOut } from "firebase/auth";

import {
	updateProfile,
	GoogleAuthProvider,
	FacebookAuthProvider,
	reauthenticateWithPopup,
	reauthenticateWithCredential,
	verifyPasswordResetCode,
	confirmPasswordReset,
	signInWithEmailAndPassword,
	EmailAuthProvider,
} from "firebase/auth";
import { useSnackbar } from "notistack";
import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";

const useAuthProfile = () => {
	const { t } = useTranslation();
	const history = useHistory();
	const { credentials } = useAuthContainer.useContainer();
	const { enqueueSnackbar } = useSnackbar();
	const [errors, setErrors] = React.useState();
	const [changingPassword, setChangingPassword] = React.useState(false);
	const [loading, setLoading] = React.useState(false);

	const updateUserProfile = async (datas, email = null) => {
		setLoading(true);
		try {
			await updateProfile(auth.currentUser, datas);
		} catch (error) {
			enqueueSnackbar(t(error.code), { variant: "error" });
			setErrors({
				errorCode: error.code,
				errorMessage: error.message,
			});
		}
		setLoading(false);
	};

	const reauth = async () => {
		const { providerId } = auth.currentUser.providerData[0];
		let r;
		try {
			if (providerId === "password") {
				if (credentials) {
					r = await reauthenticateWithCredential(
						auth.currentUser,
						credentials
					);
				}
			} else if (providerId === "google.com") {
				r = await reauthenticateWithPopup(
					auth.currentUser,
					new GoogleAuthProvider()
				);
			} else if (providerId === "facebook.com") {
				r = await reauthenticateWithPopup(
					auth.currentUser,
					new FacebookAuthProvider()
				);
			}
		} catch (e) {
			return;
		}
	};
	const deleteUser = async () => {
		try {
			await reauth();
			try {
				await auth.currentUser.delete();
				enqueueSnackbar(t("account_deleted"), { variant: "success" });
				history.push("/");
			} catch (e) {
				return;
			}
		} catch (e) {
			console.log("delete==>", e);
			enqueueSnackbar(t(e.code), { variant: "error" });
			return;
		}
	};
	const changePassword = async (actionCode, newPassword) => {
		setChangingPassword(true);
		try {
			const email = await verifyPasswordResetCode(auth, actionCode);
			const resp = await confirmPasswordReset(
				auth,
				actionCode,
				newPassword
			);
			await signInWithEmailAndPassword(auth, email, newPassword);
			history.push("/profile");
		} catch (e) {
			enqueueSnackbar(t(e.code), { variant: "error" });
		}
		setChangingPassword(false);
	};

	const logout = async () => {
		signOut(auth)
			.then(() => {})
			.catch((e) => {
				enqueueSnackbar(t(e.code), { variant: "error" });
			});
	};

	return {
		updateUserProfile,
		logout,
		loading,
		errors,
		reauth,
		deleteUser,
		changePassword,
		changingPassword,
	};
};

export default useAuthProfile;
