import React from "react";
import {
	Switch,
	Input,
	Stack,
	Button,
	IconButton,
	FormControl,
	FormLabel,
	List,
	ListItem,
	Typography,
	Box,
	Select,
	Option,
} from "@mui/joy";
import { Save, Edit } from "@mui/icons-material";
import { useTranslation } from "react-i18next";
import moment from "moment";
import {
	DateRangePicker,
	SingleDatePicker,
	DayPickerRangeController,
} from "react-dates";
import useMediaQuery from "@mui/material/useMediaQuery";

import { Controller } from "react-hook-form";
import Helpers from "../../../utils/Helpers";

moment.locale("fr-fr");

const AdvancedDateRange = ({
	setVal,
	names,
	action,
	getVal,
	control,
	autoSave,
	...props
}) => {
	const { t } = useTranslation();
	const [dateStopped, setDateStopped] = React.useState(
		getVal(names?.dateStopped),
	);
	const matches = useMediaQuery("(max-width:500px)");
	const [startDate, setStartDate] = React.useState(
		moment(getVal(names?.dateStart)?.toDate()),
	);
	const [endDate, setEndDate] = React.useState(
		moment(getVal(names?.dateEnd)?.toDate()),
	);
	const [focusedInput, setFocusedInput] = React.useState(null);
	const [editable, setEditable] = React.useState(autoSave ? true : false);

	const months = (years = 3) => {
		const m = moment().month();
		const mths = moment.months();
		const year = moment().year();
		let monthList = [];
		for (let i = 0; i < years; i++) {
			const a = mths.map((m) => ({
				label: `${t(m)} ${year + i}`,
				value: `${m}_${year + i}`,
			}));
			monthList = [...monthList, ...a];
		}

		for (let i = 1; i < m; i++) {
			monthList.pop();
		}
		monthList.splice(0, m);

		return monthList;
	};

	console.log(months());

	return (
		<>
			<Stack direction="row" alignItems="baseline" spacing={4}>
				{editable ? (
					<Box>
						<Typography
							sx={{ mb: 2 }}
							component="label"
							startDecorator={
								<Switch
									checked={dateStopped}
									onChange={(e) => {
										setDateStopped(e.target.checked);
										names?.dateStopped &&
											setVal(
												names?.dateStopped,
												e.target.checked,
											);
									}}
									sx={{ ml: 1 }}
								/>
							}
						>
							{t("date_is_selected")}
						</Typography>

						{!dateStopped ? (
							<Box>
								<FormControl sx={{ mb: 2 }}>
									<FormLabel>{t("period")} ? </FormLabel>
									<Controller
										control={control}
										name={names?.datePeriod}
										render={({
											field: {
												value,
												onChange,
												ref,
												onBlur,
											},
										}) => (
											<select
												onChange={onChange}
												onBlur={onBlur}
												value={value}
												ref={ref}
												style={{
													border: "1px solid #DDD",
													fontSize: "16px",
													borderRadius: "3px",
													padding: "7px 3px",
													boxShadow:
														"0px 1px 2px #00000017",
												}}
											>
												{months().map((m) => (
													<option
														key={m.value}
														value={m.value}
													>
														{m?.label}
													</option>
												))}
											</select>
										)}
									/>
								</FormControl>
								<FormControl sx={{ mb: 2 }}>
									<FormLabel>{t("duration")} ? </FormLabel>

									<Typography
										startDecorator={
											<Controller
												name={names?.dateDuration}
												control={control}
												render={({
													field: {
														value,
														onChange,
														onBlur,
													},
												}) => (
													<Input
														onChange={(e) => {
															onChange(
																e.target.value,
															);
														}}
														onBlur={onBlur}
														value={value}
														type="number"
													/>
												)}
											/>
										}
									>
										{t("day(s)")}
									</Typography>
								</FormControl>
							</Box>
						) : (
							<Box
								sx={{
									".DateRangePicker_picker": {
										zIndex: 10000,
									},
									".DateRangePicker_picker__portal": {
										zIndex: 100,
									},
								}}
							>
								<DateRangePicker
									orientation={
										matches ? "vertical" : "horizontal"
									}
									endDatePlaceholderText={t("end")}
									startDatePlaceholderText={t("start")}
									{...props}
									startDate={startDate}
									startDateId="s_id"
									endDate={endDate}
									endDateId="e_id"
									onDatesChange={({ startDate, endDate }) => {
										setStartDate(startDate);
										setEndDate(endDate);
										setVal(
											names?.dateStart,
											startDate || null,
										);
										setVal(names?.dateEnd, endDate || null);
									}}
									focusedInput={focusedInput}
									onFocusChange={(e) => setFocusedInput(e)}
									displayFormat="DD/MM/YYYY"
								/>
							</Box>
						)}
					</Box>
				) : (
					<Box sx={{ display: autoSave && "none" }}>
						{getVal(names?.dateStopped) ? (
							<Box>
								<List>
									<ListItem>
										{t("start")} :{" "}
										{Helpers.fbDate(
											getVal(names?.dateStart),
											"DD/MM/YYYY",
										)}
									</ListItem>
									<ListItem>
										{t("end")} :{" "}
										{Helpers.fbDate(
											getVal(names?.dateEnd),
											"DD/MM/YYYY",
										)}
									</ListItem>
								</List>
							</Box>
						) : (
							<Box>
								<List>
									<ListItem>
										<b>{t("period")}</b> :
										{
											months().find(
												(i) =>
													i.value ===
													getVal(names?.datePeriod),
											)?.label
										}
									</ListItem>
									<ListItem>
										<b>{t("duration")}</b> :{" "}
										{getVal(names?.dateDuration)}{" "}
										{t("day(s)")}
									</ListItem>
								</List>
							</Box>
						)}
					</Box>
				)}
				<Box sx={{ display: autoSave && "none" }}>
					{editable ? (
						<IconButton
							onClick={() => {
								setEditable(false);
								action && action();
							}}
						>
							<Save />
						</IconButton>
					) : (
						<IconButton
							sx={{ background: "none" }}
							onClick={() => {
								setEditable(true);
							}}
						>
							<Edit />
						</IconButton>
					)}
				</Box>
			</Stack>
		</>
	);
};

export default AdvancedDateRange;
