import { useState } from "react";
import { signInWithPopup, GoogleAuthProvider } from "firebase/auth";
import { useSnackbar } from "notistack";
import { useTranslation } from "react-i18next";
import { auth } from "../../../utils/MyFirebase";

const provider = new GoogleAuthProvider();

const useGoogleAuth = () => {
  const [user, setUser] = useState();
  const [errors, setErrors] = useState();
  const [signingIn, setSigningIn] = useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const { t } = useTranslation();

  const signIn = async () => {
    setSigningIn(true);
    try {
      const _signin = await signInWithPopup(auth, provider);
      setUser(_signin.user);
    } catch (error) {
      enqueueSnackbar(t("singin_with_google_failed"), { variant: "error" });
      setErrors({
        errorCode: error.code,
        errorMessage: error.message,
        email: error.email,
      });
      setSigningIn(false);
    }
  };

  return {
    signIn,
    errors,
    user,
    signingIn,
  };
};

export default useGoogleAuth;
