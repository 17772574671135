import React from "react";
import {
	Modal,
	ModalDialog,
	ModalClose,
	Box,
	Input,
	FormControl,
	FormLabel,
	Button,
	Typography,
} from "@mui/joy";
import useBuilderContainer from "../../../../BuilderStore";
import { useTranslation } from "react-i18next";
import Helpers from "../../../../../../../utils/Helpers";

const CreateElement = (props) => {
	const { t } = useTranslation();
	const close = () => setCreateElementType(null);
	const [title, setTitle] = React.useState("");
	const titleRef = React.useRef(null);
	const {
		createElementType,
		setCreateElementType,
		contentState,
		setMainParent,
		config,
		setContentState,
		selected,
		setSelected,
	} = useBuilderContainer.useContainer();

	React.useEffect(() => {
		setTitle("");
	}, [createElementType]);

	const handleCreateElement = (e) => {
		e.preventDefault();
		let ID = Helpers.uuid();

		if (selected) {
			let el = {
				...Helpers.deepArraySearch(contentState, selected),
			};

			el.children.push({
				id: ID,
				title,
				type: createElementType,
				children: [],
			});
			setContentState(
				Helpers.deepArrayUpdate(contentState, el, selected),
			);
		} else {
			setContentState([
				...contentState,
				{
					id: ID,
					title,
					type: createElementType,
					children: [],
				},
			]);
			setSelected(ID);
			setMainParent(ID);
		}

		close();
	};
	return (
		<Modal size="lg" open={Boolean(createElementType)} onClose={close}>
			<ModalDialog
				aria-labelledby="layout-modal-title"
				aria-describedby="layout-modal-description"
				layout="center"
				sx={{
					width: "70%",
				}}
			>
				<ModalClose />

				<Typography id="layout-modal-title" component="h2">
					{t("create_a", {
						type: t(
							config?.terms[createElementType] ||
								createElementType,
						),
					})}
				</Typography>

				<form onSubmit={handleCreateElement}>
					<Box sx={{ mb: 2 }}>
						<Input
							required
							onChange={(e) => setTitle(e.target.value)}
							value={title}
							placeholder={t(
								config?.terms[
									`${createElementType}_placeholder`
								] || "title",
							)}
						/>
					</Box>

					<Button fullWidth type="submit">
						{t("create_element_button")}
					</Button>
				</form>
			</ModalDialog>
		</Modal>
	);
};

export default CreateElement;
