import React from "react";
import { createContainer } from "unstated-next";

const useFrontStore = createContainer((props) => {
	const [eventDatas, setEventDatas] = React.useState(null);
	const [planningsDatas, setPlanningsDatas] = React.useState([]);

	return { eventDatas, setEventDatas, setPlanningsDatas, planningsDatas };
});

export default useFrontStore;

export const FrontStoreContainer = (Component) => (props) => {
	return (
		<useFrontStore.Provider>
			<Component {...props} />
		</useFrontStore.Provider>
	);
};
