import React, { useRef } from "react";
import { useGeoSearch } from "react-instantsearch";
import { Box } from "@mui/joy";
import MapWrapper from "./MapWrapper"; // Assurez-vous que le chemin est correct

const PlaceMap = ({ props, selected, mapCenter }) => {
	const { items, refine } = useGeoSearch(props);
	const markerRefs = useRef({}); // Définir markerRefs

	const handleMapReady = (mapInstance) => {
		console.log("Map is ready:", mapInstance);
	};

	return (
		<Box sx={{ height: "100%" }}>
			{mapCenter && mapCenter.lat && mapCenter.lng ? (
				<MapWrapper
					mapCenter={mapCenter}
					onMapReady={handleMapReady}
					refine={refine}
					items={items}
					markerRefs={markerRefs}
				/>
			) : (
				<div>Loading...</div>
			)}
		</Box>
	);
};

export default PlaceMap;
