import React from "react";
import { useTranslation } from "react-i18next";
import { Box, Typography } from "@mui/joy";
import PropTypes from "prop-types";
import { useHistory, useParams } from "react-router-dom";
import { Settings } from "forms/places";
import Place from "models/places";

const PlaceEntity = new Place();

const PlaceSettings = ({ setDocId, setTitle }) => {
	const { t } = useTranslation();
	const { placeID } = useParams();
	const [datas, setDatas] = React.useState(null);
	const [err, setErr] = React.useState(null);

	const fetchPlace = async () => {
		const place = await PlaceEntity.getPlace(placeID);
		if (place?.id) {
			setDatas(place);
		}
	};

	React.useEffect(() => {
		setTitle("place_settings");
		setDocId(placeID);
		fetchPlace();
	}, [placeID]);

	return (
		datas && (
			<Box>
				<Settings datas={datas} setDatas={setDatas} />
			</Box>
		)
	);
};

export default PlaceSettings;
PlaceSettings.propTypes = {};
PlaceSettings.defaultProps = {};
