import React from "react";
import { useTranslation } from "react-i18next";
import { Box, Typography, Grid } from "@mui/joy";
import PropTypes from "prop-types";
import { AccessTime, Person, Settings, Percent } from "@mui/icons-material";

const InfoBox_1 = ({ content }) => {
	const { t } = useTranslation();

	return (
		<Box
			sx={{
				width: "100%",
				overflow: "hidden",
				p: 2,
				boxShadow: 1,
				borderRadius: "sm",
				bgcolor: "#F1F1F1",
				borderRadius: 20,
			}}
		>
			<Grid container spacing={2}>
				{content &&
					content.map((item) => (
						<Grid key={item.id} item xs={12} sm={6}>
							<Box
								display="flex"
								flexDirection="column"
								alignItems="center"
								gap={2}
								sx={(theme) => ({
									".MuiSvgIcon-root ": {
										fontSize: "40px",
										color: theme.vars.palette.primary[500],
									},
								})}
							>
								{item?.icon}
								<Typography
									level="body2"
									sx={{ textAlign: "center" }}
								>
									{item?.desc}
								</Typography>
							</Box>
						</Grid>
					))}
			</Grid>
		</Box>
	);
};

export default InfoBox_1;

InfoBox_1.propTypes = {};
