import React from "react";
import { BOLayout } from "../../../../components/layouts";
import { Header, Content } from "./partials";
import { WhiteContainer } from "../../../../components/UI";
import useEventStore, { EventStoreContainer } from "../../EventStore";
import { useParams } from "react-router-dom";

const ListEvent = (props) => {
	let { eventID } = useParams();
	const { docStatus } = useEventStore.useContainer();
	return (
		<BOLayout
			isDocumentStatusMonitoring
			documentStatus={docStatus}
			title="my_events"
		>
			<Header />
			<WhiteContainer>
				<Content />
			</WhiteContainer>
		</BOLayout>
	);
};

export default ListEvent;
