import React from "react";
import { useTranslation } from "react-i18next";
import { Box, Typography, Input } from "@mui/joy";
import PropTypes from "prop-types";

const BackgroundColor = ({ setValue, color }) => {
	const { t } = useTranslation();

	return (
		<>
			<Box
				sx={{
					display: "flex",
					flexDirection: "row",
					alignItems: "center",
					gap: 1,
					textAlign: "center",
				}}
			>
				<Input
					sx={{
						boxShadow: "none",
						border: "none",
						margin: 0,
						p: 0,
					}}
					type="color"
					value={color}
					onChange={(e) => {
						setValue("backgroundOptions", {
							color: e.target.value,
						});
					}}
				/>
				<Typography>{t("qr_code_background_style")}</Typography>
			</Box>
		</>
	);
};

export default BackgroundColor;
BackgroundColor.propTypes = {};
BackgroundColor.defaultProps = {};
