import React from "react";
import useCVContainer from "../../../CVStore";
import { Stack, Grid, Box, Typography } from "@mui/joy";
import { useTranslation } from "react-i18next";

const Experiences = (props) => {
	const { t } = useTranslation();
	const { experiences, color } = useCVContainer.useContainer();

	return (
		<>
			<Typography
				sx={{
					textTransform: "uppercase",
					mb: 2,
					mt: 8,
					"&::after": {
						content: '""',
						position: "relative",
						width: "40%	",
						display: "block",
						height: "2px",
						backgroundColor: color,
					},
				}}
				level="h4"
			>
				{t("Experiences")}
			</Typography>
			{experiences.map((xp) => (
				<Box sx={{ mb: 3 }}>
					<Stack direction="row" spacing={3}>
						{xp?.period && (
							<Stack
								direction="column"
								justifyContent="center"
								alignItems="center"
								sx={{
									background: "#f1F1F1",
									padding: "4px",
									borderRadius: "11px",
								}}
							>
								<Typography
									sx={{
										fontSize: "13px",
										fontWeight: "bold",
									}}
								>
									{(xp?.period && parseInt(xp?.period[0])) ||
										""}
								</Typography>
								{xp?.period && xp?.period[1] && (
									<Typography>-</Typography>
								)}
								<Typography
									sx={{
										fontSize: "13px",
										fontWeight: "bold",
									}}
								>
									{(xp?.period && parseInt(xp?.period[1])) ||
										""}
								</Typography>
							</Stack>
						)}
						<Box>
							<Typography level="h5" sx={{ color }}>
								{xp?.title}
							</Typography>
							<Typography level="body2" sx={{ mb: 1 }}>
								<b> {xp?.compagny_name}</b>
							</Typography>
							<Typography level="body2">
								{xp?.description}
							</Typography>
							<ul>
								{xp?.children.map((task) => (
									<li>
										<Typography level="body2">
											{task?.title}
										</Typography>
										<Typography level="body3">
											{task?.description}
										</Typography>
									</li>
								))}
							</ul>
						</Box>
					</Stack>
				</Box>
			))}
		</>
	);
};

export default Experiences;
