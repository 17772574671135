import React from "react";
import { useTranslation } from "react-i18next";
import { Box, Typography } from "@mui/joy";
import PropTypes from "prop-types";
import Icons from "assets/icons/Icons";

const NbrSalles = ({ nbr_salle_plus_50, nbr_salle_minus_50 }) => {
	const { t } = useTranslation();
	const childContainerStyle = (theme) => ({
		textAlign: "center",
		display: "flex",
		flexDirection: "column",
		p: 1,
		borderRadius: "5px",
		border: "1px solid #EEE",
		".label": {
			color: theme.vars.palette.primary[500],
		},
		".roomSize": {
			fontWeight: "bold",
		},
	});
	return nbr_salle_plus_50 || nbr_salle_minus_50 ? (
		<>
			<Box
				sx={{
					display: "flex",

					justifyContent: "center",
					gap: 3,
				}}
			>
				<Box
					sx={(theme) => ({
						...childContainerStyle(theme),
						display: !nbr_salle_plus_50 && "none",
					})}
				>
					<Typography>
						<span className="label">{nbr_salle_plus_50}</span>{" "}
						{t("salles")}
					</Typography>
					<Typography className="roomSize">{t("+_50m2")}</Typography>
				</Box>
				<Box
					sx={(theme) => ({
						...childContainerStyle(theme),
						display: !nbr_salle_minus_50 && "none",
					})}
				>
					<Typography>
						<span className="label">{nbr_salle_minus_50}</span>{" "}
						{t("salles")}
					</Typography>
					<Typography className="roomSize">{t("-_50m2")}</Typography>
				</Box>
			</Box>
		</>
	) : null;
};

export default NbrSalles;
NbrSalles.propTypes = {};
NbrSalles.defaultProps = {};
