import React from "react";
import { useTranslation } from "react-i18next";
import { Box, Typography } from "@mui/joy";
import PropTypes from "prop-types";
import { PlanningFront } from "modules/planning";

const Planning = ({ style }) => {
	const { t } = useTranslation();

	return (
		<>
			<PlanningFront style={style} />
		</>
	);
};

export default Planning;
Planning.propTypes = {};
Planning.defaultProps = {};
