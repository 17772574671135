import React from "react";
import { Controller } from "react-hook-form";
import { FormControl, FormLabel, Select, Option, Typography } from "@mui/joy";
import { useTranslation } from "react-i18next";

const fonts = [
	"Arial",
	"Helvetica",
	"Barlow",
	"Anton",
	"Roboto",
	"Montserrat",
	"Poppins",
	"Lato",
	"Oswald",
	"Playfair",
	"Nunito",
	"Kanit",
	"Source Code Pro",
	"Ubunto Sans",
	"Freeman",
];

const EventThemeFont = ({ control }) => {
	const { t } = useTranslation();
	return (
		<Controller
			control={control}
			name="theme_font"
			render={({ field: { onChange, value, ref, defaultValues } }) => {
				return (
					<FormControl sx={{ width: 200, maxWidth: "100%" }}>
						<Select
							onChange={onChange}
							value={value}
							inputRef={ref}
							onChange={(e, v) => {
								onChange(v);
							}}
						>
							{fonts.map((font) => (
								<Option value={font}>
									<Typography sx={{ fontFamily: font }}>
										{font}
									</Typography>
								</Option>
							))}
						</Select>
					</FormControl>
				);
			}}
		/>
	);
};

export default EventThemeFont;
