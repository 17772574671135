import React from "react";
import { useTranslation } from "react-i18next";
import PropTypes from "prop-types";
import {
	IconButton,
	Stack,
	Box,
	Input,
	Grid,
	Typography,
	List,
	ListItem,
} from "@mui/joy";
import moment from "moment";
import { Edit, Save } from "@mui/icons-material";
import { Controller } from "react-hook-form";

const info = {
	no_date: "no_dates_added",
};

const EditableDateRangeField = ({
	transformFunction,
	transformFunctionInput,
	control,
	withReactHookForm,
	action,
	startDate,
	endDate,
	startDateName,
	endDateName,
}) => {
	const { t } = useTranslation();
	const [editable, setEditable] = React.useState(false);

	return (
		<>
			{editable ? (
				<Stack direction="row" spacing={3} alignItems="center">
					<Box>
						<Grid container spacing={1} sx={{ mt: 2 }}>
							<Grid md={6}>
								<Controller
									control={control}
									name={startDateName}
									render={({
										field: { onChange, value, ref },
									}) => {
										return (
											<Input
												type="date"
												onChange={onChange}
												value={transformFunctionInput(
													value
												)}
												inputRef={ref}
											/>
										);
									}}
								/>
							</Grid>
							<Grid md={6}>
								<Controller
									control={control}
									name={endDateName}
									render={({
										field: { onChange, value, ref },
									}) => (
										<Input
											type="date"
											onChange={onChange}
											value={transformFunctionInput(
												value
											)}
											inputRef={ref}
										/>
									)}
								/>
							</Grid>
						</Grid>
					</Box>
					<Box>
						<IconButton sx={{ background: "white" }}>
							<Save
								onClick={() => {
									action &&
										(withReactHookForm
											? action()()
											: action());
									setEditable(false);
								}}
							/>
						</IconButton>
					</Box>
				</Stack>
			) : (
				<Stack direction="row" spacing={3} alignItems="center">
					<Box>
						<List>
							<ListItem>
								<b>{t("start")}</b> :{" "}
								{transformFunction(startDate)}
							</ListItem>
							<ListItem>
								<b>{t("end")}</b> : {transformFunction(endDate)}
							</ListItem>
						</List>
					</Box>
					<Box>
						<IconButton
							onClick={() => setEditable(true)}
							sx={{ background: "white" }}
						>
							<Edit />
						</IconButton>
					</Box>
				</Stack>
			)}
		</>
	);
};

export default EditableDateRangeField;
