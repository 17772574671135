import React from "react";
import { useTranslation } from "react-i18next";
import { Box, Typography, Container, Stack, Button } from "@mui/joy";
import PropTypes from "prop-types";

const Footer = ({ loading, cancelAction }) => {
	const { t } = useTranslation();

	return (
		<>
			<Box
				sx={{
					background: "white",
					position: "fixed",
					width: "100%",
					bottom: "0",
					zIndex: 200,
					left: 0,
					boxShadow: " 4px 4px 15px 0px rgba(0, 0, 0, 0.05)",
				}}
			>
				<Container maxWidth="md">
					<Stack
						sx={{ p: 2 }}
						direction="row"
						justifyContent="space-between"
					>
						<Button onClick={cancelAction} color="neutral">
							{t("cancel")}
						</Button>
						<Button
							loading={loading}
							disabled={loading}
							type="submit"
						>
							{t("create_new_event")}
						</Button>
					</Stack>
				</Container>
			</Box>
		</>
	);
};

export default Footer;
Footer.propTypes = {};
Footer.defaultProps = {};
