import React from "react";
import { useTranslation } from "react-i18next";
import { Box, Typography, Button } from "@mui/joy";
import PropTypes from "prop-types";
import { Builder } from "Common";
import { WhiteContainer } from "UI";
import Meals from "models/meals";

const MealsBuilder = ({ datas }) => {
	const { t } = useTranslation();
	const [stateDatas, setStateDatas] = React.useState(datas);
	const [saving, setSaving] = React.useState(false);

	const update = async () => {
		setSaving(true);
		try {
			const mealsEntity = new Meals(stateDatas);
			const update = await mealsEntity.updateMeals(datas?.id);
		} catch (e) {
			console.log(e.message);
		}
		setSaving(false);
	};

	return (
		<Box sx={{ position: "relative" }}>
			<WhiteContainer style={{ textAlign: "center" }}>
				<Button loading={saving} disabled={saving} onClick={update}>
					{t("save")}
				</Button>
			</WhiteContainer>
			<Builder
				content={stateDatas?.content || []}
				action={(d) => setStateDatas({ ...stateDatas, content: d })}
				editorConfig={{
					modalSize: 700,
					limitDepth: 3,
					itemsConfig: {
						default: {
							cardFields: ["title"],
							terms: {
								type: "meal",
								type_plural: "meals",
								create_new_item: "create_new_meal",
								create_new_children: "create_new_meal",
								detail_tab: "item_details",
								children_type: "item",
								content_tab: "item_content",
							},
							editor: {
								hasChildren: false,
								fields: [
									{
										label: "image",
										type: "simple-image",
										rules: {
											required: false,
										},
									},
									{
										label: "title",
										type: "text",
										rules: {
											required: true,
										},
									},
									{
										label: "description",
										type: "textarea",
										rules: {
											required: false,
										},
									},
									{
										label: "start_hour",
										type: "time",
										rules: {
											required: false,
										},
									},
									{
										label: "end_hour",
										type: "time",
										rules: {
											required: false,
										},
									},
								],
							},
						},
						depth_1: {
							cardFields: ["title"],
							ifNochilds: "default",
							terms: {
								type: "category",
								type_plural: "categories",
								create_new_item: "add_category_or_meal",
								create_new_children: "create_new_element",
								detail_tab: "category_details",
								children_type: "element",
								content_tab: "category_content",
							},
							editor: {
								hasChildren: true,
								fields: [
									{
										label: "image",
										type: "simple-image",
										rules: {
											required: false,
										},
									},
									{
										label: "title",
										type: "text",
										rules: {
											required: true,
										},
									},
									{
										label: "description",
										type: "textarea",
										rules: {
											required: false,
										},
									},
								],
							},
						},
						depth_2: {
							ifNochilds: "default",

							cardFields: ["title"],
							terms: {
								type: "sub_category",
								type_plural: "sub_category",
								create_new_item:
									"create_new_sub_category_or_meal",
								create_new_children: "create_new_meal",
								detail_tab: "sub_category_detail",
								children_type: "meals",
								content_tab: "item_content",
							},
							editor: {
								hasChildren: true,
								fields: [
									{
										label: "image",
										type: "simple-image",
										rules: {
											required: false,
										},
									},
									{
										label: "title",
										type: "text",
										rules: {
											required: true,
										},
									},
									{
										label: "description",
										type: "textarea",
										rules: {
											required: false,
										},
									},
								],
							},
						},
					},
				}}
			/>
		</Box>
	);
};

export default MealsBuilder;

MealsBuilder.propTypes = {
	datas: PropTypes.object.isRequired,
};
MealsBuilder.defaultProps = {
	datas: {},
};
