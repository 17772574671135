import React from "react";
import { ServicesListing } from "./partials";
import { Box } from "@mui/joy";
import { BOLayout } from "../../../../components/layouts";
import { Banners } from "../../../.";

const ServicesList = () => {
	return (
		<BOLayout title={"services"}>
			<Banners name="banner_1" contentFullID={"3g9OnS0He0nEsCd6V13Acx"} />
			<ServicesListing />
		</BOLayout>
	);
};

export default ServicesList;
