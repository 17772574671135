import React from "react";
import { Button, Typography, Divider } from "@mui/joy";
import { useTranslation } from "react-i18next";
import { ButtonActionConfirm } from "../../../../../../components/UI/Modals";
import useAuthProfile from "../../../../../authentification/hooks/useAuthProfile";

const DeleteAccount = (props) => {
	const { t } = useTranslation();
	const [anchor, setAnchor] = React.useState(null);
	const { deleteUser, logout } = useAuthProfile();

	return (
		<>
			<Button
				onClick={(e) => setAnchor(e.currentTarget)}
				sx={{ mt: 2, mb: 2 }}
				color="danger"
			>
				{t("delete_my_account")}
			</Button>

			<ButtonActionConfirm
				action={() => deleteUser()}
				cancelLabel={t("cancel")}
				okLabel={t("are_you_sure")}
				close={() => setAnchor(null)}
				anchor={anchor}
				actionMessage={t("sure_to_delete_account_message")}
			/>
		</>
	);
};

export default DeleteAccount;
