import React from "react";
import { Input } from "@mui/joy";
import { useController, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";

const InputText = ({ type, style, control, placeholder, name, rules }) => {
	const { t } = useTranslation();
	const {
		field,
		fieldState: { invalid, isTouched, isDirty },
	} = useController({
		name,
		control,
		rules,
	});

	return (
		<Input
			type={type}
			sx={style}
			error={invalid}
			placeholder={t(placeholder)}
			{...field}
		/>
	);
};

export default InputText;
