import React from "react";
import { useTranslation } from "react-i18next";
import { Box, Typography, Button, Stack } from "@mui/joy";
import PropTypes from "prop-types";
import { PlacesTable } from "./partials";
import { orderBy, where } from "firebase/firestore";
import Place from "models/places";
import { WhiteContainer } from "UI";
import { Link } from "react-router-dom";
import { PlaceAdvancedSearch } from "./partials";

const PlacesList = (props) => {
	const PlaceEntity = new Place();
	const { t } = useTranslation();
	const [filters, setFilters] = React.useState([]);
	const [places, setPlaces] = React.useState([]);
	const [lastVisible, setLastVisible] = React.useState(null);
	const [isMore, SetIsMore] = React.useState(true);
	const [filterChanged, setFilterChanged] = React.useState(false);

	/*	const getPlaces = async (reset = false) => {
		try {
			const datas = await PlaceEntity.getPlacesPagination(
				lastVisible,
				filters,
			);
			if (datas) {
				SetIsMore(!datas?.no_more_data);
				if (datas?.lastVisible) {
					setPlaces(
						reset
							? datas?.documents
							: [...places, ...datas?.documents],
					);
					setLastVisible(datas?.lastVisible);
				} else {
					setPlaces(datas);
				}
			} else {
				SetIsMore(false);
			}
		} catch (e) {
			console.log(e);
		}
	};

	React.useEffect(() => {
		setPlaces([]);
		setLastVisible(null);
		setFilterChanged(true);
	}, [filters]);

	React.useEffect(() => {
		if (filterChanged) {
			getPlaces(true);
			setFilterChanged(false);
		}
	}, [filterChanged]);*/

	return (
		<>
			<WhiteContainer>
				<Stack direction="row" spacing={2}>
					<Button component={Link} to="/places/create">
						{t("create_place")}
					</Button>
				</Stack>
			</WhiteContainer>
			{/*<PlacesTable
				isMore={isMore}
				getPlaces={getPlaces}
				places={places}
				setPlaces={setPlaces}
			/>*/}
			<PlaceAdvancedSearch />
		</>
	);
};

export default PlacesList;
PlacesList.propTypes = {};
PlacesList.defaultProps = {};
