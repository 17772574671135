import React from "react";
import {
	Modal,
	ModalDialog,
	ModalOverflow,
	Box,
	Typography,
	List,
	ListItem,
	ListItemDecorator,
	ListItemContent,
	Button,
	Stack,
	ListItemButton,
	Divider,
	ListSubheader,
	Select,
	Alert,
	Option,
} from "@mui/joy";
import { useTranslation } from "react-i18next";
import folderList from "../folderList";
import { useForm, Controller } from "react-hook-form";
import useAuthContainer from "stores/Auth";

const PlaceTree = ({ setPlaceSearch, placeSearch, places }) => {
	const { t } = useTranslation();
	const { userAccount } = useAuthContainer.useContainer();

	return (
		userAccount?.role === "partner" ||
		(userAccount?.role === "admin" && (
			<Box component="nav" aria-label="My site" sx={{ flexGrow: 1 }}>
				<List
					sx={{
						justifyContent: "start",
						marginBottom: "28px",
						background: "#eee",
						overflow: "auto",
						padding: "9px",
						textAlign: "center",
						borderRadius: "10px",
					}}
					role="menubar"
					orientation="horizontal"
				>
					<ListItemButton
						variant="outlined"
						selected={placeSearch === null}
						onClick={() => setPlaceSearch(null)}
						key={"all_places"}
						sx={{ mr: 1 }}
					>
						{t("all_my_places")}
					</ListItemButton>

					{places &&
						places?.map((place) => (
							<ListItemButton
								selected={placeSearch === place?.id}
								onClick={() => setPlaceSearch(place?.id)}
								key={place?.id}
							>
								{place.data().nom}
							</ListItemButton>
						))}
				</List>
			</Box>
		))
	);
};

export default PlaceTree;
