import React from "react";
import { useTranslation } from "react-i18next";
import { Box, Typography, Button } from "@mui/joy";
import PropTypes from "prop-types";
import { useHistory } from "react-router-dom";
import Event from "models/events";

const DetachEvent = ({ id }) => {
	const { t } = useTranslation();
	const history = useHistory();
	const [saving, setSaving] = React.useState(false);
	const EventEntity = new Event({
		fromPlaceId: null,
	});

	const detach = async () => {
		if (window.confirm(t("sure_detach_event_from_model"))) {
			setSaving(true);
			try {
				await EventEntity.updateEvent(id);
				history.go(0);
			} catch (e) {
				alert(e.message);
			}
			setSaving(false);
		}
	};

	return (
		<>
			<Button
				color="neutral"
				variant="outlined"
				loading={saving}
				disabled={saving}
				onClick={detach}
			>
				{t("detach_event_from_model")}
			</Button>
		</>
	);
};

export default DetachEvent;
DetachEvent.propTypes = {};
DetachEvent.defaultProps = {};
