import React from "react";
import { useTranslation } from "react-i18next";
import { Box, Typography } from "@mui/joy";
import PlacesRoutes from "./PlacesRoutes";

const Places = (props) => {
	const { t } = useTranslation();

	return (
		<>
			<PlacesRoutes />
		</>
	);
};

export default Places;
