import React from "react";
import QRCodeStyling, {
	DrawType,
	TypeNumber,
	Mode,
	ErrorCorrectionLevel,
	DotType,
	CornerSquareType,
	CornerDotType,
	Extension,
	Options,
} from "qr-code-styling";
import { Box, Button, Select, Option, Stack } from "@mui/joy";
import { Download } from "@mui/icons-material";
import { useTranslation } from "react-i18next";

const Renderer = ({ watch, links, setValue, data, setData }) => {
	const { t } = useTranslation();
	const [fileExt, setFileExt] = React.useState("svg");
	const qrOption = {
		width: watch("width"),
		height: watch("height"),
		margin: watch("margin"),
		errorCorrectionLevel: watch("errorCorrectionLevel"),
		image: watch("image"),
		imageOptions: watch("imageOptions"),
		data,
		backgroundOptions: watch("backgroundOptions"),
		dotsOptions: watch("dotsOptions"),
	};

	const [qrCode] = React.useState(new QRCodeStyling(qrOption));
	const ref = React.useRef(null);

	React.useEffect(() => {
		if (ref.current) {
			qrCode.append(ref.current);
		}
	}, [qrCode, ref]);

	React.useEffect(() => {
		if (!qrCode) return;
		qrCode.update(qrOption);
	}, [qrCode, qrOption]);

	const onExtensionChange = (v) => {
		setFileExt(v);
	};

	const onDownloadClick = () => {
		if (!qrCode) return;
		qrCode.download({
			extension: fileExt,
		});
	};

	return (
		<>
			<Box>
				<Select
					sx={{
						mb: 2,
					}}
					onChange={(e, v) => setData(v)}
					value={data}
				>
					{links.map((link) => (
						<Option
							value={`${process.env.REACT_APP_base_url}${link.path}`}
						>
							{t(link.label)}
						</Option>
					))}
				</Select>
				<Box
					sx={{
						textAlign: "center",
						canvas: {
							width: "100% !important",
						},
					}}
					ref={ref}
				/>

				{qrOption?.data && (
					<Stack direction="row" justifyContent="center" spacing={1}>
						<Select
							onChange={(e, v) => onExtensionChange(v)}
							value={fileExt}
						>
							<Option value="svg">SVG</Option>
							<Option value="png">PNG</Option>
							<Option value="jpeg">JPEG</Option>
							<Option value="webp">WEBP</Option>
						</Select>
						<Button onClick={onDownloadClick}>
							<Download /> {t("download")}
						</Button>
					</Stack>
				)}
			</Box>
		</>
	);
};

export default Renderer;
