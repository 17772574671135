import { extendTheme } from "@mui/joy/styles";

const theme = extendTheme({
  cssVarPrefix: "joy",

  components: {
    JoyButton: {
      styleOverrides: {
        root: {
          borderRadius: "3px",
        },
      },
    },
    JoyInput: {
      styleOverrides: {
        root: {
          borderRadius: "3px",
        },
      },
    },
    JoyListItemButton: {
      styleOverrides: {
        root: {
          borderRadius: "3px",
        },
      },
    },
    JoyListItem: {
      styleOverrides: {
        root: {
          borderRadius: "3px",
        },
      },
    },
  },
  colorSchemes: {
    light: {
      palette: {
        primary: {
          10: "#EDF7F7",
          50: "#e0f2f1",
          100: "#b2dfdb",
          200: "#80cbc4",
          300: "#4db6ac",
          400: "#26a69a",
          500: "#009688",
          600: "#00897b",
          700: "#00796b",
          800: "#00695c",
          900: "#004d40",
        },
        secondary: {
          10: "#EF4C22",
          50: "#EF4C22",
          100: "#EF4C22",
          200: "#EF4C22",
          300: "#EF4C22",
          400: "#EF4C22",
          500: "#EF4C22",
          600: "#EF4C22",
          700: "#EF4C22",
          800: "#EF4C22",
          900: "#EF4C22",
        },
        warning: {
          50: "#fff3e0",
          100: "#ffe0b2",
          200: "#ffcc80",
          300: "#ffb74d",
          400: "#ffa726",
          500: "#ff9800",
          600: "#fb8c00",
          700: "#f57c00",
          800: "#ef6c00",
          900: "#e65100",
          solidDisabledBg: undefined,
          solidDisabledColor: undefined,
          solidActiveBg: undefined,
          solidBg: "var(--joy-palette-warning-900)",
          solidColor: "var(--joy-palette-common-white)",
        },
      },
    },
    dark: {
      palette: {
        primary: {
          10: "#EDF7F7",
          50: "#e0f2f1",
          100: "#b2dfdb",
          200: "#80cbc4",
          300: "#4db6ac",
          400: "#26a69a",
          500: "#009688",
          600: "#00897b",
          700: "#00796b",
          800: "#00695c",
          900: "#004d40",
        },
        secondary: {
          10: "#EF4C22",
          50: "#EF4C22",
          100: "#EF4C22",
          200: "#EF4C22",
          300: "#EF4C22",
          400: "#EF4C22",
          500: "#EF4C22",
          600: "#EF4C22",
          700: "#EF4C22",
          800: "#EF4C22",
          900: "#EF4C22",
        },
        warning: {
          50: "#fff3e0",
          100: "#ffe0b2",
          200: "#ffcc80",
          300: "#ffb74d",
          400: "#ffa726",
          500: "#ff9800",
          600: "#fb8c00",
          700: "#f57c00",
          800: "#ef6c00",
          900: "#e65100",
          solidDisabledBg: undefined,
          solidDisabledColor: undefined,
          solidActiveBg: undefined,
          solidBg: "var(--joy-palette-warning-900)",
          solidColor: "var(--joy-palette-common-white)",
        },
      },
    },
  },
});

export default theme;
