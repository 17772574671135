import React from "react";
import { Grid, Box, Typography } from "@mui/joy";
import useContentfulDelivery from "../../hooks/contentful/useContentfulDelivery";
import { useLocation } from "react-router-dom";

const entriesArray = {
	"/login": "1IqQWHprOZsUuIFEACtX1R",
	"/login/create-account": "2ypiZ9MZFuQclk4QS3iX4S",
	"/login/reset-password": "1IqQWHprOZsUuIFEACtX1R",
	"/login/informations": "1IqQWHprOZsUuIFEACtX1R",
};

const LayoutTwoColumn = ({ children, content }) => {
	const location = useLocation();

	const { entry, getContentfullEntry } = useContentfulDelivery();

	React.useEffect(() => {
		getContentfullEntry(entriesArray[location.pathname]);
	}, [location.pathname]);

	return (
		<Grid container>
			<Grid md={6} xs={12}>
				<Box
					sx={{
						maxWidth: { md: "440px", xs: "90%" },
						m: "0 auto ",
					}}
				>
					{children}
				</Box>
			</Grid>
			<Grid
				xs={12}
				md={6}
				sx={{
					position: "fixed",
					right: 0,
					display: { md: "flex", xs: "none" },
					alignItems: "center",
					backgroundImage: `url(${entry?.fields?.backgroundImage?.fields?.file.url})`,
					backgroundSize: "cover",
					backgroundPosition: "center",
					height: "100vh",
				}}
			>
				<Typography
					level="h4"
					sx={{
						color: "white",
						textAlign: "center",
						width: "60%",
						margin: "0 auto ",
					}}
					children={entry?.fields?.text}
				/>
			</Grid>
		</Grid>
	);
};

export default LayoutTwoColumn;
