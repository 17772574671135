import React from "react";
import { FormSeparator } from "UI";
import { ChipList } from "UI";
import list from "datas/events/event_type_list.json";

const EventType = ({ register, selected, setValue }) => {
	const mainTypes = list.map((d) => d.label);

	return (
		<FormSeparator required title="event_type">
			<ChipList
				selectItem={selected}
				register={register}
				items={mainTypes}
				setValue={setValue}
				fieldName="event_type"
			/>
		</FormSeparator>
	);
};

export default EventType;
