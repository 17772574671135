import React from "react";
import { useTranslation } from "react-i18next";
import { Box } from "@mui/joy";
import PropTypes from "prop-types";
import { WhiteContainer } from "UI";
import { EventTemplatesForm } from "forms/eventsTemplates";
import { Results } from "./partials";

const EventModels = (props) => {
	const { t } = useTranslation();

	return (
		<WhiteContainer title="load_event_model">
			<EventTemplatesForm />
			<Results />
		</WhiteContainer>
	);
};

export default EventModels;
EventModels.propTypes = {};
EventModels.defaultProps = {};
