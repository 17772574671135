import React from "react";
import ErrorRoutes from "./ErrorRoutes";
import { Switch, Route } from "react-router-dom";
import {
  AuthentificationModule,
  DashboardModule,
  Places,
  Front,
  AccountModule,
  CV,
  Services,
  PlacesTemplates,
  Event,
  Admin,
  EventTemplates,
  Module,
} from "./containers";

import { CreateEventForm } from "forms/events";

const AppRoutes = (props) => {
  return (
    <Switch>
      <Route exact path="/">
        <DashboardModule />
      </Route>
      <Route path="/dashboard">
        <DashboardModule />
      </Route>
      <Route path="/event/templates">
        <EventTemplates />
      </Route>
      <Route path="/places-templates">
        <PlacesTemplates />
      </Route>
      <Route path="/admin">
        <Admin />
      </Route>
      <Route path="/event/:eventID?">
        <Event />
      </Route>
      <Route path="/login/">
        <AuthentificationModule />
      </Route>
      <Route path="/dashboard">
        <DashboardModule />
      </Route>
      <Route path="/account">
        <AccountModule />
      </Route>
      <Route path="/services">
        <Services />
      </Route>
      <Route path="/my_event/:eventID?">
        <Front />
      </Route>{" "}
      <Route path="/places">
        <Places />
      </Route>
      <Route path="/module/:moduleName?/:eventID?">
        <Module />
      </Route>
      <Route path="/64c0d77e9e824174a32561c3">
        <CV />
      </Route>
      {/*
          
          ELEMENT EN CONSTrUCTIOn

      */}
    </Switch>
  );
};

export default AppRoutes;
