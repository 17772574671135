import React from "react";
import { Box, Typography, Chip } from "@mui/joy";
import { useTranslation } from "react-i18next";

const Tags = ({ tags }) => {
	const { t } = useTranslation();
	return (
		<Box
			sx={{
				mt: 1,
				mb: 1,
				display: "flex",
				flexDirection: "row",
				flexWrap: "wrap",
				gap: 1,
			}}
		>
			{" "}
			{tags && tags.map((tag) => <Chip key={tag}>#{t(tag)}</Chip>)}
		</Box>
	);
};

export default Tags;
