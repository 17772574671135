import React from "react";
import { useTranslation } from "react-i18next";
import { Box, Typography, Input, Button, Textarea } from "@mui/joy";
import PropTypes from "prop-types";
import { useForm } from "react-hook-form";

const Editable = ({ close, datas, saveInvoice }) => {
	const { t } = useTranslation();
	const { handleSubmit, reset, register } = useForm();

	React.useEffect(() => {
		reset(datas);
	}, [datas]);

	const onSubmit = (data) => {
		saveInvoice(data);
		close();
	};

	return (
		<Box sx={{ padding: 2 }}>
			<Button
				sx={{ position: "absolute", right: 8, top: 8 }}
				onClick={close}
			>
				{t("close")}
			</Button>
			<form onSubmit={handleSubmit(onSubmit)}>
				<Box
					sx={{
						display: "flex",
						flexDirection: "column",
						gap: 2,
						mt: 2,
					}}
				>
					<Input
						placeholder={t("adresse")}
						{...register("adresse")}
					/>
					<Input
						placeholder={t("code_postal")}
						{...register("code_postal")}
					/>
					<Input
						placeholder={t("complement_adresse")}
						{...register("complement_adresse")}
					/>
					<Input
						placeholder={t("coordonnees_facturation")}
						{...register("coordonnees_facturation")}
					/>
					<Textarea
						placeholder={t("indice_retrocom")}
						{...register("indice_retrocom")}
						minRows={3}
					></Textarea>
					<Input placeholder={t("nom")} {...register("nom")} />
					<Input placeholder={t("pays")} {...register("pays")} />
					<Input placeholder={t("prenom")} {...register("prenom")} />
					<Input
						placeholder={t("raison_sociale")}
						{...register("raison_sociale")}
					/>
					<Input placeholder={t("siret")} {...register("siret")} />
					<Input placeholder={t("ville")} {...register("ville")} />

					<Button type="submit" fullWidth>
						{t("save")}
					</Button>
				</Box>
			</form>
		</Box>
	);
};

export default Editable;

Editable.propTypes = {
	// You can define prop types if necessary
};

Editable.defaultProps = {
	// You can define default props if necessary
};
