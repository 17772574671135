import React from "react";
import { useTranslation } from "react-i18next";
import {
	Box,
	Typography,
	Modal,
	ModalDialog,
	ModalClose,
	Select,
	Button,
	Option,
} from "@mui/joy";
import { useCollection } from "react-firebase-hooks/firestore";

import {
	query,
	collection,
	where,
	deleteDoc,
	doc,
	orderBy,
	addDoc,
} from "firebase/firestore";
import { db } from "utils/MyFirebase";
import useAuthContainer from "stores/Auth";
import { useForm, Controller } from "react-hook-form";

const AttachAPlace = ({ open, close, title, attach, saving }) => {
	const {
		handleSubmit,
		formState: { errors },
		register,
		reset,
		control,
	} = useForm();
	const { user } = useAuthContainer.useContainer();
	const documentsQuery = query(
		collection(db, "places"),
		where("uid", "==", user?.uid ?? null),
	);
	const [documents, loading, error] = useCollection(documentsQuery);
	const { t } = useTranslation();

	React.useEffect(() => {
		reset();
	}, [open]);

	const save = (datas) => {
		const { place } = datas;
		attach(place);
		close();
	};

	return (
		<Modal open={open} onClose={close}>
			<ModalDialog>
				<ModalClose />
				<Typography level="h4">{t("attach_a_place")}</Typography>
				<form onSubmit={handleSubmit(save)}>
					<Controller
						control={control}
						name="place"
						rules={{
							required: true,
						}}
						render={({ field: { value, onChange } }) => (
							<Select
								sx={{ mt: 1 }}
								onChange={(d, v) => {
									onChange(v);
								}}
								placeholder={t("select_a_place")}
							>
								{documents?.docs &&
									documents.docs.map((doc) => (
										<Option value={doc.id}>
											{doc.data().nom}
										</Option>
									))}
							</Select>
						)}
					/>
					<Button
						fullWidth
						sx={{ mt: 2 }}
						type="submit"
						color="primary"
						loading={saving}
						disabled={saving}
					>
						{t("attach_to_selected_place")}
					</Button>
				</form>
			</ModalDialog>
		</Modal>
	);
};

export default AttachAPlace;
