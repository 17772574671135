import React, { useState, useEffect } from "react";
import Rheostat from "rheostat";
import { Box, Typography } from "@mui/joy";
import "./RangeSlider.css";
import { useRange } from "react-instantsearch";

const AlgoliaRheostatSlider = ({
  attribute,
  minAttribute,
  maxAttribute,
  label = "label",
  labelAdornment = "",
}) => {
  // Call useRange hooks unconditionally
  const singleRange = useRange({ attribute: attribute || "default_single" });
  const minRange = useRange({ attribute: minAttribute || "default_min" });
  const maxRange = useRange({ attribute: maxAttribute || "default_max" });

  const [sliderState, setSliderState] = useState([0, 0]);

  useEffect(() => {
    if (attribute) {
      // Single attribute mode
      setSliderState([singleRange.range.min, singleRange.range.max]);
    } else if (minAttribute && maxAttribute) {
      // Dual attribute mode
      setSliderState([minRange.range.min, maxRange.range.max]);
    }
  }, [
    attribute,
    minAttribute,
    maxAttribute,
    singleRange.range.min,
    singleRange.range.max,
    minRange.range.min,
    maxRange.range.max,
  ]);

  const handleChange = (event) => {
    setSliderState(event.values); // This maintains the slider position as user drags it
  };

  const handleValuesUpdated = (event) => {
    if (attribute) {
      singleRange.refine(event.values);
    } else if (minAttribute && maxAttribute) {
      minRange.refine([event.values[0], event.values[0]]);
      maxRange.refine([event.values[1], event.values[1]]);
    }
  };

  const canRefine = attribute
    ? singleRange.canRefine && attribute !== "default_single"
    : (minRange.canRefine && minAttribute !== "default_min") ||
      (maxRange.canRefine && maxAttribute !== "default_max");

  const rangeMin = attribute
    ? singleRange.range.min
    : Math.min(minRange.range.min, maxRange.range.min);
  const rangeMax = attribute
    ? singleRange.range.max
    : Math.max(minRange.range.max, maxRange.range.max);

  return (
    <div>
      {canRefine && (
        <>
          <Box>
            <Box
              sx={{
                mb: 2,
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
              }}
            >
              <Typography>
                {sliderState[0]}
                {labelAdornment}
              </Typography>
              <Typography>
                {sliderState[1]}
                {labelAdornment}
              </Typography>
            </Box>
          </Box>
          <div>
            <Rheostat
              min={rangeMin}
              max={rangeMax}
              values={sliderState}
              onValuesUpdated={handleChange}
              onChange={handleValuesUpdated}
            />
          </div>
          <Typography sx={{ mt: 1, textAlign: "center" }}>{label}</Typography>
        </>
      )}
    </div>
  );
};

export default AlgoliaRheostatSlider;
