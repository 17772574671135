import React from "react";
import {
	Typography,
	FormControl,
	FormLabel,
	Input,
	Box,
	Stack,
	Textarea,
	FormHelperText,
} from "@mui/joy";
import { useTranslation } from "react-i18next";

const fields = [
	"nombre_de_chambres",
	"nombre_couchage_indiv",
	"nombre_couchage_max",
	"capacite_en_twin",
	"nombre_chambres_pmr",
];

const Rooms = ({ register, errors, children }) => {
	const { t } = useTranslation();

	return (
		<Box sx={{ background: "white", p: 2, mt: 1, mb: 1 }}>
			<Typography sx={{ mb: 2 }} level="h4" component="h2">
				{t("rooms")}
			</Typography>
			{/* Description */}
			<FormControl variant="outlined">
				<FormLabel>Description de l'hébergement</FormLabel>
				<Textarea
					{...register("description_hebergement")}
					placeholder="Décrivez l'hébergement"
					multiline
					minRows={3}
				/>
				<FormHelperText>
					{errors?.description_hebergement?.message}
				</FormHelperText>
			</FormControl>{" "}
			<Stack spacing={2}>
				{fields.map((field) => (
					<FormControl variant="outlined" error={!!errors[field]}>
						<FormLabel>{t(field)}</FormLabel>
						<Input
							type="number"
							{...register(field, {
								required: false,
							})}
							placeholder={t(field)}
						/>
						<FormHelperText>
							{errors[field]?.message}
						</FormHelperText>
					</FormControl>
				))}
			</Stack>
			{/* Description */}
			<FormControl sx={{ mt: 2 }} variant="outlined">
				<FormLabel>Hébergement à proximité</FormLabel>
				<Textarea
					{...register("hebergement_proximite")}
					placeholder="Hébergement à proximité"
					multiline
					minRows={3}
				/>
				<FormHelperText>
					{errors?.hebergement_proximite?.message}
				</FormHelperText>
			</FormControl>
			{children}
		</Box>
	);
};

export default Rooms;
