import React from "react";
import PlacesAutocomplete, {
	geocodeByAddress,
	getLatLng,
} from "react-places-autocomplete";
import {
	Box,
	Typography,
	Stack,
	FormControl,
	FormLabel,
	Input,
	FormHelperText,
	Chip,
	Textarea,
	Select,
	Option,
} from "@mui/joy";
import TagsInput from "react-tagsinput";
import { useTranslation } from "react-i18next";
import { Controller } from "react-hook-form";

import { FormSeparator, AdvancedLocationInput } from "UI";
import { LocationOn } from "@mui/icons-material";

const EventLocations = ({
	setValue,
	getValues,
	errors,
	register,
	defaultAdresse,
}) => {
	const { t } = useTranslation();
	const [address, setAddress] = React.useState("");

	React.useEffect(() => {
		setAddress(defaultAdresse);
	}, []);

	const handleSelect = async (value) => {
		const results = await geocodeByAddress(value);
		const latLng = await getLatLng(results[0]);
		if (results.length > 0) {
			for (const component of results[0].address_components) {
				const componentType = component.types[0];

				switch (componentType) {
					case "locality":
						setValue("event_ville", component.long_name);
						break;
					case "postal_code":
						setValue("event_code_postal", component.long_name);
						break;
					case "country":
						setValue("event_pays", component.long_name);
						break;
					case "administrative_area_level_1":
					case "administrative_area_level_2":
						setValue("event_region", component.long_name);
						break;
					default:
						break;
				}
			}

			setValue("event_coordonnees_gps", latLng, { shouldDirty: true });
			setValue("event_adresse", value, { shouldDirty: true });
		}
	};

	const handleChange = (value) => {
		setAddress(value);
		if (!value) {
			setAddress("");
			setValue("event_adresse", "", { shouldDirty: true });
		}
	};

	return (
		<FormSeparator title="event_locations">
			<Box
				sx={{
					background: "white",
					p: 2,
					mt: 1,
					mb: 1,
				}}
			>
				<Stack spacing={2}>
					<FormControl variant="outlined" error={!!errors?.adresse}>
						<FormLabel>Adresse</FormLabel>
						<PlacesAutocomplete
							value={address}
							onChange={handleChange}
							onSelect={handleSelect}
						>
							{({
								getInputProps,
								suggestions,
								getSuggestionItemProps,
								loading,
							}) => (
								<div>
									<Input
										{...getInputProps({
											placeholder:
												"Recherchez une adresse ...",
										})}
									/>
									<div
										style={{
											background: "white",
											border: "1px #eee solid",
										}}
									>
										{loading ? (
											<div>...chargement</div>
										) : null}
										{suggestions.map((suggestion) => {
											const style = {
												backgroundColor:
													suggestion.active
														? "#41b6e6"
														: "#fff",
											};

											return (
												<div
													{...getSuggestionItemProps(
														suggestion,
														{ style },
													)}
												>
													{suggestion.description}
												</div>
											);
										})}
									</div>
								</div>
							)}
						</PlacesAutocomplete>
						<FormHelperText>
							{errors?.adresse?.message}
						</FormHelperText>
					</FormControl>

					<FormControl variant="outlined" error={!!errors?.ville}>
						<FormLabel>Ville</FormLabel>
						<Input
							{...register("event_ville", {
								required: "La ville est requise",
							})}
							placeholder="Entrez la ville"
						/>
						<FormHelperText>
							{errors?.ville?.message}
						</FormHelperText>
					</FormControl>

					<FormControl
						variant="outlined"
						error={!!errors?.code_postal}
					>
						<FormLabel>Code Postal</FormLabel>
						<Input
							{...register("event_code_postal", {
								required: "Le code postal est requis",
							})}
							placeholder="Entrez le code postal"
						/>
						<FormHelperText>
							{errors?.code_postal?.message}
						</FormHelperText>
					</FormControl>

					<FormControl variant="outlined" error={!!errors?.region}>
						<FormLabel>Région</FormLabel>
						<Input
							{...register("event_region", {
								required: "Région requise",
							})}
							placeholder="Votre région"
						/>
						<FormHelperText>
							{errors?.region?.message}
						</FormHelperText>
					</FormControl>

					<FormControl variant="outlined" error={!!errors?.pays}>
						<FormLabel>Pays</FormLabel>
						<Input
							{...register("event_pays", {
								required: "Le pays est requis",
							})}
							placeholder="Entrez le pays"
						/>
						<FormHelperText>{errors?.pays?.message}</FormHelperText>
					</FormControl>
				</Stack>
			</Box>
		</FormSeparator>
	);
};

export default EventLocations;
