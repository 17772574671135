import React from "react";
import { useTranslation } from "react-i18next";
import { Box, Typography } from "@mui/joy";
import PropTypes from "prop-types";
import { StatInfoCard } from "UI/Cards";
import { WhiteContainer } from "UI";
import { Person, Celebration, VerticalSplit } from "@mui/icons-material";
import { useHistory } from "react-router-dom";
import Kpi from "models/kpi";
import { Requests } from "./partials";

import { RequestsList } from "../requests/views";

const DashboardAdmin = (props) => {
	const { t } = useTranslation();
	const [kpis, setKpis] = React.useState([]);
	const KpiEntity = new Kpi();
	const history = useHistory();

	React.useEffect(() => {
		KpiEntity.getKpis()
			.then((k) => {
				setKpis(k);
			})
			.catch((e) => {
				console.log(e);
			});
	}, []);

	return (
		<Box sx={{ display: "flex" }}>
			<Box
				sx={{
					gap: 2,
					width: "500px",
					display: "flex",
					flexWrap: "wrap",
				}}
			>
				<Box sx={{ width: "230px" }}>
					<StatInfoCard
						number={kpis?.places?.count}
						texts={{
							title: t("places_stats"),
							button_1: t("show_places"),
						}}
						actions={{
							action_1: () => history.push("/admin/places"),
						}}
						progress={0}
					/>
				</Box>
				<Box sx={{ width: "230px" }}>
					<StatInfoCard
						number={kpis?.events?.count}
						icon={<Celebration />}
						texts={{
							title: t("events"),
							button_1: t("show_events"),
						}}
						actions={{
							action_1: () => history.push("/admin/events"),
						}}
						progress={0}
					/>
				</Box>
				<Box sx={{ flexGrow: 1, mr: 3 }}>
					<StatInfoCard
						number={kpis?.users?.count}
						icon={<Person />}
						texts={{
							title: t("users"),
							button_1: t("show_users"),
						}}
						actions={{
							action_1: () => history.push("/admin/users"),
						}}
						progress={0}
					/>
				</Box>
			</Box>
			<WhiteContainer title={"requests"} style={{ flexGrow: 1, m: 0 }}>
				<Requests />
			</WhiteContainer>
		</Box>
	);
};

export default DashboardAdmin;
DashboardAdmin.propTypes = {};
DashboardAdmin.defaultProps = {};
