import React from "react";
import { BOLayout } from "Components/layouts";
import { useParams } from "react-router-dom";
import { Box } from "@mui/joy";
import { VisualEditor } from "Common";
import ModuleRouter from "./ModuleRouter";

const Module = ({ hideLayout }) => {
	let { eventID, moduleName } = useParams();

	return (
		<BOLayout
			documentID={eventID}
			isDocumentStatusMonitoring
			showEventMenu
			title={moduleName}
		>
			<Box sx={{ gap: 4, display: "flex", flexDirection: "row" }}>
				<Box sx={{ flexGrow: 1 }}>
					<ModuleRouter eventID={eventID} moduleName={moduleName} />
				</Box>
				<VisualEditor
					src={`${process.env.REACT_APP_base_url}/my_event/${eventID}/${moduleName}`}
				/>
			</Box>
		</BOLayout>
	);
};

export default Module;
