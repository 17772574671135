import React from "react";
import { useTranslation } from "react-i18next";
import { Box, Typography, Sheet, Button } from "@mui/joy";
import PropTypes from "prop-types";
import {
	PhoneIphone,
	TabletMac,
	DesktopMacOutlined,
} from "@mui/icons-material";

const ToogleSize = ({ size, setSize }) => {
	return (
		<Box
			sx={{
				position: "absolute",
				border: "1px solid #eee",
				top: 280,
				zIndex: 3,
				background: "white",
				p: 1,
				opacity: 0.5,
				transition: "ease 200ms",
				"&:hover": {
					opacity: 1,
				},
				borderRadius: "10px",
				display: "flex",
				flexDirection: "column",
				gap: "4px",
			}}
		>
			<Button
				onClick={() => setSize(400)}
				variant={size === 400 ? "solid" : "plain"}
			>
				<PhoneIphone />
			</Button>
			<Button
				onClick={() => setSize(700)}
				variant={size === 700 ? "soft" : "plain"}
			>
				<TabletMac />
			</Button>
			<Button
				onClick={() => setSize(1000)}
				variant={size === 1000 ? "soft" : "plain"}
			>
				<DesktopMacOutlined />
			</Button>
		</Box>
	);
};

const VisualEditor = ({ src }) => {
	const { t } = useTranslation();
	const [size, setSize] = React.useState(400);
	return (
		<Box
			sx={{
				position: "relative",
				display: { xs: "none", md: "block" },
				width: size,
			}}
		>
			<Box
				sx={{
					border: "10px solid #000",
					borderRadius: "20px",
					width: size,
					height: "85%",

					background: "white",
					overflow: "hidden",
					position: "fixed",
				}}
			>
				{/*<ToogleSize size={size} setSize={setSize} />*/}

				<iframe
					style={{
						width: "100%",
						height: "100%",
					}}
					src={src}
				/>
			</Box>
		</Box>
	);
};

export default VisualEditor;
VisualEditor.propTypes = {};
VisualEditor.defaultProps = {};
