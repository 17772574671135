import React from "react";
import { useTranslation } from "react-i18next";
import { Box, Typography, Stack } from "@mui/joy";
import PropTypes from "prop-types";
import { EmptyEvent, EventModels, EventLoadModelModal } from "./partials";
import { AlgoliaContainer } from "Components/algolia";
import useEventTemplatesStore from "../../EventTemplatesStore";

const EventTemplateList = (props) => {
	const { t } = useTranslation();
	const { selectedEvent, setSelectedEvent } =
		useEventTemplatesStore.useContainer();

	return (
		<Stack spacing={2} sx={{ position: "relative" }}>
			<EmptyEvent />
			<AlgoliaContainer
				filters="placeId != 0 AND shared:true"
				index="events"
			>
				<EventModels />
			</AlgoliaContainer>
			<EventLoadModelModal
				event={selectedEvent}
				close={() => setSelectedEvent(null)}
			/>
		</Stack>
	);
};

export default EventTemplateList;
EventTemplateList.propTypes = {};
EventTemplateList.defaultProps = {};
