import React from "react";
import { useTranslation } from "react-i18next";
import { Box, Typography, FormControl, FormLabel, Input } from "@mui/joy";
import PropTypes from "prop-types";

const Name = ({ register }) => {
	const { t } = useTranslation();

	return (
		<>
			<FormControl>
				<FormLabel>{t("name")}</FormLabel>
				<Input {...register("name")} />
			</FormControl>
			<FormControl>
				<FormLabel>{t("lastname")}</FormLabel>
				<Input {...register("lastname")} />
			</FormControl>
		</>
	);
};

export default Name;
Name.propTypes = {};
Name.defaultProps = {};
