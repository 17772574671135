import React from "react";
import { Typography, ListItem, IconButton, Box, List, Button } from "@mui/joy";
import { Edit, Delete, Add } from "@mui/icons-material";
import { useTranslation } from "react-i18next";
import RestaurationModal from "./RestaurationModal";
import { v4 as uuidv4 } from "uuid";

const Restauration = ({ setValue, datas, errors, register, control }) => {
	const { t } = useTranslation();
	const [restauration, setRestauration] = React.useState(datas);
	const [isModalOpen, setIsModalOpen] = React.useState(false);
	const [editingRestauration, setEditingRestauration] = React.useState(null);

	React.useEffect(() => {
		if (restauration.length < 1) {
			if (datas.length > 0) {
				setRestauration(datas);
			}
		}
	}, [datas]);

	const updateValues = (d) => {
		setValue("restauration", d);
	};

	const openModalToAdd = () => {
		setEditingRestauration(null);
		setIsModalOpen(true);
	};

	const openModalToEdit = (restauration) => {
		setEditingRestauration(restauration);
		setIsModalOpen(true);
	};

	const closeModal = () => {
		setIsModalOpen(false);
	};

	const saveRestauration = (restaurationData) => {
		if (editingRestauration) {
			const updatedRestauration = restauration.map(
				(restauration, index) =>
					restauration.id === editingRestauration.id
						? restaurationData
						: restauration,
			);

			updateValues(updatedRestauration);
			setRestauration(updatedRestauration);
		} else {
			const newsRestauration = [
				...restauration,
				{ id: uuidv4(), ...restaurationData },
			];
			updateValues(newsRestauration);
			setRestauration(newsRestauration); // Ajouter une nouvelle salle
		}
	};

	return (
		<Box sx={{ background: "white", p: 2, mt: 1, mb: 1 }}>
			<Typography sx={{ mb: 2 }} level="h4" component="h2">
				{t("Restauration")}
			</Typography>

			<div>
				<Typography sx={{ mb: 1, mt: 2 }} component="h4">
					Liste des espaces de restauration
				</Typography>
				<List sx={{ width: { md: "350px", xs: "100%" } }}>
					{restauration.map((_restauration, index) => (
						<ListItem
							key={_restauration.id}
							sx={{
								p: 2,
								border: "1px solid #eee",
								mb: 1,
							}}
							endAction={
								<>
									<IconButton
										onClick={() =>
											openModalToEdit(_restauration)
										}
									>
										<Edit
											variant="container"
											color="primary"
										/>
									</IconButton>
									<IconButton
										onClick={() =>
											setRestauration(
												restauration.filter(
													(_, i) => i !== index,
												),
											)
										}
									>
										<Delete color="danger" />
									</IconButton>
								</>
							}
						>
							{_restauration.nom}
						</ListItem>
					))}
				</List>
				<Button onClick={openModalToAdd}>
					<Add />
					Ajouter un restauration
				</Button>

				<RestaurationModal
					isOpen={isModalOpen}
					onClose={closeModal}
					onSave={saveRestauration}
					defaultValues={editingRestauration} // Passer les valeurs par défaut pour l'édition
				/>
			</div>
		</Box>
	);
};

export default Restauration;
