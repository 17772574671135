import React from "react";
import { useTranslation } from "react-i18next";
import { Box, Typography } from "@mui/joy";
import PropTypes from "prop-types";
import { Filters } from "./partials";

const EventTemplatesForm = (props) => {
	const { t } = useTranslation();

	return (
		<>
			<Filters />
		</>
	);
};

export default EventTemplatesForm;
EventTemplatesForm.propTypes = {};
EventTemplatesForm.defaultProps = {};
