import React from "react";
import { useTranslation } from "react-i18next";
import { Box, Typography } from "@mui/joy";
import PropTypes from "prop-types";
import { WhiteContainer } from "UI";
import Upload from "models/uploads";
import { where } from "firebase/firestore";
import { List } from "./partials";

const UploadEntity = new Upload();

const PlaceUserFiles = ({ uid, placeID }) => {
	const { t } = useTranslation();
	const [documents, setDocuments] = React.useState([]);
	const [filt, setFilt] = React.useState({
		type: "application/",
		place: true,
	});

	const fetchUploads = async () => {
		const filters = [where("uid", "==", uid)];
		if (filt.type) {
			filters.push(where("contentType", ">=", filt.type));
			filters.push(where("contentType", "<=", filt.type + "\uf8ff"));
		}
		if (filt.place) {
			filters.push(where("place", "==", placeID));
		}

		UploadEntity.getUploads(filters, (d) => {
			setDocuments(d);
		});
	};

	React.useEffect(() => {
		if (uid && placeID) {
			fetchUploads();
		}
	}, [uid, filt]);

	return (
		<>
			<WhiteContainer title="PlaceUserFiles">
				<List filt={filt} setFilt={setFilt} docs={documents} />
			</WhiteContainer>
		</>
	);
};

export default PlaceUserFiles;
PlaceUserFiles.propTypes = {};
PlaceUserFiles.defaultProps = {};
