import React from "react";
import items from "datas/events/event_seminar_type.json";
import { ChipList, FormSeparator } from "UI";
import { Input, FormControl, FormHelperText } from "@mui/joy";

const EventSeminarType = ({ register, selected, setValue, watch, error }) => {
	let eventType = watch && watch("event_type");
	const isSeminar = eventType === "seminar";

	return isSeminar ? (
		<>
			<FormSeparator required title="event_seminar_type">
				<FormControl>
					<ChipList
						selectItem={selected}
						register={register}
						items={items}
						setValue={setValue}
						fieldName="event_seminar_type"
					/>
					{error && <FormHelperText>{error.message}</FormHelperText>}
				</FormControl>
			</FormSeparator>
		</>
	) : null;
};

export default EventSeminarType;
