import React from "react";
import { IconButton, Typography, Box, Stack, Switch } from "@mui/joy";
import { Info, ArrowBack } from "@mui/icons-material";
import { WhiteContainer } from "UI";
import { Builder } from "Common";
import useUsefullInfosContainer from "../../../../UsefullInfosStore";
import { useTranslation } from "react-i18next";

const UsefullPhone = ({ id }) => {
	const { t } = useTranslation();

	const { setEditor, setUsefullInfosState, usefullInfosState } =
		useUsefullInfosContainer.useContainer();

	return (
		<WhiteContainer>
			<Stack
				direction="row"
				alignItems="center"
				justifyContent="space-between"
				sx={{ mb: 3 }}
				spacing={2}
			>
				<Stack direction="row" alignItems="center" spacing={1}>
					<IconButton onClick={() => setEditor(null)}>
						<ArrowBack />
					</IconButton>
					<Typography>{t("back_usefull_infos")}</Typography>
				</Stack>

				<Stack direction="row" spacing={1} alignItems="center">
					<Switch
						checked={usefullInfosState?.usefull_phone?.active}
						onChange={(e) => {
							const v = e.target.checked;
							setUsefullInfosState({
								...usefullInfosState,
								usefull_phone: {
									...usefullInfosState?.usefull_phone,
									active: v,
								},
							});
						}}
					/>

					<Typography
						startDecorator={<Info />}
						level="h5"
						sx={(theme) => ({
							textTransform: "uppercase",
							background: usefullInfosState?.usefull_phone?.active
								? theme.vars.palette.primary[400]
								: "#EEE",
							color: usefullInfosState?.usefull_phone?.active
								? "white"
								: "initial",
							borderRadius: "200px",

							pl: 2,
							pr: 2,
						})}
					>
						{t("usefull_number")}
					</Typography>
				</Stack>
			</Stack>

			<Box>
				<Builder
					content={usefullInfosState?.usefull_phone?.content || []}
					action={(d) =>
						setUsefullInfosState({
							...usefullInfosState,
							usefull_phone: {
								...usefullInfosState?.usefull_phone,
								content: d,
							},
						})
					}
					editorConfig={{
						modalSize: 700,
						limitDepth: 1,
						itemsConfig: {
							default: {
								cardFields: ["title"],
								terms: {
									type: "usefull_number",
									type_plural: "usefull_numbers",
									create_new_item: "add_usefull_number",
									create_new_children:
										"create_new_usefull_number",
									detail_tab: "wifi_usefull_number",
									children_type: "usefull_number",
									content_tab: "usefull_number",
								},
								editor: {
									hasChildren: false,
									fields: [
										{
											label: "image",

											type: "simple-image",
										},
										{
											label: "title",
											placeholder: "phone_contact",
											type: "text",
											rules: {
												required: true,
											},
										},
										{
											label: "phone_number",
											type: "number",
											rules: {
												required: false,
											},
										},
									],
								},
							},
						},
					}}
				/>
			</Box>
		</WhiteContainer>
	);
};

export default UsefullPhone;
