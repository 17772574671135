const folder = () => ({
	documents: ["presentation", "quote", "others"],
	images: [
		"environment",
		"place",
		"room",
		"space",
		"hall",
		"catering",
		"activities",
		"team_building",
		"other",
	],
});

export default folder;
