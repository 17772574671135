import React from "react";
import { Switch, Route } from "react-router-dom";
import {
	General,
	Settings,
	NewEvent,
	ListEvent,
	EventQrCode,
	UsefullInfos,
	Home,
} from "./views";

const EventRoutes = () => {
	return (
		<Switch>
			<Route path="/event/list">
				<ListEvent />
			</Route>
			<Route path="/event/models/:placeID?">
				<ListEvent models={true} />
			</Route>
			<Route path="/event/:eventID/settings">
				<Settings />
			</Route>
			<Route path="/event/:eventID/qrcode">
				<EventQrCode />
			</Route>
			<Route path="/event/:eventID/general">
				<General />
			</Route>
			<Route path="/event/:eventID/infos">
				<UsefullInfos />
			</Route>
			<Route path="/event/:eventID/home">
				<Home />
			</Route>
			<Route path="/event/new-event">
				<NewEvent />
			</Route>
		</Switch>
	);
};

export default EventRoutes;
