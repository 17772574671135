import React from "react";
import { useTranslation } from "react-i18next";
import {
	Box,
	Typography,
	Select,
	Option,
	FormControl,
	FormLabel,
} from "@mui/joy";
import PropTypes from "prop-types";

const style = [
	"rounded",
	"dots",
	"classy",
	"classy-rounded",
	"square",
	"extra-rounded",
];

const DotStyle = ({ dotsOptions, setValue }) => {
	const { t } = useTranslation();

	return (
		<FormControl>
			<FormLabel>{t("dot_style")}</FormLabel>
			<Select
				value={dotsOptions.type}
				onChange={(e, v) => {
					setValue("dotsOptions", { ...dotsOptions, type: v });
				}}
			>
				{style.map((style) => (
					<Option key={style} value={style}>
						{t(style)}
					</Option>
				))}
			</Select>
		</FormControl>
	);
};

export default DotStyle;
DotStyle.propTypes = {};
DotStyle.defaultProps = {};
