import React, { useRef } from "react";
import { Box, Typography, Table, Sheet, Card, Button } from "@mui/joy";
import { useTranslation } from "react-i18next";
import { BedRoomsList } from "../.";
import { PlaceRoomModalCard } from "UI/Grids";
import { ArrowBackIos, ArrowForwardIos } from "@mui/icons-material";
import SwipeComponent from "Components/hoc/SwipeComponent";
import { HideSectionButton } from "forms/places";

const Hebergement = ({
	description_hebergement,
	nombre_couchage_indiv,
	capacite_en_twin,
	nombre_chambres_pmr,
	nombre_couchage_max,
	nombre_de_chambres,
	bedrooms,
	placeId,
	sectionsVisibility,
	uid,
}) => {
	const { t } = useTranslation();
	const scrollContainerRef = useRef(null);

	const rooms = [
		{ label: "nombre_couchage_indiv", data: nombre_couchage_indiv || null },
		{ label: "capacite_en_twin", data: capacite_en_twin || null },
		{ label: "nombre_couchage_max", data: nombre_couchage_max || null },
		{ label: "nombre_de_chambres", data: nombre_de_chambres || null },
		{ label: "nombre_chambres_pmr", data: nombre_chambres_pmr || null },
	];

	const scroll = (direction) => {
		const { current } = scrollContainerRef;
		if (current) {
			const scrollAmount = direction === "left" ? -200 : 200;
			current.scrollBy({ left: scrollAmount, behavior: "smooth" });
		}
	};

	return (
		<>
			<Box>
				<Typography
					level="h4"
					sx={(theme) => ({
						mb: 2,
						textAlign: "left",
						color: theme.vars.palette.primary[600],
					})}
				>
					{t("hebergement")}
				</Typography>

				<HideSectionButton
					section="chambres"
					placeId={placeId}
					uid={uid}
					sectionsVisibility={sectionsVisibility || []}
				/>

				<Typography
					sx={{ whiteSpace: "pre-wrap" }}
					paragraph
					variant="body2"
				>
					{description_hebergement}
				</Typography>
				<Sheet
					sx={{
						mt: 2,
						width: "950px",
						maxWidth: "100%",
						overflox: "auto",
					}}
					variant="outlined"
				>
					<Table
						sx={{ width: "max-content" }}
						aria-label="striped table"
					>
						<tbody>
							<tr>
								{rooms.map((room) => (
									<th key={room.label}>
										<b>{t(room.label)}</b>
									</th>
								))}
							</tr>
							<tr>
								{rooms.map((room) => (
									<td key={room.label}>{t(room.data)}</td>
								))}
							</tr>
						</tbody>
					</Table>
				</Sheet>
			</Box>

			{bedrooms && (
				<>
					<Typography
						level="h5"
						sx={(theme) => ({
							mb: 2,
							mt: 3,
							textAlign: "left",
							color: theme.vars.palette.primary[600],
						})}
					>
						{t("bedroom_list")}
					</Typography>
					<Box sx={{ display: "flex", alignItems: "center" }}>
						<SwipeComponent>
							{bedrooms.map((e, index) => (
								<PlaceRoomModalCard
									key={`bedrooms_${index}`}
									{...e}
								/>
							))}
						</SwipeComponent>
					</Box>
				</>
			)}
		</>
	);
};

export default Hebergement;
