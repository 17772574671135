import React from "react";
import { useTranslation } from "react-i18next";
import {
	Box,
	Typography,
	Input,
	Button,
	FormControl,
	FormLabel,
	IconButton,
} from "@mui/joy";
import { Search } from "@mui/icons-material";

import PropTypes from "prop-types";

const EmailSearch = ({ register, save, setValue }) => {
	const { t } = useTranslation();

	return (
		<>
			<FormControl>
				<FormLabel>{t("search_by_email")}</FormLabel>
				<Input
					placeholder={t("email")}
					{...register("email_search")}
					type="text"
					endDecorator={
						<IconButton
							variant="solid"
							color="primary"
							onClick={save}
							type="button"
							sx={{
								borderTopLeftRadius: 0,
								borderBottomLeftRadius: 0,
							}}
						>
							<Search />
						</IconButton>
					}
				/>
			</FormControl>
		</>
	);
};

export default EmailSearch;
EmailSearch.propTypes = {};
EmailSearch.defaultProps = {};
