import React from "react";
import { useGeoSearch } from "react-instantsearch";
import {
	MapContainer,
	Marker,
	Popup,
	TileLayer,
	useMapEvents,
} from "react-leaflet";
import { Box, Stack, Button, Typography } from "@mui/joy";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";

const MapEvents = ({ refine }) => {
	useMapEvents({
		zoomend: (event) => {
			refine({
				northEast: event.target.getBounds().getNorthEast(),
				southWest: event.target.getBounds().getSouthWest(),
			});
		},
		dragend: (event) => {
			refine({
				northEast: event.target.getBounds().getNorthEast(),
				southWest: event.target.getBounds().getSouthWest(),
			});
		},
	});
	return null;
};

const PlacesMap = ({ props, selected }) => {
	const { items, refine } = useGeoSearch(props);
	const { t } = useTranslation();
	const history = useHistory();
	const markerRefs = React.useRef({});

	// Ouvrir les popups en fonction de `selected`
	/*	React.useEffect(() => {
		Object.keys(markerRefs.current).forEach((objectID) => {
			const marker = markerRefs.current[objectID];
			if (marker) {
				if (selected === objectID) {
					marker.openPopup();
				} else {
					marker.closePopup();
				}
			}
		});
	}, [selected]);*/

	return (
		<Box sx={{ height: "100%" }}>
			<MapContainer
				center={[48.85, 2.35]}
				zoom={10}
				style={{ minHeight: "100%" }}
				minZoom={4}
				scrollWheelZoom={true}
			>
				<TileLayer
					attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
					url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
				/>
				{items.map((item) => (
					<Marker
						key={item.objectID}
						position={item._geoloc}
						ref={(ref) => {
							if (ref) {
								markerRefs.current[item.objectID] = ref;
							}
						}}
					>
						<Popup>
							<Stack spacing={1}>
								<Typography>
									<strong>{item.nom}</strong>
								</Typography>
								<Typography>{item.region}</Typography>
								<Button
									onClick={() =>
										history.push(
											`places/view/${item.objectID}`,
										)
									}
								>
									{t("visualize")}
								</Button>
							</Stack>
						</Popup>
					</Marker>
				))}
				<MapEvents refine={refine} />
			</MapContainer>
		</Box>
	);
};

export default PlacesMap;
