import React from "react";
import {
	doc,
	setDoc,
	collection,
	getDocs,
	query,
	orderBy,
	onSnapshot,
	getDoc,
	deleteDoc,
	where,
} from "firebase/firestore";
import { db } from "../../../utils/MyFirebase";
import useAuthContainer from "../../../stores/Auth";
import { useSnackbar } from "notistack";
import { useTranslation } from "react-i18next";

const useFrontEventModel = ({ eventID }) => {
	const [event, setEvent] = React.useState(null);
	const [plannings, setPlannings] = React.useState([]);

	const getEventRT = () => {
		onSnapshot(doc(db, "events", eventID), (doc) => {
			if (doc.exists()) {
				setEvent({ ...doc.data(), id: doc.id });
			}
		});
	};

	const getPlanningsRT = () => {
		const q = query(
			collection(db, "plannings"),
			where("event_id", "==", eventID),
			orderBy("planning_order", "asc"),
		);
		const unsubscribe = onSnapshot(q, (querySnapshot) => {
			const plannings = [];
			querySnapshot.forEach((doc) => {
				plannings.push({ ...doc.data(), id: doc.id });
			});

			setPlannings(plannings);
		});
	};

	return { event, getEventRT, plannings, getPlanningsRT };
};

export default useFrontEventModel;
