import React from "react";
import { createContainer } from "unstated-next";
import Helpers from "../../../utils/Helpers";

const useBuilderContainer = createContainer((props) => {
	const [contentState, setContentState] = React.useState(null);
	const [selected, setSelected] = React.useState(null);
	const [config, setConfig] = React.useState(null);
	const [createElementType, setCreateElementType] = React.useState(null);
	const [confirmDelete, setConfirmDelete] = React.useState(null);
	const [parent, setParent] = React.useState(null);
	const [mainParent, setMainParent] = React.useState(null);
	const [checked, setChecked] = React.useState([]);

	const handleCreateElement = (selected) => {
		let ID = Helpers.uuid();

		if (selected) {
			let el = {
				...Helpers.deepArraySearch(contentState, selected),
			};

			el.children.push({
				id: ID,
				title: "",
				children: [],
			});
			setContentState(
				Helpers.deepArrayUpdate(contentState, el, selected),
			);
		} else {
			setContentState([
				...contentState,
				{
					id: ID,
					title: "",
					children: [],
				},
			]);
		}
		return ID;
	};

	const deleteElement = (id) => {
		setContentState(Helpers.deepArrayDelete(contentState, id));
	};

	return {
		handleCreateElement,
		setConfig,
		parent,
		mainParent,
		setMainParent,
		setParent,
		createElementType,
		setCreateElementType,
		setChecked,
		checked,
		config,
		contentState,
		deleteElement,
		confirmDelete,
		setConfirmDelete,
		setContentState,
		selected,
		setSelected,
	};
});

export default useBuilderContainer;

export const BuilderStoreContainer = (Component) => (props) => {
	return (
		<useBuilderContainer.Provider>
			<Component {...props} />
		</useBuilderContainer.Provider>
	);
};
