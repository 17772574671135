import React from "react";
import { Box, Divider } from "@mui/joy";
import ColorSelector from "./ColorSelector";
import useCVContainer from "../../CVStore";
import ThemeItem from "./ThemeItem";

const themes = [
	{
		id: "classy",
		name: "Classique",
	},

	{
		id: "modern",
		name: "Moderne",
	},
];

const ThemeSelector = (props) => {
	const { themeEditor } = useCVContainer.useContainer();
	return themeEditor ? (
		<Box
			sx={{
				position: "fixed",
				top: 0,
				width: "300px",
				right: 0,
				p: 2,
				zIndex: 25,
				background: "#f1F1F1",

				boxShadow: "3px -3px 10px rgba(0,0,0,0.5)",
			}}
		>
			<ColorSelector />

			<Divider sx={{ m: 3 }} />

			<Box>
				{themes.map((t) => (
					<ThemeItem {...t} />
				))}
			</Box>
		</Box>
	) : null;
};

export default ThemeSelector;
