import React from "react";
import { Card, Stack, Typography, Box, IconButton, Switch } from "@mui/joy";
import { useTranslation } from "react-i18next";
import useUsefullInfosContainer from "../../UsefullInfosStore";
import { Edit, AddCircle } from "@mui/icons-material";

const InfoItem = ({ label, Icon, id, active }) => {
	const { t } = useTranslation();
	const { editor, setEditor, setUsefullInfosState, usefullInfosState } =
		useUsefullInfosContainer.useContainer();
	return (
		<Card
			sx={(theme) => ({
				cursor: "pointer",
				position: "relative",
				"&:hover": {
					opacity: 0.8,

					".MuiStack-root .MuiSvgIcon-root:nth-child(1)": {
						display: "none",
					},
					".MuiStack-root .MuiSvgIcon-root:nth-child(2)": {
						display: "block",
					},
				},
				p: 3,
				flexGrow: 1,

				"@media (max-width:502px)": {
					width: "100%",
				},
				border:
					editor === id &&
					`2px solid ${theme.vars.palette.primary[400]}`,
			})}
		>
			<Box onClick={() => setEditor(id)}>
				<Stack justifyContent="center" alignItems="center">
					<Icon
						sx={(theme) => ({
							fontSize: "45px",
							transition: "ease 300ms",
							color: active
								? theme.vars.palette.primary[400]
								: "neutral",
						})}
					/>
					{active ? (
						<Edit
							sx={(theme) => ({
								display: "none",
								transition: "ease 300ms",
								fontSize: "45px",
								color: theme.vars.palette.primary[400],
							})}
						/>
					) : (
						<AddCircle
							sx={(theme) => ({
								display: "none",
								transition: "ease 300ms",
								fontSize: "45px",
								color: theme.vars.palette.primary[400],
							})}
						/>
					)}
					<Typography>{t(label)}</Typography>
				</Stack>
			</Box>
			<Box
				sx={{
					textAlign: "center",
					mt: 2,
				}}
			>
				<Switch
					onChange={(e) => {
						const v = e.target.checked;
						setUsefullInfosState({
							...usefullInfosState,
							[id]: {
								...usefullInfosState[id],
								active: v,
							},
						});
					}}
					checked={usefullInfosState[id]?.active}
				/>
			</Box>
		</Card>
	);
};

export default InfoItem;
