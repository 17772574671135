import React from "react";
import { Box } from "@mui/joy";
import { useTranslation } from "react-i18next";
import useBuilderContainer from "../../../../../../BuilderStore";
import { Save, Edit } from "@mui/icons-material";
import { useForm } from "react-hook-form";

const ImageFirebase = ({
	id,
	update,
	type,
	label,
	rules,
	defaultValue,
	compression,
}) => {
	const { t } = useTranslation();

	const handleSave = (d, n) => {
		update({
			[label]: d,
			[`${label}_name`]: n,
		});
	};

	return <Box sx={{ mb: 4 }}></Box>;
};

export default ImageFirebase;
