import React from "react";
import { useTranslation } from "react-i18next";
import { Box, Typography } from "@mui/joy";
import PropTypes from "prop-types";
import { Hits } from "react-instantsearch";
import { PlacesTemplateCard } from "UI/Grids";
import { AddToFavorite } from "Common/favorites";

const PlaceCardHit = ({
	t,
	description,
	pays,
	image,
	nom,
	selected,
	region,
	setSelect,
	type_lieu,
	objectID,
	nombre_couchage_max,
}) => (
	<PlacesTemplateCard
		setSelect={() => null}
		title={nom}
		selectedID={selected}
		image={image}
		location={`${region ? region : ""} ${pays ? `,${pays}` : ""}`}
		type={type_lieu}
		description={description}
		beds={nombre_couchage_max}
		id={objectID}
		quoteDisabled
		addToFavorite={
			<AddToFavorite
				id={objectID}
				featured_image={image}
				nom={nom}
				type={"places"}
				iconButton={true}
			/>
		}
		visualizeLink={`places/view/${objectID}`}
	/>
);

const Results = ({ showMap, selected, setSelect }) => {
	const { t } = useTranslation();

	return (
		<>
			<Box
				sx={{
					height: showMap && "calc(60vh)",
					overflow: showMap && "auto",

					mt: 2,
					mb: 2,
					p: 2,
					".ais-Hits-list": {
						display: "flex",
						justifyContent: "center",
						flexWrap: "wrap",
						gap: 2,
						borderRadius: 5,
						li: {
							width: "280px",
							maxWidth: "100%",
						},
					},
				}}
			>
				<Hits
					hitComponent={({ hit }) => (
						<PlaceCardHit
							setSelect={setSelect}
							selected={selected}
							key={hit.objectID}
							t={t}
							{...hit}
						/>
					)}
				/>
			</Box>
		</>
	);
};

export default Results;
Results.propTypes = {
	showMap: PropTypes.bool,
};
Results.defaultProps = {};
