import FirestoreService from "services/firestoreServices";
import Helpers from "utils/Helpers";
import { where, orderBy } from "firebase/firestore";
import Activities from "models/activities";
import Planning from "models/plannings";
import Meals from "models/meals";

/**
 * Creates an instance of the Event class.
 * @constructor
 * @param {Object} params - The constructor parameters.
 * @param {string} params.event_name - The name of the event.
 * @param {string} params.coordonnees_gps - The name of the event.
 * @param {string} params.event_adresse - The name of the event.
 * @param {string} params.event_code_postal - The name of the event.
 * @param {string} params.event_ville - The name of the event.
 * @param {string} params.event_region - The name of the event.
 * @param {string} params.event_pays - The name of the event.
 * @param {string} params.event_placeId - Id of the place (only for partner users).
 * @param {string} params.event_background_image - URL of the background image for the event.
 * @param {number} params.event_participants_qty - The quantity of participants.
 * @param {string} params.status - The current status of the event.
 * @param {Array} params.event_locations - An array of locations for the event.
 * @param {Object} params.usefull_infos - Useful information about the event.
 * @param {boolean} params.event_date_stopped - Indicates if the event date is final.
 * @param {Array} params.event_theme - Themes of the event.
 * @param {string} params.event_frame - Frame or structure of the event.
 * @param {string} params.event_type - Type event.
 * @param {string} params.event_description - Description of the event.
 * @param {Array} params.event_objective - Objectives of the event.
 * @param {string} params.event_date_period - Period of the event.
 * @param {Date} params.event_end_date - End date of the event.
 * @param {Array} params.event_periods - périodes (pour les evénèments de partagé)
 * @param {String} params.event_seminar_type - Type de séminaire
 * @param {String} params.usefull_infos_image - Image bannière infos utiles
 * @param {number} params.days_duration - Nombre de journée (pour les evénèments de partagé) .
 * @param {number} params.nights_duration - Nombre de Nuité (pour les evénèments de partagé).
 * @param {Date} params.event_start_date - Start date of the event.
 * @param {string} params.event_day_duration - Duration of the event days.
 * @param {string} params.event_logo - URL of the event logo.
 * @param {string} params.event_budget - Budget for the event.
 * @param {string} params.event_budget_type - Type of the event budget.
 * @param {string} params.uid - User ID of the event creator.
 * @param {string} params.fromPlaceId - From place ID (not a model).
 * @param {Object} params.style - Style settings for the event.
 * @param {Array} params.active_modules - Active modules for the event.
 * @param {Array} params.active_modules_global - Active modules for the event.
 * @param {boolean} params.shared - Whether the event is shared publicly.
 * @param {Object} params.qrcode_options - Options for generating a QR code.
 * @param {Date} params.modified_at - Last modification date.
 * @param {number} params.event_participants_qty_min - Participants quantity min (disponible pour les modeles de lieux)
 * @param {number} params.event_participants_qty_max - Participants quantity max (disponible pour les modeles de lieux)
 * @param {Date} params.created_at - Creation date of the event.
 */

class Event {
    constructor(params) {
        Object.assign(this, params);
        this.modified_at = new Date();

        this.firestoreService = new FirestoreService("events");
    }

    async getEvent(id) {
        try {
            const event = await this.firestoreService.getDocument(id);
            return event;
        } catch (error) {
            console.error("Failed to fetch event:", error);
            throw error;
        }
    }

    async getEvents(lastVisible = null, constraints = []) {
        try {
            const result = await this.firestoreService.getDocuments(
                constraints,
                false,
                null,
                20,
                lastVisible,
            );
            if (lastVisible) {
                return {
                    documents: result.documents,
                    lastVisible: result.lastVisible,
                    no_more_data: result.no_more_data,
                };
            } else {
                return result;
            }
        } catch (error) {
            console.error("Failed to fetch events:", error);
            throw error;
        }
    }
    async getEventsByUid(uid, params) {
        try {
            const clauses = [where("uid", "==", uid)];
            if (params?.placeID) {
                clauses.push(where("placeId", "==", params.placeID));
            }
            if (params?.models) {
                clauses.push(orderBy("placeId", "desc"));
            }
            clauses.push(orderBy("created_at", "desc"));

            const events = await this.firestoreService.getDocuments(clauses);
            if (events && events.length > 0) {
                return events;
            } else {
                return null;
            }
        } catch (error) {
            console.error("Failed to fetch event:", error);
            throw error;
        }
    }

    async addEvent() {
        try {
            const event = await this.firestoreService.addDocument(
                this.toJSON(),
            );
            return { id: event.id, ...this.toJSON() };
        } catch (error) {
            console.error("Failed to add event:", error);
            throw error;
        }
    }

    async deleteEvent(id) {
        try {
            await this.firestoreService.deleteDocument(id);
        } catch (error) {
            console.error("Failed to delete event:", error);
            throw error;
        }
    }

    async updateEvent(id) {
        try {
            const updatedEvent = await this.firestoreService.updateDocument(
                id,
                this.toJSON(),
                [],
            );
            return updatedEvent;
        } catch (error) {
            console.error("Failed to update event:", error);
            throw error;
        }
    }

    async duplicateEvent(event_name, eventId, propertiesToRemove = [], uid) {
        try {
            // Fetch the original event
            const originalEvent = await this.getEvent(eventId);
            if (!originalEvent) {
                throw new Error(`Event with ID ${eventId} not found.`);
            }

            // Filter out the properties to be removed
            const newEventParams = {
                ...originalEvent,
                event_name,
                fromPlaceId: originalEvent.placeId,
                uid,
            };
            propertiesToRemove.forEach((prop) => {
                delete newEventParams[prop];
            });

            // Update the necessary properties
            newEventParams.created_at = new Date();
            newEventParams.modified_at = new Date();

            // Create a new event object with filtered properties
            const newEvent = new Event(newEventParams);

            // Save the new event to Firestore
            const addedEvent = await newEvent.addEvent();

            // Duplicate activities
            const activities = new Activities({});
            const originalActivities = await activities.getActivitiesByEventId(
                eventId,
            );
            if (originalActivities) {
                await activities.duplicateActivity(eventId, addedEvent.id, uid);
            }

            // Duplicate planning
            const planning = new Planning({});
            const originalPlanning = await planning.getPlanningByEventId(
                eventId,
            );
            if (originalPlanning) {
                await planning.duplicatePlanning(eventId, addedEvent.id, uid);
            }

            // Duplicate meals
            const meals = new Meals({});
            const originalMeals = await meals.getMealsByEventId(eventId);
            if (originalMeals) {
                await meals.duplicateMeals(eventId, addedEvent.id, uid);
            }

            return addedEvent;
        } catch (error) {
            console.error("Failed to duplicate event:", error);
            throw error;
        }
    }

    toJSON() {
        let data = {};
        const properties = {
            event_name: this.event_name,
            event_periods: this.event_periods,
            days_duration: this.days_duration,
            nights_duration: this.nights_duration,
            event_participants_qty_min: this.event_participants_qty_min,
            event_participants_qty_max: this.event_participants_qty_max,
            event_background_image: this.event_background_image,
            event_participants_qty: this.event_participants_qty,
            status: this.status,
            event_coordonnees_gps: this.event_coordonnees_gps,
            event_adresse: this.event_adresse,
            event_ville: this.event_ville,
            event_code_postal: this.event_code_postal,
            event_region: this.event_region,
            event_pays: this.event_pays,
            event_locations: this.event_locations,
            usefull_infos: this.usefull_infos,
            event_date_stopped: this.event_date_stopped,
            event_theme: this.event_theme,
            event_frame: this.event_frame,
            event_description: this.event_description,
            event_objective: this.event_objective,
            usefull_infos_image: this.usefull_infos_image,
            event_date_period: this.event_date_period,
            event_end_date: this.event_end_date,
            event_start_date: this.event_start_date,
            fromPlaceId: this.fromPlaceId,
            event_day_duration: this.event_day_duration,
            event_type: this.event_type,
            placeId: this.placeId,
            event_logo: this.event_logo,
            event_budget: this.event_budget,
            event_budget_type: this.event_budget_type,
            uid: this.uid,
            style: this.style,
            active_modules: this.active_modules,
            active_modules_global: this.active_modules_global,
            event_seminar_type: this.event_seminar_type,
            shared: this.shared,
            qrcode_options: this.qrcode_options,
            modified_at: this.modified_at,
            created_at: this.created_at,
        };

        // Add only properties that are not undefined
        for (let key in properties) {
            if (properties[key] !== undefined) {
                data[key] = properties[key];
            }
        }

        return data;
    }
}

export default Event;
