import React from "react";
import { EditPlaceForm } from "forms/places";
import { Button, Sheet, Typography, Stack } from "@mui/joy";
import { ArrowBack } from "@mui/icons-material";
import { Link } from "react-router-dom";

function PlaceCreate() {
	return (
		<>
			<Button
				variant="outlined"
				sx={{ mb: 3, textDecoration: "none" }}
				component={Link}
				to="/places"
				direction="row"
			>
				<ArrowBack />
				<Typography>Retour à la liste des lieux</Typography>
			</Button>
			<Sheet sx={{ background: "none", position: "relative" }}>
				<EditPlaceForm create />
			</Sheet>
		</>
	);
}

export default PlaceCreate;
