import React from "react";
import { PlanningBack } from "modules/planning";
import { ActivitiesBack } from "modules/activities";
import { MealsBack } from "modules/meals";
import PropTypes from "prop-types";

const modules = [
	{
		id: "planning",
		component: PlanningBack,
		description: "manage_planning",
	},
	{
		id: "activities",
		component: ActivitiesBack,
		description: "manage_activities",
	},
	{
		id: "meals",
		component: MealsBack,
		description: "manage_meals",
	},
];

const ModuleRouter = ({ moduleName, eventID }) => {
	try {
		const mod = modules.find((m) => moduleName === m.id);
		if (mod) {
			const Module = mod.component;
			return <Module eventID={eventID} />;
		} else {
			console.log("module not found");
			return null;
		}
	} catch (e) {
		console.log("module does not exist");
		return null;
	}
};

export default ModuleRouter;

ModuleRouter.propTypes = {
	moduleName: PropTypes.string.isRequired,
	eventID: PropTypes.string.isRequired,
};
ModuleRouter.defaultProps = {};
