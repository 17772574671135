import FirestoreService from "services/firestoreServices";

/**
 * Creates an instance of the PrivatePlace class.
 * @constructor
 * @param {array} params.contacts - liste de contact
 * @param {array} params.documents - liste de documents
 * @param {object} params.facturation - Informations de facturation
 * @param {date} params.created_at - Date de création
 * @param {date} params.modified_at - Date de modification
 
 * @param {array} params.notes - Notes 
 
 * @param {string} placeId - ID du document parent dans la collection 'places'
 */
class PrivatePlace {
    constructor(placeId, params) {
        Object.assign(this, params);
        this.placeId = placeId;
        this.modified_at = new Date();
        this.firestoreService = new FirestoreService(
            `/places/${placeId}/private`,
        );
    }

    async getPrivateData(clause, onUpdate) {
        try {
            const privateData = await this.firestoreService.getDocuments(
                clause,
                true,
                onUpdate,
            );
            return privateData;
        } catch (error) {
            console.error("Failed to fetch private data:", error);
            throw error;
        }
    }

    async getPrivateDataPagination(lastVisible = null, constraints = []) {
        try {
            const result = await this.firestoreService.getDocuments(
                constraints,
                false,
                null,
                20,
                lastVisible,
            );
            if (lastVisible) {
                return {
                    documents: result.documents,
                    lastVisible: result.lastVisible,
                    no_more_data: result.no_more_data,
                };
            } else {
                return result;
            }
        } catch (error) {
            console.error("Failed to fetch private data:", error);
            throw error;
        }
    }

    async getPrivateDocument(id) {
        try {
            const privateDoc = await this.firestoreService.getDocument(id);
            return privateDoc;
        } catch (error) {
            console.error("Failed to fetch private document:", error);
            throw error;
        }
    }

    async addPrivateDocument() {
        try {
            const privateDoc = await this.firestoreService.addDocument(
                this.toJSON(),
            );
            return { id: privateDoc.id, ...this.toJSON() };
        } catch (error) {
            console.error("Failed to add private document:", error);
            throw error;
        }
    }

    async deletePrivateDocument(id) {
        try {
            await this.firestoreService.deleteDocument(id);
        } catch (error) {
            console.error("Failed to delete private document:", error);
            throw error;
        }
    }

    async updatePrivateDocument(id) {
        try {
            const updatedPrivateDoc =
                await this.firestoreService.updateDocument(
                    id,
                    this.toJSON(),
                    [],
                );
            return updatedPrivateDoc;
        } catch (error) {
            console.error("Failed to update private document:", error);
            throw error;
        }
    }

    toJSON() {
        let data = {};
        const properties = {
            contacts: this.contacts,
            documents: this.documents,
            facturation: this.facturation,
            created_at: this.created_at,
            modified_at: this.modified_at,
            notes: this.notes,
        };

        // Ajoute uniquement les propriétés définies
        for (let key in properties) {
            if (properties[key] !== undefined) {
                data[key] = properties[key];
            }
        }

        return data;
    }
}

export default PrivatePlace;
