import React from "react";
import { Builder } from "../../../../components/UI";
import { Typography, Box, Divider } from "@mui/joy";
import { useTranslation } from "react-i18next";

const Contact = ({ contact, setContact }) => {
	const { t } = useTranslation();
	return (
		<Box>
			<Typography
				sx={{
					mb: 2,
				}}
				level="h4"
			>
				{t("Contact")}
			</Typography>

			<Builder
				content={contact}
				action={(d) => setContact(d)}
				editorConfig={{
					hideSubcat: true,

					terms: {
						category: "Information de contact",

						subCategory: "Sous-catégorie plannign",
						element: "Détails de contact",
						add_main_category:
							" Ajouter une nouvelle information de contact",
						content: "Listes des détails",
						informations: "Détails",
						add_sub_cat: "Sous cat créer",

						element_in_this_cat: "",
						sure_you_want_delete_this_element:
							"Tu veux supprimer ça ?!  Ah OUAI  ?  t'es ouuuuuuf ou quoi !  ",
						add_element: "Ajouter un détail",
					},
					modalSize: "100%",
					limitCatDepth: true,
					noChilds: true,
					mainCategory: {
						cardFields: ["title"],
						editor: {
							hasChildren: true,
							fields: [
								{
									label: "title",
									type: "text",
									rules: {
										required: true,
									},
								},
								{
									label: "contact_content",
									type: "text",
									rules: {
										required: true,
									},
								},
								{
									label: "contact_icon",
									type: "icon",
									rules: {
										required: true,
									},
								},
							],
						},
					},
					element: {
						cardFields: ["title"],
						editor: {
							hasChildren: false,
							fields: [
								{
									label: "title",
									type: "text",
									rules: {
										required: true,
									},
								},

								{
									label: "description",
									type: "textarea",
									rules: {
										required: false,
									},
								},
							],
						},
					},
					subCategory: {
						cardFields: ["title"],
						editor: {
							hasChildren: false,
							fields: [
								{
									label: "title",
									type: "text",
									rules: {
										required: true,
									},
								},
								{
									label: "description",
									type: "textarea",
									rules: {
										required: false,
									},
								},
							],
						},
					},
				}}
			/>
			<Divider sx={{ m: 3 }} />
		</Box>
	);
};

export default Contact;
