import React from "react";
import { Box, Table, IconButton, Switch, Typography } from "@mui/joy";

import { useTranslation } from "react-i18next";

import { Settings, Edit, Delete, ContentCopy } from "@mui/icons-material";

import { Dropdown } from "react-bootstrap";

const SimpleTable = ({ head, datas, fields, actions, style, inputProps }) => {
	const { t } = useTranslation();

	return (
		<Table size="lg" sx={{ ...style }} {...inputProps}>
			<thead>
				{head.map((th) => (
					<th key={th}>{t(th)}</th>
				))}
			</thead>
			<tbody>
				{Array.isArray(datas) &&
					datas.map((event) => (
						<tr>
							{Object.keys(fields).map((k) => {
								const v = fields[k](event[k]);
								return <td key={k}>{v}</td>;
							})}
							{actions.map((action) => (
								<td>{action(event)}</td>
							))}
						</tr>
					))}
			</tbody>
		</Table>
	);
};

export default SimpleTable;
