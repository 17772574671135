import React from "react";
import EditorRouter from "./EditorRouter";
import useUsefullInfosContainer from "../../UsefullInfosStore";
import {
	Modal,
	ModalDialog,
	ModalOverflow,
	Box,
	Stack,
	ModalClose,
} from "@mui/joy";

const Editor = (props) => {
	const { editor, setEditor } = useUsefullInfosContainer.useContainer();
	return (
		<Box>
			<EditorRouter id={editor} />
		</Box>
	);
};

export default Editor;
