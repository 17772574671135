import React from "react";
import { Header, Footer } from "../../partials";
import LayoutTwoColumn from "../../LayoutTwoColumn";
import { RecoverForm } from "./partials";

const ResetPassword = (props) => {
	return (
		<LayoutTwoColumn>
			<Header
				title="password_forgoten"
				subtitle="set_your_email_to_recover_password"
			/>

			<RecoverForm />
			<Footer />
		</LayoutTwoColumn>
	);
};

export default ResetPassword;
