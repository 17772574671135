import React from "react";
import { useTranslation } from "react-i18next";
import { Avatar, Box, Typography, List, ListItem, Button } from "@mui/joy";
import PropTypes from "prop-types";
import { Add } from "@mui/icons-material";

const View = ({ notes, select }) => {
	const { t } = useTranslation();

	return (
		<>
			<List>
				{notes &&
					notes.map((note) => (
						<ListItem
							onClick={() => select(note)}
							sx={{
								"&:hover": {
									opacity: 0.8,
								},
								cursor: "pointer",
								mb: 1,
								background: "",
								border: "1px solid #EEE",
							}}
						>
							<Box sx={{ overflow: "hidden" }}>
								<Typography sx={{ mb: 1 }}>
									<b>{note?.by}</b> {t("as_wroten")} :
								</Typography>
								<Typography
									sx={{
										display: "-webkit-box",
										WebkitLineClamp: "3",
										WebkitBoxOrient: "vertical",
										overflow: "hidden",
										textOverflow: "ellipsis",
									}}
								>
									{note?.note}
								</Typography>
							</Box>
						</ListItem>
					))}
			</List>
			<Button onClick={() => select(null)} fullWidth>
				<Add /> {t("add_note")}
			</Button>
		</>
	);
};

export default View;
View.propTypes = {};
View.defaultProps = {};
