import React from "react";
import { useTranslation } from "react-i18next";
import { Box, Typography, Container } from "@mui/joy";
import PropTypes from "prop-types";
import { AddSimpleImage } from "UI";
import { Image } from "@mui/icons-material";

const EventLogoAndBackground = ({ setValue, getValues, watch }) => {
	const { t } = useTranslation();
	return (
		<>
			<Box
				sx={{
					display: "flex",
					flexDirection: { xs: "column", md: "row" },
					gap: 2,
				}}
			>
				<AddSimpleImage
					style={{
						height: { md: 250, xs: 150 },
						width: { md: 250, xs: "100%" },
					}}
					icon={<Image />}
					remove={(i) => setValue("event_logo", null)}
					image={watch("event_logo")}
					action={(i) => setValue("event_logo", i)}
					label={t("event_logo")}
				/>
				<AddSimpleImage
					style={{
						height: { md: 250, xs: 150 },

						flexGrow: 1,
					}}
					icon={<Image />}
					remove={(i) => setValue("event_background_image", null)}
					image={watch("event_background_image")}
					action={(i) => setValue("event_background_image", i)}
					label={t("event_background_image")}
				/>
			</Box>
		</>
	);
};

export default EventLogoAndBackground;
EventLogoAndBackground.propTypes = {};
EventLogoAndBackground.defaultProps = {};
