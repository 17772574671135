import React from "react";
import { Box, Typography } from "@mui/joy";
import { Banners } from "../../../.";
import { ServicesListing } from "../../../services/views/ServicesList/partials";
import { BOLayout } from "../../../../components/layouts";
import useEventStore, { EventStoreContainer } from "../../../event/EventStore";
import { Content as EventList } from "../../../event/views/ListEvent/partials";
import { useTranslation } from "react-i18next";
import { WhiteContainer } from "../../../../components/UI";

const Dashboard = (props) => {
	const { docStatus } = useEventStore.useContainer();
	const { t } = useTranslation();
	return (
		<BOLayout
			isDocumentStatusMonitoring
			documentStatus={docStatus}
			title="dashboard"
		>
			<Box>
				<Banners
					name="banner_slider"
					contentModel="dashboardSliderBanner"
				/>
				<WhiteContainer>
					<Typography sx={{ mb: 2, fontWeight: "bold" }} level="h5">
						{t("my_events")}
					</Typography>
					<EventList />
				</WhiteContainer>
			</Box>
			<Box>
				<Typography sx={{ mb: 2, fontWeight: "bold" }} level="h5">
					{t("services")}
				</Typography>

				<ServicesListing />
			</Box>
		</BOLayout>
	);
};

export default EventStoreContainer(Dashboard);
