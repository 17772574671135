import React from "react";
import MainCategoryItem from "./MainCategoryItem";
import { Button, Stack, Typography, Box } from "@mui/joy";
import { Add } from "@mui/icons-material";
import { useTranslation } from "react-i18next";
import useBuilderContainer from "../../BuilderStore";
import { Container, Draggable } from "react-smooth-dnd";
import Helpers from "../../../../../utils/Helpers";

const MainCategoryList = ({ content }) => {
	const {
		setCreateElementType,
		contentState,
		mainParent,
		setContentState,
		config,
	} = useBuilderContainer.useContainer();

	const { t } = useTranslation();
	const onDragEnd = (e) => {
		const _res = Helpers.applyDrag(e, contentState);

		setContentState(_res);
	};

	return (
		<Box
			sx={(theme) => ({
				".category-dragging": {
					borderColor: theme.vars.palette.primary[400],
					borderStyle: "dashed",
					borderWidth: "2px",
				},
			})}
		>
			<Container
				lockAxis="y"
				dragHandleSelector=".category-drag-handle"
				onDrop={onDragEnd}
				dragClass="category-dragging"
				style={{
					marginRight: mainParent && (config?.modalSize || 700),
				}}
			>
				{content &&
					content.map((category) => (
						<Draggable key={category.id}>
							<MainCategoryItem
								selectedEl={mainParent === category.id}
								key={category.id}
								{...category}
							/>
						</Draggable>
					))}
			</Container>
			<Button
				onClick={() => setCreateElementType("category")}
				fullWidth
				varian="solid"
			>
				<Add />
				{t(config?.terms["add_main_category"] || "add_main_category")}
			</Button>
		</Box>
	);
};

export default MainCategoryList;
