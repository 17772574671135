import React from "react";
import { WhiteContainer } from "../../../../../../components/UI";
import { Stack, Box, Typography, Button } from "@mui/joy";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

const EmptyEvent = (props) => {
	const { t } = useTranslation();
	return (
		<Stack
			direction="row"
			justifyContent="space-between"
			alignItems="center"
			sx={{
				background: "white",
				p: 3,
				border: "1px solid #888",
				borderRadius: "10px",
			}}
		>
			<Box>
				<Typography>
					<b>{t("empty_event")}</b>
				</Typography>
				<Typography>{t("create_an_event_from_scratch")}</Typography>
			</Box>
			<Button component={Link} to="/event/new-event">
				{t("Start")}
			</Button>
		</Stack>
	);
};

export default EmptyEvent;
