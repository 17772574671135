import React from "react";
import { createContainer } from "unstated-next";

const useEventTemplatesStore = createContainer((props) => {
	const [docStatus, setDocStatus] = React.useState();
	const [selectedEvent, setSelectedEvent] = React.useState(null);

	return {
		docStatus,
		setDocStatus,
		selectedEvent,
		setSelectedEvent,
	};
});

export default useEventTemplatesStore;

export const EventTemplatesStoreContainer = (Component) => (props) => {
	return (
		<useEventTemplatesStore.Provider>
			<Component {...props} />
		</useEventTemplatesStore.Provider>
	);
};
