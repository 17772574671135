import React from "react";
import {
	Typography,
	FormControl,
	FormLabel,
	ListItem,
	IconButton,
	Input,
	Box,
	List,
	Chip,
	Stack,
	Button,
	Textarea,
	FormControlLabel,
	Divider,
	Checkbox,
	FormGroup,
	FormHelperText,
} from "@mui/joy";
import { Edit, Delete, Add } from "@mui/icons-material";
import { useTranslation } from "react-i18next";
import EspaceModal from "./EspaceModal";
import { Controller } from "react-hook-form";
import { v4 as uuidv4 } from "uuid";

const Espaces = ({ setValue, datas, errors, register, control }) => {
	const { t } = useTranslation();
	const [espaces, setEspaces] = React.useState(datas);
	const [isModalOpen, setIsModalOpen] = React.useState(false);
	const [editingEspace, setEditingEspace] = React.useState(null);

	React.useEffect(() => {
		if (espaces.length < 1) {
			if (datas.length > 0) {
				setEspaces(datas);
			}
		}
	}, [datas]);

	const updateValues = (d) => {
		setValue("espaces", d, { shouldDirty: true });
	};

	const handleDelete = (index) => {
		const newRooms = espaces.filter((_, i) => i !== index);
		setEspaces(newRooms);
		updateValues(newRooms);
	};
	const openModalToAdd = () => {
		setEditingEspace(null);
		setIsModalOpen(true);
	};

	const openModalToEdit = (espace) => {
		setEditingEspace(espace);
		setIsModalOpen(true);
	};

	const closeModal = () => {
		setIsModalOpen(false);
	};

	const saveEspace = (espaceData) => {
		if (editingEspace) {
			const updatedEspaces = espaces.map((espace, index) =>
				espace.id === editingEspace.id ? espaceData : espace,
			);

			updateValues(updatedEspaces);
			setEspaces(updatedEspaces);
		} else {
			const newsEspaces = [...espaces, { id: uuidv4(), ...espaceData }];
			updateValues(newsEspaces);
			setEspaces(newsEspaces); // Ajouter une nouvelle salle
		}
	};

	return (
		<Box sx={{ background: "white", p: 2, mt: 1, mb: 1 }}>
			<Typography sx={{ mb: 2 }} level="h4" component="h2">
				{t("Espaces")}
			</Typography>

			<div>
				<Typography sx={{ mb: 1, mt: 2 }} component="h4">
					Liste des espaces
				</Typography>
				<List sx={{ width: { md: "350px", xs: "100%" } }}>
					{espaces.map((espace, index) => (
						<ListItem
							key={espace.id}
							sx={{
								p: 2,
								border: "1px solid #eee",
								mb: 1,
							}}
							endAction={
								<>
									<IconButton
										onClick={() => openModalToEdit(espace)}
									>
										<Edit
											variant="container"
											color="primary"
										/>
									</IconButton>
									<IconButton
										onClick={() => handleDelete(index)}
									>
										<Delete color="danger" />
									</IconButton>
								</>
							}
						>
							{espace.nom}
						</ListItem>
					))}
				</List>
				<Button onClick={openModalToAdd}>
					<Add />
					Ajouter un espace
				</Button>

				<EspaceModal
					isOpen={isModalOpen}
					onClose={closeModal}
					onSave={saveEspace}
					defaultValues={editingEspace} // Passer les valeurs par défaut pour l'édition
				/>
			</div>
		</Box>
	);
};

export default Espaces;
