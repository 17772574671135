import React from "react";

import "bootstrap/dist/css/bootstrap.min.css";
import "@fontsource/public-sans";

import ReactDOM from "react-dom/client";
import { MyFirebase, analytics } from "./utils/MyFirebase";
import "./index.css";
import "./i18n";
import { BrowserRouter as Router, Route } from "react-router-dom";
import App from "./App";
import { SnackbarProvider } from "notistack";
import { CssVarsProvider } from "@mui/joy/styles";
import theme from "./theme";
import "rheostat/css/rheostat.css";
import "react-dates/lib/css/_datepicker.css";
import ThemedStyleSheet from "react-with-styles/lib/ThemedStyleSheet";
import cssInterface from "react-with-styles-interface-css";
import RheostatDefaultTheme from "rheostat/lib/themes/DefaultTheme";
import ReactDatesDefaultTheme from "react-dates/lib/theme/DefaultTheme";

ThemedStyleSheet.registerInterface(cssInterface);
ThemedStyleSheet.registerTheme({
  ...RheostatDefaultTheme,
  ...ReactDatesDefaultTheme,
});

const app = MyFirebase;
const root = ReactDOM.createRoot(document.getElementById("root"));

root.render(
  <React.StrictMode>
    <SnackbarProvider>
      <CssVarsProvider theme={theme}>
        <Router basename="/">
          <Route path="/">
            <App />
          </Route>
        </Router>
      </CssVarsProvider>
    </SnackbarProvider>
  </React.StrictMode>,
);
