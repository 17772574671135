import React from "react";
import useCVContainer from "../../../CVStore";
import { Stack, Grid, Box, Typography } from "@mui/joy";
import { useTranslation } from "react-i18next";
import { IconPicker, IconPickerItem } from "react-fa-icon-picker";

const Contact = (props) => {
	const { t } = useTranslation();
	const { contact, color } = useCVContainer.useContainer();

	return (
		<>
			<Typography
				sx={{
					textTransform: "uppercase",
					mb: 2,
					"&::after": {
						content: '""',
						position: "relative",
						width: "40%	",
						display: "block",
						height: "2px",
						backgroundColor: "#333",
					},
				}}
				level="h4"
			>
				{t("Contact")}
			</Typography>
			{contact.map((con) => (
				<Box sx={{ mb: 1 }}>
					<Box>
						<Stack
							direction="row"
							sx={{ flexWrap: "wrap" }}
							alignItems="center"
							spacing={2}
						>
							<IconPickerItem
								icon={con?.contact_icon}
								size={14}
							/>
							<Typography sx={{ fontSize: "12px" }}>
								{con?.contact_content}
							</Typography>
						</Stack>
					</Box>
				</Box>
			))}
		</>
	);
};

export default Contact;
