import React from "react";
import { Box, Button } from "@mui/joy";
import { useTranslation } from "react-i18next";
import { useHistory, useLocation } from "react-router-dom";

const Header = (props) => {
	const location = useLocation();
	const isModelList = location.pathname.split("/")[2] === "models";
	const navigate = useHistory();
	const { t } = useTranslation();

	return !isModelList ? (
		<>
			<Box sx={{ mb: 2 }}>
				<Button onClick={() => navigate.push("/event/templates")}>
					{t("create_new_event_button")}
				</Button>
			</Box>
		</>
	) : null;
};

export default Header;
