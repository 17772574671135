import React from "react";
import { useTranslation } from "react-i18next";
import { Box, Typography, List, ListItem, Stack, IconButton } from "@mui/joy";
import PropTypes from "prop-types";
import { RemoveRedEye, Edit, Delete } from "@mui/icons-material";
import Place from "models/places";
import { useHistory, Link } from "react-router-dom";
import { where } from "firebase/firestore";

const UserPlaces = ({ uid }) => {
	const { t } = useTranslation();
	const PlaceEntity = new Place();
	const history = useHistory();
	const [deleting, setDeleting] = React.useState(false);

	const [places, setPlaces] = React.useState([]);
	const getPlace = async () => {
		try {
			PlaceEntity.getPlaces([where("uid", "==", uid)], (docs) => {
				setPlaces(docs);
			});
		} catch (e) {
			console.log(e.message);
		}
	};

	React.useEffect(() => {
		if (uid) {
			getPlace();
		}
	}, [uid]);

	const deletePlace = async (id) => {
		setDeleting(id);
		if (window.confirm(t("sure_delete_this_place"))) {
			try {
				await PlaceEntity.deletePlace(id);
				setPlaces(places.filter((e) => e.id !== id));
			} catch (e) {
				console.log(e);
			}
		}
		setDeleting(false);
	};

	return (
		<>
			<Typography>{t("x_places", { nbr: places?.length })}</Typography>

			<List>
				{places.map((event) => (
					<ListItem
						sx={{
							"&:hover": {
								".MuiStack-root": {
									display: "flex",
								},
							},
							border: "1px solid #eee",
							mb: 1,
						}}
					>
						<Typography>
							{deleting && deleting === event.id
								? t("deleting")
								: event.nom}
						</Typography>
						<Stack
							sx={{ display: "none" }}
							direction={"row"}
							spacing={1}
						>
							<IconButton
								component={Link}
								to={`/places/view/${event.id}`}
								target={"_blank"}
							>
								<RemoveRedEye />
							</IconButton>
							<IconButton>
								<Delete onClick={() => deletePlace(event.id)} />
							</IconButton>
							<IconButton
								component={Link}
								to={`/places/edit/${event.id}`}
								target={"_blank"}
							>
								<Edit />
							</IconButton>
						</Stack>
					</ListItem>
				))}
			</List>
		</>
	);
};

export default UserPlaces;
UserPlaces.propTypes = {};
UserPlaces.defaultProps = {};
