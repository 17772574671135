import React from "react";
import {
	Typography,
	FormControl,
	FormLabel,
	ListItem,
	IconButton,
	Input,
	Box,
	List,
	Chip,
	Stack,
	Button,
	Textarea,
	FormControlLabel,
	Divider,
	Checkbox,
	FormGroup,
	FormHelperText,
} from "@mui/joy";
import { Edit, Delete, Add } from "@mui/icons-material";
import { useTranslation } from "react-i18next";
import BedRoomModal from "./BedRoomModal";
import { Controller } from "react-hook-form";
import { v4 as uuidv4 } from "uuid";

const BedRooms = ({ setValue, datas, errors, register, control }) => {
	const { t } = useTranslation();
	const [bedrooms, setBedRooms] = React.useState(datas);
	const [isModalOpen, setIsModalOpen] = React.useState(false);
	const [editingBedRoom, setEditingBedRoom] = React.useState(null);

	React.useEffect(() => {
		if (bedrooms.length < 1) {
			if (datas.length > 0) {
				setBedRooms(datas);
			}
		}
	}, [datas]);

	const updateValues = (d) => {
		setValue("bedrooms", d, { shouldDirty: true });
	};

	const handleDelete = (index) => {
		const newRooms = bedrooms.filter((_, i) => i !== index);
		setBedRooms(newRooms);
		updateValues(newRooms);
	};

	const openModalToAdd = () => {
		setEditingBedRoom(null);
		setIsModalOpen(true);
	};

	const openModalToEdit = (bedroom) => {
		setEditingBedRoom(bedroom);
		setIsModalOpen(true);
	};

	const closeModal = () => {
		setIsModalOpen(false);
	};

	const saveBedRoom = (bedroomData) => {
		if (editingBedRoom) {
			const updatedBedRooms = bedrooms.map((bedroom, index) =>
				bedroom.id === editingBedRoom.id ? bedroomData : bedroom,
			);

			updateValues(updatedBedRooms);
			setBedRooms(updatedBedRooms);
		} else {
			const newsBedRooms = [
				...bedrooms,
				{ id: uuidv4(), ...bedroomData },
			];
			updateValues(newsBedRooms);
			setBedRooms(newsBedRooms); // Ajouter une nouvelle salle
		}
	};

	return (
		<Box sx={{ background: "white", p: 2, mt: 1, mb: 1 }}>
			<div>
				<Typography sx={{ mb: 1, mt: 2 }} component="h4">
					{t("bedroom_list")}
				</Typography>
				<List sx={{ width: { md: "350px", xs: "100%" } }}>
					{bedrooms.map((bedroom, index) => (
						<ListItem
							key={bedroom.id}
							sx={{
								p: 2,
								border: "1px solid #eee",
								mb: 1,
							}}
							endAction={
								<>
									<IconButton
										onClick={() => openModalToEdit(bedroom)}
									>
										<Edit
											variant="container"
											color="primary"
										/>
									</IconButton>
									<IconButton
										onClick={() => handleDelete(index)}
									>
										<Delete color="danger" />
									</IconButton>
								</>
							}
						>
							{bedroom.nom}
						</ListItem>
					))}
				</List>
				<Button onClick={openModalToAdd}>
					<Add />
					{t("add_new_bedroom")}
				</Button>

				<BedRoomModal
					isOpen={isModalOpen}
					onClose={closeModal}
					onSave={saveBedRoom}
					defaultValues={editingBedRoom} // Passer les valeurs par défaut pour l'édition
				/>
			</div>
		</Box>
	);
};

export default BedRooms;
