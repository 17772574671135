import React from "react";
import {
	Box,
	Typography,
	List,
	ListItem,
	IconButton,
	Stack,
	Card,
} from "@mui/joy";
import { useTranslation } from "react-i18next";
import {
	Add,
	Remove,
	Wifi,
	LocalPhone,
	Info,
	Map,
	Groups,
} from "@mui/icons-material";
import { InfoCard } from "./partials";
const fields = {
	wifi: { f: ["network_name", "wifi_code"], icon: <Wifi /> },
	usefull_infos: { f: ["description", "image"], icon: <Info /> },
	usefull_phone: { f: ["phone_number", "image"], icon: <LocalPhone /> },
	team: { f: ["email", "phone", "image"], icon: <Groups /> },
	map: { f: "map_img", icon: <Map /> },
};

const Infos = ({ style, usefull_infos }) => {
	const [open, setOpen] = React.useState(false);
	const { t } = useTranslation();
	return usefull_infos ? (
		<Box>
			{console.log(usefull_infos)}
			<List>
				{Object.keys(usefull_infos).map((k, i) => {
					const info = usefull_infos[k];

					return usefull_infos[k]?.active ? (
						<>
							<ListItem
								sx={{
									borderTop: i !== 0 && "1px solid",
									pt: 2,
									color:
										style?.theme_mode === "dark" && "white",
									mt: 2,
									pb: 2,
									borderColor: "#888",
									justifyContent: "space-between",
								}}
							>
								<Stack
									direction="row"
									spacing={2}
									alignItems="center"
									sx={{
										".MuiSvgIcon-root": {
											fontSize: "40px",
										},
									}}
								>
									{fields[k].icon}
									<Typography
										sx={{
											color:
												style?.theme_mode === "dark" &&
												"white",
										}}
									>
										{t(k)}
									</Typography>
								</Stack>
								<IconButton
									sx={{
										background: "none",
										color: "initial",
										border: "1px solid",
										borderRadius: "100px",
										p: "1px",
										"&:hover": {
											background: style?.theme_color,
										},
										"&:active": {
											background: style?.theme_color,
										},
										background:
											open === k
												? style?.theme_color
												: "transparent",
									}}
									onClick={() =>
										open !== k && !Boolean(open)
											? setOpen(k)
											: setOpen(false)
									}
								>
									{open === k ? (
										<Remove
											sx={{
												color:
													style?.theme_mode ===
														"dark" && "white",
											}}
										/>
									) : (
										<Add
											sx={{
												color:
													style?.theme_mode ===
														"dark" && "white",
											}}
										/>
									)}
								</IconButton>
							</ListItem>
							<Box
								sx={{
									display: open === k ? "block" : "none",
								}}
							>
								{typeof fields[k]?.f === "string" &&
									fields[k]?.f.match(/_img/gm) && (
										<>
											<img
												style={{ width: "100%" }}
												src={
													info[fields[k]?.f]?.full ||
													info[fields[k]?.f]
												}
											/>
										</>
									)}

								{info?.content && (
									<List
										style={{
											color:
												style?.theme_mode === "dark" &&
												"white",
										}}
									>
										{info?.content.map((item, index) => (
											<Card
												sx={{
													background:
														style?.theme_mode ===
														"dark"
															? "#191919"
															: "white",
													mb: 2,
													boxShadow:
														style?.theme_mode ===
															"dark" && "none",
													borderRadius: "10px",
													display:
														fields[k] &&
														fields[k].f.length > 1
															? "block"
															: "flex",
												}}
											>
												<Stack
													direction="row"
													alignItems="center"
													justifyContent="space-between"
												>
													<Box>
														<Typography
															sx={(theme) => ({
																fontWeight:
																	"600",
																color:
																	style?.theme_mode ===
																		"dark" &&
																	style?.theme_color,
															})}
														>
															{item?.title}
														</Typography>
														{fields[k] &&
															(fields[k].f
																.length > 1 ? (
																<List
																	style={{}}
																>
																	{fields[k].f
																		.filter(
																			(
																				f,
																			) =>
																				f !==
																				"image",
																		)
																		.map(
																			(
																				field,
																				index,
																			) => (
																				<ListItem>
																					<div>
																						<b
																							style={{
																								color:
																									style?.theme_mode ===
																										"dark" &&
																									"#BBB",
																								fontSize:
																									"13px",
																								fontWeight: 900,
																							}}
																						>
																							{t(
																								`${field}_infos`,
																							)}{" "}
																							:{" "}
																						</b>
																						<span
																							style={{
																								color:
																									style?.theme_mode ===
																										"dark" &&
																									"#F1F1F1",
																							}}
																						>
																							{
																								item[
																									field
																								]
																							}
																						</span>{" "}
																					</div>
																				</ListItem>
																			),
																		)}
																</List>
															) : (
																<span
																	style={{
																		color:
																			style?.theme_mode ===
																				"dark" &&
																			"#FFF",
																	}}
																>
																	:{" "}
																	{
																		item[
																			fields[
																				k
																			]
																				.f[0]
																		]
																	}{" "}
																</span>
															))}
													</Box>
													{item?.image && (
														<Box
															sx={{
																background: `url(${
																	item?.image
																		?.thumb ||
																	item?.image
																		?.full ||
																	item?.image
																}) center center / cover`,
																width: "90px",
																height: "90px",
																borderRadius:
																	"10px",
															}}
														></Box>
													)}
												</Stack>
											</Card>
										))}
									</List>
								)}
							</Box>
						</>
					) : null;
				})}
			</List>
		</Box>
	) : null;
};

export default Infos;
