import React from "react";
import { useTranslation } from "react-i18next";
import {
	Box,
	Typography,
	Button,
	Tabs,
	TabList,
	TabPanel,
	Tab,
} from "@mui/joy";
import PropTypes from "prop-types";
import { Link, useParams } from "react-router-dom";
import { WhiteContainer } from "UI";
import { UserCard, UserInfos, UserEvents, UserPlaces } from "./partials";
import User from "models/user";

const UserDetails = (props) => {
	const { t } = useTranslation();
	const UserEntity = new User();
	const { userId } = useParams();
	const [user, setUser] = React.useState({});

	const fetchUser = async () => {
		try {
			// fetch user document in user collection
			const gettedUser = await UserEntity.getUser(userId);
			setUser(gettedUser);
		} catch (e) {
			console.log(e);
		}
	};

	React.useEffect(() => {
		fetchUser();
	}, [userId]);

	return (
		<>
			<Button component={Link} to="/admin/users">
				{t("back_to_user_list")}
			</Button>
			<Box
				sx={{
					mt: 2,
				}}
			>
				<WhiteContainer>
					<UserCard user={user} setUser={setUser} />
				</WhiteContainer>
				<WhiteContainer>
					<Tabs defaultValue={0}>
						<TabList>
							<Tab>{t("informations")}</Tab>
							<Tab>{t("events")}</Tab>
							<Tab>{t("places")}</Tab>
						</TabList>
						<TabPanel value={0}>
							<UserInfos user={user} setUser={setUser} />
						</TabPanel>
						<TabPanel value={1}>
							<UserEvents uid={user.id} />
						</TabPanel>
						<TabPanel value={2}>
							<UserPlaces uid={user.id} />
						</TabPanel>
					</Tabs>
				</WhiteContainer>
			</Box>
		</>
	);
};

export default UserDetails;
UserDetails.propTypes = {};
UserDetails.defaultProps = {};
