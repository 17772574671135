import React from "react";
import { useTranslation } from "react-i18next";
import { Box, Typography, Stack, FormControl, FormLabel } from "@mui/joy";
import { Image as ImageIcon } from "@mui/icons-material";
import PropTypes from "prop-types";
import { AddSimpleImage } from "UI";

const Image = ({ setValue, image }) => {
	const { t } = useTranslation();

	return (
		<FormControl>
			<FormLabel>{t("qrcode_logo")}</FormLabel>

			<AddSimpleImage
				style={{
					height: "120px",
					width: "120px",
					border: "1px solid #888",
					borderRadius: "10px",

					".image-container": {
						borderRadius: "10px",
					},
				}}
				remove={() => {
					setValue("image", null);
				}}
				action={(img) => setValue("image", img)}
				icon={
					<Stack alignItems="center">
						<Typography>Logo</Typography>
						<ImageIcon />
					</Stack>
				}
				image={image}
			/>
		</FormControl>
	);
};

export default Image;
Image.propTypes = {};
Image.defaultProps = {};
