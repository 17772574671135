import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { Button } from "@mui/joy";
import PropTypes from "prop-types";

const SavingButton = ({ loading }) => {
	const { t } = useTranslation();
	const [buttonText, setButtonText] = useState(t("save"));

	useEffect(() => {
		// Initialize buttonText directly in effect to ensure it loads the correct translation
		if (!loading) {
			setButtonText(t("save"));
		}
	}, []); // Ensuring the text updates if the translation changes

	useEffect(() => {
		if (loading) {
			// When loading starts, show "Saving..."
			setButtonText(t("saving"));
		} else {
			// Once loading finishes, show "Saved" and then revert to "Save" after 2 seconds
			setButtonText(t("saved"));
			const timer = setTimeout(() => {
				setButtonText(t("save"));
			}, 2000);
			return () => clearTimeout(timer);
		}
	}, [loading]); // This effect depends on the loading state and translation function

	return (
		<Button loading={loading} disabled={loading} type="submit">
			{buttonText}
		</Button>
	);
};

SavingButton.propTypes = {
	loading: PropTypes.bool.isRequired,
};

export default SavingButton;
