import React from "react";
import ChildrenCard from "./ChildrenCard";
import { Container, Draggable } from "react-smooth-dnd";
import Helpers from "../../../../../../../utils/Helpers";
import useBuilderContainer from "../../../../BuilderStore";
import { Button } from "@mui/joy";
import { Add } from "@mui/icons-material";
import { useTranslation } from "react-i18next";

const Chidren = ({ element }) => {
	console.log(element);
	const { t } = useTranslation();
	const {
		contentState,
		setContentState,
		setCreateElementType,
		config: conf,
	} = useBuilderContainer.useContainer();
	const onDragEnd = (e) => {
		const childrens = [...element.children];

		const _res = Helpers.applyDrag(e, childrens);

		console.log(_res);
		const el = { ...element, children: _res };

		setContentState(Helpers.deepArrayUpdate(contentState, el, element.id));
	};
	const depth = parseInt(Helpers.findDepthById(contentState, element.id));
	const formatedDepth = `depth_${depth}`;

	const config = conf.itemsConfig[formatedDepth]
		? conf.itemsConfig[formatedDepth]
		: conf.itemsConfig.default;

	return (
		<>
			<Container
				lockAxis="y"
				dragHandleSelector=".element-drag-handle"
				onDrop={onDragEnd}
				dragClass="element-dragging"
			>
				{element?.children &&
					element?.children.map((child) => (
						<Draggable key={child.id}>
							<ChildrenCard
								t={t}
								elementID={element.id}
								key={child.id}
								{...child}
							/>
						</Draggable>
					))}
			</Container>

			<Button
				onClick={() => setCreateElementType("subCategory")}
				fullWidth
				varian="solid"
			>
				<Add />

				{t(config?.terms["create_new_children"] || "add_element")}
			</Button>
		</>
	);
};

export default Chidren;
