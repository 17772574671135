import React from "react";
import { useTranslation } from "react-i18next";
import { Box, Typography, List, ListItem } from "@mui/joy";
import PropTypes from "prop-types";
import { FormSeparator } from "UI";

const EventLocationFromAModel = ({ place }) => {
	const { t } = useTranslation();

	return (
		<>
			<FormSeparator title="event_locations">
				<em>{t("model_location_attach_to_place")}</em>
				<List>
					{place.adresse && (
						<ListItem>
							<b>{t("Adresse")}</b> :{" "}
							<a
								href={`https://www.google.fr/maps/dir//${place.adresse}`}
								target="_blank"
							>
								{place.adresse}
							</a>
						</ListItem>
					)}
				</List>
			</FormSeparator>
		</>
	);
};

export default EventLocationFromAModel;
EventLocationFromAModel.propTypes = {};
EventLocationFromAModel.defaultProps = {};
