import React from "react";
import { useTranslation } from "react-i18next";
import { Box, Typography } from "@mui/joy";
import PropTypes from "prop-types";
import { useParams } from "react-router-dom";
import Activities from "models/activities";
import { ActivitiesList, ElementModal } from "./partials";

const ActivitiesFront = ({ style }) => {
	const { t } = useTranslation();
	const { eventID } = useParams();
	const [datas, setDatas] = React.useState([]);
	const ActivityEntity = new Activities();
	const [selectedElement, setSelectedElement] = React.useState(null);

	const fetchDatas = async () => {
		try {
			ActivityEntity.getActivitiesByEventId(eventID, true, (d) => {
				setDatas(d[0]);
			});
		} catch (e) {
			console.log(e.message);
		}
	};
	React.useEffect(() => {
		fetchDatas();
	}, eventID);

	return (
		<>
			{datas && (
				<>
					<ActivitiesList
						style={style}
						setSelectedElement={setSelectedElement}
						selectedElement={selectedElement}
						activities={datas?.content}
					/>
					<ElementModal
						t={t}
						style={style}
						setSelectedElement={setSelectedElement}
						selectedElement={selectedElement}
					/>
				</>
			)}
		</>
	);
};

export default ActivitiesFront;
ActivitiesFront.propTypes = {};
ActivitiesFront.defaultProps = {};
