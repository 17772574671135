import React from "react";
import { FormSeparator } from "UI";
import { Input, Typography, FormControl, FormHelperText } from "@mui/joy";
import { Group } from "@mui/icons-material";
import { useTranslation } from "react-i18next";

const ParticipantQty = ({ register, error }) => {
	const { t } = useTranslation();
	return (
		<FormSeparator title="how_many" required>
			<FormControl>
				<Input
					startDecorator={<Group color="primary" />}
					type="number"
					placeholder={t("Pax")}
					{...register("event_participants_qty", {
						required: t("event_participants_qty_required"),
					})}
				/>
				{error && <FormHelperText>{error.message}</FormHelperText>}
			</FormControl>
		</FormSeparator>
	);
};

export default ParticipantQty;
