import React from "react";
import { Card, Typography, Box } from "@mui/joy";

const PlanningItem = ({ title, description, image }) => {
	return (
		<Card
			sx={{
				background: `url(${image}) center center / cover white`,
				mb: 2,
				p: 3,
				textAlign: "center",
			}}
		>
			<Card
				sx={{
					background: "rgba(0,0,0,0.6)",
					position: "absolute",
					top: 0,
					left: 0,
					right: 0,
					bottom: 0,
					zIndex: 0,
				}}
			/>
			<Box sx={{ zIndex: 1, position: "relative" }}>
				<Typography
					sx={{
						whiteSpace: "pre",
						overflow: "hidden",
						textAlign: "center",
						textOverflow: "ellipsis",
						color: "white",
					}}
					level="h5"
				>
					{title}
				</Typography>
				<Typography
					sx={{
						whiteSpace: "pre",
						overflow: "hidden",
						textAlign: "center",
						textOverflow: "ellipsis",
						color: "white",
					}}
				>
					{description}
				</Typography>
			</Box>
		</Card>
	);
};

export default PlanningItem;
