import React from "react";
import MainLogo from "../../../../../../assets/logo.png";
import { Box } from "@mui/joy";

const Logo = () => {
	return (
		<Box sx={{ textAlign: "center", mt: "40px", mb: "50px" }}>
			<img width="54px" src={MainLogo} alt="weevup logo" />
		</Box>
	);
};

export default Logo;
