import React from "react";
import { Logo, Title } from "./partials";

const Header = ({ title, subtitle }) => {
	return (
		<>
			<Logo />
			<Title title={title} subtitle={subtitle} />
		</>
	);
};

export default Header;
