import React from "react";
import MainLogo from "../../../../../../../assets/logo_text.png";
import { Box, Divider } from "@mui/joy";

const Logo = () => {
	return (
		<Box
			sx={{
				width: "100%",
				textAlign: "center",
				mt: "40px",
				mb: "50px",
			}}
		>
			<img width="79px" src={MainLogo} alt="weevup logo" />
			<Divider
				sx={{
					m: "30px auto",
					width: "60%",
				}}
			/>
		</Box>
	);
};

export default Logo;
