import React from "react";
import { useTranslation } from "react-i18next";
import {
	Box,
	Typography,
	Modal,
	ModalOverflow,
	ModalDialog,
	Input,
	Button,
	IconButton,
} from "@mui/joy";
import PropTypes from "prop-types";
import { useForm } from "react-hook-form";
import { Delete } from "@mui/icons-material";

const Editable = ({ open, close, selected, saveContact, deleteContact }) => {
	const { t } = useTranslation();
	const { register, handleSubmit, reset } = useForm();

	React.useEffect(() => {
		if (selected) {
			reset(selected);
		} else {
			reset();
		}
	}, [selected]);

	const save = (d) => {
		saveContact({ ...selected, ...d });
		reset();
		close();
	};

	return (
		<Modal open={open} onClose={close}>
			<ModalOverflow>
				<ModalDialog>
					{selected && (
						<IconButton
							sx={{ position: "absolute", right: 2, top: 2 }}
							type="button"
							color="danger"
							onClick={() => {
								deleteContact(selected.id);
								close();
							}}
						>
							<Delete />
						</IconButton>
					)}
					<Typography>{t("contact")}</Typography>
					<form onSubmit={handleSubmit(save)}>
						<Box
							sx={{
								mb: 2,
								display: "flex",
								flexDirection: "column",
								gap: 2,
							}}
						>
							<Input
								placeholder={t("first_name")}
								{...register("firstName")}
							/>
							<Input
								placeholder={t("last_name")}
								{...register("lastName")}
							/>
							<Input
								placeholder={t("mail")}
								type="mail"
								{...register("mail")}
							/>
							<Input
								placeholder={t("phone")}
								{...register("phone")}
							/>
							<Input
								placeholder={t("mobile")}
								{...register("mobile")}
							/>
							<Input
								placeholder={t("job")}
								{...register("job")}
							/>
						</Box>
						{selected ? (
							<Button fullWidth type="submit">
								{t("save")}
							</Button>
						) : (
							<Button fullWidth type="submit">
								{t("create")}
							</Button>
						)}
					</form>
				</ModalDialog>
			</ModalOverflow>
		</Modal>
	);
};

export default Editable;

Editable.propTypes = {
	open: PropTypes.bool.isRequired,
	close: PropTypes.func.isRequired,
	selected: PropTypes.object,
	saveContact: PropTypes.func.isRequired,
	deleteContact: PropTypes.func.isRequired,
};

Editable.defaultProps = {
	selected: null,
};
