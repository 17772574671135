import React from "react";
import { useTranslation } from "react-i18next";
import { Box, Typography } from "@mui/joy";
import PropTypes from "prop-types";
import { Switch, Route } from "react-router-dom";
import { GroupList, GroupDetails } from "./views";

const Groups = (props) => {
	const { t } = useTranslation();

	return (
		<Switch>
			<Route exact path="/admin/groups">
				<GroupList />
			</Route>
			<Route path="/admin/groups/:groupID">
				<GroupDetails />
			</Route>
		</Switch>
	);
};

export default Groups;
Groups.propTypes = {};
Groups.defaultProps = {};
