import React from "react";
import { Divider, Box, Typography, Stack } from "@mui/joy";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";

const FormSeparator = ({ title, children, required }) => {
	const { t } = useTranslation();
	return (
		<Box sx={{ mb: "30px" }}>
			<Typography sx={{ mb: "9px" }} level="h5">
				<Stack direction="row" gap={1}>
					{t(title)}
					<Box
						sx={(theme) => ({
							color: theme.vars.palette.primary[500],
						})}
					>
						{required && "*"}
					</Box>
				</Stack>
			</Typography>
			<Box sx={{ mb: "30px" }}>{children}</Box>
			<Divider />
		</Box>
	);
};

export default FormSeparator;

PropTypes.propTypes = {
	title: PropTypes.string,
	required: PropTypes.bool,
};

PropTypes.defaultProps = {
	title: "Mon titre",
	required: false,
};
