import React from "react";
import { useTranslation } from "react-i18next";
import { Box, Typography } from "@mui/joy";
import PropTypes from "prop-types";

const ErrorEvent = (props) => {
	const { t } = useTranslation();

	return <h3>{t("this_event_not_accessible")}</h3>;
};

export default ErrorEvent;
ErrorEvent.propTypes = {};
ErrorEvent.defaultProps = {};
