import React from "react";
import { useTranslation } from "react-i18next";
import { Box, Typography, LinearProgress } from "@mui/joy";
import PropTypes from "prop-types";
import Planning from "models/plannings";
import { NoDataYet } from "UI";
import { PlanningBuilder } from "./views";
import AuthContainer from "stores/Auth";

const PlanningBack = ({ eventID }) => {
	const { t } = useTranslation();
	const { user } = AuthContainer.useContainer();
	const [datas, setDatas] = React.useState(null);
	const [creating, setCreating] = React.useState(false);
	const [fetchAttempt, setFetchAttempt] = React.useState(false);
	const planningEntity = new Planning({
		content: [],
		created_at: new Date(),
		uid: user.uid,
		event_id: eventID,
		status: "active",
	});

	const fetchPlanning = async () => {
		try {
			const planning = await planningEntity.getPlanningByEventId(eventID);
			setFetchAttempt(true);
			if (planning) {
				setDatas(planning);
			}
		} catch (e) {
			console.log(e.message);
		}
	};

	const addPlanning = async () => {
		setCreating(true);
		try {
			const d = await planningEntity.addPlanning();
			setDatas(d);
		} catch (e) {
			console.log(e.message);
		}
		setCreating(false);
	};

	React.useEffect(() => {
		fetchPlanning();
	}, []);

	const NoData = (
		<NoDataYet
			action={addPlanning}
			loading={creating}
			terms={{
				subtitle: "planning",
				title: "add_planning_to_your_event",
				button: "start_to_add_planning",
			}}
		/>
	);

	return datas ? (
		<PlanningBuilder datas={datas} />
	) : !datas && !fetchAttempt ? (
		<LinearProgress />
	) : (
		NoData
	);
};

export default PlanningBack;
PlanningBack.propTypes = {};
PlanningBack.defaultProps = {};
