import React from "react";
import { Switch, Route, useHistory, useLocation } from "react-router-dom";
import { PlacesList, PlacesFavorites } from "./views";
import { BOLayout } from "Components/layouts";
import { Box, Typography, Tabs, Tab, TabList } from "@mui/joy";
import { useTranslation } from "react-i18next";

const SubMenu = [
	{
		id: 0,
		label: "places",
		to: "/places-templates",
	},
	{
		id: 1,
		label: "Favoris",
		to: "/places-templates/favorites",
	},
];

const PlacesTemplatesRoutes = (props) => {
	const history = useHistory();
	const location = useLocation();
	const defaultValue = SubMenu.find((m) => m.to === location.pathname).id;
	const [docId, setDocId] = React.useState(null);
	const [value, setValue] = React.useState(0);
	const { t } = useTranslation();

	return (
		<BOLayout
			documentID={docId}
			isDocumentStatusMonitoring
			title={"partner_places"}
		>
			<Tabs
				aria-label="tabs"
				defaultValue={defaultValue}
				onChange={(e, v) => history.push(SubMenu[v].to)}
			>
				<TabList
					disableUnderline
					sx={{
						p: 0.5,
						gap: 0.6,
						borderRadius: "md",
						bgcolor: "#FFF",
					}}
				>
					{SubMenu.map((link) => (
						<Tab>{t(link.label)}</Tab>
					))}
				</TabList>
			</Tabs>
			<Switch>
				<Route exact path="/places-templates">
					<PlacesList />
				</Route>
				<Route path="/places-templates/favorites">
					<PlacesFavorites />
				</Route>
			</Switch>
		</BOLayout>
	);
};

export default PlacesTemplatesRoutes;
