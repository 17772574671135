import React from "react";
import { Box } from "@mui/joy";
import { useTranslation } from "react-i18next";
import useBuilderContainer from "../../../../../../BuilderStore";
import { Save, Edit } from "@mui/icons-material";
import { useForm } from "react-hook-form";
import { ImageUploader } from "../../../../../../../";

const ImageFirebase = ({
	id,
	update,
	type,
	label,
	rules,
	defaultValue,
	compression,
}) => {
	const { t } = useTranslation();

	const handleSave = (d, n) => {
		update({
			[label]: d,
			[`${label}_name`]: n,
		});
	};

	return (
		<Box sx={{ mb: 4 }}>
			<ImageUploader
				compressImage={compression}
				labelPure={`${label}_${id}`}
				imageUrl={defaultValue}
				label={t(label)}
				onUploaded={(images) =>
					handleSave(images[0].url, images[0].name)
				}
				path={`images`}
				width="98%"
				hideLabel
				onDelete={(defaultValue) => handleSave(null, null)}
			/>
		</Box>
	);
};

export default ImageFirebase;
