import * as React from "react";
import Box from "@mui/joy/Box";
import Button from "@mui/joy/Button";
import Divider from "@mui/joy/Divider";
import Modal from "@mui/joy/Modal";
import ModalDialog from "@mui/joy/ModalDialog";
import DeleteForever from "@mui/icons-material/DeleteForever";
import WarningRoundedIcon from "@mui/icons-material/WarningRounded";
import Typography from "@mui/joy/Typography";
import { useTranslation } from "react-i18next";

export default function ConfirmModal({
	show,
	close,
	action,
	title,
	message,
	action_text,
}) {
	const [open, setOpen] = React.useState(show);
	const { t } = useTranslation();

	React.useEffect(() => {
		setOpen(show);
	}, [show]);

	return (
		<React.Fragment>
			<Modal open={open} onClose={close}>
				<ModalDialog
					variant="outlined"
					role="alertdialog"
					aria-labelledby="alert-dialog-modal-title"
					aria-describedby="alert-dialog-modal-description"
				>
					<Typography
						id="alert-dialog-modal-title"
						component="h2"
						startDecorator={<WarningRoundedIcon />}
					>
						{t(title)}
					</Typography>
					<Divider />
					<Typography
						id="alert-dialog-modal-description"
						textColor="text.tertiary"
					>
						{t(message)}
					</Typography>
					<Box
						sx={{
							display: "flex",
							gap: 1,
							justifyContent: "flex-end",
							pt: 2,
						}}
					>
						<Button variant="plain" color="neutral" onClick={close}>
							{t("cancel")}
						</Button>
						<Button
							variant="solid"
							color="danger"
							onClick={() => {
								action();
								close();
							}}
						>
							{t(action_text)}
						</Button>
					</Box>
				</ModalDialog>
			</Modal>
		</React.Fragment>
	);
}
