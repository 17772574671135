import React from "react";
import LayoutTwoColumn from "../../LayoutTwoColumn";
import { Header, Footer } from "../../partials/";
import { CreateAccountForm } from "./partials";

const CreateAccount = () => {
	return (
		<LayoutTwoColumn>
			<Header title="create_your_account" subtitle="enter_your_infos" />

			<CreateAccountForm />

			<Footer />
		</LayoutTwoColumn>
	);
};

export default CreateAccount;
