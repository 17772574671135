import React from "react";
import { useTranslation } from "react-i18next";
import {
	Box,
	Typography,
	Stack,
	Button,
	Chip,
	IconButton,
	Tooltip,
} from "@mui/joy";
import PropTypes from "prop-types";
import SimpleTable from "UI/Tables/SimpleTable/SimpleTable";
import InfiniteScroll from "react-infinite-scroll-component";
import Helpers from "utils/Helpers";
import { WhiteContainer } from "UI";
import { RemoveRedEye, Edit, Delete, Storage } from "@mui/icons-material";
import { Link } from "react-router-dom";
import { useHistory } from "react-router-dom";

const Places = ({ places, getPlaces, setPlaces, isMore }) => {
	const { t } = useTranslation();
	const history = useHistory();
	console.log(places);
	const TypographyStyle = {
		maxWidth: "100%",
		overflow: "hidden",
		textOverflow: "ellipsis",
	};
	const columns = ["Nom", "Pays", "Region", "Type", "Utilisateur", ""];
	const actions = [
		(d) => (
			<Stack direction="row" spacing={1}>
				<IconButton
					variant="outlined"
					color="primary"
					component={Link}
					to={`/places/view/${d.id}`}
					target={"_blank"}
				>
					<RemoveRedEye />
				</IconButton>
				<IconButton
					variant="outlined"
					color="primary"
					component={Link}
					//to={`/places/edit/${d.id}`}
					to={`/admin/places/${d.id}`}
					target={"_blank"}
				>
					<Edit />
				</IconButton>
				<Tooltip title={t("display_in_db")}>
					<IconButton
						variant="outlined"
						color="primary"
						component={"a"}
						href={`https://console.firebase.google.com/project/weevuptools/firestore/databases/-default-/data/places/${d.id}`}
						target={"_blank"}
					>
						<Storage />
					</IconButton>
				</Tooltip>
			</Stack>
		),
	];

	const fields = {
		nom: (v) => <Typography sx={TypographyStyle}>{v}</Typography>,
		pays: (v) => <Typography sx={TypographyStyle}>{v}</Typography>,
		region: (v) => <Typography sx={TypographyStyle}>{v}</Typography>,
		type_lieu: (v) => <Typography sx={TypographyStyle}>{t(v)}</Typography>,
		uid: (v) => (
			<Typography sx={TypographyStyle}>
				<Link to={`/admin/users/${v}`}>{v}</Link>
			</Typography>
		),
	};

	return (
		<WhiteContainer>
			<Box
				id="ed"
				sx={{ height: "60vh", width: "100%", overflowY: "scroll" }}
			>
				<InfiniteScroll
					dataLength={places.length}
					scrollableTarget="ed"
					next={getPlaces}
					hasMore={isMore}
					loader={<h4>{t("loading")}...</h4>}
				>
					<SimpleTable
						inputProps={{
							stickyHeader: true,
							stickyFooter: true,
							hoverRow: true,
							borderAxis: "both",
						}}
						style={{
							maxWidth: "100%",
							overflow: "auto",
							background: "white",
							borderRadius: "10px",
							mb: 2,
						}}
						head={columns}
						datas={places}
						fields={fields}
						actions={actions}
					/>{" "}
				</InfiniteScroll>
			</Box>
		</WhiteContainer>
	);
};

export default Places;
Places.propTypes = {
	getPlaces: PropTypes.func,
	places: PropTypes.array,
	setPlaces: PropTypes.func,
};
Places.defaultProps = {
	places: [],
};
