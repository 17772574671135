import React from "react";
import useCVContainer from "../../CVStore";
import { Stack, Grid, Box, Typography } from "@mui/joy";
import { Experiences, Formations, Profil, Contact } from "./partials";

const Classy = (props) => {
	const { color } = useCVContainer.useContainer();

	return (
		<>
			<Box container sx={{ minHeight: "100%" }}>
				<Box
					sx={{
						background: color,
						height: "100%",
						position: "absolute",
						width: "200px",
						p: 2,
					}}
				>
					<Formations />
					<Contact />
				</Box>
				<Box sx={{ p: 2, ml: "200px" }}>
					<Profil />
					<Experiences />
				</Box>
			</Box>
		</>
	);
};

export default Classy;
