import React from "react";
import { Grid, Typography, Divider, Box } from "@mui/joy";
import ContentfulClass from "../../../../utils/ContentfulClass";
import { LoginForm } from "./partials";
import LayoutTwoColumn from "../../LayoutTwoColumn";
import { Header, Footer } from "../../partials";

const contentful = new ContentfulClass();
const entry = "1IqQWHprOZsUuIFEACtX1R";

const Login = (props) => {
  const [content, setContent] = React.useState();

  const getDatas = async () => {
    try {
      const datas = await contentful.getEntry(entry);
      setContent(datas?.fields);
      console.log(datas);
    } catch (e) {
      console.log(e);
    }
  };

  React.useEffect(() => {
    getDatas();
  }, []);

  return (
    <>
      <LayoutTwoColumn>
        <Header title="login_title" subtitle="login_subtitle" />
        <LoginForm />

        <Footer />
      </LayoutTwoColumn>
    </>
  );
};

export default Login;
