import React from "react";
import { BuilderStoreContainer } from "./BuilderStore";
import {
	Editor,
	MainCategoryList,
	ConfirmDelete,
	GlobalActions,
} from "./partials";
import { CreateElement } from "./partials/Editor/partials";
import { Box } from "@mui/joy";
import PropTypes from "prop-types";
import useBuilderContainer from "./BuilderStore";

const Builder = ({ content, editorConfig, action }) => {
	const { contentState, setContentState, setConfig } =
		useBuilderContainer.useContainer();
	const [run, setRun] = React.useState(0);

	React.useEffect(() => {
		if (run === 0) {
			setContentState(content);
			setConfig(editorConfig);
		}

		if (contentState && run > 1) {
			if (action) {
				action(contentState);
			}
		}
		setRun(run + 1);
	}, [contentState]);

	return (
		<Box>
			<GlobalActions />
			<MainCategoryList content={contentState} />
			<Editor />
			<CreateElement />
			<ConfirmDelete />
		</Box>
	);
};

export default BuilderStoreContainer(Builder);

Builder.defaultProps = {
	content: [],
};

Builder.propTypes = {
	content: PropTypes.arrayOf(Object),
};
