import FirestoreService from "services/firestoreServices";
import Helpers from "utils/Helpers";
import { where, orderBy } from "firebase/firestore";

/**
 * Creates an instance of the Upload class.
 * @constructor
 * @param {string} params.contentType - Type de contenu
 * @param {date} params.createdAt - Date de création
 * @param {string} params.folder - Dossier
 * @param {string} params.modified_at - Date de modification
 * @param {string} params.name - Nom du fichier
 * @param {string} params.place - Lieu
 * @param {string} params.thumbnail - Url de la miniature
 * @param {string} params.uid - Uid de l'utilisateur
 * @param {string} params.url - Url taille original
 */

class Upload {
    constructor(params) {
        Object.assign(this, params);
        this.modified_at = new Date();

        this.firestoreService = new FirestoreService("uploads");
    }

    async getUploads(clause, onUpdate) {
        try {
            const uploads = await this.firestoreService.getDocuments(
                clause,
                true,
                onUpdate,
            );
            return uploads;
        } catch (error) {
            console.error("Failed to fetch uploads:", error);
            throw error;
        }
    }

    async getUpload(id) {
        try {
            const upload = await this.firestoreService.getDocument(id);
            return upload;
        } catch (error) {
            console.error("Failed to fetch upload:", error);
            throw error;
        }
    }

    async addUpload() {
        try {
            const upload = await this.firestoreService.addDocument(
                this.toJSON(),
            );
            return { id: upload.id, ...this.toJSON() };
        } catch (error) {
            console.error("Failed to add upload:", error);
            throw error;
        }
    }

    async deleteUpload(id) {
        try {
            await this.firestoreService.deleteDocument(id);
        } catch (error) {
            console.error("Failed to delete upload:", error);
            throw error;
        }
    }

    async updateUpload(id) {
        try {
            const updatedUpload = await this.firestoreService.updateDocument(
                id,
                this.toJSON(),
                [],
            );
            return updatedUpload;
        } catch (error) {
            console.error("Failed to update upload:", error);
            throw error;
        }
    }

    toJSON() {
        let data = {};
        const properties = {
            contentType: this.contentType,
            createdAt: this.createdAt,
            folder: this.folder,
            modified_at: this.modified_at,
            name: this.name,
            place: this.place,
            thumbnail: this.thumbnail,
            uid: this.uid,
            url: this.url,
        };

        // Add only properties that are not undefined
        for (let key in properties) {
            if (properties[key] !== undefined) {
                data[key] = properties[key];
            }
        }

        return data;
    }
}

export default Upload;
