import React from "react";
import { useTranslation } from "react-i18next";
import {
	Box,
	Typography,
	Button,
	Drawer,
	DialogTitle,
	ModalClose,
	List,
	ListItem,
	Divider,
} from "@mui/joy";
import { AttachFile } from "@mui/icons-material";
import PropTypes from "prop-types";
import queryString from "query-string";
import { useLocation, useHistory } from "react-router-dom";
import Document from "models/documents";

const RequestEditModal = () => {
	const { t } = useTranslation();
	const DocumentEntity = new Document();
	const location = useLocation();
	const history = useHistory();
	const { requestModal, userId } = queryString.parse(location.search);
	const [datas, setDatas] = React.useState({});

	const fetchDatas = async () => {
		try {
			const _datas = await DocumentEntity.fetchDocumentsByUid(userId);
			setDatas(_datas);
			console.log(_datas);
		} catch (e) {
			console.log(e);
		}
	};

	React.useEffect(() => {
		if (requestModal && userId) {
			fetchDatas();
		}
	}, [requestModal, userId]);

	return (
		<Box sx={{ display: "flex" }}>
			<Drawer
				anchor="right"
				open={requestModal || userId}
				onClose={() => history.replace(location.pathname)}
			>
				<ModalClose />
				<DialogTitle>{t("request_details")}</DialogTitle>
				<Box sx={{ mt: 2, p: 2 }}>
					<Typography level="h3">{t("Informations")}</Typography>
					<List>
						<ListItem>
							<b>{t("email")}</b> :{" "}
							<a href={`mailto:${datas?.email}`}>
								{datas?.email}
							</a>
						</ListItem>
						<ListItem>
							<b>{t("name")}</b> :
							{datas?.additionalDatas?.lastname}{" "}
							{datas?.additionalDatas?.name}
						</ListItem>
						<ListItem>
							<b>{t("job")}</b> : {t(datas?.additionalDatas?.job)}
						</ListItem>
						<ListItem>
							<b>{t("phone")}</b> :{" "}
							{datas?.additionalDatas?.phone}
						</ListItem>
					</List>

					<Divider sx={{ m: 3 }} />
					<Typography level="h3">{t("Documents")}</Typography>
					<List>
						{datas?.userDocuments &&
							datas?.userDocuments.map((doc) => (
								<ListItem
									sx={{
										border: "1px solid #444",
										borderRadius: "5px",
										p: 2,
										mb: 1,
									}}
								>
									<AttachFile />
									<Typography
										component="a"
										target="_blank"
										href={`${doc?.downloadURL}`}
										sx={{
											whiteSpace: "nowrap",
											overflow: "hidden",
											textOverflow: "ellipsis",
										}}
									>
										{doc?.fileName}
									</Typography>
								</ListItem>
							))}
					</List>
					<Button
						onClick={() => DocumentEntity.approveRequest(userId)}
						fullWidth
					>
						{t("approve_request")}
					</Button>
				</Box>
			</Drawer>
		</Box>
	);
};

export default RequestEditModal;
RequestEditModal.propTypes = {};
RequestEditModal.defaultProps = {};
