import React from "react";
import {
	Card,
	Stack,
	Box,
	Typography,
	IconButton,
	Chip,
	Input,
	Tooltip,
} from "@mui/joy";
import {
	DragIndicator,
	Edit,
	Delete,
	Settings,
	Add,
	KeyboardArrowRight,
	Launch,
} from "@mui/icons-material";
import useBuilderContainer from "../../BuilderStore";
import { Container, Draggable } from "react-smooth-dnd";
import Helpers from "../../../../../utils/Helpers";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";

const MainCategoryItem = ({
	id,
	selectedEl,
	index,
	title,
	draggable,
	children,
	...props
}) => {
	const {
		selected,
		setSelected,
		config,
		deleteElement,
		setConfirmDelete,
		setMainParent,
		parent,
		contentState,
		setContentState,
		setParent,
		handleCreateElement,
		setCreateElementType,
	} = useBuilderContainer.useContainer();
	const { t } = useTranslation();
	const { register, handleSubmit } = useForm({
		defaultValues: { title: title || t("title_here") },
	});
	const [editable, setEditable] = React.useState(false);
	const depthMaxReach =
		config?.limitDepth <= parseInt(Helpers.findDepthById(contentState, id));
	const inputRef = React.useRef(null);

	const save = (d) => {
		const content = [...contentState];
		setContentState(Helpers.deepArrayUpdate(content, d, id));
		setEditable(false);
	};

	const itemConfig = Helpers.getConfig(contentState, id, config);

	React.useEffect(() => {
		if (inputRef.current && (!selected || selected !== id)) {
			inputRef.current.focus();
			inputRef.current.select();
		}
	}, [inputRef, editable]);

	const edit = () => {
		setSelected(id);
		setParent(id);
		setMainParent(id);
	};
	return (
		<Box
			sx={(theme) => ({
				background:
					window.document.activeElement === inputRef.current &&
					theme.vars.palette.primary[100],
				borderColor: selectedEl
					? theme.vars.palette.primary[400]
					: "transparent",
				borderWidth: "2px",
				borderStyle: "solid",
				"&:hover": {
					".openButton": {
						display: "inline-block",
					},
				},
			})}
		>
			<Stack direction="row" justifyContent="space-between">
				<Stack direction="row" alignItems="center" spacing={2}>
					{!editable && title ? (
						<Stack
							direction="row"
							justifyContent="center"
							alignItems="center"
							spacing={2}
							sx={{
								"&:hover": {
									".buttonEdit": {
										display: "inline-block",
									},
								},
							}}
						>
							{" "}
							<Typography
								onClick={edit}
								sx={(theme) => ({
									color:
										selected === id &&
										theme.vars.palette.primary[500],

									fontWeight: selected === id && "bold",

									"&:hover": {
										color: theme.vars.palette.primary[500],
									},
								})}
							>
								{title || ""}
							</Typography>
							<Tooltip title={t("change_title")}>
								<IconButton
									variant="outlined"
									color="primary"
									className="buttonEdit"
									sx={{ display: "block" }}
									onClick={() => setEditable(true)}
								>
									<Edit />
								</IconButton>
							</Tooltip>
						</Stack>
					) : (
						<form onSubmit={handleSubmit(save)}>
							<Input
								slotProps={{
									input: {
										ref: inputRef,
										onBlur: handleSubmit(save),
									},
								}}
								sx={{ minWidth: "200px" }}
								{...register("title", { required: true })}
							/>
							<button
								style={{ display: "none" }}
								type="submit"
							></button>
						</form>
					)}
					{children.length > 0 && (
						<Chip size="sm">
							{children.length}
							{}
						</Chip>
					)}
					<Chip size="sm">{t(itemConfig?.conf.terms.type)}</Chip>
				</Stack>

				<Stack direction="row" spacing={1}>
					<Tooltip title={t("edit")}>
						<IconButton
							sx={{ display: "none" }}
							className="openButton"
							onClick={() => edit(id)}
							color="primary"
							variant={selectedEl ? "outlined" : "outlined"}
						>
							{selectedEl ? <KeyboardArrowRight /> : <Settings />}
						</IconButton>
					</Tooltip>

					<Stack direction="row" spacing={1}>
						{!depthMaxReach && (
							<Tooltip title={t("add_an_item")}>
								<IconButton
									sx={{ display: "none" }}
									className="openButton"
									variant="solid"
									color="primary"
									onClick={() => {
										window.document.activeElement.blur();

										const i = handleCreateElement(id);
									}}
								>
									<Add />
								</IconButton>
							</Tooltip>
						)}
						<Tooltip title={t("remove")}>
							<IconButton
								sx={{ display: "none" }}
								className="openButton"
								onClick={() => setConfirmDelete(id)}
								variant="solid"
								color="danger"
							>
								<Delete />
							</IconButton>
						</Tooltip>
						<IconButton sx={{ visibility: "hidden" }}></IconButton>
					</Stack>
				</Stack>
			</Stack>
		</Box>
	);
};

export default MainCategoryItem;
