import FirestoreService from "services/firestoreServices";
import User from "models/user";
import { doc, getDoc, where } from "firebase/firestore";

/**
 * Creates an instance of the Document class.
 * @constructor
 * @param {Object} params - The constructor parameters.
 */
class Document {
    constructor(params) {
        Object.assign(this, params);
        this.modified_at = new Date();
        this.firestoreService = new FirestoreService("documents");
    }

    async getDocument(uid) {
        try {
            const document = await this.firestoreService.getDocument(uid);
            return document;
        } catch (error) {
            console.error("Failed to fetch document:", error);
            throw error;
        }
    }

    async addDocument() {
        try {
            const document = await this.firestoreService.addDocument(
                this.toJSON(),
            );
            return { id: document.id, ...this.toJSON() };
        } catch (error) {
            console.error("Failed to add document:", error);
            throw error;
        }
    }

    async approveRequest(userId) {
        console.log(userId);
        try {
            // Mise à jour des paramètres de l'utilisateur
            const userEntity = new User({
                isApproved: true,
                role: "partner",
            });
            await userEntity.updateUser(userId);

            await this.firestoreService.deleteDocument(null, [
                where("uid", "==", userId),
            ]);

            return true;
        } catch (error) {
            console.error("Failed to approve request:", error);
            throw error;
        }
    }

    async deleteDocument(id) {
        try {
            await this.firestoreService.deleteDocument(id);
        } catch (error) {
            console.error("Failed to delete document:", error);
            throw error;
        }
    }

    async updateDocument(id) {
        try {
            const updatedDocument = await this.firestoreService.updateDocument(
                id,
                this.toJSON(),
            );
            return updatedDocument;
        } catch (error) {
            console.error("Failed to update document:", error);
            throw error;
        }
    }

    async fetchDocumentsByUid(uid) {
        try {
            const documents = await this.firestoreService.getDocuments([
                where("uid", "==", uid),
            ]);

            const UserEntity = new User();
            const user = await UserEntity.getUser(uid);

            if (documents && user) {
                return {
                    ...user,
                    userDocuments: documents,
                };
            } else {
                throw new Error("cannot fetch user or docs");
            }
        } catch (error) {
            console.error("Failed to fetch documents user:", error);
            throw error;
        }
    }

    fetchUniqueUserDocuments(onUpdate) {
        try {
            this.firestoreService.getDocuments([], true, async (documents) => {
                const userDocuments = {};

                for (const doc of documents) {
                    const uid = doc.uid;

                    if (!userDocuments[uid]) {
                        // Récupérer les informations de l'utilisateur référencé
                        const userRef = doc.user;
                        if (userRef) {
                            const userDoc = await getDoc(userRef);
                            if (userDoc.exists()) {
                                const user = userDoc.data();
                                userDocuments[uid] = {
                                    ...doc,
                                    ...user, // Fusionner les détails de l'utilisateur directement dans le document
                                    userId: userDoc.id, // Si vous avez besoin de l'identifiant de l'utilisateur
                                };
                            }
                        }
                    }
                }

                const result = Object.values(userDocuments);
                onUpdate(result);
            });
        } catch (error) {
            console.error("Failed to fetch unique user documents:", error);
            throw error;
        }
    }

    toJSON() {
        const data = {};
        const properties = {
            modified_at: this.modified_at,
        };

        // Add only properties that are not undefined
        for (let key in properties) {
            if (properties[key] !== undefined) {
                data[key] = properties[key];
            }
        }

        return data;
    }
}

export default Document;
