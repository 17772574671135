import FirestoreService from "services/firestoreServices";
import Helpers from "utils/Helpers";
import { where } from "firebase/firestore";

/**
 * Creates an instance of the Planning class.
 * @constructor
 * @param {string} params.event_id - Event ID of the event .
 * @param {Array} params.content - Planning content .
 * @param {string} params.status - planning status (active / inactive) .
 * @param {string} params.uid - User ID of the planning creator.
 * @param {Date} params.modified_at - Last modification date.
 * @param {Date} params.created_at - Creation date of the event.
 */

class Planning {
    constructor(params) {
        Object.assign(this, params);
        this.modified_at = new Date();

        this.firestoreService = new FirestoreService("plannings");
    }

    async getPlanningByEventId(id, realtime = false, onUpdate = null) {
        try {
            const planning = await this.firestoreService.getDocuments(
                [where("event_id", "==", id)],
                realtime,
                onUpdate,
            );
            if (planning[0]) {
                return planning[0];
            } else {
                return null;
            }
        } catch (error) {
            console.error("Failed to fetch planning:", error);
            throw error;
        }
    }

    async addPlanning() {
        try {
            const planning = await this.firestoreService.addDocument(
                this.toJSON(),
            );
            return { id: planning.id, ...this.toJSON() };
        } catch (error) {
            console.error("Failed to add planning:", error);
            throw error;
        }
    }

    async deletePlanning(id) {
        try {
            await this.firestoreService.deleteDocument(id);
        } catch (error) {
            console.error("Failed to delete planning:", error);
            throw error;
        }
    }

    async updatePlanning(id) {
        try {
            const updatedPlanning = await this.firestoreService.updateDocument(
                id,
                this.toJSON(),
                [],
            );
            return updatedPlanning;
        } catch (error) {
            console.error("Failed to update planning:", error);
            throw error;
        }
    }

    async duplicatePlanning(eventId, newEventId, uid) {
        try {
            // Fetch the original planning using getPlanningByEventId
            const originalPlanning = await this.getPlanningByEventId(eventId);
            if (!originalPlanning) {
                console.warn(
                    `No planning found for event ID ${eventId}. No duplication performed.`,
                );
                return null;
            }

            // Create a new planning object with the new event_id
            const newPlanning = new Planning({
                ...originalPlanning,
                event_id: newEventId,
                uid,
                created_at: new Date(),
                modified_at: new Date(),
            });

            // Save the new planning to Firestore
            const addedPlanning = await newPlanning.addPlanning();
            return addedPlanning;
        } catch (error) {
            console.error("Failed to duplicate planning:", error);
            throw error;
        }
    }

    toJSON() {
        let data = {};
        const properties = {
            event_id: this.event_id,
            content: this.content,
            status: this.status,
            uid: this.uid,
            modified_at: this.modified_at,
            created_at: this.created_at,
        };

        // Add only properties that are not undefined
        for (let key in properties) {
            if (properties[key] !== undefined) {
                data[key] = properties[key];
            }
        }

        return data;
    }
}

export default Planning;
