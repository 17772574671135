import React from "react";
import { useTranslation } from "react-i18next";
import { Avatar, Box, Typography, List, ListItem, Button } from "@mui/joy";
import PropTypes from "prop-types";
import { Add } from "@mui/icons-material";

const View = ({ invoice }) => {
	const { t } = useTranslation();

	return (
		<List>
			{invoice &&
				Object.keys(invoice).map((dat) => (
					<ListItem>
						<Typography>
							<b>{t(dat)}:</b> {invoice?.[dat]}
						</Typography>
					</ListItem>
				))}
		</List>
	);
};

export default View;
View.propTypes = {};
View.defaultProps = {};
