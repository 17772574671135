import React from "react";
import { useTranslation } from "react-i18next";
import { Box, Typography, List, ListItem, Select, Option } from "@mui/joy";
import PropTypes from "prop-types";
import Helpers from "utils/Helpers";
import User from "models/user";

const UserCard = ({ user, setUser }) => {
	const UserEntity = new User();
	const { t } = useTranslation();
	const { additionalDatas } = user;

	const update = (d) => {
		setUser({ ...user, ...d });
		Object.assign(UserEntity, d);
		UserEntity.updateUser(user.id);
	};

	return (
		<>
			<h5>
				{additionalDatas?.name} {additionalDatas?.lastname}
			</h5>
			<List>
				<ListItem>
					<Typography>
						<b> {t("created_at")} </b>
					</Typography>
					<Typography>
						{Helpers.fbDate(user?.created_at, "DD-MM-YYYY")}{" "}
						{t("at")} {Helpers.fbDate(user?.created_at, "HH:MM")}
					</Typography>
				</ListItem>
				<ListItem>
					<Typography>
						<b> ID: </b>
					</Typography>
					<Typography>
						<a
							target="_blank"
							href={`https://console.firebase.google.com/u/0/project/weevuptools/firestore/databases/-default-/data/users/${user.id}?hl=fr`}
						>
							{user.id}
						</a>
					</Typography>
				</ListItem>
				{["email"].map((item) =>
					user[item] ? (
						<ListItem>
							<Typography>
								<b>{t(item)}</b>:
							</Typography>
							<Typography
								sx={{
									width: "100%",
									overflow: "hidden",
									textOverflow: "ellipsis",
								}}
							>
								{t(user[item])}
							</Typography>
						</ListItem>
					) : null,
				)}
				<ListItem>
					<Typography>
						<b>{t("roles")}</b>
					</Typography>
					<Select
						value={user?.role}
						onChange={(e, v) => {
							update({ role: v });
						}}
					>
						{["user", "partner", "admin"].map((role) => (
							<Option value={role}>{t(role)}</Option>
						))}
					</Select>
				</ListItem>
			</List>
		</>
	);
};

export default UserCard;
UserCard.propTypes = {
	user: PropTypes.object,
};
UserCard.defaultProps = {
	user: {},
};
