import React from "react";
import { useTranslation } from "react-i18next";
import {
	Button,
	IconButton,
	Stack,
	Box,
	Typography,
	List,
	ListItem,
} from "@mui/joy";
import {
	Edit,
	setSelectedId,
	Visibility,
	Hotel,
	LocationOn,
	Groups,
	Delete,
	EventSeat,
} from "@mui/icons-material";
import { Link } from "react-router-dom";
import PropTypes from "prop-types";

const PlacesListCard = ({
	name,
	id,
	setSelectedId,
	hideStatus,
	location,
	beds,
	event_background_image,
	editLink,
	showLink,
}) => {
	const { t } = useTranslation();
	const textOverflowStyle = {
		whiteSpace: "nowrap",
		overflow: "hidden",
		textOverflow: "ellipsis",
	};
	return (
		<Box
			sx={{
				width: {
					xs: "100%",
					sm: "46%",
					md: "29%",
					lg: "22%",
					xl: "280px",
				},
				boxShadow: "1px 2px 6px 0px #0000001f",
				borderRadius: "10px 10px 5px 5px",
			}}
		>
			<Box
				sx={{
					background: `url("${event_background_image}")  center center / cover`,
					width: "100%",
					height: "150px",
					borderRadius: "10px 10px 0 0",
				}}
			></Box>
			<Box sx={{ p: 1 }}>
				<Typography
					sx={{
						...textOverflowStyle,
					}}
				>
					<b>{name}</b>
				</Typography>
				<Box>
					<List>
						{location && (
							<ListItem>
								<Typography
									sx={{
										...textOverflowStyle,
									}}
								>
									<LocationOn color={"primary"} />
									{location}
								</Typography>{" "}
							</ListItem>
						)}
						{beds && (
							<ListItem>
								<Typography
									sx={{
										...textOverflowStyle,
									}}
								>
									<Hotel color="primary" />
									{beds}
								</Typography>{" "}
							</ListItem>
						)}
					</List>
				</Box>
			</Box>
			<Stack
				justifyContent="space-between"
				direction="row"
				sx={{ p: 2, borderTop: "1px solid #eee" }}
			>
				<Box></Box>
				<Box>
					<Stack direction="row" spacing={1}>
						<IconButton
							variant="outlined"
							component={Link}
							to={editLink}
						>
							<Edit />
						</IconButton>
						<IconButton
							variant="outlined"
							component={Link}
							color="neutral"
							to={showLink}
						>
							<Visibility />{" "}
						</IconButton>
						<IconButton
							variant="outlined"
							onClick={() => setSelectedId(id)}
							color="neutral"
						>
							<Delete />{" "}
						</IconButton>
					</Stack>
				</Box>
			</Stack>
		</Box>
	);
};

export default PlacesListCard;
PlacesListCard.propTypes = {};
PlacesListCard.defaultProps = {};
