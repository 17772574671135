import React from "react";
import { Switch, Route } from "react-router-dom";
import { Account, BecomePartner } from "./views";
import { BOLayout } from "../../components/layouts";

export const AccountRoutes = (props) => {
	return (
		<BOLayout title="my_account">
			<Switch>
				<Route exact path="/account">
					<Account />
				</Route>
				<Route path="/account/partner">
					<BecomePartner />
				</Route>
			</Switch>
		</BOLayout>
	);
};
