import React from "react";
import { useTranslation } from "react-i18next";
import {
	Box,
	Typography,
	Modal,
	Button,
	Divider,
	ModalOverflow,
	ModalDialog,
	List,
	ListItem,
} from "@mui/joy";
import { DocumentUploader } from "./partials";
import PropTypes from "prop-types";
import useAuthProfile from "containers/authentification/hooks/useAuthProfile";

const requiredDocs = [
	"Presentation",
	"Photos",
	"Offers",
	"Pricing_Grid",
	"Videos",
];

const RequestDocumentForm = ({ open, close, uid }) => {
	const { t } = useTranslation();
	const { logout } = useAuthProfile();

	return (
		<Modal open={open} onClose={close}>
			<ModalOverflow>
				<ModalDialog sx={{ width: "800px" }}>
					<Typography level="h3">
						{t("you_ask_for_partner_yes")}
					</Typography>
					<Typography level="p">
						{t("you_ask_for_partner_description")}
					</Typography>
					<Divider sx={{ m: 2 }} />
					<Box>
						<Typography level="h5">
							<b> {t("required_documents")} :</b>
						</Typography>

						<List>
							{requiredDocs.map((doc, i) => (
								<ListItem key={`document_required_${i}`}>
									- <Typography>{t(doc)}</Typography>
								</ListItem>
							))}
						</List>

						<DocumentUploader uid={uid} />
					</Box>
					<Button onClick={logout}>{t("logout")}</Button>
				</ModalDialog>
			</ModalOverflow>
		</Modal>
	);
};

export default RequestDocumentForm;

RequestDocumentForm.propTypes = {
	open: PropTypes.bool,
	close: PropTypes.func,
	uid: PropTypes.oneOfType([PropTypes.string, null]),
};

RequestDocumentForm.defaultProps = {
	open: false,
	close: () => null,
	uid: null,
};
