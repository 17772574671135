import React from "react";
import { Card, Button } from "@mui/joy";
import useCVContainer from "../../CVStore";

const ThemeItem = ({ id, name }) => {
	const { setTheme, theme: th } = useCVContainer.useContainer();

	return (
		<Card
			onClick={() => setTheme(id)}
			sx={(theme) => ({
				mb: 2,
				"&:hover": {
					opacity: 0.8,
				},
				cursor: "pointer",

				border:
					th == id && `3px dashed ${theme.vars.palette.primary[400]}`,
			})}
		>
			{name}
		</Card>
	);
};

export default ThemeItem;
