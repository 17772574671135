import React from "react";
import { useTranslation } from "react-i18next";
import { Avatar, Box, Typography, List, ListItem, Button } from "@mui/joy";
import PropTypes from "prop-types";
import { Add } from "@mui/icons-material";

const View = ({ contacts, select }) => {
	const { t } = useTranslation();

	return (
		<>
			<List>
				{contacts &&
					contacts.map((contact) => (
						<ListItem
							onClick={() => select(contact)}
							sx={{
								"&:hover": {
									opacity: 0.8,
								},
								cursor: "pointer",
								mb: 1,
								background: "#FAFAFA",
								border: "1px solid #EEE",
							}}
						>
							<Avatar src={contact?.image} />
							<Box sx={{ overflow: "hidden" }}>
								<Typography>
									{contact?.firstName} {contact?.lastName}
								</Typography>
								<Typography>{contact?.phone}</Typography>
								<Typography
									sx={{
										textOverflow: "ellipsis",
										overflow: "hidden",
									}}
								>
									{contact?.mail}
								</Typography>
							</Box>
						</ListItem>
					))}
			</List>
			<Button onClick={() => select(null)} fullWidth>
				<Add /> {t("add_contact")}
			</Button>
		</>
	);
};

export default View;
View.propTypes = {};
View.defaultProps = {};
