import React from "react";
import { BOLayout } from "Components/layouts";
import { WhiteContainer } from "UI";
import { useParams } from "react-router-dom";
import { Grid, Button, Box } from "@mui/joy";
import { useTranslation } from "react-i18next";
import { EditQrCodeForm } from "forms/events";
import Event from "models/events";
import { VisualEditor } from "Common";

const EventQrCode = ({ props }) => {
	const { t } = useTranslation();
	let { eventID } = useParams();

	const [data, setData] = React.useState(
		`${process.env.REACT_APP_base_url}/my_event/${eventID}`,
	);

	const [datas, setDatas] = React.useState(null);
	const EventEntity = new Event();

	const fetchEvent = async () => {
		const event = await EventEntity.getEvent(eventID);
		if (event) {
			setDatas(event);
		}
	};

	React.useEffect(() => {
		fetchEvent();
	}, []);

	return (
		<BOLayout
			documentID={eventID}
			isDocumentStatusMonitoring
			showEventMenu
			title="Qr Code"
		>
			{datas && (
				<Box sx={{ gap: 4, display: "flex", flexDirection: "row" }}>
					<Box sx={{ flexGrow: 1 }}>
						<EditQrCodeForm
							data={data}
							setData={setData}
							eventDatas={datas}
						/>
					</Box>
					<VisualEditor src={data} />
				</Box>
			)}
		</BOLayout>
	);
};

export default EventQrCode;
