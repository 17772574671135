import React from "react";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { Typography, Box, Divider } from "@mui/joy";
import { useLocation } from "react-router-dom";
import {
	LoginFooter,
	ResetPasswordFooter,
	CreateAccountFooter,
} from "./partials";
import { GoogleAuth, FacebookAuth } from "../../components";

const Footer = () => {
	const location = useLocation();
	const path = location.pathname;
	let FooterComponent = null;

	switch (path) {
		case "/login":
			FooterComponent = <LoginFooter />;
			break;
		case "/login/reset-password":
			FooterComponent = <ResetPasswordFooter />;
			break;
		case "/login/create-account":
			FooterComponent = <CreateAccountFooter />;
			break;
	}

	return (
		<>
			<Divider
				sx={{
					margin: "0 auto",
					maxWidth: "490px",
					mt: "32px",
					mb: "32px",
				}}
			/>
			<GoogleAuth />
			<FacebookAuth />

			{FooterComponent}
		</>
	);
};

export default Footer;
